const CreateProductCtrl = ['$scope', '$filter', 'Alert', 'ProductAPI', 'TaxAPI',
    function ($scope, $filter, Alert, ProductAPI, TaxAPI) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        var start_date = new Date();

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.resetForm = function(){
            $scope.product = {};
            $scope.product.price = {
                type : 'FIXD',
                start_date : $filter('date')(start_date, 'yyyy-MM-dd'),
                value : ""
            };
        };

        $scope.hideCreateForm = function() {
            $scope.resetForm();
            $scope.$emit('cancelCreate');
        };

        $scope.saveProduct = function(){
            ProductAPI.createProduct($scope.product).then(function (product) {
                $scope.resetForm();
                $scope.createProductForm.$setPristine();
                $scope.createProductForm.$setUntouched();
                Alert.createAlert('success', $translate('PRODUCTS.PRODUCT_CREATED_SUCCESSFULLY'), true);
                $scope.listReload();
                $scope.$parent.mode = 'list';
            }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
            });
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        TaxAPI.getAllTaxes().then(function (taxes) {
            $scope.taxes = taxes.results;
        }).catch(function (error) {
                $scope.taxes = [{name: 'Error'}];
        });

        $scope.resetForm();
    }
]

export { CreateProductCtrl }
