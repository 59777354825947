const ProviderFactory = ['$resource',
    function ($resource){
        return $resource(API_URL + '/api/1.0/providers/:id/', { id: '@id' },
            {
                'update': { method: 'PUT'}
            }
        );
    }
]

export { ProviderFactory }