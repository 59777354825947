


const UserInfo = ['$http', '$cookies', '$window', function ($http, $cookies, $window) {
    var user = {
        userInfo: {
            username: '',
            first_name: '',
            last_name: '',
            email: '',
            user_permissions: [],
            token: '',
            channels: []
        },

        retrieveInfo: function () {
            $http.get(API_URL + '/api/1.0/users/me/').then(function (response) {
                user.userInfo.username = response.data.username;
                user.userInfo.first_name = response.data.first_name;
                user.userInfo.last_name = response.data.last_name;
                user.userInfo.email = response.data.email;
                user.userInfo.user_permissions = response.data.user_permissions;
                user.userInfo.jwt = response.data.jwt;
            }).catch(function(data){
                $cookies.remove("token")

                if($window.location.pathname != '/login/'){
                    $window.location.href = '/login/'
                }

            });

        },

        hasPerm: function (perm) {
            return user.userInfo.user_permissions.indexOf(perm) != -1;
        }

    };

    return user;
}]

export {UserInfo}
