function dataFactory($http){
    return {
        get: function(url) {
            return $http.get(url).then(function(resp) {
                return resp.data; // success callback returns this
            });
        }
    };
}

export { dataFactory }
