// const contractsAddExcludedProduct = function
function contractsAddExcludedProduct(UserInfo, ExcludedProductApi, Task, filtersUtils, Alert, ProductCodeMaster, $filter){
    return {
        restrict: 'AE',
        scope: {},
        //templateUrl: require('../../static/contracts/templates/add-excluded-product-contract.html'),
        templateUrl: require('../../templates/add-excluded-product-contract.html'),
        controller: function ($scope) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/tasks/';
            var $translate = $filter('translate');
            var type = 'EPFD';

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.listToUpdate = [];
            $scope.update = {};
            $scope.hasPerm = UserInfo.hasPerm('contracts.change_contract');

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/
            function resetForm() {
                $scope.update.denomination = "";
                $scope.selected_product = "";
                $scope.update.count_exclusion = 0;
                $scope.update.max_exclusions = 1;
                $scope.update.reason = '';
                $scope.excludedProduct.$setPristine();
                $scope.excludedProduct.$setUntouched();
            }

            function performRequest(ids) {
                var data = {};
                $scope.update['product'] = $scope.selected_product.code;
                data['values'] = $scope.update;
                data['ids'] = ids;

                var task = new Task();
                task.type = type;
                task.params = data;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('CONTRACTS.BULK_UPDATE_QUEUED'), true);
                    resetForm();
                }).catch(function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.BULK_UPDATE_REJECTED'), true);
                });

                // TODO: Si $scope.update.invoicing_generation_mode == 'UNF' llamar también al endpoint de agregar el producto excluído masivamente!!!!
            }

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/
            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
                $('#addExcludedProductContractsModeModal').modal('toggle');
                resetForm();
            };

            $scope.clickYes = function () {
                var ids = filtersUtils.getItemsToDownload($scope.listToUpdate);
                performRequest(ids);
                $('#addExcludedProductContractsModeModal').modal('toggle');
            };

            /***********************************************************************************************************
             * Initialization
             ***********************************************************************************************************/

            ProductCodeMaster.loadProductCodesNotForce().then(function (response) {
                $scope.products_codes = response;
            });
            if ($scope.update.max_exclusions == null) {
                $scope.update.max_exclusions = 1;
            }
            if ($scope.update.count_exclusion == null) {
                $scope.update.count_exclusion = 0;
            }
        }
    }
}

export {contractsAddExcludedProduct}
