const TaxDetailCtrl = ['$scope', '$routeParams', '$http', '$filter', 'TaxAPI', 'TaxRuleAPI', 'UserInfo', 'Alert',
    function ($scope, $routeParams, $http, $filter, TaxAPI, TaxRuleAPI, UserInfo, Alert) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.id = $routeParams.code;
        $scope.showCreateTaxRuleBool = false;
        $scope.tax_rule = {};

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.getTax = function () {
            $scope.loading_detail = true;

            TaxAPI.getTaxDetail($scope.id)
                .then(function (tax) {
                    $scope.tax = tax;
                    $scope.loading_detail = false;
                }).catch(function (error) {
                    $scope.error_message = error.data.__all__[0];
                });
        };

        $scope.updateTax = function () {
            TaxAPI.updateTax($scope.id, $scope.tax).then(function () {
                Alert.createAlert('success', $translate('TAXES.TAX_UPDATED'), true);
                }).catch(function (error) {
                    $scope.error_message = error.data.__all__[0];
                }
            );
        };

        $scope.showCreateTaxRule = function () {
            $scope.showCreateTaxRuleBool ? $scope.showCreateTaxRuleBool = false : $scope.showCreateTaxRuleBool = true;
            $scope.tax_rule = {};
            $scope.createTaxRuleForm.$setPristine();
            $scope.createTaxRuleForm.$setUntouched();
        };

        $scope.createTaxRule = function () {
            var tax_rule = $scope.tax_rule;
            tax_rule.tax = $scope.id;

            TaxRuleAPI.createTaxRule($scope.id, $scope.tax_rule).then(function (tax_rule) {
                Alert.createAlert('success', $translate('TAXES.TAXRULE_CREATED'), true);
                // Reset values
                $scope.tax_rule = {};
                $scope.listReload();
                $scope.showCreateTaxRuleBool = false;
                $scope.createTaxRuleForm.$setPristine();
                $scope.createTaxRuleForm.$setUntouched();

            }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
                //Alert.createAlert('danger', $translate('TAXES.TAX_CREATION_ERROR'), true);
            });
        };

        $scope.canUpdateTax = function () {
            return UserInfo.hasPerm('taxes.update_taxes');
        };

        $scope.canCreateTaxRule = function () {
            return UserInfo.hasPerm('taxes.add_taxrule');
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        $scope.getTax();


    }
]

export { TaxDetailCtrl }
