const ItemToBeInvoicedAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var contracts_url = API_URL + '/api/1.0/contracts/{0}/items';

        function listItemToBeInvoicedForContract(contract, data) {
            var deferred = $q.defer();

            $http({
                url: contracts_url.format(contract),
                method: "GET",
                params: data
            })
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        return {
            listItemToBeInvoicedForContract: listItemToBeInvoicedForContract
        };
    }
]

export { ItemToBeInvoicedAPI };
