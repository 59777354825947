{
  "FILES": {
    "ATTACHED_FILES": "Archivos ajduntos",
    "NO_ATTACHED_FILES": "No hay archivos adjuntos",
    "CONFIRM_DELETE": "¿Quieres eliminarlo?",
    "YES": "Si",
    "NO": "No",
    "UPLOAD_FILES": "Subir archivos",
    "SHOW_ATTACHED_FILES": "Ver archivos adjuntos",
    "CLICK_HERE": "Haz click o arrastra aquí los archivos que quieras subir",
    "MAX_ITEMS_UPLOAD": "No se pueden subir más archivos.",
    "NUM_MAX_ITEMS_UPLOAD": "Nº máximo de archivos a adjuntar: ",
    "CONFIRM_SHARE": "¿Quieres compartir este archivo?",
    "SHARE_FILE": "Compartir"
  }
}
