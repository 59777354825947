const CreateProviderInvoiceCtrl = ['$scope', '$filter', '$location', 'ProviderInvoiceAPI', 'Alert', 'INVOICES_ORIGINS_TYPES', 'ProvidersMaster',
    function ($scope, $filter, $location, ProviderInvoiceAPI, Alert, INVOICES_ORIGINS_TYPES, ProvidersMaster) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.invoices_origins = INVOICES_ORIGINS_TYPES;
        $scope.saving = false;
        $scope.provider_invoice = {};

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.createProviderInvoice = function() {
            $scope.saving = true;

            ProviderInvoiceAPI.createProviderInvoice($scope.provider_invoice).then(function (provider_invoice) {
                $location.path('/providers/invoices/' + provider_invoice.id);
            }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
            }).finally(function () {
                $scope.saving = false;
            });
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        ProvidersMaster.loadProviders().then(function(response){
            $scope.providers = response;
        });

    }
]

export { CreateProviderInvoiceCtrl }
