const CustomerAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/customers/';

        var self = this;
        var customer = null;

        function getCustomerDetail(code) {
            if (customer == null || customer.code != code) {
                customer = {
                    'code': code
                };
                return _getCustomerDetail(code);
            } else {
                var deferred = $q.defer();
                deferred.resolve(customer);

                return deferred.promise;
            }
        }

        function _getCustomerDetail(code) {
            var deferred = $q.defer();

            $http.get(url + code)
                .then(function (response) {
                    deferred.resolve(response);
                }).catch(function (error) {
                    deferred.reject(error);
                });

            self.code = code;
            return deferred.promise;
        }

        return {
            getCustomerDetail: getCustomerDetail
        };
    }
]

export { CustomerAPI };