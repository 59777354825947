const ContractExcludedProductsDetail = 
        ['$scope', '$filter', '$routeParams', 'DateUtils', '$http', 'UserInfo', 'ExcludedProductApi', 'Alert',
            function ($scope, $filter, $routeParams, DateUtils, $http, UserInfo, ExcludedProductApi, Alert) {
                /***********************************************************************************************************
                 * Controller Attributes
                 ***********************************************************************************************************/
                var $translate = $filter('translate');

                /***********************************************************************************************************
                 * Scope Attributes
                 ***********************************************************************************************************/

                $scope.action = 'detail';
                $scope.errors = {};

                /***********************************************************************************************************
                 * Scope Watchers
                 ***********************************************************************************************************/


                /***********************************************************************************************************
                 * Controller Methods
                 ***********************************************************************************************************/


                /***********************************************************************************************************
                 * Scope Methods
                 ***********************************************************************************************************/

                $scope.deleteExcludedProductMode = function ($event) {
                    $event.preventDefault();
                    $scope.action = 'delete';
                };

                $scope.editExcludedProductMode = function ($event) {
                    $event.preventDefault();
                    $scope.action = 'edit';
                };

                $scope.cancelExcludedProduct = function ($event) {
                    $event.preventDefault();
                    $scope.action = 'detail';
                };

                $scope.canDeleteExcludedProduct = function () {
                    return UserInfo.hasPerm("products.delete_excludedproduct");
                };

                $scope.canEditExcludedProduct = function () {
                    return UserInfo.hasPerm("products.change_excludedproduct");
                };


                $scope.deleteExcludedProduct = function (id) {
                    ExcludedProductApi.deleteExcludedProduct(id).then(function (contract) {
                        $scope.listReload();
                        Alert.createAlert('success', $translate('CONTRACTS.EXCLUDED_PRODUCT_DELETED'), true);
                    }).catch(function (error) {

                    });
                };

                $scope.updateExcludedProduct = function (id) {
                    $scope.errors = {};
                    var data = {
                        'denomination': $scope.denomination,
                        'product': $scope.result.product,
                        'count_exclusion': $scope.count_exclusion,
                        'max_exclusions': $scope.max_exclusions
                    };

                    ExcludedProductApi.setExcludedProductDetailPartial(id, data).then(function (contract) {
                        $scope.listReload();
                        Alert.createAlert('success', $translate('CONTRACTS.EXCLUDED_PRODUCT_UPDATED'), true);
                    }).catch(function (error) {
                        $scope.errors = error;
                    });

                };

                $scope.change_agreement_manages = function (value) {
                    if ($scope.action == 'edit') {
                        $scope.result.agreement_manages = value;
                    }
                };

                /***********************************************************************************************************
                 * Controller Initialization
                 ***********************************************************************************************************/
                if ($scope.result.denomination != null) {
                    $scope.denomination = $scope.result.denomination;
                }

                if ($scope.result.contract != null) {
                    $scope.contract = $scope.result.contract;
                }
                if ($scope.result.count_exclusion != null) {
                    $scope.count_exclusion = $scope.result.count_exclusion;
                }
                if ($scope.result.max_exclusions != null) {
                    $scope.max_exclusions = $scope.result.max_exclusions;
                }
                if ($scope.result.product != null) {
                    $scope.product = $scope.result.product;
                }

            }]

export {ContractExcludedProductsDetail}
