// Controllers
import { ActionsComplaintCtrl } from './controllers/ActionsComplaintCtrl'
import { ComplaintATRCtrl } from './controllers/ComplaintATRCtrl'
import { ComplaintDetailCtrl } from './controllers/ComplaintDetailCtrl'
import { ComplaintListCtrl } from './controllers/ComplaintListCtrl'
import { ComplaintResultController } from './controllers/ComplaintResultController'
import { CreateComplaintCtrl } from './controllers/CreateComplaintCtrl'

// Directives
import { complaintStatus } from './directives/complaintStatus'

// Filters
import { subtypeName } from './filters/subtypeName'

// Services
import { ComplaintAPI } from './services/ComplaintAPI'

const complaints = angular.module('complaints', ['pascalprecht.translate', ]);

complaints.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

complaints.constant("AGENT_CODES", {
    1: 'Titular',
    2: 'Representante legal',
    3: 'Aseguradora',
    4: 'Organismos publicos',
    6: 'Comercializador',
    7: 'Juzgados',
    8: 'Afectado no titular',
    9: 'Asociacion de consumidores',
    10: 'Distribuidor',
    11: 'Junta Arbitral de Consumo'
});

complaints.constant("CHANNEL_CODES", {
    1: 'Telefono',
    2: 'Registro oficial',
    3: 'Presencial',
    4: 'Web',
    5: 'Ficheros intercambio agentes',
    6: 'Correo electronico/buzones agentes'
});

complaints.constant("SUBTYPE_CODES", {
    1: 'Atención incorrecta al consumidor',
    2: 'Privacidad de los datos',
    3: 'Incidencia en el equipo de medida',
    4: 'Daños originados en el equipo de medida',
    5: 'Contador en factura no corresponde con instalado',
    6: 'Contratos ATR que no se facturan',
    7: 'Consumidor (CUPS) no pertenece a comercializadora no vigente en periodo de factura',
    8: 'Disconformidad con conceptos facturados por el distribuidor',
    9: 'Disconformidad con lectura facturada',
    10: 'Disconformidad en factura anomalía/fraude',
    11: 'Reclamación factura pago duplicado',
    12: 'Refacturación no recibida',
    13: 'Disconformidad con cambio de comercializador',
    14: 'Requerimiento de fianza/deposito de garantía',
    15: 'Retraso corte de suministro por impago',
    18: 'Conexiones: Disconformidad con criterios económicos/cobros',
    19: 'Conexiones: Disconformidad con criterios técnicos/obra ejecutada',
    20: 'Calidad de onda',
    21: 'Cortes o averías con petición de indemnización por daños',
    22: 'Cortes o averías sin petición de indemnización',
    23: 'Retraso en pagos indemnización',
    24: 'Daños a terceros por instalaciones',
    25: 'Impacto ambiental instalaciones',
    26: 'Reclamaciones sobre instalaciones de distribución',
    27: 'Disconformidad indemnizaciones por calidad de suministro',
    28: 'Ejecución indebida del corte',
    29: 'Retraso en la atención a las reclamaciones',
    30: 'Conexiones: Retraso plazo de contestación nuevos suministros',
    31: 'Conexiones: Retraso alta de un nuevo suministro',
    32: 'Retraso reenganche por un corte por impago',
    33: 'Daños producido en una intervención de urgencia',
    34: 'Disconformidad con conceptos de contratación de ATR-Peaje',
    35: 'Conexiones: Disconformidad rechazo solicitud ATR-Peaje',
    37: 'Fichero XML incorrecto',
    38: 'Privacidad de los datos',
    39: 'Solicitud de Certificado / Informe de Calidad',
    40: 'Solicitud de Duplicados de Factura',
    41: 'Solicitud de actuación sobre Instalaciones',
    42: 'Solicitud de Descargo',
    43: 'Petición de Precintado / Desprecintado de equipos',
    44: 'Peticiones con origen en campañas de telegestión',
    45: 'Actualización dirección punto de suministro',
    46: 'Certificado de Lectura',
    47: 'Solicitud recalculo CCH sin modificación cierre ATR',
    48: 'Petición de información adicional sobre un rechazo en una solicitud o tras actuaciones en campo de ATR/ Peaje',
    49: 'Falta fichero medida',
    50: 'Desacuerdo facturación inspección periódica',
    51: 'Conducta inadecuada personal inspector',
    //52: 'Disconformidad con la ejecucion de la inspeccion periodica',
    53: 'Incumplimiento hora inspección periódica',
    54: 'Daños inspección periódica',
    55: 'Disconformidad importe facturado autoconsumo',
    56: 'Petición de desglose del importe facturado como cargo fijo y/o cargo variable autoconsumo',
    57: 'Disconformidad con expediente de anomalía y fraude',
    58: 'Retraso en plazo aceptación cambio de comercializador',
    59: 'Retraso en plazo activación cambio de comercializador',
    60: 'Retraso en plazo aceptación modificación contractual',
    61: 'Retraso en plazo activación modificación contractual',
    62: 'Retraso en plazo aceptación alta de un nuevo suministro',
    63: 'Retraso en plazo activación alta de un nuevo suministro',
    64: 'Retraso en plazo aceptación de una baja de un suministro',
    65: 'Retraso en plazo activación baja de un suministro',
    66: 'Información/Validación sobre datos del contrato ATR/Peaje',
    67: 'Verificación de Contador',
    68: 'Reclamación por aplicación del factor de conversion o el PCS',
    69: 'Copia F1 en PDF',
    70: 'Retraso en la atención a reclamaciones no sujetas a atención reglamentaria',
    71: 'Retraso en plazo de aceptación de desistimiento',
    72: 'Retraso en plazo de activación de desistimiento',
    73: 'Parámetros de comunicación',
    74: 'Retraso en plazo de aceptación de anulación',
    100: 'Incidencias contratacion bono social',
    101: 'Datos bancarios/forma de pago erronea',
    102: 'Errores en cobros/abonos',
    103: 'Disconformidad precios facturados o repercutidos por la comercializadora',
    104: 'Disconformidad fraccionamiento o gastos especiales cobrados',
    105: 'Disconformidad con el recobro',
    106: 'Disconformidad con la penalizacion por pronta resolucion',
    107: 'Insuficiente informacion en el momento de la contratacion',
    108: 'Reclamacion respecto al derecho de desistimiento',
    109: 'Disconformidad con la facturación de otros servicios diferentes al suministro',
    110: 'Falta de claridad en las facturas',
    111: 'Falta de claridad en las condiciones contractuales',
    112: 'Dificultad en la contratacion de la TUR/PVPC con el CUR/COR',
    113: 'Reclamaciones por practicas comerciales incorrectas',
    114: 'Retraso en facturacion comercializador'
});

complaints.constant("LEGACY_SUBTYPES", [67])

complaints.constant("RATE_CODES", {
    1: '2.0 A',
    3: '3.0 A',
    4: '2.0 DHA',
    5: '2.1 A',
    6: '2.1 DHA',
    7: '2.0 DHS',
    8: '2.1 DHS',
    11: '3.1 A',
    12: '6.1 A',
    13: '6.2',
    14: '6.3',
    15: '6.4',
    17: '6.1 B',
    18: '2.0 TD',
    19: '3.0 TD',
    20: '6.1 TD',
    21: '6.2 TD',
    22: '6.3 TD',
    23: '6.4 TD',
    24: '3.0 TDVE',
    25: '6.1 TDVE'
});

complaints.constant("COMPLAINT_STATUSES", {
    'CRE': "Abierta",
    'CLO': "Cerrado"
});

complaints.constant("COMPLAINT_RESULTS", {
    1: "Favorable",
    2: "Desfavorable",
    3: "No Gestionable",
    4: "Anulada"
});

complaints.constant("WATIUM_DEPARTMENTS", {
    1: 'Atención al cliente',
    2: 'Tramitaciones',
    3: 'Comercial',
    4: 'Cobros',
    5: 'Facturación',
    6: 'Operaciones',
    7: 'Distribución',
    8: 'Incidencias',
    9: 'Jurídico'
});

complaints.constant("ATR_TYPES", {
    "01": 'ATENCIÓN PERSONAL',
    "02": 'FACTURACIÓN Y MEDIDA',
    "03": 'CONTRATACIÓN',
    "04": 'GESTIÓN DE ACOMETIDAS',
    "05": 'CALIDAD DE SUMINISTRO',
    "06": 'SITUACIÓN DE INSTALACIONES',
    "07": 'ATENCION REGLAMENTARIA'
});

complaints
    .controller('ActionsComplaintCtrl', ActionsComplaintCtrl)
    .controller('ComplaintATRCtrl', ComplaintATRCtrl)
    .controller('ComplaintDetailCtrl', ComplaintDetailCtrl)
    .controller('ComplaintListCtrl', ComplaintListCtrl)
    .controller('ComplaintResultController', ComplaintResultController)
    .controller('CreateComplaintCtrl', CreateComplaintCtrl)
    .directive('complaintStatus', complaintStatus)
    .filter('subtypeName', subtypeName)
    .service('ComplaintAPI', ComplaintAPI)

export { complaints }
