function unlinkProgrammingUnitToSupplies(UserInfo, Alert, filtersUtils) {
    return {
        restrict: 'AE',
        templateUrl: require('../../templates/unlink_programming_units.html'),
        controller: function ($scope, $filter, $http, $rootScope, ProgrammingUnitsMaster, ProgrammingUnitAPI) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagUnlinkConfirm = false;
            $scope.listToUpdate = [];

            $scope.hasPerm = UserInfo.hasPerm('programming_units.set_programming_unit_to_supplies');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var json = {ids: ids, unlink: true};

                ProgrammingUnitAPI.setProgrammingUnitToSupplies(json)
                    .then(function (data) {
                        Alert.createAlert('success', $translate('PROGRAMMING_UNITS.SET_PROGRAMMING_UNIT_SUCCESSFULLY') + data.updated_programming_unit, true);
                        $rootScope.$broadcast('refreshList');
                    }).catch(function (error) {
                        $scope.error_message = error.data.__all__[0];
                    }).finally(function () {
                        $scope.flagUnlinkConfirm = false;
                        $scope.listToUpdate = [];
                    });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.clickUnlinkNo = function () {
                $scope.flagUnlinkConfirm = false;
            };

            $scope.clickUnlinkYes = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToUpdate);
                performRequest(ids);
            };

            $scope.confirmSetUnlink = function () {
                $scope.flagUnlinkConfirm = true;
            };

            /***********************************************************************************************************
            * Controller Initialization
            ***********************************************************************************************************/
            ProgrammingUnitsMaster.loadProgrammingUnits().then(function (response){
                $scope.programming_units = response;
            });

        }
    }
}

export {unlinkProgrammingUnitToSupplies};