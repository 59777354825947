import { AgreementsLoading, AgreementsMaster } from "./services/AgreementsMaster";
import { atrComplaintService } from "./services/ATRSubtypesComplaintsMaster";
import { BanksLoading, BanksMaster } from "./services/BanksMaster";
import { BrandsLoading, BrandsMaster } from "./services/BrandsMaster";
import { CalendarsLoading, CalendarsMaster } from "./services/CalendarsMaster";
import { CitiesLoading, CitiesMaster} from "./services/CitiesMaster"
import { CnaesLoading, CnaesMaster } from "./services/CnaesMaster";
import { CompaniesLoading, CompaniesMaster } from "./services/CompaniesMaster";
import { ComplaintsLoading, ComplaintsMaster } from "./services/ComplaintsMaster";
import { eventInfoDownloadService } from "./services/EventDownloadsMaster";
import { eventInfoService } from "./services/EventStatusMaster";
import { ProductRatesLoading, ProductRatesMaster } from "./services/ProductRatesMaster";
import { ProductCodeMaster, ProductCodesLoading } from "./services/ProductCodesMaster";
import { ProgrammingUnitsLoading, ProgrammingUnitsMaster } from "./services/ProgrammingUnitsMaster";
import { ProvidersLoading, ProvidersMaster } from "./services/ProvidersMaster";
import { StatesCodeMaster, StatesCodesLoading } from "./services/StatesCodesMaster";
import { SuppliesTypesLoading, SuppliesTypesMaster } from "./services/SuppliesTypesMaster";
import { SuppliesRatesLoading, SupplyRatesMaster } from "./services/SupplyRatesMaster";
import { StatesMaster, StatesLoading } from "./services/StatesMaster";
import { ProductServiceCodeMaster, ProductServiceCodesLoading } from "./services/ProductServicesCodesMaster"


const masters = angular.module('masters', []);

masters
    .factory('AgreementsLoading', AgreementsLoading)
    .factory('atrComplaintService', atrComplaintService)
    .factory('BanksLoading', BanksLoading)
    .factory('BrandsLoading', BrandsLoading)
    .factory('CalendarsLoading', CalendarsLoading)
    .service('CalendarsMaster', CalendarsMaster)    
    .service('AgreementsMaster', AgreementsMaster)
    .service('BanksMaster', BanksMaster)
    .service('BrandsMaster', BrandsMaster)
    .factory('CitiesLoading', CitiesLoading)
    .service('CitiesMaster', CitiesMaster)
    .factory('CnaesLoading', CnaesLoading)
    .service('CnaesMaster', CnaesMaster)
    .factory('CompaniesLoading', CompaniesLoading)
    .service('CompaniesMaster', CompaniesMaster)
    .factory('ComplaintsLoading', ComplaintsLoading)
    .service('ComplaintsMaster', ComplaintsMaster)
    .factory('eventInfoDownloadService', eventInfoDownloadService)
    .factory('eventInfoService', eventInfoService)
    .factory('ProductCodesLoading', ProductCodesLoading)
    .service('ProductCodeMaster', ProductCodeMaster)
    .factory('ProgrammingUnitsLoading', ProgrammingUnitsLoading)
    .service('ProgrammingUnitsMaster', ProgrammingUnitsMaster)   
    .factory('ProvidersLoading', ProvidersLoading)
    .service('ProvidersMaster', ProvidersMaster)
    .factory('StatesCodesLoading', StatesCodesLoading)
    .service('StatesCodeMaster', StatesCodeMaster)
    .factory('SuppliesTypesLoading', SuppliesTypesLoading)
    .service('SuppliesTypesMaster', SuppliesTypesMaster)
    .factory('SuppliesRatesLoading', SuppliesRatesLoading)
    .service('SupplyRatesMaster', SupplyRatesMaster)
    .factory('ProductRatesLoading', ProductRatesLoading)
    .service('ProductRatesMaster', ProductRatesMaster)
    .factory('StatesLoading', StatesLoading)
    .service('StatesMaster', StatesMaster)
    .factory('ProductServiceCodesLoading', ProductServiceCodesLoading)
    .service('ProductServiceCodeMaster', ProductServiceCodeMaster)

export {masters}
