import angular from "angular";
import ngRoute from "angular-route";
import ngCookie from "angular-cookies";

import { MetaResources } from './services/api.js'
import { queuesList } from "./directives/queues-list.js";

const meta =  angular.module('meta', ['pascalprecht.translate', 'ngResource', 'powr']);

meta.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

meta
    .service('MetaResources', MetaResources)
    .directive('queuesList', queuesList)

export { meta }
