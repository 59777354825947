const NewRemittanceCtrl = ['$scope', '$http', '$filter', '$rootScope', 'Alert',
                           'Bank', 'UserInfo', 'filtersUtils', 'INVOICES_TYPES',
                           'AgreementsMaster', 'Task', 'notificationCenter','InvoiceSeriesAPI', 'BrandsList',
    function ($scope, $http, $filter, $rootScope, Alert,
                Bank, UserInfo, filtersUtils, INVOICES_TYPES, AgreementsMaster, Task, notificationCenter, InvoiceSeriesAPI, BrandsList) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.performingRemittance = false;
        $scope.dates = {};
        $scope.dates.date = new Date();
        $scope.dates.creationDate = new Date();
        $scope.accumulated_credit = 0;
        $scope.invoices_types = INVOICES_TYPES;
        $scope.brands = [];

        $scope.type = [
            {'label': $translate('BANKS.NORMAL'), 'value': 'DBT'},
            {'label': $translate('BANKS.B2B_REMITTANCE'), 'value': 'B2B'},
            {'label': $translate('BANKS.REFUND_REMIT'), 'value': 'RBT'},
            {'label': $translate('BANKS.REFUND_B2B_REMIT'), 'value': 'R2B'},
            {'label': $translate('BANKS.NOT_DEBITED'), 'value': 'TRF'}
        ];

        $scope.searchFilters = {
            'invoice_id': $translate('INVOICES.NO_INVOICE'),
            'contract_id': $translate('INVOICES.NO_CONTRACT'),
            'vat_id': $translate('INVOICES.NIF/CIF'),
            'cups': $translate('CONTRACTS.SUPPLY_CUPS'),
            'search': $translate('INVOICES.ALL')
        };

        $scope.statuses = [
            {code: 'UNP', label: $translate('INVOICES.UNPAID')},
            {code: 'INV', label: $translate('INVOICES.INVOICED')}
        ];

        $scope.debit_method = [
            {code: 'DBT', label: $translate('INVOICES.DEBIT')},
            {code: 'B2B', label: $translate('INVOICES.DEBIT_B2B')},
        ]

        $scope.first_values = [
            {code: 'FIRST', label: $translate('BANKS.FIRST')},
            {code: 'RCUR', label: $translate('BANKS.RCUR')}
        ];

        $scope.debt_values = [
            {code: 'SHOW', label: $translate('INVOICES.SHOW_DEBT_INVOICES')},
            {code: 'HIDE', label: $translate('INVOICES.HIDE_DEBT_INVOICES')}
        ];

        $scope.reconciled_status_mode = [
            {code: '0', label: $translate('INVOICES.NO')},
            {code: '1', label: $translate('INVOICES.YES')}
        ];

        $scope.is_foreign_account_values = [
            {code: '0', label: $translate('INVOICES.NO')},
            {code: '1', label: $translate('INVOICES.YES')}
        ];

        $scope.filters = {
            search: "",
            total_min: "",
            total_max: "",
            invoice_id: "",
            contract_id: "",
            status: "",
            agreement: null,
            state: null,
            vat_id: "",
            is_remittable: true,
            is_not_debited: false,
            entity: "",
            handmade: false,
            is_first: "",
            is_debt: "",
            debit_method: "",
            reconciled: '1',
            brand: ''
        };

        $scope.searchBy = Object.keys($scope.searchFilters)[0];
        $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];

        $scope.ordering = {
            "idn": $translate('INVOICES.NO_INVOICE'),
            "contract_unsigned": $translate('INVOICES.CONTRACT'),
            "creation_date": $translate('INVOICES.DATE'),
            "total": $translate('INVOICES.TOTAL_AMOUNT'),
            "customer_name": $translate('INVOICES.CUSTOMER_NAME'),
            "start_date": $translate('INVOICES.BILLING_PERIOD')
        };

        $scope.date_filters = {
            product_date_range: null,
            date_range_from: null,
            date_range_to: null,
            from_date: null,
            to_date: null
        };

        $scope.filters_by_date = [
            {value: "creation", description: $translate('INVOICES.Creation date range')},
            {value: "invoiced", description: $translate('INVOICES.Billing period date range')},
            {value: "remit", description: $translate('INVOICES.REMIT_DATE')},
            {value: "unpaid", description: $translate('INVOICES.UNPAID_DATE')}
        ];

        InvoiceSeriesAPI.listInvoiceSeries().then(function(data){
            $scope.invoices_series = data.results
        });

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/

        $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
            setGlobalSum();
        });

        $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
            $scope.counter = selectedCounterChange;
        });

        $scope.$watchCollection('date_filters', function (newVal, oldVal) {
            if (oldVal.from_date !== null) {
                $scope.filters["from_date"] = null;
            }
            if (oldVal.to_date !== null) {
                $scope.filters["to_date"] = null;
            }
            if (newVal.from_date !== null) {
                var from_date = newVal.from_date;
                $scope.filters["from_date"] = from_date;
            }
            if (newVal.to_date !== null) {
                var to_date = newVal.to_date;
                $scope.filters["to_date"] = to_date;
            }
            if (oldVal.product_date_range !== null) {
                var sel_option = oldVal.product_date_range.value;
                if (oldVal.date_range_from !== null) {
                    $scope.filters[sel_option + "_date_from"] = null;
                }
                if (oldVal.date_range_to !== null) {
                    $scope.filters[sel_option + "_date_to"] = null;
                }
            }
            if (newVal.product_date_range !== null) {
                var sel_option = newVal.product_date_range.value;
                if (newVal.date_range_from !== null) {
                    var from_date = newVal.date_range_from;
                    $scope.filters[sel_option + "_date_from"] = from_date;
                }
                if (newVal.date_range_to !== null) {
                    var to_date = newVal.date_range_to;
                    $scope.filters[sel_option + "_date_to"] = to_date;
                }
            }
        });

        $scope.$watch('searchQuery', function (newVal, oldVal) {
            $scope.filters[$scope.searchBy] = newVal;
        });

        $scope.$watch('entity_filter', function (newVal, oldVal) {
            if (typeof newVal == "string") {
                if ((newVal.length == 4) && (!isNaN(newVal))) {
                    $scope.filters['entity'] = newVal;
                } else {
                    delete $scope.filters['entity'];
                }
            }
        });

        $scope.$watch('is_refund', function(newVal, oldVal) {
            if(newVal === 'TRF') {
                $scope.filters.debit_method = "DBT";
                $scope.filters.is_remittable = false;
                $scope.filters.is_not_debited = true;
            } else if (newVal === 'B2B' || newVal === 'R2B') {
                $scope.filters.debit_method = 'B2B';
                $scope.filters.is_remittable = true;
                $scope.filters.is_not_debited = false;
            } else {
                $scope.filters.debit_method = "DBT";
                $scope.filters.is_remittable = true;
                $scope.filters.is_not_debited = false;
            }
        });

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        function setGlobalSum() {
            var ids = filtersUtils.getItemsToDownload($scope.itemsToDownload);
            if (ids.length < 1) {
                $scope.accumulated_credit = 0;
            } else {
                var data = {
                    ids:ids
                };
                $http.post(API_URL + '/api/1.0/invoices/get_total/', data).then(function (data, status, headers, config) {
                    if (data.data.total == null) {
                        data.data.total = 0;
                    }
                    $scope.accumulated_credit = data.data.total;
                });
            }
        }

        function performRemittance(ids, bank, creationDate, date, is_refund) {
            $scope.performingRemittance = true;

            var task = new Task();


            if($scope.is_refund === 'TRF') {
                task.type = 'GRND';
            }else{
                task.type = 'GREM';
            }
            task.params = {
                "ids": ids,
                "bank": bank,
                is_refund: is_refund,
                "creation_date": creationDate,
                "date": date
            };
            task.start().then(function (data, status, headers, config) {
                Alert.createAlert('success', $translate('BANKS.CREATING_REMITTANCE'), true);
            }).catch(function (data, status, headers, config) {
                Alert.createAlert('danger', data.data, true);
            });
        }

        notificationCenter.subscribe('BG_TASK_REMITTANCE_FINISHED', function (type, data) {
            $scope.performingRemittance = false;
        });

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        // Get credit
        $scope.updateBankCredit = function () {
            $http.get(API_URL + '/api/1.0/banks/credit/' + $scope.bank.id + '/', {params: {date: $scope.dates.date}}).then(function (data, status, headers, config) {
                $scope.bank.credit_used = $scope.bank.credit_limit - data.data['credit'];
            }).catch(function (data, status, headers, config) {
            });
        };

        $scope.generateRemittance = function () {
            var ids = filtersUtils.getItemsToDownload($scope.itemsToDownload);
            performRemittance(ids.toString().split(',').map(function (i) {
                return parseInt(i, 10);
            }), $scope.bank.id, $scope.dates.creationDate, $scope.dates.date, $scope.is_refund);
        };
        $scope.setReceiptsAmount = function () {
            $scope.filters.max_amount = $scope.max_amount;
            $scope.filters.retrieveAll = 'id';
            $scope.$broadcast('unselectAll');
            $('#modal_amount').modal('hide');
        };
        $scope.setSearchBy = function (val) {
            $scope.filters[$scope.searchBy] = '';
            $scope.searchBy = val;
            $scope.searchByLabel = $scope.searchFilters[val];
            $scope.filters[val] = $scope.searchQuery;
        };

        $scope.getStyles = function (result) {
            var styles = '';
            if (result.status == 'INV')
                styles += ' ' + 'invoiced';
            if (result.status == 'UNP')
                styles += ' ' + 'danger';
            return styles;
        };

        $scope.getCreditPercentage = function (qty) {
            var bank = $scope.bank || null;
            if (!bank) return 0;
            var credit_percent = (qty * 100) / bank.credit_limit;
            return credit_percent <= 100 ? credit_percent : 100;
        };

        $scope.canCreateRemittance = function () {
            return UserInfo.hasPerm('banks.can_remit');
        };

        $scope.canPerformanceRemittance = function () {
            return $scope.counter == 0 || !$scope.bank ||
                $scope.bank.credit_limit < ($scope.bank.credit_used + $scope.accumulated_credit) ||
                $scope.performingRemittance || $scope.is_refund == null;
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        AgreementsMaster.loadAgreements().then(function (response) {
            $scope.agreements = response;
        });

        Bank.get(function (data) {
            var arr = [];
            data.results.forEach(function (bank) {
                arr.push(bank);
            });
            $scope.banks = arr;
        });

        BrandsList.all().then(function (response) {
            if(response)
                $scope.brands = [{
                    'code': 'null',
                    'name': $translate('BRANDS.NONE')
                }].concat(response)
            });

    }
]

export { NewRemittanceCtrl }
