const SupplyAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/supplies/';
        var url_contracts = API_URL + '/api/1.0/contracts/';
        var url_sips = API_URL + '/api/1.0/sips/';
        var url_energy_consumptions =API_URL  + "/api/1.0/energy-consumptions/";

        var self = this;


        function getSupplyDetail(cups) {
            return _getSupplyDetail(cups);
        }

        function _getSupplyDetail(cups) {
            var deferred = $q.defer();

            $http.get(url + cups)
                .then(function (supply) {
                    deferred.resolve(supply);
                })
                .catch(function (error) {
                    deferred.reject(error);
                });

            self.cups = cups;
            return deferred.promise;
        }

        function getContractsForSupply(cups) {
            return _getContractsForSupply(cups);
        }

        function _getContractsForSupply(cups) {
            var deferred = $q.defer();

            $http({
                    url: url_contracts,
                    method: "GET",
                    params: {cups: cups}
                })
                .then(function (contracts) {
                    deferred.resolve(contracts);
                })
                .catch(function (error) {
                    deferred.reject(error);
                });

            self.cups = cups;
            return deferred.promise;
        }

        function getSupplyFromSIPS(cups){
            return _getSupplyFromSIPS(cups)
        }

        function _getSupplyFromSIPS(cups){
            var deferred = $q.defer();

            $http.get(url_sips + cups)
                .then(function(response) {
                    deferred.resolve(response);
                }).catch(function (error) {
                    deferred.reject(error);
                });

            self.cups = cups;
            return deferred.promise;
        }

        function updateSupply(supply, new_cups){
            return _updateSupply(supply, new_cups)
        }

        function _updateSupply(supply, new_cups) {
            var deferred = $q.defer();
            var cups = new_cups || supply.cups;

            $http.patch(url + cups, supply)
                .then(function(response) {
                    deferred.resolve(response.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function createSupply(supply){
            return _createSupply(supply)
        }

        function _createSupply(supply) {
            var deferred = $q.defer();

            $http.post(url, supply)
                .then(function(response) {
                    deferred.resolve(response);
                })
                .catch(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getSIPSConsumptions(cups){
            return _getSIPSConsumptions(cups)
        }

        function _getSIPSConsumptions(cups){
            var deferred = $q.defer();

            $http.get(url_sips + cups + '/consumptions/')
                .then(function (contracts) {
                    deferred.resolve(contracts);
                })
                .catch(function (error) {
                    deferred.reject(error);
                });

            self.cups = cups;
            return deferred.promise;
        }

        function getPOWRConsumptions(cups){
            return _getPOWRConsumptions(cups)
        }

        function _getPOWRConsumptions(cups){
            var deferred = $q.defer();

            $http.get(url + cups + '/consumptions/')
                .then(function (contracts) {
                    deferred.resolve(contracts);
                })
                .catch(function (error) {
                    deferred.reject(error);
                });

            self.cups = cups;
            return deferred.promise;
        }

        function patchReadings(cups, id, data) {
            return _patchReadings(cups, id, data);
        }

        function _patchReadings(cups, id, data) {
            var deferred = $q.defer();
            $http.patch(url + cups + '/readings/' + id, data)
                .then(function(response) {
                    deferred.resolve(response)
                })
                .catch(function (error) {
                    deferred.reject(error)
            });

            self.cups = cups;
            return deferred.promise;
        }

        function saveReading(cups, data) {
            return _saveReading(cups, data);
        }

        function _saveReading(cups, data) {
            var deferred = $q.defer();
            $http.post(url + cups + '/readings/', data)
                .then(function(response) {
                    deferred.resolve(response)
                })
                .catch(function (error){
                    deferred.reject(error)
                });
            
            return deferred.promise;
        }

        function getReadings(cups) {
            return _getReadings(cups);
        }

        function _getReadings(cups) {
            var deferred = $q.defer();
            $http.get(url + cups + '/readings/')
                .then(function(readings){
                    deferred.resolve(readings);
                })
                .catch(function (error) {
                    deferred.reject(error);
                });
            
            return deferred.promise;
        }

        function getSupplyElectricityMeters(cups) {
            return _getSupplyElectricityMeters(cups);
        }

        function _getSupplyElectricityMeters(cups) {
            var deferred = $q.defer();
            $http.get(url + cups + '/electricity-meters/')
                .then(function(electricity_meters){
                    deferred.resolve(electricity_meters);
                })
                .catch(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;

        }

        function getEnergyConsumptions(cups) {
            return _getEnergyConsumptions(cups)
        }

        function _getEnergyConsumptions(cups) {
            var deferred = $q.defer();
            $http.get(url_energy_consumptions, {params: {cups: cups}})
                .then(function(energy_consumptions){
                    deferred.resolve(energy_consumptions);
                })
                .catch(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        return {
            getSupplyDetail: getSupplyDetail,
            getContractsForSupply: getContractsForSupply,
            getSupplyFromSIPS: getSupplyFromSIPS,
            updateSupply: updateSupply,
            createSupply: createSupply,
            getSIPSConsumptions: getSIPSConsumptions,
            getPOWRConsumptions: getPOWRConsumptions,
            patchReadings: patchReadings,
            saveReading: saveReading,
            getReadings: getReadings,
            getSupplyElectricityMeters: getSupplyElectricityMeters,
            getEnergyConsumptions: getEnergyConsumptions
        };
    }
]

export {SupplyAPI};
