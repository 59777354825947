
const ContractForAgreementCtrl = 
        ['$scope', '$filter', 'Task', 'Alert', 'filtersUtils', '$routeParams', 'DateUtils', '$http', 'ContractForAgreement', 'AgreementsMaster',
            function ($scope, $filter, Task, Alert, filtersUtils, $routeParams, DateUtils, $http, ContractForAgreement, AgreementsMaster) {
                /***********************************************************************************************************
                 * Controller Attributes
                 ***********************************************************************************************************/
                var $translate = $filter('translate');
                var self = this;
                var id = $routeParams.id;

                /***********************************************************************************************************
                 * Scope Attributes
                 ***********************************************************************************************************/


                $scope.ordering = {
                    "started_date": $translate('CONTRACTS.STARTED_DATE')
                };

                $scope.filters = {
                    contract_id: id
                };

                $scope.creationMode = false;
                $scope.errors = {};
                $scope.agreement_manages = false;

                /***********************************************************************************************************
                 * Scope Watchers
                 ***********************************************************************************************************/


                /***********************************************************************************************************
                 * Controller Methods
                 ***********************************************************************************************************/

                function resetForm() {
                    $scope.agreement = "";
                    $scope.started_date = "";
                    $scope.finished_date = "";
                    $scope.agreement_manages = false;
                    $scope.contractAgrement.$setPristine();
                    $scope.contractAgrement.$setUntouched();
                }


                /***********************************************************************************************************
                 * Scope Methods
                 ***********************************************************************************************************/

                $scope.toggleCreationMode = function () {
                    $scope.creationMode = !$scope.creationMode;
                };

                $scope.change_agreement_manages = function (value) {
                    $scope.agreement_manages = value;
                };


                $scope.clearError = function() {
                    if ($scope.errors.started_date !== undefined) {
                        delete($scope.errors.started_date);
                    }
                };

                $scope.createContractForAgreement = function () {
                    $scope.errors = {};
                    var data = {
                        "contract": id,
                        "agreement": $scope.selected_agreement,
                        "started_date": DateUtils.transformToRESTyDateTime($scope.started_date),
                        "agreement_manages": $scope.agreement_manages
                    };


                    ContractForAgreement.createContractForAgreement(data).then(function (contract) {
                        $scope.listReload();
                        Alert.createAlert('success', $translate('CONTRACTS.AGREEMENT_ADDED'), true);
                        resetForm();
                        $scope.creationMode = false;
                    }).catch(function (error) {
                        $scope.errors = error;
                    });
                };

                /***********************************************************************************************************
                 * Controller Initialization
                 ***********************************************************************************************************/

                AgreementsMaster.loadAgreements().then(function (response) {
                    $scope.agreements = response;
                });


            }]

export {ContractForAgreementCtrl}
