function energyPeriodsData(SUPPLY_POWER_PERIODS){

    return {
        require: 'ngModel',
        scope : {
            object: '=ngModel',
            disabled: '=?',
            condition: '=?ngDisabled',
            errorMessage: '=?',
            isEnergy: '=?',
            fieldName: '=?',
            rateType: '=?',
            change: '=ngChange'
        },
        templateUrl: require('../../templates/energy-periods-data.html'),
        link: function(scope, element, attrs, ctrl){
            scope.errorMessage = {};
            scope.errorMessage.show = false;

            scope.disablePeriod = function (index) {

                if (scope.rateType == null) {
                    return false;
                }
                var matrix = SUPPLY_POWER_PERIODS;

                if(!matrix[scope.rateType][index - 1])
                    scope.object[scope.fieldName.replace('{0}', index)] = 0;

                return scope.disabled || !matrix[scope.rateType][index - 1];
            };

        }
    };
}

export { energyPeriodsData }
