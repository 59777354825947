function IberdrolaParser(){
    /**
     * Checks if the given data is valid for this parser
     * @param data
     * @returns {boolean}
     */
    this.isValid = function(data) {
        var is_valid = true;
        if (data.length < 784)
            is_valid = false;
        // Parse Cups
        var is_cups = data.substr(0, 20).trim();
        if ( isNaN(parseFloat(is_cups.substr(2,16))) || is_cups.substr(0,2).indexOf("ES") == -1 )
            is_valid = false;

        // Parse powers
        var p1 = parseFloat(data.substr(581, 12)); // POTENCIA PERIODO 1
        var p2 = parseFloat(data.substr(593, 12)); // POTENCIA PERIODO 2
        var p3 = parseFloat(data.substr(605, 12)); // POTENCIA PERIODO 3
        var p4 = parseFloat(data.substr(617, 12)); // POTENCIA PERIODO 4
        var p5 = parseFloat(data.substr(629, 12)); // POTENCIA PERIODO 5
        var p6 = parseFloat(data.substr(641, 12)); // POTENCIA PERIODO 6
        var p7 = parseFloat(data.substr(653, 12)); // POTENCIA PERIODO 7
        var p8 = parseFloat(data.substr(665, 12)); // POTENCIA PERIODO 8
        var p9 = parseFloat(data.substr(677, 12)); // POTENCIA PERIODO 9
        var p10 = parseFloat(data.substr(689, 12)); // POTENCIA PERIODO 10

        if ( isNaN(p1) || isNaN(p2) || isNaN(p3) || isNaN(p4) || isNaN(p5) || isNaN(p6) || isNaN(p7) || isNaN(p8) || isNaN(p9) || isNaN(p10))
            is_valid = false;

        return is_valid;
    };

    /**
     * Converts a number with left padding zeros into a float
     * @param string
     * @returns float
     */
    this.parseNumber = function(number) {
        return parseFloat(number.trim().replace('.', '').replace(',', '.'));
    };

    /**
     * Converts a string rate into a SUPPLY_RATE
     * @param string
     * @returns supply.rate
     */
    this.parseRates = function(rate){
        var rate_code = '';
        switch(rate) {
            case "001":
                rate_code =  'T20A';
                break;
            case "003":
                rate_code =  'T30A';
                break;
            case "004":
                rate_code =  'T20DHA';
                break;
            case "005":
                rate_code =  'T21A';
                break;
            case "006":
                rate_code =  'T21DHA';
                break;
            case "007":
                rate_code =  'T20A';
                break;
            case "008":
                rate_code =  'T20A';
                break;
            case "0011":
                rate_code =  'T31A';
                break;
            case "0012":
                rate_code =  'T61';
                break;
            case "0013":
                rate_code =  'T62';
                break;
            case "0014":
                rate_code =  'T63';
                break;
            case "0015":
                rate_code =  'T64';
                break;
            case "0016":
                rate_code =  'T65';
                break;
        }
        return rate_code;
    };


    /**
     * Parses data and return the built object
     * @param data
     */
    this.parse = function(data) {
        var supply = {};

        supply.cups = data.substr(0, 20).trim();
        supply.nt_code = data.substr(20, 2).trim();
        supply.name = data.substr(274, 50).trim();

        supply.address = data.substr(22, 150).trim();
        supply.city = data.substr(172, 45).trim();
        supply.state = data.substr(227, 45).trim();
        supply.zip = data.substr(217, 10).trim();

        supply.owner_type = data.substr(272, 2).trim();
        supply.owner_address = data.substr(324, 80).trim();
        supply.owner_city = data.substr(404, 45).trim();
        supply.owner_zip = data.substr(449, 10).trim();
        supply.owner_state = data.substr(459, 45).trim();

        supply.contract_started_on = data.substr(504, 10).trim();

        supply.rate = this.parseRates(data.substr(514, 3).trim());
        supply.voltage = this.parseNumber(data.substr(517, 9));

        supply.max_authorised_power_bie = data.substr(526, 12).trim(); // POTENCIA MÁXIMA AUTORIZADA BIE
        supply.max_authorised_power_starting = data.substr(538, 12).trim(); // POTENCIA MÁXIMA AUTORIZADA ACTA PUESTA EN MARCHA

        supply.measurement_point_type = data.substr(550, 2).trim(); // TIPO PUNTO MEDIDA
        supply.icp_installed = data.substr(552, 1).trim(); // ICP INSTALADO
        supply.consumption_profile_type = data.substr(553, 2).trim(); // TIPO PERFIL DE CONSUMO
        supply.access_rights_recognized = data.substr(555, 12).trim(); // DERECHOS DE ACCESO RECONOCIDOS
        supply.extension_rights_reconigzed = data.substr(567, 12).trim(); // DERECHOS DE EXTENSION RECONOCIDOS
        supply.owns_measurement_device = data.substr(579, 1).trim(); // PROPIEDAD DEL EQUIPO DE MEDIDA
        supply.owns_icp = data.substr(580, 1).trim(); // PROPIEDAD DEL ICP

        supply.power_p1 = this.parseNumber(data.substr(581, 12)); // POTENCIA PERIODO 1
        supply.power_p2 = this.parseNumber(data.substr(593, 12)); // POTENCIA PERIODO 2
        supply.power_p3 = this.parseNumber(data.substr(605, 12)); // POTENCIA PERIODO 3
        supply.power_p4 = this.parseNumber(data.substr(617, 12)); // POTENCIA PERIODO 4
        supply.power_p5 = this.parseNumber(data.substr(629, 12)); // POTENCIA PERIODO 5
        supply.power_p6 = this.parseNumber(data.substr(641, 12)); // POTENCIA PERIODO 6
        supply.power_p7 = this.parseNumber(data.substr(653, 12)); // POTENCIA PERIODO 7
        supply.power_p8 = this.parseNumber(data.substr(665, 12)); // POTENCIA PERIODO 8
        supply.power_p9 = this.parseNumber(data.substr(677, 12)); // POTENCIA PERIODO 9
        supply.power_p10 = this.parseNumber(data.substr(689, 12)); // POTENCIA PERIODO 10

        supply.last_trading_change_on = data.substr(701, 10).trim(); // FECHA ULTIMO CAMBIO DE COMERCIALIZADOR
        supply.last_recruitment_change_on = data.substr(711, 10).trim(); // FECHA ULTIMO MOVIMIENTO CONTRATACION
        supply.extensions_rights_deadline_on = data.substr(721, 10).trim(); // FECHA LIMITE DERECHOS DE EXTENSION
        supply.last_reading_on = data.substr(731, 10).trim(); // FECHA ULTIMA LECTURA
        supply.available_box_power = this.parseNumber(data.substr(741, 12)); // POTENCIA DISPONIBLE CAJA
        supply.non_payment = data.substr(753, 11).trim(); // IMPAGO
        supply.deposit_warranty = data.substr(764, 1).trim(); // DEPOSITO GARANTIA
        supply.deposit_amount = data.substr(765, 11).trim(); // IMPORTE DEPOSITO
        supply.usual_housing = data.substr(776, 1).trim(); // VIVIENDA HABITUAL
        supply.remote_management_active = data.substr(777, 2).trim(); // TELEGESTIONADO ACTIVO

        // SUSTITUCION DE CONTADORES
        supply.measurement_device_change_year = data.substr(779, 4).trim(); // AÑO
        supply.measurement_device_change_quarter = data.substr(783, 1).trim(); // TRIMESTRE

        // Added default distributor
        supply.distributor = "0021";

        return supply;
    };
}

export {IberdrolaParser};
