function AlertCtrl($scope, $timeout, Alert){
    var timer = null;
    $scope.service = Alert;

    $scope.create = function () {
        Alert.createAlert('info', 'Hola!', true);
    };

    $scope.close = function () {
        $timeout.cancel(timer);
        Alert.alert.info = false;
        Alert.alert.success = false;
        Alert.alert.warning = false;
        Alert.alert.danger = false;
        Alert.alert.timeout = false;
    };

    $scope.$watchCollection('service.alert', function (newVal, oldVal) {
        if (typeof newVal !== 'undefined') {
            $scope.info = newVal.info;
            $scope.success = newVal.success;
            $scope.warning = newVal.warning;
            $scope.danger = newVal.danger;

            $scope.message = newVal.message;

            if (newVal.timeout) {
                timer = $timeout(function () {
                    Alert.alert.info = false;
                    Alert.alert.success = false;
                    Alert.alert.warning = false;
                    Alert.alert.danger = false;
                    Alert.alert.timeout = false;
                }, 5000);
            }
        }
    });
}

export {AlertCtrl}
