// Services
import { BrandsList, BrandsListResource } from "./services/BrandsList"

const brands = angular.module('brands', []);

brands 
    .factory("BrandsListResource", BrandsListResource)
    .service("BrandsList", BrandsList)

export { brands }
