const CreateInvoiceSeriesCtrl = ['$scope', 'InvoiceSeries', '$filter',
    function($scope, InvoiceSeries, $filter) {
        var $translate = $filter('translate');

        var self = this;

        $scope.successMessage = null;
        $scope.errorMessage = null;

        $scope.resetForm = function(){
            $scope.code = "";
            $scope.description = "";
            $scope.counter = null;
            $scope.invoice_number_len = null;
            $scope.successMessage = null;
            $scope.errorMessage = null;
            $scope.createInvoiceSeriesForm.$setPristine();
            $scope.createInvoiceSeriesForm.$setUntouched();
        };

        $scope.hideCreateForm = function() {
            $scope.resetForm();
            $scope.$emit('cancelCreate');
        };

        $scope.saveInvoiceSeries = function(){

            var invoice_series = new InvoiceSeries();
            invoice_series.code = $scope.code;
            invoice_series.description = $scope.description;
            invoice_series.counter = $scope.counter;
            invoice_series.invoice_number_len = $scope.invoice_number_len;

            invoice_series.$save({ id: "" },
                function(success){
                    $scope.resetForm();
                    $scope.$emit('newInvoiceSeries');
                    $scope.successMessage = $translate('INVOICES.INVOICE_SERIES_CREATED_SUCCESSFULLY');
                    $scope.loading = false;
                }, function(error){
                    $scope.errorMessage = error.data.code.join("<br>");
                    $scope.loading = false;
                }
            );

        };
    }
]

export { CreateInvoiceSeriesCtrl }
