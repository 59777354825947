function restListView(){
    return {
        scope: {
            results: '=ngModel',
            listReload: '=?',
            current: '@url',
            filters: '=?',
            ordering: '=',
            selectable: '=',
            selectedItems: '=?',
            primaryKey: '=?',
            getStyles: '=?',
            defaultAscOrder: '=?',
            mongoDb: '=?',
            draggablePattern: '=?',
            lastItem: '=?',
            header: '=?',
            scrollable: '=?',
            childTemplateUrl: '=?',
            method: '@method',
            eventMethod: '@eventMethod'
        },
        restrict: 'E',
        transclude: true,
        templateUrl: require('../../templates/rest-list.html'),
        controller: function ($q, $scope, $http, listViewConfig, DateUtils, $rootScope, filtersUtils, INVOICES_TYPES, Alert, $filter) {
            var $translate = $filter('translate');

            var self = this;

            self.httpCanceller = null;

            $scope.results = [];
            $scope.query_params = {};
            $scope.invoices_types = INVOICES_TYPES;
            $scope.perPage = null;
            $scope.page = 1;
            $scope.count = 0;
            $scope.next = null;
            $scope.previous = null;
            $scope.loading = false;
            $scope.loadingCount = false;
            $scope.selectedItems = {};
            $scope.order_asc = $scope.defaultAscOrder !== undefined ? $scope.defaultAscOrder : true;
            $scope.lastItem = {};
            $scope.request_in_progress = null;
            $scope.request_canceller = $q.defer();

            if ($scope.scrollable === undefined || $scope.scrollable === null) {
                $scope.scrollable = true;
            }

            if ($scope.method === undefined || $scope.method === null) {
                $scope.method = 'get';
            }

            var pk = $scope.primaryKey !== undefined ? $scope.primaryKey : 'id';

            if ($scope.ordering) {
                $scope.order_field = Object.keys($scope.ordering)[0];
                $scope.order = ($scope.order_asc ? '' : '-') + $scope.order_field;
            } else {
                $scope.order = null;
                $scope.order_field = null;
            }

            $scope.nextPage = function () {
                if ($scope.next) {
                    $scope.current = $scope.next;
                    $scope.page++;
                    $scope.query_params[listViewConfig.PAGE_PARAM] = $scope.page;
                }
            };

            $scope.previousPage = function () {
                if ($scope.previous) {
                    $scope.current = $scope.previous;
                    $scope.page--;
                    $scope.query_params[listViewConfig.PAGE_PARAM] = $scope.page;
                }
            };

            $scope.changeOrder = function () {
                if ($scope.order.substr(0, 1) == '-') {
                    $scope.order = $scope.order.substr(1, $scope.order.length);
                    $scope.order_asc = true;
                } else {
                    $scope.order = "-" + $scope.order;
                    $scope.order_asc = false;
                }
            };

            $scope.review = function () {
                let pk = $scope.primaryKey !== undefined ? $scope.primaryKey : 'id'
                $scope.query_params['primary_key'] = pk;

                if ('ids' in $scope.query_params)
                    delete $scope.query_params['ids'];
                else
                    $scope.query_params['ids'] = filtersUtils.getItemsToDownload($scope.selectedItems);
            };

            $scope.showReviewMode = function () {
                return 'ids' in $scope.query_params;
            };

            $scope.listReload = function () {
                self.loadData();
            };

            $scope.share = function(){
                navigator.clipboard.writeText(location.href);

                Alert.createAlert('success', $translate('POWR.COPIED_TO_CLIPBOARD'), true)
            }

            $scope.retrieveCount = function(){
                $scope.loadingCount = true
                
                let query_params = JSON.parse(JSON.stringify($scope.query_params));
                
                query_params.count = true
                
                let url = self.buildURL($scope.base_url, query_params);

                var deferred = $q.defer();

                this.xhr = $http[$scope.method](url, {timeout: deferred.promise}).then(function(data){
                    $scope.count = data.data.count;
                    $scope.loadingCount = false
                })
   
            }

            $scope.$watch('query_params', async function (newVal, oldVal) {
                if (newVal != oldVal) {
                    var url = $scope.current.split('?')[0]
                    $scope.current = self.buildURL(url, newVal);
                    self.loadData()
                }
            }, true);

            $scope.$watch('filters', function (newVal, oldVal) {
                if (newVal) {
                    var query_params = {}   

                    angular.forEach(newVal, function (value, key) {
                        if (key != "" && value && key) {
                            
                            var parsed_value = value;

                            if (value instanceof Date) {
                                parsed_value = DateUtils.transformToRESTyDate(value);
                            }

                            query_params[key] = parsed_value;
                        }
                    }, query_params);

                    query_params[listViewConfig.ORDER_PARAM] = $scope.query_params[listViewConfig.ORDER_PARAM]
                    $scope.page = 1;
                    query_params[listViewConfig.PAGE_PARAM] = $scope.page;

                    $scope.query_params = query_params;
                }
            }, true);

            $scope.setOrderField = function (key) {
                $scope.order_field = key;
                if ($scope.order_asc) {
                    $scope.order = key;
                } else {
                    // Added for initial inverse order
                    if ($scope.order.substr(0, 1) == '-') {
                        $scope.order = "-" +key;
                    }
                    else {
                        $scope.order = key;
                    }
                }
            };

            $scope.$watch('order', function (newVal, oldVal) {
                if (newVal) {
                    $scope.query_params[listViewConfig.ORDER_PARAM] = newVal;
                }
            });

            this.buildURL = function (url, params) {
                if (!params || $scope.method == 'post') return url;

                var baseUrl = url;

                var parts = [];
                angular.forEach(params, function (value, key) {
                    parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
                }, parts);

                return baseUrl + '?' + parts.join('&');
            };

            this.loadData = function () {
                let query_params = Object.assign({}, $scope.query_params)

                if ($scope.current === null) return;
                if (self.httpCanceller !== null) {
                    self.httpCanceller.resolve();
                }
                $scope.loading = true;

                var deferred = $q.defer();
                self.httpCanceller = deferred;

                var url = $scope.current

                let config = {
                    'timeout': deferred.promise
                }

                if(['post', 'put', 'patch'].includes($scope.method)){
                    this.xhr = $http[$scope.method](url=url, query_params, config=config)
                }else{
                    config.params = query_params;
                    this.xhr = $http[$scope.method](url=url, config=config)
                }
                
                this.xhr.then(function(data){

                    if($scope.query_params.hasOwnProperty('retrieveAll')){
                        angular.forEach(data.data, function (item) {
                            $scope.selectedItems[item] = true;
                        }, $scope.selectedItems);
                        $scope.loading = false;
                        delete $scope.query_params.retrieveAll;
                    }else{
                        $scope.count = data.data.count;
                        $scope.next = data.data.next;
                        $scope.previous = data.data.previous;
                        
                        
                        if ($scope.perPage == null) {
                            $scope.perPage = data.data.results.length;
                        }
                        
                    // Added for checkbox common to all components
                    angular.forEach(data.data.results, function (item) {

                            if ($scope.mongoDb === true) {
                                item.checkbox = item[pk].$oid;
                            } else {
                                item.checkbox = item[pk];
                            }
                        }, data.data.results);
                        $scope.results = data.data.results;

                        $scope.loading = false;
                    }
                }).catch(function(data){})
            };

            if (!$scope.order) this.loadData();

            $scope.selectPageClick = function () {
                angular.forEach($scope.results, function (item) {
                    if ($scope.mongoDb === true) {
                        $scope.selectedItems[item[pk].$oid] = true;
                    } else {
                        $scope.selectedItems[item[pk]] = true;
                    }
                }, $scope.selectedItems);
            };

            $scope.lastItemChanged = function (element) {
                $scope.lastItem = element;
            };

            $scope.unselectPageClick = function () {
                angular.forEach($scope.results, function (item) {
                    if ($scope.mongoDb === true) {
                        $scope.selectedItems[item[pk].$oid] = false;
                    } else {
                        $scope.selectedItems[item[pk]] = false;
                    }
                }, $scope.selectedItems);
            };

            $scope.selectAllClick = function () {  // AQUI INYECTAR EL ELEMENTO QUE SE DIGA EN EL UI
                $scope.query_params['retrieveAll'] = pk;
            };

            $scope.unselectAllClick = function () {
                $scope.selectedItems = {};
            };

            $scope.$watch("selectedItems", function () {
                $scope.select_counter = 0;

                // Check dictionary lenght
                if (Object.keys($scope.selectedItems).length !== 0) {
                    angular.forEach($scope.selectedItems, function (value) {
                        if (value == true)
                            $scope.select_counter++;
                    });
                }
                
                if($scope.eventMethod == 'emit'){
                    $scope.$emit('selectedItemsChange', $scope.selectedItems);
                    $scope.$emit('selectedCounterChange', $scope.select_counter);
                }else{
                    $rootScope.$broadcast('selectedItemsChange', $scope.selectedItems);
                    $rootScope.$broadcast('selectedCounterChange', $scope.select_counter);
                }
            }, true);


        },
        link: function (scope, element, attr) {
            scope.$on('loadingList', function(loading){
                scope.loading = loading;
            });

            // Refresh when data is changed
            scope.$on('refreshList', function (e, data) {
                scope.selectedItems = {};
                scope.select_counter = 0;
                scope.listReload();
            });

            scope.$on('unselectAll', function (e, data) {
                scope.selectedItems = {};
                scope.select_counter = 0;
            });

            var pk = scope.primaryKey !== undefined ? scope.primaryKey : 'id';

            scope.selectItemFromDiv = function (item) {
                item.isChecked = item.isChecked ? false : true;
                if (scope.selectable) {
                    if (scope.mongoDb === true) {
                        scope.selectedItems[item[pk].$oid] = scope.selectedItems[item[pk].$oid] ? false : true;
                    } else {
                        scope.selectedItems[item[pk]] = scope.selectedItems[item[pk]] ? false : true;
                    }
                }
            };

            scope.selectItemByIds = function (items) {
                if (items) {
                    for (var index = 0; index < items.length; index += 1) {
                        scope.selectedItems[items[index]] = true;
                    }
                }
            };

            if (scope.draggablePattern) {
                // drag'n'drop zone handlers
                element.on('dragenter', function (ev) {
                    ev.stopPropagation();
                    ev.preventDefault();
                }).on('dragleave', function (ev) {
                    ev.stopPropagation();
                    ev.preventDefault();
                }).on('dragover', function (ev) {
                    ev.stopPropagation();
                    ev.preventDefault();
                }).on('drop', function (ev) {

                    var data = event.dataTransfer.items;

                    for (var i = 0; i < data.length; i += 1) {
                        if ((data[i].kind == 'string') &&
                            (data[i].type.match('^text/plain'))) {

                            data[i].getAsString(function (str) {
                                var items = str.match(new RegExp(scope.draggablePattern, 'gm'));

                                if (items) {
                                    scope.selectItemByIds(items);
                                    scope.listReload();
                                }
                            });
                        }
                    }
                });
            }
        }
    };
}

export {restListView}
