function lockUnlockRemittance(UserInfo, filtersUtils, Alert, $filter) {
    return {
        restrict: 'AE',
        scope: {
            lock: '='
        },
        templateUrl: require('../../templates/lock-unlock-remittance.html'),
        controller: function ($scope, $http, $rootScope) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/remittances/';
            var $translate = $filter('translate');

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.remittanceList = [];
            $scope.hasPerm = UserInfo.hasPerm('banks.lock_unlock_remittance');

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.remittanceList = selectedItemsChange;
            });

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/
            function performRequest(ids) {
                var json = {ids: ids, lock: $scope.lock};

                var config = {
                    method: "PATCH",
                    url: api,
                    data: json,
                    headers: {"Content-Type": "application/json;charset=utf-8"}
                };
                $http(config).then(function (data, status, headers, config) {
                    if ($scope.lock) {
                        Alert.createAlert('success', $translate('BANKS.REMITTANCE_LOCKED'), true);
                    } else {
                        Alert.createAlert('success', $translate('BANKS.REMITTANCE_UNLOCKED'), true);
                    }
                    $rootScope.$broadcast('refreshList');
                }).finally(function () {
                    $scope.remittanceList = [];
                    $scope.$parent.loading = false;
                });
            };

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/
            $scope.doRequest = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.remittanceList);
                performRequest(ids);
            };
        }
    }
}

export { lockUnlockRemittance }
