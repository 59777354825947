const ProductRateDetailCtrl = ['$scope', '$routeParams', '$filter', 'UserInfo', 'Alert', 'DateUtils', 'RatesService', 'PricesService', 'ProductRateAPI', 'ProductAPI', 'ProductPriceAPI', 'SUPPLY_RATES', 'RATES_INVOICING_MODE',
    function ($scope, $routeParams, $filter, UserInfo, Alert, DateUtils, RatesService, PricesService, ProductRateAPI, ProductAPI, ProductPriceAPI, SUPPLY_RATES, RATES_INVOICING_MODE) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var rates_list = SUPPLY_RATES.map(function(rate) { return rate.code });
        var rates_dict = {}
        SUPPLY_RATES.forEach(function(rate) { rates_dict[rate.code] = false; });
        rates_dict[$translate('PRODUCTS.OTHERS')] = $translate('PRODUCTS.OTHERS');
        var tab = 0;
        var pricesToUpdate = [];

        $scope.PRODUCTS_RATE_PRICE_CREATION = require('../../templates/rate-price-creation.html')

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.loading_detail = true;
        $scope.rates_dict = rates_dict;
        $scope.editionMode = false;
        $scope.deleteMode = false;
        $scope.invoicing_mode = RATES_INVOICING_MODE;
        $scope.others = $translate('PRODUCTS.OTHERS');
        $scope.types = [
            {code: 'FIXD', name: $translate('PRODUCTS.FIXED')},
            {code: 'DIFF', name: $translate('PRODUCTS.DIFFERENTIAL')},
            {code: 'PERC', name: $translate('PRODUCTS.PERCENTAGE')},
            {code: 'DFXD', name: $translate('PRODUCTS.DAILY_FIXED')},
            {code: 'YFXD', name: $translate('PRODUCTS.YEARLY_FIXED')}
        ];
        $scope.other_types = [
            {code: 'FIXD', name: $translate('PRODUCTS.FIXED')},
            {code: 'DIFF', name: $translate('PRODUCTS.DIFFERENTIAL')},
            {code: 'PERC', name: $translate('PRODUCTS.PERCENTAGE')},
            {code: 'DFXD', name: $translate('PRODUCTS.DAILY_FIXED')},
            {code: 'YFXD', name: $translate('PRODUCTS.YEARLY_FIXED')},
            {code: 'XTDF', name: $translate('PRODUCTS.EXTRA_DAILY_FIXED')},
            {code: 'XTCN', name: $translate('PRODUCTS.EXTRA_BY_CONSUMPTION_FIXED')}
        ];
        $scope.prices = {};
        $scope.magnitudes = {};
        $scope.code = $routeParams.code;
        $scope.creationMode = false;
        $scope.loading_dates = true;
        $scope.products = [];

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        $scope.$on('refreshProducts', function (event) {
            $scope.creationMode = false;
            initialState();
        });

        $scope.$watch('date_active', function (newVal, oldVal) {
            if (($scope.date_active === null) || ($scope.date_active === undefined)) {
                $scope.creationMode = true;
            } else {
                $scope.creationMode = false;
                load_prices();
            }
        });

        $scope.$watch('creationMode', function (newVal, oldVal) {
            if (newVal === false && oldVal === true && $scope.date_active === null) {
                if ($scope.candidate_dates.length > 0) {
                    initialState();
                    $scope.date_active = $scope.candidate_dates[1];
                }
            }
        });

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        function load_prices() {
            var date = DateUtils.transformToRESTyDate($scope.date_active);

            ProductPriceAPI.getProductPricesByProductRateGroupByDate($scope.code, date).then(function (data) {
                initializePrices();
                var selected_rate_flag = false;

                for (var price = 0; price < data.results.length; price++) {
                    var p = data.results[price];
                    var rate = RatesService.getRateFromCode(p.product.code);
                    var magnitude = RatesService.getMagnitudeFromCode(p.product.code);
                    var period = RatesService.getPeriodFromCode(p.product.code);

                    if ((rate === 'T20DHA' || rate === 'T21DHA') && period === 'P2') {
                        period = 'P3';
                    }

                    if (period === "") {
                        rate = $translate('PRODUCTS.OTHERS');
                        $scope.prices[rate][magnitude] = [p.id, p.value, p.type, p.start_date];
                        $scope.rates_dict[rate] = true;
                    } else {
                        $scope.prices[rate][period][magnitude] = [p.id, p.value, p.type, p.start_date];
                        $scope.rates_dict[rate] = true;
                        $scope.magnitudes[rate][magnitude] = true;
                    }

                    if (!selected_rate_flag) {
                        selected_rate_flag = true;
                        $scope.selectedRate = rate;
                    }
                }
            }).catch(function (error) {
                $scope.offer_prices = [{name: 'Error'}];
            });
        }

        function initializePrices() {
            pricesToUpdate = [];
            $scope.prices = {};
            $scope.magnitudes = {};
            $scope.prices[$translate('PRODUCTS.OTHERS')] = {};
            for (var rate in rates_list) {
                $scope.magnitudes[rates_list[rate]] = {};
                $scope.prices[rates_list[rate]] = {};
                for (var n = 1; n <= 6; n++) {
                    $scope.prices[rates_list[rate]]['P' + n] = {};
                    $scope.prices[rates_list[rate]]['P' + n]['E'] = false;
                    $scope.prices[rates_list[rate]]['P' + n]['P'] = false;
                    $scope.prices[rates_list[rate]]['P' + n]['ERG'] = false;
                    $scope.prices[rates_list[rate]]['P' + n]['EXP'] = false;
                    $scope.prices[rates_list[rate]]['P' + n]['REP'] = false;
                }
            }
            for (var k in rates_dict) {
                if (rates_dict.hasOwnProperty(k)) {
                    rates_dict[k] = false;
                }
            }
        }

        function saveChanges() {
            for (var i = 0; i < pricesToUpdate.length; i++) {
                var p = pricesToUpdate[i];
                var rate = RatesService.getRateFromCode(p);
                var magnitude = RatesService.getMagnitudeFromCode(p);
                var period = RatesService.getPeriodFromCode(p);
                var id, value, type;
                var start_date = DateUtils.transformToRESTyDate($scope.date_active);
                var price_date = null
                var product = null

                if (period === "") {
                    id = $scope.prices[$scope.others][p][0];
                    value = $scope.prices[$scope.others][p][1];
                    type = $scope.prices[$scope.others][p][2];
                    price_date = $scope.prices[$scope.others][p][3];
                    product = rate+magnitude;
                } else {
                    id = $scope.prices[rate][period][magnitude][0];
                    value = $scope.prices[rate][period][magnitude][1];
                    type = $scope.prices[rate][period][magnitude][2];
                    price_date = $scope.prices[rate][period][magnitude][3];
                    if(rate.includes('DH') && period === 'P3') {
                        product = rate + magnitude + 'P2';
                    }else{
                        product = rate + magnitude + period;
                    }
                }

                if(price_date === start_date) {
                    var json = {
                        "type": type,
                        "value": value,
                        "start_date": start_date
                    };

                    ProductPriceAPI.updateProductPrice(id, json).then(function (product_price) {
                    }).catch(function (error) {
                        $scope.error_message = error.data.__all__[0];
                    });
                }

                else{
                    var json = {
                        "product": product,
                        "product_rate": $scope.product_rate.code,
                        "type": type,
                        "value": value,
                        "start_date": start_date
                    };

                    ProductPriceAPI.createProductPrice(json).then(function (product_price) {
                    }).catch(function (error) {
                        $scope.error_message = error.data.__all__[0];
                    });
                }
            }

            Alert.createAlert('success', $translate('PRODUCTS.PRODUCT_UPDATED'), true);
            $scope.editionMode = false;
        }

        function retrieveDates() {
            ProductPriceAPI.getProductPricesByProductRate($scope.code).then(function (data) {
                $scope.candidate_dates = [];
                for (var i = 0; i < data.results.length; i++) {
                    $scope.candidate_dates.push(new Date(data.results[i].start_date));
                }
                $scope.date_active = $scope.candidate_dates[0];
                $scope.loading_dates = false;
            }).catch(function (error) {
                $scope.products = [{name: 'Error'}];
            });
        }

        function initialState () {
            $scope.editionMode = false;
            $scope.creationMode = false;
            retrieveDates();
        }

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.creationModeToggle = function () {
            $scope.creationMode = !$scope.creationMode;
        };

        $scope.deleteModeToggle = function () {
            $scope.deleteMode = !$scope.deleteMode;
        };

        $scope.saveSingleProductRate = function () {
            $scope.loading = true;

            ProductRateAPI.updateProductRate($scope.product_rate.code, $scope.product_rate)
                .then(function (product_rate) {
                    $scope.loading = false;
                    Alert.createAlert('success', $translate('PRODUCTS.SUCCESS_PRODUCT_RATE_UPDATED'), true);
                }).catch(function (error) {
                    $scope.error_message = error.data.__all__[0];
                });
        };

        $scope.canEdit = function () {
            return (UserInfo.hasPerm("products.change_product"));
        };

        $scope.canDelete = function () {
            return (UserInfo.hasPerm("products.delete_product"));
        };

        $scope.canCreate = function () {
            return UserInfo.hasPerm('products.add_productspecialrate');
        };

        $scope.canShowProductRate = function () {
            return UserInfo.hasPerm('products.retrieve_products_rates');
        };

        $scope.canDeleteProductRate = function () {
            return UserInfo.hasPerm('products.delete_productrate');
        };

        $scope.canEditProductRate = function () {
            return UserInfo.hasPerm('products.change_productrate');
        };

        $scope.priceActive = PricesService.priceActive;

        $scope.setPriceDirty = function (rate, period, magnitude) {
            var price = rate + magnitude + period;
            if (pricesToUpdate.indexOf(price) == -1) {
                pricesToUpdate.push(price);
            }
        };

        $scope.toggleEditionMode = function () {
            $scope.editionMode = !$scope.editionMode;
        };

        $scope.saveChanges = function () {
            saveChanges();
        };

        $scope.deletePrices = function () {
            var json = {
                'date': DateUtils.transformToRESTyDate($scope.date_active),
                'product_rate': $scope.code
            };

            ProductPriceAPI.deleteProductPriceByProductAndDate(json).then(function (data) {
                Alert.createAlert('success', $translate('PRODUCTS.SUCCESSFULLY_DELETED'), true);
                load_prices();
                retrieveDates();
                $scope.deleteMode = false;
            }).catch(function (error) {
                Alert.createAlert('danger', $translate('PRODUCTS.ERROR_DELETING'), true);
            });
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        ProductRateAPI.getProductRate($scope.code).then(function (product_rate) {
            $scope.product_rate = product_rate;
            $scope.loading_detail = false;
        }).catch(function (error) {});

        ProductAPI.getProductByRate($scope.code, 1000).then(function (data) {
            $scope.products = data.results;
        }).catch(function (error) {
            $scope.products = [{name: 'Error'}];
        });

        retrieveDates();
    }
]

export { ProductRateDetailCtrl }
