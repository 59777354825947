const AgreementUserManagementCtrl = ['$scope', '$filter', 'AgreementsMaster', 'AgreementAPI', 'Alert',
    function ($scope, $filter, AgreementsMaster, AgreementAPI, Alert) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        function validateEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

        function isNotInArray(value, array) {
            return array.indexOf(value) == -1;
        }

        this.cleanAgreementEmailFormErrors = function (errors) {
            for (var item in errors) {
                if (item.substring(0, 1) != '$') {
                    for (let key in errors[item].$error) {
                        errors[item].$setValidity(key, true);
                    }
                }
            }
        };

        this.refreshData = function () {
            if ($scope.agreement.professionals_zone != null && $scope.agreement.professionals_zone != '')
                $scope.professionalZone = $scope.agreement.professionals_zone.split(",");

            if ($scope.agreement.customers_zone != null && $scope.agreement.customers_zone != '')
                $scope.customerZone = $scope.agreement.customers_zone.split(",");

            if ($scope.agreement.related_agreements != null && $scope.agreement.related_agreements != '')
                $scope.relatedAgreements = $scope.agreement.related_agreements.split(",");
        };

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/


        $scope.addProfessional = function (email) {
            self.cleanAgreementEmailFormErrors($scope.addUserProfessional);
            self.cleanAgreementEmailFormErrors($scope.addUserCustomer);
            var valid_email = validateEmail(email);
            if (!valid_email)
                $scope.addUserProfessional.professionalEmail.$error.api = $translate('AGREEMENTS.ERROR_INVALID_MAIL');
            else {
                $scope.professionalZone.push(email);
                var data = $scope.professionalZone.toString();

                AgreementAPI.setAgreementDetail($scope.agreement.code, {
                    code: $scope.agreement.code,
                    professionals_zone: data
                })
                    .then(function (data) {
                        $scope.professional.email = '';
                        $scope.agreement = data;
                        self.refreshData();
                        $scope.$emit('refreshAgreementDetail');
                        Alert.createAlert('success', $translate('AGREEMENTS.USER_ADDED'), true);
                    }).catch(function (error) {
                        $scope.professionalZone.splice($scope.professionalZone.indexOf(email), 1);
                    });
            }
        };

        $scope.deleteProfessional = function (email) {
            $scope.professionalZone.splice($scope.professionalZone.indexOf(email), 1);

            var data = $scope.professionalZone.toString();

            AgreementAPI.setAgreementDetail($scope.agreement.code, {
                code: $scope.agreement.code,
                professionals_zone: data
            })
                .then(function (data) {
                    $scope.agreement = data;
                    self.refreshData();
                    $scope.$emit('refreshAgreementDetail');
                    Alert.createAlert('success', $translate('AGREEMENTS.USER_DELETED'), true);
                }).catch(function (error) {
                    $scope.professionalZone.splice($scope.professionalZone.indexOf(email), 1);
                });
        };

        $scope.addCustomer = function (email) {
            self.cleanAgreementEmailFormErrors($scope.addUserProfessional);
            self.cleanAgreementEmailFormErrors($scope.addUserCustomer);
            var valid_email = validateEmail(email);
            if (!valid_email)
                $scope.addUserCustomer.customerEmail.$error.api = $translate('AGREEMENTS.USER_ADDED');
            else {
                $scope.customerZone.push(email);

                var data = $scope.customerZone.toString();

                AgreementAPI.setAgreementDetail($scope.agreement.code, {
                    code: $scope.agreement.code,
                    customers_zone: data
                })
                    .then(function (data) {
                        $scope.customer.email = '';
                        $scope.agreement = data;
                        self.refreshData();
                        $scope.$emit('refreshAgreementDetail');
                        Alert.createAlert('success', $translate('AGREEMENTS.USER_DELETED'), true);
                    }).catch(function (error) {
                        $scope.customerZone.splice($scope.customerZone.indexOf(email), 1);
                    });
            }
        };

        $scope.deleteCustomer = function (email) {
            $scope.customerZone.splice($scope.customerZone.indexOf(email), 1);

            var data = $scope.customerZone.toString();

            AgreementAPI.setAgreementDetail($scope.agreement.code, {
                code: $scope.agreement.code,
                customers_zone: data
            })
                .then(function (data) {
                    $scope.agreement = data;
                    self.refreshData();
                    $scope.$emit('refreshAgreementDetail');
                    Alert.createAlert('success', $translate('AGREEMENTS.SUCCESS_AGREEMENT_UPDATED'), true);
                }).catch(function (error) {
                });
        };

        $scope.addRelated = function (agreement) {
            if ($scope.relatedAgreements === undefined)
                $scope.relatedAgreements = [];

            $scope.relatedAgreements.push(agreement);

            var data = $scope.relatedAgreements.toString();

            AgreementAPI.setAgreementDetail($scope.agreement.code, {
                code: $scope.agreement.code,
                related_agreements: data
            })
                .then(function (data) {
                    $scope.agreement = data;
                    $scope.related = '';
                    self.refreshData();
                    Alert.createAlert('success', $translate('AGREEMENTS.RELATED_AGREEMENT_ADDED'), true);
                }).catch(function (error) {
                    //TODO: comprobar de donde sale "email"
                    $scope.relatedAgreements.splice($scope.relatedAgreements.indexOf(email), 1); // eslint-disable-line no-undef
                });
        };

        $scope.deleteRelated = function (agreement) {
            $scope.relatedAgreements.splice($scope.relatedAgreements.indexOf(agreement), 1);

            var data = $scope.relatedAgreements.toString();

            AgreementAPI.setAgreementDetail($scope.agreement.code, {
                code: $scope.agreement.code,
                related_agreements: data
            })
                .then(function (data) {
                    $scope.agreement = data;
                    self.refreshData();
                    Alert.createAlert('success', $translate('AGREEMENTS.RELATED_AGREEMENT_DELETED'), true);
                }).catch(function (error) {
                    //TODO: comprobar de donde sale "email"
                    $scope.relatedAgreements.splice($scope.relatedAgreements.indexOf(email), 1); // eslint-disable-line no-undef
                });
        };

        $scope.filterAlreadyAdded = function(item) {
            return isNotInArray(item.code, $scope.relatedAgreements);
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        AgreementsMaster.loadAgreements().then(function (response) {
            $scope.agreements = response;
        });

    }
]

export {AgreementUserManagementCtrl}
