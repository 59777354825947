const CalendarDaysCtrl = ['$scope', '$filter', 'Alert', 'CalendarDaysAPI', 'UserInfo',
    function ($scope, $filter, Alert, CalendarDaysAPI, UserInfo) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;
        var id = $scope.$parent.calendar.id;
        $scope.calendar_id = '';

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.ordering = {
            "date": $translate('CALENDARS.DATE')
        };

        $scope.filters = {
            calendar: id
        };
        
        $scope.calendar_day_types = [
            {code: 'FEST', label: $translate('CALENDARS.HOLIDAY')}
        ];
        // Only can create calendar day type = FEST
        $scope.calendar_day_type = 'FEST';
        
        $scope.creationDayMode = false;

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        $scope.$watch('$parent.calendar', function (newValue, oldValue) {
            if (newValue != null) {
                $scope.filters.calendar = newValue.id;
                id = newValue.id;
            }

        }, true);

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        function resetForm() {
            $scope.calendar_day_name = "";
            $scope.calendar_day_date = null;
            //$scope.calendar_day_type = "";
            $scope.calendarDayForm.$setPristine();
            $scope.calendarDayForm.$setUntouched();
        }
        
        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.toggleCreationDayMode = function () {
            $scope.creationDayMode = !$scope.creationDayMode;
        };

        $scope.createCalendarDay = function () {
            var data = {
                "calendar": id,
                "name": $scope.calendar_day_name,
                "date": $scope.calendar_day_date,
                "type": $scope.calendar_day_type
            };

            CalendarDaysAPI.createCalendarDay(data).then(function (data) {
                $scope.listReload();
                Alert.createAlert('success', $translate('CALENDARS.CALENDAR_DAY_ADDED'), true);
                resetForm();
                $scope.creationDayMode = false;
            }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
            });
        };

        $scope.canCreateCalendarDay = function () {
            return UserInfo.hasPerm('calendars.add_day');
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/

    }
]

export { CalendarDaysCtrl };
