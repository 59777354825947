const ProgrammingUnitAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/programming-units/';

        var self = this;
        var programming_unit = null;

        function getProgrammingUnitDetail(id) {
            if (programming_unit == null || programming_unit.id != id) {
                programming_unit = {
                    'id': id
                };
                return _getProgrammingUnitDetail(id);
            } else {
                var deferred = $q.defer();
                deferred.resolve(programming_unit);
                return deferred.promise;
            }
        }

        function _getProgrammingUnitDetail(id) {
            var deferred = $q.defer();
            
            $http.get(url + id)
                .then(function (programming_unit) {
                    deferred.resolve(programming_unit);
                },function (error) {
                    deferred.reject(error);
                });

            self.id = id;
            return deferred.promise;
        }

        function createProgrammingUnit(programming_unit) {
            var deferred = $q.defer();

            $http.post(url, programming_unit)
                .then(function (data) {
                    deferred.resolve(data);
                }).catch(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        function deleteProgrammingUnit(id) {
            var deferred = $q.defer();

            $http.delete(url + id)
                .then(function (programming_unit) {
                    deferred.resolve(programming_unit);
                }).catch(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        function updateProgrammingUnit(id, data) {
            var deferred = $q.defer();

            $http.put(url + id, data)
                .then(function (data) {
                    deferred.resolve(data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function setProgrammingUnitToSupplies(programming_unit) {
            var deferred = $q.defer();

            $http.put(url, programming_unit)
                .then(function (data) {
                    deferred.resolve(data);
                }).catch(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        return {
            getProgrammingUnitDetail: getProgrammingUnitDetail,
            createProgrammingUnit: createProgrammingUnit,
            deleteProgrammingUnit: deleteProgrammingUnit,
            updateProgrammingUnit: updateProgrammingUnit,
            setProgrammingUnitToSupplies: setProgrammingUnitToSupplies
        };

    }
]

export { ProgrammingUnitAPI };
