const CreateDeferredInvoices = ['DateUtils',
    function (DateUtils) {

        var invoices = {};
        var total = 0;
        var invoices_with_total;

        function setInvoices(invoicesToDefer) {
            invoices = invoicesToDefer;
        }

        function getInvoices() {
            return invoices;
        }

        function setInvoicesWithTotal(invoicesWithTotal) {
            invoices_with_total = invoicesWithTotal;
        }

        function getInvoicesWithTotal() {
            return invoices_with_total;
        }

        function setTotal(invoice_total) {
            total = Math.round(invoice_total * 100) / 100;
        }

        function getTotal() {
            return total;
        }

        function prepareDataToApi(data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].total == 0) {
                    data.splice(i, 1);
                    continue;
                }

                delete data[i].remittance;
            }
            return data;
        }

        return {
            setInvoices: setInvoices,
            getInvoices: getInvoices,
            setInvoicesWithTotal: setInvoicesWithTotal,
            getInvoicesWithTotal: getInvoicesWithTotal,
            setTotal: setTotal,
            getTotal: getTotal,
            prepareDataToApi: prepareDataToApi
        };

    }
]

export {CreateDeferredInvoices}
