function fileService($q, $http, $cookies, filesConfig, $routeParams) {
    var self = this;

    this.upload = function (file) {
        var formData = new FormData();
        formData.append('file', file, file.name);

        var xhr = new XMLHttpRequest();
        xhr.open('POST', filesConfig.API_URL, true);
        xhr.setRequestHeader($http.defaults.xsrfHeaderName, $cookies.get($http.defaults.xsrfCookieName));
        xhr.setRequestHeader('Authorization', $cookies.get('token_type') + ' ' + $cookies.get('token') )

        var deferred = $q.defer();
        xhr.addEventListener("load", function (evt) {
            if (200 <= xhr.status && xhr.status < 300) {
                deferred.resolve({ xhr: xhr, ev: evt });
            } else {
                deferred.reject({ xhr: xhr, ev: evt });
            }
        }, false);

        xhr.addEventListener("error", function (evt) {
            deferred.reject({ xhr: xhr, ev: evt });
        }, false);

        xhr.addEventListener("abort", function (evt) {
            deferred.reject({ xhr: xhr, ev: evt });
        }, false);

        xhr.upload.addEventListener("progress", function (evt) {
            deferred.notify({ xhr: xhr, ev: evt });
        }, false);

        xhr.send(formData);

        file.xhr = xhr;

        return deferred.promise;
    };

    this.delete = function (file) {
        if (file.id) {
            return $http.delete(filesConfig.API_URL + file.id);
        }
    };

}

export { fileService };
