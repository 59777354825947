// Controllers
import { ReportsCtrl } from "./controllers/ReportsCtrl";

// Services
import { Report } from "./services/Report";

const reports = angular.module('reports', ['pascalprecht.translate', ]);

reports.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

reports
    .controller('ReportsCtrl', ReportsCtrl)
    .service('Report', Report)

export { reports }
