function mainNavigation(){
    return {
        templateUrl: require('../../templates/main-navigation.html'),
        controller: function($scope, $cookies, UserInfo){
            $scope.isLogged = $cookies.get('token') != undefined;

            $scope.SUPPLIES_ENABLED = false;
            $scope.CLIENTS_ENABLED = false;
            $scope.PROVIDERS_ENABLED = false;
            $scope.AGREEMENTS_ENABLED = false;
            $scope.PRODUCTS_ENABLED = false;
            $scope.PORTFOLIO_ENABLED = false;
            $scope.REPORTS_ENABLED = false;
            $scope.COMMERCIAL_ENABLED = false;
            $scope.ISSUES_ENABLED = false;
            $scope.COMPLAINTS_ENABLED = false;
            $scope.COMPLAINTS_ENABLED = false;
            $scope.ATR_COMPLAINTS_ENABLED = false;
            $scope.META_ENABLED = false;

            if($scope.isLogged){
                $scope.user = UserInfo.userInfo

                $scope.UserInfo = UserInfo
            }
        }
    };
}

export {mainNavigation}