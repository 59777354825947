// const contractUnsubscribe = function
function contractUnsubscribe(UserInfo, Alert){
    return {
        restrict: 'AE',
        //templateUrl: require('../../static/contracts/templates/contract-detail-unsubscribe.html'),
        templateUrl: require('../../templates/contract-detail-unsubscribe.html'),
        controller: function ($scope, $http, $rootScope, $filter, $routeParams, ContractDetail) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var self = this;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.id = $routeParams.id;
            $scope.flafUnsubscribe = false;
            $scope.motive = '';

            $scope.hasPerm = UserInfo.hasPerm('contracts.can_unsubscibe_contract_request');

            $scope.motives = {
                "01": 'Cese de Actividad',
                "02": 'Fin de contrato de energía',
                "03": 'Corte de suministro',
                "04": 'Baja por impago',
                "05": 'Baja sin desconexión definitiva de instalaciones'
            };

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest() {
                var element = {
                    id: $scope.id,
                    unsubscribe_contract: true,
                    motive : $scope.motive
                };

                ContractDetail.unsubscribeContract(element)
                    .then(function (contract) {
                        Alert.createAlert('success', $translate('CONTRACTS.EVENT_CREATED_FOR_CONTRACT'), true);
                    }).catch(function (error) {
                        console.log('ERROR', error)
                        for(var k in error){
                            $scope.error_message = error[k];
                            if (k === 'non_field_errors') {
                                $scope.error_message = error[k][0];
                                Alert.createAlert('danger', $scope.error_message, true);
                            }
                        }
                    }).finally(function () {
                        $scope.flafUnsubscribe = false;
                        $scope.motive = '';
                    });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.clickUnsubscribeYes = function () {
                performRequest();
            };

            $scope.confirmUnsubscribe = function () {
                $scope.flafUnsubscribe = true;
            };

            $scope.clickUnsubscribeNo = function () {
                $scope.flafUnsubscribe = false;
                $scope.motive = '';
            };

        }
    }
}

export {contractUnsubscribe}
