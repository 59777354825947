const ProgrammingUnitsListCtrl = ['$scope', '$filter', '$location', 'Alert', 'UserInfo', 'ProgrammingUnitAPI',
    function ($scope, $filter, $location, Alert, UserInfo, ProgrammingUnitAPI) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.programming = $scope.result;
        $scope.programmings = $scope.results;

        $scope.mode = 'detail';
        $scope.action = 'detail';
        $scope.loading = false;
        $scope.edited_programming = { id: "", name: "", description: "", electrical_subsystem: "" };

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        $scope.$on('programmingsListReload', function (event) {
            $scope.listReload();
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.viewProgrammingUnit = function(programming) {
            $location.path( '/programming_units/' + programming.id);
        };

        $scope.deleteProgrammingUnit = function() { $scope.action = 'delete'; };
        $scope.cancelDeleteProgrammingUnit = function() { $scope.action = 'detail'; };
        $scope.confirmDeleteProgrammingUnit = function() {
            $scope.loading = true;

            ProgrammingUnitAPI.deleteProgrammingUnit($scope.programming.id).then(function (data) {
                Alert.createAlert('success', $translate('PROGRAMMING_UNITS.PROGRAMMING_UNIT_DELETED'), true);
                $scope.action = 'detail';
                $scope.listReload();
                $scope.loading = false;
            }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
                $scope.loading = false;
            });
        };

        $scope.canShowProgrammingUnit = function () {
            return UserInfo.hasPerm('programming_units.retrieve_programming_unit');
        };

        $scope.canDeleteProgrammingUnit = function () {
            return UserInfo.hasPerm('programming_units.delete_programmingunit');
        };

    }
]

export {ProgrammingUnitsListCtrl}
