const FCImportHistoryCtrl = ['$scope', '$http', '$window','notificationCenter', 'Task',
    function ($scope, $http, $window, notificationCenter, Task) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var self = this;
        var show = false;

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/
        this.loadData = function () {
            $scope.results = [];
            $http.get($scope.current).then(function (data, status, headers, config) {
                $scope.count = data.data.count;
                $scope.next = data.data.next;
                $scope.previous = data.data.previous;
                $scope.loading = false;
            }).catch(function (data, status, headers, config) {
                $scope.loading = false;
            });
        };

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.on = function(){
            show = true;
            return show;
        };

        $scope.off = function(){
            show = false;
            return show;
        };

        $scope.changeDatas = function(status, result){
            if (status == 'KO'){
                result =result.replace(/\[/g, '');
                result =result.replace(/]/g, '');
                result = result.replace(/[&\/\\#+()$~%.'"*?<>{}]/g,'');
                result =result.replace(/,/g, "<br/>");
            }
            return result;
        };

        $scope.revertTask = function (task) {
            //alert(task.status);
            var taskModel = new Task(task);
            taskModel.revert().then(function (data, status, headers, config) {
                $scope.updateTask(data.data);
            }).catch(function (data, status, headers, config) {
                // TODO Better errors control
            });
        };

        $scope.updateTask = function(updated_task) {
            for (var i = 0; i < $scope.results.length; i++) {
                var task = $scope.results[i];
                if (task.id == updated_task.id) {
                    $scope.results[i] = updated_task;
                    $scope.results[i].updated = true
                    break;
                }
            }
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        notificationCenter.subscribe('BG_TASK_ENDS', function (type, data) {
            $scope.$apply(function(){
                $scope.updateTask(data);
            });
        });

        notificationCenter.subscribe('FG_RELOAD_LIST', function (type, data) {
            $scope.listReload();
        });

    }
]

export { FCImportHistoryCtrl }
