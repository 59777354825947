const CustomersCtrl = ['$scope', '$filter',
    function ($scope, $filter) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.searchBy = 'id';
        $scope.searchByLabel = $translate('CUSTOMERS.NO_CLIENT');
        $scope.searchFilters = {
            'vat_id': $translate('CUSTOMERS.NIF/CIF'),
            'fullname': $translate('CUSTOMERS.CUSTOMER_FULLNAME'),
            'contract_id': $translate('CUSTOMERS.CONTRACT_NUM'),
            'id': $translate('CUSTOMERS.NO_CLIENT'),
            'search': $translate('CUSTOMERS.ALL')
        };
        $scope.searchBy = Object.keys($scope.searchFilters)[0];
        $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];

        $scope.agreements = [];
        $scope.results = [];

        $scope.ordering = {
            "id": $translate('CUSTOMERS.NO_CLIENT'),
            "name": $translate('CUSTOMERS.NAME'),
            "vat_id": $translate('CUSTOMERS.NIF/CIF'),
            "state": $translate('CUSTOMERS.STATE')
        };
        $scope.filters = {
            search:"",
            id: "",
            status: null,
            agreement: null,
            state: null,
            vat_id: "",
            contract_id:"",
            fullname: "",
            label: ""
        };

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        $scope.$watch('searchQuery', function(newVal, oldVal){
            $scope.filters[$scope.searchBy] = newVal;
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.setSearchBy = function (val) {
            $scope.filters[$scope.searchBy] = '';
            $scope.searchBy = val;
            $scope.searchByLabel = $scope.searchFilters[val];
            $scope.filters[val] = $scope.searchQuery;
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
    }
]

export { CustomersCtrl };
