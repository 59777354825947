const FeesTabsCtrl = ['notificationCenter', '$scope',
    function(notificationCenter, $scope){
        $scope.FEES_LIST = require('../../templates/list-fees.html')
        $scope.FEES_GENERATION_REPORT = require('../../templates/fees-generation-report.html')

        $scope.selectedTab = 'list-fees';
        $scope.reloadList = function() {
            notificationCenter.publish('FG_RELOAD_LIST', {});
        };
    }
]

export { FeesTabsCtrl }
