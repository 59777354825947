// const renewProductRate = function
function renewProductRate(UserInfo, SUPPLY_RATES, filtersUtils, Alert, $filter, ProductRatesMaster) {
    return {
        restrict: 'AE',
        scope: {},
        //templateUrl: require('../../static/contracts/templates/renovation-product-rate.html'),
        templateUrl: require('../../templates/renovation-product-rate.html'),
        controller: function ($scope, $http, $rootScope) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/tasks/';
            var task = 'BPRP';
            var $translate = $filter('translate');
            var self = this;
            var first_access = true;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.listToUpdate = [];
            $scope.change_product_rate = '';

            $scope.hasPerm = UserInfo.hasPerm('contracts.change_product_rate');
            $scope.selected_product_rate = false;

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            $scope.$watch('productRate.$valid', function (newValue, oldValue) {
                $scope.$emit("setValidity", newValue);
                if (first_access == true)
                    first_access = false;
                else {
                    if (newValue)
                        $scope.selected_product_rate = true;
                }
            }, true);

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var json = {type: task, params: {ids: ids, values: {product_rate: $scope.change_product_rate}}};
                var config = {
                    method: "POST",
                    url: api,
                    data: json,
                    headers: {"Content-Type": "application/json;charset=utf-8"}
                };
                $http(config).
                    then(function(response) {
                        Alert.createAlert('success', $translate('CONTRACTS.BULK_UPDATE_QUEUED'), true);
                        $rootScope.$broadcast('refreshList');
                    }).
                    catch(function (response) {
                        Alert.createAlert('danger', $translate('CONTRACTS.ERROR_REQUEST'), true);
                    }).
                    finally(function () {
                        $scope.flagConfirm = false;
                        $scope.listToUpdate = [];
                    });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
                $scope.change_product_rate = '';
                $scope.selected_product_rate = false;
            };

            $scope.clickYes = function () {
                var ids = filtersUtils.getItemsToDownload($scope.listToUpdate);
                performRequest(ids);
                $scope.change_product_rate = '';
                $scope.selected_product_rate = false;
            };

            /***********************************************************************************************************
            * Controller Initialization
            ***********************************************************************************************************/
            ProductRatesMaster.loadProductRatesNotForce().then(function (response){
                $scope.product_rates = response;
            });

            ProductRatesMaster.loadProductRatesForce().then(function (response){
                $scope.product_rates_not_in_force = response;
            });

        }
    }
}

export {renewProductRate}
