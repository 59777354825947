
const ItemToBeInvoicedForContractDetailCtrl = 
        ['$scope', '$filter', '$routeParams', 'ContractAPI', 'UserInfo', 'Alert',
            function ($scope, $filter, $routeParams, ContractAPI, UserInfo, Alert) {
                /***********************************************************************************************************
                 * Controller Attributes
                 ***********************************************************************************************************/
                var $translate = $filter('translate');
                var contract_id = $routeParams.id;

                /***********************************************************************************************************
                 * Scope Attributes
                 ***********************************************************************************************************/
                $scope.action = 'detail';

                /***********************************************************************************************************
                 * Scope Watchers
                 ***********************************************************************************************************/


                /***********************************************************************************************************
                 * Controller Methods
                 ***********************************************************************************************************/


                /***********************************************************************************************************
                 * Scope Methods
                 ***********************************************************************************************************/
                $scope.deleteItem = function ($event) {
                    $event.preventDefault();
                    $scope.action = 'delete';
                };

                $scope.editItem = function ($event) {
                    $event.preventDefault();
                    $scope.action = 'edit';
                };

                $scope.cancelItem = function ($event) {
                    $event.preventDefault();
                    $scope.action = 'detail';
                };

                $scope.canDeleteItem = function (type) {
                    if (type == 'PENALTY') {
                        return UserInfo.hasPerm("invoices.delete_penaltyitem");
                    } else {
                        return UserInfo.hasPerm("invoices.delete_itemtobeinvoiced");
                    }
                };

                $scope.canEditItem = function (type) {
                    if (type == 'PENALTY') {
                        return UserInfo.hasPerm("invoices.change_penaltyitem");
                    } else {
                        return UserInfo.hasPerm("invoices.change_itemtobeinvoiced");
                    }

                };


                $scope.deleteContractItem = function (id) {
                    ContractAPI.deleteItemToBeInvoice(contract_id, id).then(function (data) {
                        $scope.listReload();
                        Alert.createAlert('success', $translate('CONTRACTS.ITEM_TO_BE_INVOICE_DELETED'), true);
                    }).catch(function (error) {
                        $scope.error_message = error.data.__all__[0];
                    });
                };

                $scope.updateItem = function (id) {
                    var data = {
                        "value": $scope.result.value,
                        "status": $scope.result.status,
                        "start_date": $scope.result.start_date,
                        "finish_date": $scope.result.finish_date,
                        "validity_date": $scope.result.validity_date
                    };

                    ContractAPI.updateItemToBeInvoice(contract_id, id, data).then(function (data) {
                        $scope.listReload();
                        Alert.createAlert('success', $translate('CONTRACTS.ITEM_TO_BE_INVOICE_UPDATED'), true);
                    }).catch(function (error) {
                        $scope.error_message = error.data.__all__[0];
                    });
                };

                /***********************************************************************************************************
                 * Controller Initialization
                 ***********************************************************************************************************/

            }]

export {ItemToBeInvoicedForContractDetailCtrl}
