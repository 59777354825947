const eventInfoService = [
    '$http', '$q', function ($http, $q) {
        var settings = {
            apiUrl: API_URL + '/api/1.0/masters/event-info/'
        };

        function getEventType() {
            var deferred = $q.defer();

            $http.get(settings.apiUrl)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function getEventCategory(type) {
            var deferred = $q.defer();

            $http.get(settings.apiUrl + '?type=' + type)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function getEventStatus(type, category) {
            var deferred = $q.defer();

            $http.get(settings.apiUrl + '?type=' + type + '&category=' + category)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            getEventType: getEventType,
            getEventCategory: getEventCategory,
            getEventStatus: getEventStatus
        };

    }
]

export {eventInfoService}

/*
// OTHER WAY TO DO THAT
angular.module('masters')
.factory('statusService', [
    '$http', function ($http) {
        var settings = {
            apiUrl: API_URL + '/api/1.0/masters/event-status/',
        };
        return {
            getEventStatus: function(type, category, callback){
                $http.get(settings.apiUrl + '?type=' + type + '&category=' + category).then(callback);
            }
        };
    }
]);*/