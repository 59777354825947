const ProviderDetailCtrl = ['$scope', '$routeParams', '$filter', 'UserInfo', 'Alert', 'stringUtils', 'ProviderAPI', 'StatesMaster', 'CitiesMaster', 'BanksMaster',
    function ($scope, $routeParams, $filter,  UserInfo, Alert, stringUtils, ProviderAPI, StatesMaster, CitiesMaster, BanksMaster) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.id = $routeParams.id;
        $scope.loading = true;
        $scope.expirationReferences = [
            {code: 'EMI', label: $translate('PROVIDERS.EMISSION_DATE')},
            {code: 'CRE', label: $translate('PROVIDERS.CREATION_DATE')},
            {code: 'PPR', label: $translate('PROVIDERS.RECEIVE_PAPER_DATE')}
        ]

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        // Asignacion de STATE a uno que exista en el master de STATES
        $scope.$watchGroup(['providerStateFlag', 'statesFlag'], function(newValues, oldValues, scope) {
            if (newValues[0] && newValues[1]){
                $scope.provider.state = stringUtils.updateState($scope.states, $scope.provider.state);
            }
        });

        $scope.$watchGroup(['provider.phone', 'provider.mobile'], function (newValues, oldValues, scope) {
            scope.hasPhone = false;
            if (newValues[0] !== null && newValues[0] !== '')
                scope.hasPhone = true;

            if (newValues[1] !== null && newValues[1] !== '')
                scope.hasPhone = true;
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.change_domiciled = function (value) {
            $scope.editProvider.$setDirty();
            $scope.provider.domiciled = value;
        };

        $scope.updateProvider = function () {
            // To clean Api errors
            $.each($scope.provider, function(key, value) {
                if ($scope.editProvider['provider_' + key] !== undefined)
                    $scope.editProvider['provider_' + key].$error.api = null;
            });

            ProviderAPI.updateProvider($scope.id, $scope.provider).then(function (provider) {
                Alert.createAlert('success', $translate('PROVIDERS.Provider Updated'), true);
                $scope.provider = provider;
                $scope.editProvider.$setPristine();
                $scope.editProvider.$setUntouched();
            }).catch(function (error) {
                $.each(error, function(key, value) {
                    $scope.editProvider['provider_' + key].$error.api = value[0];
                });
            });
        };

        $scope.canViewDetail = function () {
            return UserInfo.hasPerm('providers.retrieve_provider');
        };

        $scope.canViewEdit = function () {
            return UserInfo.hasPerm('providers.change_provider');
        };

        $scope.canViewProviderInvoices = function () {
            return (UserInfo.hasPerm('providers.list_providerinvoices') && UserInfo.hasPerm('providers.retrieve_provider'));
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        ProviderAPI.getProvider($scope.id).then(function (provider) {
            $scope.provider = provider;
            $scope.loading = false;
            $scope.providerStateFlag = true;
        }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
        });

        StatesMaster.loadStates().then(function (response) {
            $scope.states = response;
            $scope.statesFlag = true;
        });

        CitiesMaster.loadCities().then(function(response){
            $scope.cities=response;
        });

        BanksMaster.loadBanks().then(function(response){
            $scope.banks=response;
        });

    }
]

export { ProviderDetailCtrl }
