const ProviderCreateCtrl = ['$scope', '$filter', '$location', 'ProviderAPI', 'UserInfo', 'Alert',
    function ($scope, $filter, $location,  ProviderAPI, UserInfo, Alert) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.create_provider = {};

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.resetForm = function () {
            $scope.create_provider = {};
            $scope.createProviderForm.$setPristine();
            $scope.createProviderForm.$setUntouched();
        };

        $scope.createProvider = function () {
            // To clean Api errors
            $.each($scope.create_provider, function(key, value) {
                if ($scope.createProviderForm['provider_' + key] !== undefined)
                    $scope.createProviderForm['provider_' + key].$error.api = null;
            });

            ProviderAPI.createProvider($scope.create_provider).then(function (provider) {
                $scope.resetForm();
                Alert.createAlert('success', $translate('PRODUCTS.PRODUCT_CREATED_SUCCESSFULLY'), true);
                $location.path("/providers/" + provider.id);
            }).catch(function (error) {
                $.each(error, function(key, value) {
                    $scope.createProviderForm['provider_' + key].$error.api = value[0];
                });
            });
        };

        $scope.canCreate = function () {
            return UserInfo.hasPerm('providers.add_provider');
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/

    }
]

export { ProviderCreateCtrl }
