const listedFeeCtrl = ['$scope', '$window',
    function ($scope, $window) {
        var self = this;

        $scope.downloadCSV = function(agreement, month, year) {
            var date_from =  year + '-' + month + '-01';
            var lastDayOfMonth = new Date(parseInt(year), parseInt(month), 0);
            lastDayOfMonth = lastDayOfMonth.getDate();

            var date_to =  year + '-' + month + '-' + lastDayOfMonth;
            var params = '?agreement=' + agreement + '&date_from=' + date_from + '&date_to=' + date_to;
            var url = API_URL + '/fees/download/csv/'+ params;
            $window.open(url);
        }

    }
]

export { listedFeeCtrl }
