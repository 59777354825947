{
  "TASKS": {
    "TITLE": "Background tasks",
    "ERROR": "Error",
    "TODO": "To do",
    "TO_CANCEL": "To cancel",
    "TO_UNDO": "To undo",
    "UNDOING": "Undoing",
    "DOING": "Doing",
    "CANCELED": "Canceled",
    "FINISHED": "Finished",
    "DISCARDED": "Discarded",
    "DOWNLOAD": "Download",
    "UNDO": "Undo",
    "CANCEL": "Cancel",
    "REPORT": "Report",
    "REVERT":"Revert"
  }
}