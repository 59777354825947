function chooseFiles() {
    return {
        require: '^ngModel',
        scope: {
            files : '=ngModel',
        },
        restrict: 'E',
        template: '<div class="file-dropper"><div class="dropzone" ng-class="{ success : isInside }"><span class="fa fa-cloud-upload fa-2x"></span><br>{{ "FILES.CLICK_HERE" | translate }}<input type="file" multiple style="display: none"></div></div>',
        link: function ($scope, $element) {

            // input file change handler to load selected files
            $element.find('input').on('change', function ($event) {
                $event.stopPropagation();
                $event.preventDefault();
                $scope.addFiles(this.files);
            });

            $element.find('input').parent().on('click', function($event){
                $element.find('input')[0].click();
            });

            // drag'n'drop zone handlers
            $element.on('dragenter', function (e) {
                var ev = e || event;
                ev.stopPropagation();
                ev.preventDefault();
                $scope.$apply(function () {
                    $scope.isInside = true;
                });
            }).on('dragleave', function (e) {
                var ev = e || event;
                ev.stopPropagation();
                ev.preventDefault();
                $scope.$apply(function () {
                    $scope.isInside = false;
                });
            }).on('dragover', function (e) {
                var ev = e || event;
                ev.stopPropagation();
                ev.preventDefault();
                $scope.$apply(function () {
                    $scope.isInside = true;
                });
            }).on('drop', function (e) {
                var ev = e || event;
                var files = ev.target.files || ev.dataTransfer.files;
                ev.stopPropagation();
                ev.preventDefault();
                $scope.isInside = false;
                $scope.addFiles(files);
            });

        },
        controller: function ($scope) {
            $scope.isInside = false;
            $scope.files = [];
            $scope.addFiles = function (files) {
                $scope.$apply(function () {
                    for (var i = 0; i < files.length; i++) {
                        var file = files[i];
                        $scope.files.push( file );
                    }
                    $scope.$emit("filesAdded", files);
                });
            };
        }
    }
}

export { chooseFiles }