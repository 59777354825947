const APISelectService = ['$q', '$http',
    function ($q, $http) {

        var url = null;
        var key = null;
        var value = null;

        function init(f_url, f_key, f_value) {
            _init(f_url, f_key, f_value);
        }

        function _init(f_url, f_key, f_value) {
            url = f_url;
            key = f_key;
            value = f_value;
        }

        function getData() {
            return _getData();
        }

        function _getData() {
            var deferred = $q.defer();

            $http.get(API_URL + url)
                .then(function (data) {
                    if (data.data.results !== undefined) {
                        data = data.results;
                    }
                    var result = {};
                    angular.forEach(data.data, function(item) {
                        result[item[key]] = item[value];
                    });

                    deferred.resolve(result);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            getData: getData,
            init: init
        };

    }
]

export { APISelectService };
