const FCGenerationHistoryCtrl = ['$scope', '$http', '$window','notificationCenter', 'Task', 'FEE_GENERATION_TASK', 'UserInfo',
    function ($scope, $http, $window, notificationCenter, Task, FEE_GENERATION_TASK, UserInfo) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var self = this;

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.results = [];

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/
        this.loadData = function () {
            $scope.results = [];
            $http.get($scope.current).then(function (data, status, headers, config) {
                $scope.count = data.data.count;
                $scope.next = data.data.next;
                $scope.previous = data.data.previous;
                $scope.loading = false;
            }).catch(function (data, status, headers, config) {
                $scope.loading = false;
            });
        };

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.updateTask = function(updated_task) {
            for (var i = 0; i < $scope.results.length; i++) {
                var task = $scope.results[i];
                if (task.id == updated_task.id) {
                    $scope.results[i] = updated_task;
                    $scope.results[i].updated = true
                    break;
                }
            }
        };

        $scope.canDownloadFees = function () {
            return UserInfo.hasPerm('fees.download_generated_fees_excel');
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        notificationCenter.subscribe('BG_TASK_ENDS', function (type, data) {
            $scope.$apply(function(){
                $scope.updateTask(data);
            });
        });

        notificationCenter.subscribe('FG_RELOAD_LIST', function (type, data) {
            $scope.listReload();
        });

    }
]

export { FCGenerationHistoryCtrl }
