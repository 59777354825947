// const contractListFiltersToInvoice = function
function contractListFiltersToInvoice(SUPPLY_RATES){
    return {
        scope: {
            filters: '='
        },
        //templateUrl: require('../../static/contracts/templates/contracts-list-filters-to-invoice.html'),
        templateUrl: require('../../templates/contracts-list-filters-to-invoice.html'),
        controller: ['$scope', '$filter', 'ProductRatesMaster', 'AgreementsMaster', 'CONTRACT_STATUS', '$routeParams', '$location',
            function ($scope, $filter, ProductRatesMaster, AgreementsMaster, CONTRACT_STATUS, $routeParams, $location) {
                /***********************************************************************************************************
                * Controller Attributes
                ***********************************************************************************************************/
                var $translate = $filter('translate');

                /***********************************************************************************************************
                * Scope Attributes
                ***********************************************************************************************************/
                $scope.searchQuery = '';
                $scope.searchBy = 'search';
                $scope.searchByLabel = $translate('CONTRACTS.ALL');
                $scope.searchFilters = {
                    'id' : $translate('CONTRACTS.CONTRACT_NUM'),
                    'cups': $translate('CONTRACTS.SUPPLY_CUPS'),
                    'vat_id': $translate('CONTRACTS.Vat id')
                };
                $scope.searchBy = Object.keys($scope.searchFilters)[0];
                $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];
                $scope.statuses = CONTRACT_STATUS;

                $scope.rate = SUPPLY_RATES;

                $scope.invoicing_modes = [
                    {code: 'NOR', label: $translate('INVOICES.NORMAL_INVOICE')},
                    {code: 'IND', label: $translate('INVOICES.INDEXED_INVOICE')},
                    {code: 'INB', label: $translate('INVOICES.INDEXED_INVOICE_WITHOUT_ELEVATION')},
                    {code: 'Q11', label: $translate('INVOICES.Q101')},
                    {code: 'EST', label: $translate('INVOICES.ESTIMATED_INVOICE')}
                ];

                $scope.filters = {};

                /***********************************************************************************************************
                * Scope Watchers
                ***********************************************************************************************************/
                $scope.$watch('searchQuery', function(newVal, oldVal){
                    $scope.filters[$scope.searchBy] = newVal;
                });

                /***********************************************************************************************************
                * Scope Methods
                ***********************************************************************************************************/
                $scope.setSearchBy = function(val) {
                    if($scope.searchBy != val){
                        $scope.filters[$scope.searchBy] = '';
                        $scope.searchBy = val;
                        $scope.searchByLabel = $scope.searchFilters[val];
                        $scope.filters[val] = $scope.searchQuery;
                    }
                };

                /***********************************************************************************************************
                * Controller Initialization
                ***********************************************************************************************************/
                AgreementsMaster.loadAgreements().then(function (response){
                    $scope.agreements = response;
                });

                ProductRatesMaster.loadProductRatesNotForce().then(function (response){
                    $scope.product_rates = response;
                });

                ProductRatesMaster.loadProductRatesForce().then(function (response){
                    $scope.product_rates_not_in_force = response;
                });
            }
        ]
    };
}

export {contractListFiltersToInvoice}
