const DeleteBankCtrl = ['$scope', '$filter', 'Bank', 'Alert', 'UserInfo',
    function ($scope, $filter, Bank, Alert, UserInfo) {
        var $translate = $filter('translate');

        var self = this;

        $scope.deleteBank = function () {
            $scope.action = 'delete';
        };

        $scope.cancelDeleteBank = function () {
            $scope.action = 'detail';
        };

        $scope.confirmDeleteBank = function (bank) {
            $scope.loading = true;

            Bank.delete({id: bank.id}, function () {
                $scope.loading = false;
                $scope.listReload();
                Alert.createAlert('success', $translate('BANKS.DELETE_BANK_OK'), true);
            }).catch(function (error) {
                $scope.loading = false;
                Alert.createAlert('danger', $translate('BANKS.DELETE_BANK_ERROR'), true);
            });
        };

        $scope.canDeleteBank = function () {
            return UserInfo.hasPerm('banks.delete_bank');
        };

    }
]

export { DeleteBankCtrl }
