const NotificationsCtrl = ['$scope', '$cookies', 'notificationSound', 'notificationCenter', 'createHTML5Notification',
    function ($scope, $cookies, notificationSound, notificationCenter, createHTML5Notification) {
        var self = this;

        this.isEmpty = function isEmpty(myObject) {
            for(var key in myObject) {
                if (myObject.hasOwnProperty(key)) {
                    return false;
                }
            }

            return true;
        };

        var maxNotifications = 10;
        $scope.notifications = [];
        $scope.newNotifications = false;
        $scope.readNotifications = function () {
            $scope.newNotifications = false;
        };

        notificationCenter.subscribe('BG_TASK_ENDS', function (type, data) {
            var status = '';

            switch (data['status']) {
                case 'ERRO':
                    status = 'Error';
                    break;
                case 'TODO':
                    status = 'Por hacer';
                    break;
                case 'TCAN':
                    status = 'Por cancelar';
                    break;
                case 'TREV':
                    status = 'Por deshacer';
                    break;
                case 'RING':
                    status = 'Deshaciendo';
                    break;
                case 'DING':
                    status = 'Ejecutando';
                    break;
                case 'CANC':
                    status = 'Cancelada';
                    break;
                case 'DONE':
                    status = 'Finalizada';
                    break;
                case 'REVT':
                    status = 'Deshecha';
                    break;
            }
            data.message = data['name'] + ' ' + status.toLowerCase();

            if (data.result == 'KO') {
                status = 'Error'
            }

            createHTML5Notification.sendNotification(data['name'], {'body': status});

            $scope.$apply(function () {
                notificationSound.play();
                $scope.newNotifications = true;
                $scope.notifications.unshift(data);
                if ($scope.notifications.length > maxNotifications) {
                    $scope.notifications.pop();
                }

            });
        });

        notificationCenter.subscribe('BG_TASK_UNPAID_TO_PAID', function (type, data) {
            data.message = data['name'] + ' ' + status.toLowerCase();

            createHTML5Notification.sendNotification('Alerta facturas impagadas', {'body': data['name']});

            $scope.$apply(function () {
                notificationSound.play();
                $scope.newNotifications = true;
                $scope.notifications.unshift(data);
                if ($scope.notifications.length > maxNotifications) {
                    $scope.notifications.pop();
                }

            });
        });

        notificationCenter.subscribe('BG_TASK_INVOICING_FAIL', function (type, data) {
            data.message = data['name'] + ' ' + status.toLowerCase();

            createHTML5Notification.sendNotification('Fallo facturacion contrato', {'body': data['name']});

            $scope.$apply(function () {
                notificationSound.play();
                $scope.newNotifications = true;
                $scope.notifications.unshift(data);
                if ($scope.notifications.length > maxNotifications) {
                    $scope.notifications.pop();
                }

            });
        });

        notificationCenter.subscribe('DOWNLOAD_FILE', function (type, data) {
            if (data.result == 'KO') {
                createHTML5Notification.sendNotification('POWR', {'body': 'Error en la realización de la tarea'});
            } else if (data.result == 'OK') {
                if (data.type == "IPDF")
                    createHTML5Notification.sendNotification('POWR', {'body': 'Generacion de facturas finalizada'});
                else {
                    createHTML5Notification.sendNotification('POWR', {'body': 'La descarga ha comenzado'});

                    var url = API_URL + '';

                    if (data.type == "IVDW")
                        url = API_URL + '/invoices/download/pdf-bundle/' + data.result_data.file;
                    else if (data.type == 'ICSV')
                        url = API_URL + '/invoices/download/csv/' + data.result_data.file;
                    else if (data.type == 'SCSV')
                        url = API_URL + 'supplies/download/csv/' + data.result_data.file;
                    else if (data.type == 'ECSV')
                        url = API_URL + '/issues/downloads/csv/' + data.result_data.file;
                    else if (data.type == "PCSV")
                        url = API_URL + '/provider-invoices/download/csv/' + data.result_data.file;
                    else if (data.type == "PDWN")
                        url = API_URL + '/provider/download/csv/' + data.result_data.file;
                    else if (data.type == "GCRP")
                        url = API_URL + '/provider-invoices/download/csv/' + data.result_data.file;
                    else if (data.type == "GREP" || data.type == 'BPRP' || data.type == 'CCPR' || data.type == 'ECAD')
                        url = API_URL + '/reports/download/' + data.result_data.file;
                    else if (data.type == 'DCSV')
                        url = API_URL + '/debts/download/csv/' + data.result_data.file;
                    else if (data.type == 'CCSV')
                        url = API_URL + '/contracts/download/excel/' + data.result_data.file;
                    else if (data.type == 'RCSV')
                        url = API_URL + '/remittances/download/csv/' + data.result_data.file;
                    else if (data.type == 'DCSV')
                        url = API_URL + '/remittances/download/csv/' + data.result_data.file;
                    else
                        url = API_URL + '/contracts/download/pdf-bundle/' + data.result_data.file;

                    window.open(url + '?token=' + $cookies.get("token"));
                }
            }
        });

    }
]

export { NotificationsCtrl }
