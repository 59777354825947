{
  "COMMENTS": {
    "TITLE": "Comments",
    "NO_COMMENTS": "No comments",
    "CONFIRM_DELETE": "Do you want to delete?",
    "COMMENT": "Comment",
    "WRITE_COMMENT": "Writes the comment...",
    "NO": "No",
    "YES": "Yes",
    "EDIT": "Edit"
  }
}