const ProviderInvoiceAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/provider-invoices/';

        var self = this;
        var provider_invoice = null;

        function getProviderInvoice(id) {
            return _getProviderInvoice(id);
        }

        function _getProviderInvoice(id) {
            var deferred = $q.defer();

            $http.get(url + id)
                .then(function (provider_invoice) {
                    deferred.resolve(provider_invoice);
                })
                .catch(function (error) {
                    deferred.reject(error);
                });

            self.id = id;
            return deferred.promise;
        }

        function createProviderInvoice(data){
            var deferred = $q.defer();

            $http.post(url, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function updateProviderInvoice(id, provider, partial) {
            var deferred = $q.defer();

            if (partial !== undefined && !partial){
                $http.put(url + id, provider)
                    .then(function (data) {
                        deferred.resolve(data.data);
                    })
                    .catch(function (error) {
                        deferred.reject(error.data);
                    });
            }
            else{
                $http.patch(url + id, provider)
                    .then(function (data) {
                        deferred.resolve(data.data);
                    })
                    .catch(function (error) {
                        deferred.reject(error.data);
                    });
            }

            return deferred.promise;
        }

        function deleteProviderInvoices(data) {
            var deferred = $q.defer();

            var sent = {
                data: data,
                headers: {"Content-Type": "application/json;charset=utf-8"}
            };

            $http.delete(url, sent)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function changeProviderInProviderInvoice(data) {
            return _bulkUpdateProviderInvoices(data);
        }

        function changeStatusProviderInvoice(data) {
            return _bulkUpdateProviderInvoices(data);
        }

        function setExpirationDate(data) {
            return _bulkUpdateProviderInvoices(data);
        }

        function setPaidDate(data) {
            return _bulkUpdateProviderInvoices(data);
        }

        function setReceivePaperInvoices(data) {
            return _bulkUpdateProviderInvoices(data);
        }

        function _bulkUpdateProviderInvoices(data) {
            var deferred = $q.defer();

            $http.put(url, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function resetItemsToBeInvoiced(id, data){
            var deferred = $q.defer();

            $http.post(url + id + '/reset-items/', data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function duplicateItemsToBeInvoiced(id, data){
            var deferred = $q.defer();

            $http.post(url + id + '/duplicate-items/', data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            getProviderInvoice: getProviderInvoice,
            createProviderInvoice: createProviderInvoice,
            updateProviderInvoice: updateProviderInvoice,
            deleteProviderInvoices: deleteProviderInvoices,
            changeProviderInProviderInvoice: changeProviderInProviderInvoice,
            changeStatusProviderInvoice: changeStatusProviderInvoice,
            setExpirationDate: setExpirationDate,
            setPaidDate: setPaidDate,
            setReceivePaperInvoices: setReceivePaperInvoices,
            resetItemsToBeInvoiced: resetItemsToBeInvoiced,
            duplicateItemsToBeInvoiced: duplicateItemsToBeInvoiced
        };
    }
]

export { ProviderInvoiceAPI }
