const LoginCtrl = ['$scope', '$http', '$window', '$cookies',
    function($scope, $http, $window, $cookies) {
        $scope.status = 'WAITING';

        $scope.login = function(){
            var data = {
                username : $scope.username,
                password : $scope.password
            };
            $scope.status = 'TRYING';
            $http.post(API_URL + '/jwt/', data).then(function(data) {
                $scope.status = 'LOGGED';

                $cookies.put('token', data.data.token)
                $cookies.put('token_type', 'JWT')

                let params = (new URL(document.location)).searchParams;
                let next = params.get("next");
          
                if(next){
                    let decoded_url = decodeURI(next)
  
                    $window.location.href = decoded_url
                }else{
                    $window.location.href = '/contracts';
                }

            }).catch(function(data) {
                if (data.status == 404) {
                    $scope.status = 'LOGIN_ERROR';
                } else {
                    $scope.status = 'UNKNOWN_ERROR';
                }
            });
            return false;
        };
    }
];

export {LoginCtrl}