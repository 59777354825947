const ProductPriceCtrl = ['$scope', '$routeParams', '$filter', 'ProductPriceAPI', 'UserInfo', 'Alert',
    function ($scope, $routeParams, $filter, ProductPriceAPI, UserInfo, Alert) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.code = $routeParams.code;

        $scope.types = [
            {name: 'FIXED', id: 'FIXD'},
            {name: 'PERCENT', id: 'PERC'},
            {name: 'DIFFERENCE', id: 'DIFF'}
        ];

        $scope.editionMode = false;
        $scope.deleteMode = false;

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        // Enable edition mode
        $scope.enableEdition = function () {
            $scope.editionMode = !$scope.editionMode;
        };

        $scope.enableDelete = function () {
            $scope.deleteMode = !$scope.deleteMode;
        };

        $scope.cancelModes = function () {
            $scope.deleteMode = false;
            $scope.editionMode = false;
        };

        // Update Price
        $scope.updatePrice = function(id) {
            ProductPriceAPI.updateProductPrice(id, $scope.result).then(function (product_price) {
                Alert.createAlert('success', $translate('PRODUCTS.PRODUCT_PRICE_UPDATED'), true);
                $scope.$emit('pricesListReload');
            }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
            });
        };

        $scope.deletePrice = function(id) {
            ProductPriceAPI.deleteProductPrice(id)
                .then(function (product_price) {
                    Alert.createAlert('success', $translate('PRODUCTS.PRODUCT_PRICE_DELETED'), true);
                    $scope.$emit('pricesListReload');
                }).catch(function (error) {
                    $scope.error_message = error.data.__all__[0];
                });
        };

        $scope.canEditPrice = function () {
            return (UserInfo.hasPerm("products.change_productprice"));
        };

        $scope.canDeletePrice = function () {
            return (UserInfo.hasPerm("products.delete_productprice"));
        };

    }
]

export { ProductPriceCtrl }
