const CreateProductPriceCtrl = ['$scope', '$filter', '$routeParams', 'ProductPriceAPI', 'Alert', 'ProductRatesMaster',
    function ($scope, $filter, $routeParams, ProductPriceAPI, Alert, ProductRatesMaster) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.code = $routeParams.code;

        $scope.types = [
            {name: 'FIXED', id: 'FIXD'},
            {name: 'PERCENT', id: 'PERC'},
            {name: 'DIFFERENCE', id: 'DIFF'}
        ];

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.resetForm = function () {
            $scope.price = {
                product: $scope.code,
                start_date: new Date()
            }
        };

        $scope.newPrice = function () {
            ProductPriceAPI.createProductPrice($scope.price).then(function (product_price) {
                $scope.resetForm();
                Alert.createAlert('success', $translate('PRODUCTS.PRICE_CREATED'), true);

                $scope.newPriceForm.$setPristine();
                $scope.newPriceForm.$setUntouched();
                $scope.listReload();
                $scope.$emit('pricesListReload');
            }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
            });

        };
        
        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        ProductRatesMaster.loadProductRatesNotForce().then(function (response){
            $scope.product_rates_prices = response;
        });

        $scope.resetForm();

    }
]

export { CreateProductPriceCtrl }
