{
  "COMPLAINTS": {
    "TITLE": "Complaints",
    "SEARCH": "Search...",
    "ALL": "All",
    "IDENTIFIER": "Identifier",
    "N_CONTRACT": "No. Contract",
    "CUPS": "CUPS",
    "WATIUM_CODE": "External Code",
    "CREATION_DATE": "Creation Date",
    "LAST_STATUS_CHANGE": "Last Status Change",
    "LAST_MOD_DATE": "Last Modification Date",
    "CLOSE_DATE": "Close Date",
    "NEW_COMPLAINT": "New complaint",
    "VIEW": "View",
    "DELETE": "Delete",
    "YES": "Yes",
    "NO": "No",
    "DELETE_COMPLAINT": "Delete complaint?",
    "DATE_RANGE": "Dates",
    "WITHOUT_FILTERS": "Without Filters",
    "DATE_FROM": "From",
    "DATE_TO": "To",
    "ANY": "Any",
    "STATUS": "Status",
    "CREATED": "Open",
    "PENDING_CUSTOMER": "Pending User",
    "PENDING_DISTRIBUTOR": "Pending Distributor",
    "PENDING_OTHERS": "Pending Others",
    "CLOSED": "Closed",
    "FAVORABLE": "Favorable",
    "UNFAVORABLE": "Unfavorable",
    "UNMANAGED": "Unmanaged",
    "COMPLAINT_DELETED": "Complaint has been deleted.",
    "COMPLAINT_DATA": "Complaint data",
    "COMMENTS": "Comments",
    "FILES": "Files",
    "LOGS": "Logs",
    "SYSTEM": "System",
    "ENABLE_COMPLAINT_EDITION": "Enable Edition",
    "UPDATE": "Update",
    "CANCEL": "Cancel",
    "COMPLAINT_INFO": "Complaint Info",
    "COMPLAINT_SUPPLY": "CUPS",
    "COMPLAINT_RATE_CODE": "Rate Code",
    "COMPLAINT_TOTAL_CUPS": "No. supplies affected",
    "COMPLAINT_CLAIMANT": "Claimant",
    "COMPLAINT_SUBTYPE": "Typo",
    "COMPLAINT_CHANNEL": "Channel",
    "COMPLAINT_WAITING_RESPONSE": "Waiting for response from:",
    "EXTERNAL_CODE": "External Code",
    "DISTRIBUTOR_CODE": "Distributor Code",
    "NEED_DISTRIBUTOR": "Need Distributor",
    "COMPLAINT_STATUS": "Status",
    "COMPLAINT_STATUS_INDICATOR": "Status Indicator",
    "COMPLAINT_RESULT": "Result",
    "COMPLAINT_TOTAL_DAYS": "Complaint total days",
    "COMPLAINT_DISTRIBUTOR_DAYS": "Distributor days",
    "COMPLAINT_COMMERCIAL_DAYS": "Commercial days",
    "COMPLAINT_CLIENT_DAYS": "Client days",
    "COMPLAINT_OTHER_DAYS": "Other days",
    "SUPPLY": "Supply",
    "CLAIMANT": "Claimant",
    "SUBTYPE": "Subtype",
    "CHANNEL": "Channel",
    "CREATE": "Create",
    "SELECT_ONE": "Select one",
    "COMPLAINT_CREATED_SUCCESSFULLY": "Complaint created successfully",
    "N_COMPLAINTS_CREATED_SUCCESSFULLY": "Complaints created successfully",
    "TYPE_MANDATORY": "This field is required",
    "ACTIONS": "Actions",
    "CHANGE_STATUS": "Change Status",
    "SELECT_STATUS": "Select Status",
    "SELECT_RESULT": "Select Result",
    "CHANGE_QUESTION": "Change Status?",
    "COMPLAINT_STATUS_UPDATED": "Complaint status updated.",
    "ERROR_REQUEST": "Complaint status can't be updated.",
    "COMPLAINT_UPDATE_SUCCESSFULLY": "Complaint updated successfully.",
    "COMPLAINT_STATE": "State",
    "INVALID_VALUE": "Invalid code.",
    "DEPARTMENT": "Department",
    "CURRENT_DEPARTMENT": "Current department",
    "MODIFICATION_DATE": "Modification date",
    "CONTRACT": "Contract",
    "CONTRACTS": "Contracts (ids separates by ,)",
    "DESCRIPTION": "Description",
    "COUNTERS_MANUAL_EDITION": "Counters manual edition",
    "SENT_COMPLAINT_ATR": "Create Event for Complaint",
    "SEARCH_BY_COMPLAINT_ID": "Search Complaint by Id",
    "CREATE_ATR_R1_EVENT": "Create Event",
    "COMPLAINT_INFO_PARAMS": "Params for subtype selected",
    "REQUIRED_FIELDS": "Required fields.",
    "EVENT_COMPLAINT_CREATED_SUCCESSFULLY": "Complaint Event create successfully",
    "ERROR_WHEN_CREATE_COMPLAINT_EVENT": "Error when create complaint event"
  }
}