const ContractAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/contracts/';
        var item_url = '/items/';

        var self = this;


        function updateContractsList(data) {
            return _updateContractsList(data);
        }

        function _updateContractsList(data) {
            var deferred = $q.defer();

            $http.put(url, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function updateCustomer(contract) {
            return _updateCustomer(contract);
        }

        function _updateCustomer(contract) {
            var deferred = $q.defer();

            contract.reason = 'Standard reason';

            $http.put(url + contract.id, contract)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function createItemToBeInvoice(data) {
            var deferred = $q.defer();
            var contract_id = data.contract;

            $http.post(url + contract_id + item_url, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function updateItemToBeInvoice(contract_id, id, contract) {
            return _updateItemToBeInvoice(contract_id, id,contract);
        }

        function _updateItemToBeInvoice(contract_id, id,contract) {
            var deferred = $q.defer();

            $http.put(url + contract_id + item_url + id, contract)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function deleteItemToBeInvoice(contract_id, id) {
            var deferred = $q.defer();

            $http.delete(url + contract_id + item_url + id)
                .then(function (contract) {
                    deferred.resolve(contract.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        return {
            updateContractsList: updateContractsList,
            updateCustomer: updateCustomer,
            createItemToBeInvoice: createItemToBeInvoice,
            updateItemToBeInvoice: updateItemToBeInvoice,
            deleteItemToBeInvoice: deleteItemToBeInvoice
        };
    }
]
        
export {ContractAPI}
