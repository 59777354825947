
const ConsumptionsListFormCtrl =  ['$scope', 'CreateContract', '$filter',
        function ($scope, CreateContract, $filter) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.message = $translate('CONTRACTS.CLOSE_PAGE');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$watchGroup(['contract.consumption_p1', 'contract.consumption_p2', 'contract.consumption_p3', 'contract.consumption_p4', 'contract.consumption_p5', 'contract.consumption_p6'],
                function (newValues, oldValues, scope) {
                    scope.hasConsumption = false;
                    for (var i=0;  i<6; i++) {
                        if (newValues[i] != null && newValues[i] != '') {
                            scope.hasConsumption = true;
                        }
                    }
                }
            );

            $scope.$watch('consumptionsList.$pristine', function(newVal, oldVal){
                if(!newVal)
                    $scope.$emit("setValidity", false);
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.skip = function(){
                CreateContract.setConsumptionsData(null);
                $scope.$emit("setValidity", true);
            };

            $scope.save = function(form) {
                CreateContract.setConsumptionsData($scope.contract);
                form.$setPristine();
                $scope.$emit("setValidity", true);
            };

        }]

export {ConsumptionsListFormCtrl}
