const InvoiceDetailCtrl = ['$scope', '$window', '$routeParams', 'Invoice', 'UserInfo', '$filter', '$http', 'Alert', 'CitiesMaster', 'StatesMaster', 'Contract', 'INVOICES_COVERS', 'CustomerAPI', 'SupplyAPI', 'BrandsList', 'exportUtils',
    function ($scope, $window, $routeParams, Invoice, UserInfo, $filter, $http, Alert, CitiesMaster, StatesMaster, Contract, INVOICES_COVERS, CustomerAPI, SupplyAPI, BrandsList, exportUtils) {
        
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');

        $scope.INVOICES_ACTIONS_DROPDOWN = require('../../templates/actions-dropdown-detail.html')
        $scope.INVOICES_DOWNLOADS_DROPDOWN = require('../../templates/downloads-dropdown-detail.html')
        $scope.INVOICES_READINGS_TAB = require('../../templates/invoices-detail/invoice-readings.html')
        $scope.INVOICES_POSTAL_DATA = require('../../templates/invoices-detail/postal-data.html')
        $scope.INVOICES_SUPPLY_DATA = require('../../templates/invoices-detail/supply-data.html')
        $scope.INVOICES_RECEIVER_DETAIL = require('../../templates/invoices-detail/receiver-detail.html')
        $scope.INVOICES_FEES_LIST = require('../../templates/invoices-fees-list.html')
        $scope.INVOICES_RECEIPITS_PAYMENTS = require('../../templates/invoices-detail/receipts-payments.html')
        $scope.INVOICES_DETAIL_LOGS = require('../../templates/invoices-detail-logs.html')
        $scope.INVOICES_RECEIPTS_LIST = require('../../templates/invoices-detail/receipts-payments/receipts-list.html')
        $scope.INVOICES_RECEIPTS_PAYMENTS_LIST = require('../../templates/invoices-detail/receipts-payments/payments-list.html')

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.editionMode = false;
        $scope.numberOfLines = 0;
        //$scope.canBeEdited = false;

        $scope.full = 'FULL';
        $scope.partial = 'PARTIAL';

        $scope.loading = true;
        $scope.indexed = null;
        $scope.id = $routeParams.id;

        $scope.invoiced_date = null;
        $scope.remit_date = null;
        $scope.start_date = null;
        $scope.finish_date = null;
        $scope.brands = [];
        $scope.statuses = [
            {code: 'PEN', label: $translate('INVOICES.PENDING')},
            {code: 'PAI', label: $translate('INVOICES.PAID')},
            {code: 'UNP', label: $translate('INVOICES.EXPIRED')},
            {code: 'DRF', label: $translate('INVOICES.DRAFT')},
            {code: "COP", label: $translate('INOICES.COMPENSATED')},
            {code: "CRC", label: $translate('INVOICES.CREDIT_RETURNED_CUSTOMER')}
        ];

        $scope.payment_methods = [
            {code: 'TRF', label: $translate('INVOICES.TRANSFER')},
            {code: 'DBT', label: $translate('INVOICES.DEBIT')},
            {code: 'B2B', label: $translate('INVOICES.DEBIT_B2B')}
        ];

        $scope.receipts_ordering = {
            "total": $translate('BANKS.TOTAL'),
            "date": $translate('BANKS.DATE')
        };

        $scope.receipts_filtering = {
            "invoice": $scope.id
        };

        $scope.covers = INVOICES_COVERS;

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        function canBeEdited() {
            return false

            /*((UserInfo.hasPerm("invoices.edit_draft_invoice") && $scope.invoice.status == 'DRF')
            || (UserInfo.hasPerm("invoices.edit_invoiced_invoice") && $scope.invoice.status == 'INV')
            || (UserInfo.hasPerm("invoices.edit_invoiced_invoice") && $scope.invoice.status == 'PAI')
            || (UserInfo.hasPerm("invoices.edit_invoiced_invoice") && $scope.invoice.status == 'UNP'));
            */
        }

        function getEditionMode() {

            if (UserInfo.hasPerm("invoices.edit_invoiced_invoice") || ((UserInfo.hasPerm("invoices.edit_draft_invoice") && $scope.invoice.status == 'DRF'))) {
                return $scope.full;
            }

            if (UserInfo.hasPerm("invoices.edit_draft_invoice")) {
                return $scope.partial;
            }

            return false;
        }

        function getRepeatedIdnMessage(idn) {
            return $translate('INVOICES.UPDATE_SUCCESS')
                + "<br><b>"
                + $translate('BANKS.IT_EXISTS')
                + "&nbsp;<a ng-href='/invoices/" + idn + "'>"
                + $translate('BANKS.ANOTHER_INVOICE')
                + "</a>&nbsp;"
                + $translate('BANKS.WITH_SAME_IDN')
                + "</b>"
        }

        function formatDate(date, splitChar) {
            if (date != null && date !== '') {
                var tmpDate = date.split(splitChar);
                if (tmpDate.length === 3) {
                    var day = tmpDate[0];
                    var month = tmpDate[1];
                    var year = tmpDate[2];
                    return '' + year + '-' + month + '-' + day;
                }
            } else {
                return null
            }
        }

        $scope.onChangeInvoiceDate = function (date) {
            $scope.invoiced_date = date.lDate ? date.lDate.replace(/\//g, '-') : null;
        };

        $scope.onChangeRemitDate = function (date) {
            $scope.remit_date = date.lDate ? date.lDate.replace(/\//g, '-') : null;
        };

        $scope.onChangeStartDate = function (date) {
            $scope.start_date = date.lDate ? date.lDate.replace(/\//g, '-') : null;
        };

        $scope.onChangeFinishDate = function (date) {
            $scope.finish_date = date.lDate ? date.lDate.replace(/\//g, '-') : null;
        };

        $scope.format_lines = function (invoice_lines) {
            let formated_lines = JSON.parse(JSON.stringify(invoice_lines));

            angular.forEach(formated_lines, function (value, key) {
                let array_start_date = value.start_date.match(/^(\d{2})\/(\d{2})\/(\d{4})$/)
                value.start_date = array_start_date[3] + '-' + array_start_date[2] + '-' + array_start_date[1]
                let array_finish_date = value.finish_date.match(/^(\d{2})\/(\d{2})\/(\d{4})$/)
                value.finish_date = array_finish_date[3] + '-' + array_finish_date[2] + '-' + array_finish_date[1]
            });

            return formated_lines
        };

        $scope.adjustInvoice = function (invoice) {
            if (invoice.invoiced_date != null) {
                invoice.invoiced_date = new Date(invoice.invoiced_date);
            }

            if (invoice.remit_date != null) {
                invoice.remit_date = new Date(invoice.remit_date);
            }
            invoice.start_date = new Date(invoice.start_date);
            invoice.finish_date = new Date(invoice.finish_date);
            //invoice.remit_date = invoice.remit_date != null ? new Date(invoice.remit_date) : null;
            $scope.numberOfLines = invoice.invoice_lines.length;
            $scope.indexed = (invoice.invoicing_mode === 'IND' || invoice.invoicing_mode === 'INB' || invoice.invoicing_mode === 'INM');

            if (invoice.serie != undefined)
                invoice.serie = invoice.serie.id + ' - ' + invoice.serie.code;

            $scope.canBeEdited = canBeEdited();

            $scope.origins = null;
            try {
                if (invoice.metadata != null && invoice.metadata != '') {
                    var metadata = JSON.parse(invoice.metadata);
                    if ("xml_origin" in metadata)
                        $scope.origins = metadata.xml_origin;
                }
            } catch (e) {
                console.error('Cannot parse invoicemetadata')
            }

            $http.get(API_URL + '/api/1.0/contracts/' + invoice.contract.id + '/products').then(function (data, status, headers, config) {
                $scope.invoice_product = data.data;
                $scope.loading = false;
            }, function (data, status, headers, config) {
                $scope.loading = false;
            });

            angular.forEach(invoice.invoice_lines, function (value, key) {
                if (value.start_date != null) {
                    value.start_date = new Date(value.start_date);
                }
                if (value.finish_date != null) {
                    value.finish_date = new Date(value.finish_date);
                }
            });

            $scope.invoice = invoice;
        }
        
        $scope.getInvoice = function () {
            Invoice.get({id: $scope.id}, function (invoice) {
                $scope.adjustInvoice(invoice);
            });
        };

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        $scope.$on('refreshInterface', $scope.getInvoice);

        $scope.$watch('update.contract_id', function (newValue, oldValue) {
            if ($scope.update !== undefined) {
                delete $scope.update.customer;
                delete $scope.update.supply;
            }

            if (newValue !== undefined) {
                Contract.get({id: newValue}, function (contract) {
                    $scope.update.contract = contract;

                    CustomerAPI.getCustomerDetail($scope.update.contract.customer).then(function (response) {
                        let customer = response.data
                        
                        $scope.update.customer = customer;
                    });

                    SupplyAPI.getSupplyDetail($scope.update.contract.cups).then(function (response) {
                        let supply = response.data

                        $scope.update.supply = supply;
                    });

                    delete $scope.contractData.contract_id.$error.api;

                }, function () {
                    $scope.contractData.contract_id.$error.api = $translate("CONTRACTS.This contract number doesn't exists");
                });
            }
        });
        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.editMode = function () {
            //$scope.editionMode = !$scope.editionMode;
            if ($scope.editionMode) {
                $scope.editionMode = false;
            } else {
                $scope.editionMode = getEditionMode();
            }
        };

        $scope.editPartialInvoice = function () {


            var json = {
                'contract_payment_account': $scope.invoice.contract_payment_account,
                'contract_payment_name': $scope.invoice.contract_payment_name,
                'sepa': $scope.invoice.sepa,
                'postal_name': $scope.invoice.postal_name,
                'postal_address': $scope.invoice.postal_address,
                'postal_zip': $scope.invoice.postal_zip,
                'postal_city': $scope.invoice.postal_city,
                'payment_method': $scope.invoice.payment_method,
                'contract': $scope.invoice.contract.id,
                'observations': $scope.invoice.observations,
                'remit_date': $scope.remit_date,
                'partial': true
            };

            Invoice.patch({id: $scope.id}, json, function (invoice) {
                $scope.editMode();
                Alert.createAlert('success', $translate('INVOICES.UPDATE_SUCCESS'), true);
            }, function (error) {
                $scope.editMode();
                $scope.error_message = '';
                if (typeof error.data === 'string')
                    $scope.error_message = error.data;
                else {
                    for(let k in error.data){
                        $scope.error_message += error.data[k] + ' ';
                    }
                }
                Alert.createAlert('danger', $scope.error_message, true);
            });
        };
        
        $scope.editInvoice = function () {
            if ($scope.editionMode == $scope.partial)
                return $scope.editPartialInvoice();
            else if ($scope.editionMode == $scope.full) {
                var serie = $scope.invoice.serie ? $scope.invoice.serie.split(' -')[0] : null;
                
                let invoice_lines = $scope.format_lines($scope.invoice.invoice_lines)

                var json = {
                    'invoiced_date': $scope.invoiced_date,
                    'cover': $scope.invoice.cover,
                    'start_date': $scope.start_date,
                    'finish_date': $scope.finish_date,
                    'observations': $scope.invoice.observations,
                    'serie': serie,
                    'extra_expenses': $scope.invoice.extra_expenses,
                    'remit_date': $scope.remit_date,
                    'not_remittable': $scope.invoice.not_remittable,
                    'idn': $scope.invoice.idn,
                    'postal_contract_id': $scope.invoice.contract.id,
                    'contract_payment_account': $scope.invoice.contract_payment_account,
                    'contract_payment_name': $scope.invoice.contract_payment_name,
                    'postal_name': $scope.invoice.postal_name,
                    'postal_state': $scope.invoice.postal_state,
                    'postal_address': $scope.invoice.postal_address,
                    'postal_zip': $scope.invoice.postal_zip,
                    'postal_city': $scope.invoice.postal_city,
                    'supply_address': $scope.invoice.supply_address,
                    'supply_zip': $scope.invoice.supply_zip,
                    'supply_city': $scope.invoice.supply_city,
                    'sepa': $scope.invoice.sepa,
                    'contract': $scope.invoice.contract.id,
                    'customer_name': $scope.invoice.customer_name,
                    'customer_vat_id': $scope.invoice.customer_vat_id,
                    'customer_state': $scope.invoice.customer_state,
                    'customer_city': $scope.invoice.customer_city,
                    'customer_zip': $scope.invoice.customer_zip,
                    'customer_address': $scope.invoice.customer_address,
                    'customer_email': $scope.invoice.customer_email,
                    'customer_phone': $scope.invoice.customer_phone,
                    'customer_fax': $scope.invoice.customer_fax,
                    'customer_mobile': $scope.invoice.customer_mobile,
                    'brand': $scope.invoice.brand,
                    'payment_method': $scope.invoice.payment_method,
                    'hidden': $scope.invoice.hidden,
                    'invoice_lines': invoice_lines,
                    'total': $scope.invoice.total,
                    'tax_total': $scope.invoice.tax_total,
                    'base_total': $scope.invoice.base_total
                };
                
                if ($scope.invoice.related_invoice) {
                    var related_idn = $scope.invoice.related_invoice.idn;
                    Invoice.get({'idn': related_idn}, function (data) {
                        if (data.count == 1) {
                            json['related_invoice'] = data.results[0].id;
                        } else {
                            json['related_invoice'] = null;
                        }
                        Invoice.patch({id: $scope.id}, json, function (invoice) {
                            $scope.editMode();
                            Alert.createAlert('success', $translate('INVOICES.UPDATE_SUCCESS'), true);
                        }, function (error) {
                            $scope.editMode();
                            $scope.error_message = '';
                            if (typeof error.data === 'string')
                                $scope.error_message = error.data;
                            else {
                                for(let k in error.data){
                                    $scope.error_message += error.data[k] + ' ';
                                }
                            }
                            Alert.createAlert('danger', $scope.error_message, true);
                        });
                    });
                } else {
                    $scope.loading = true;

                    Invoice.patch({id: $scope.id}, json, function (invoice) {
                        $scope.loading = false;
                        $scope.adjustInvoice(invoice);
                        $scope.editMode();
                        Alert.createAlert('success', $translate('INVOICES.UPDATE_SUCCESS'), true);
                    }, function (error) {
                        $scope.editMode();
                        $scope.error_message = '';
                            if (typeof error.data === 'string')
                                $scope.error_message = error.data;
                            else {
                                for(let k in error.data){
                                    $scope.error_message += error.data[k] + ' ';
                                }
                            }
                            Alert.createAlert('danger', $scope.error_message, true);
                    });
                }
            }
        };

        $scope.update_invoice_data = function () {
            $scope.invoice.contract_payment_account = $scope.update.contract.payment_account;
            $scope.invoice.contract_payment_name = $scope.update.contract.payment_name;
            $scope.invoice.postal_name = $scope.update.contract.name + ' ' +
                $scope.update.contract.surname_1 + ' ' +
                $scope.update.contract.surname_2;
            $scope.invoice.postal_address = $scope.update.contract.address;
            $scope.invoice.postal_zip = $scope.update.contract.zip;
            $scope.invoice.postal_city = $scope.update.contract.city;
            $scope.invoice.postal_state = $scope.update.contract.state;
            $scope.invoice.sepa = $scope.update.contract.sepa_code;
            $scope.invoice.contract.id = $scope.update.contract.id;
            $scope.invoice.observations = $scope.update.contract.invoice_observations;
            $scope.invoice.supply_address = $scope.update.supply.address;
            $scope.invoice.supply_zip = $scope.update.supply.zip;
            $scope.invoice.supply_city = $scope.update.supply.city;
            $scope.invoice.customer_name = $scope.update.customer.fullname;
            $scope.invoice.customer_vat_id = $scope.update.customer.vat_id;
            $scope.invoice.customer_state = $scope.update.customer.state;
            $scope.invoice.customer_city = $scope.update.customer.city;
            $scope.invoice.customer_zip = $scope.update.customer.zip;
            $scope.invoice.customer_address = $scope.update.customer.address;
            $scope.invoice.customer_email = $scope.update.customer.email;
            $scope.invoice.customer_phone = $scope.update.customer.phone;
            $scope.invoice.customer_fax = $scope.update.customer.fax;
            $scope.invoice.customer_mobile = $scope.update.customer.mobile;
        };

        $scope.change_not_remittable_invoices = function (value) {
            var json = {
                'not_remittable': value
            };

            Invoice.patch({id: $scope.id}, json, function (invoice) {
                Alert.createAlert('success', $translate('INVOICES.UPDATE_SUCCESS'), true);
                $scope.invoice.not_remittable = value;
            }, function (error) {
                $scope.error_message = '';
                if (typeof error.data === 'string')
                    $scope.error_message = error.data;
                else {
                    for(let k in error.data){
                        $scope.error_message += error.data[k] + ' ';
                    }
                }
                Alert.createAlert('danger', $scope.error_message, true);
            });
        };

        $scope.change_display_web = function (value) {
            var json = {
                'hidden': value
            };

            Invoice.patch({id: $scope.id}, json, function (invoice) {
                Alert.createAlert('success', $translate('INVOICES.UPDATE_SUCCESS'), true);
                $scope.invoice.hidden = value;
            }, function (error) {
                $scope.error_message = '';
                if (typeof error.data === 'string')
                    $scope.error_message = error.data;
                else {
                    for(let k in error.data){
                        $scope.error_message += error.data[k] + ' ';
                    }
                }
                Alert.createAlert('danger', $scope.error_message, true);
            });
        };

        $scope.change_print_invoice = function (value) {
            var json = {
                'print_invoice': value
            }

            Invoice.patch({id: $scope.id}, json, function (invoice) {
                Alert.createAlert('success', $translate('INVOICES.UPDATE_SUCCESS'), true);
                $scope.invoice.print_invoice = value;
            }, function (error) {
                $scope.error_message = '';
                if (typeof error.data === 'string')
                    $scope.error_message = error.data;
                else {
                    for(let k in error.data){
                        $scope.error_message += error.data[k] + ' ';
                    }
                }
                Alert.createAlert('danger', $scope.error_message, true);
            });

        }

        $scope.canEdit = function () {
            return (UserInfo.hasPerm("invoices.edit_draft_invoice"));
        };

        $scope.downloadEInvoices = function () {
            return (UserInfo.hasPerm('invoices.download_einvoices')) && ($scope.numberOfLines > 0);
        };

        $scope.downloadIndexedData = function () {
            return (UserInfo.hasPerm('invoices.download_indexed_invoice_data')) && $scope.indexed;
        };

        $scope.downloadPDFInvoice = function () {
            return (UserInfo.hasPerm('invoices.download_invoices_pdf')) && ($scope.numberOfLines > 0);
        };

        $scope.canViewDetail = function () {
            return UserInfo.hasPerm('invoices.retrieve_invoices');
        };

        $scope.canSetPaymentInvoices = function () {
            return this.canAddPaymentInvoice() || this.canAddPaymentInvoice();
        };

        $scope.canRefundInvoice = function () {
            return UserInfo.hasPerm('invoices.refund_invoice');
        };

        $scope.canAddPaymentInvoice = function () {
            return UserInfo.hasPerm('invoices.add_payment_invoice');
        };

        $scope.canAddManualReceipt = function () {
            return UserInfo.hasPerm('banks.add_receipt');
        };

        $scope.canViewReceipts = function () {
            return UserInfo.hasPerm('banks.list_receipts');
        };

        $scope.canShowFees = function () {
            return UserInfo.hasPerm('fees.list_fees');
        };

        $scope.canAssignLabel = function () {
                return UserInfo.hasPerm('labels.add_labelledobject');
        };

        $scope.canDeleteAssignLabel = function () {
            return UserInfo.hasPerm('labels.delete_labelledobject');
        };

        $scope.download_lines = function (){
            exportUtils.to_excel($scope.invoice.invoice_lines, $scope.invoice.idn + '_lines')
        }

        BrandsList.all().then(function (response) {
            if(response)
                $scope.brands = [{
                    'code': '',
                    'name': $translate('BRANDS.NONE')
                }].concat(response)
        });

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        CitiesMaster.loadCities().then(function (response) {
            $scope.cities = response;
        });

        StatesMaster.loadStates().then(function (response) {
            $scope.states = response;
        });

        $http.get(API_URL + '/api/1.0/invoices-series/?draft=false').then(function (data, status, headers, config) {
            $scope.series = data.data.results;
        }, function (data, status, headers, config) {
        });

        $scope.getInvoice();

        /***********************************************************************************************************
         * Controller Events
         ***********************************************************************************************************/
        $window.onbeforeunload = function(e){
            if($scope.editionMode){
                return e;
            }else{
                return;
            }
        };

        $scope.$on('$routeChangeStart', function(e, next, current) {
            if($scope.editionMode){
                var result = confirm($translate('INVOICES.DETAIL_PREVENT_BACK'))

                if(!result){
                    e.preventDefault();
                }
            }
          });

        $scope.$on('$destroy', function() {
            $window.onbeforeunload = undefined
        })
    }
]

export { InvoiceDetailCtrl };
