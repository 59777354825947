const CitiesLoading = ['$resource', function ($resource) {
    return $resource(API_URL + '/api/1.0/masters/cities/');
}]

const CitiesMaster = ['$q', 'CitiesLoading',  function ($q, CitiesLoading) {

    var cities= {
        items: null,

        loadCities: function () {
            var self = this;
            var deferred = $q.defer();

            if (this.items == null)
                CitiesLoading.query(function (data) {
                                        self.items = data;
                    deferred.resolve(data);
                });
            else
                deferred.resolve(self.items);

            return deferred.promise;
        }
    };

    return cities;


}]

export {CitiesLoading, CitiesMaster}
