function userAccount(){
    return {
        templateUrl: require('../../templates/user-account.html'),
        controller: function($scope, $cookies, UserInfo){
            $scope.isLogged = $cookies.get('token') != undefined;

            if($scope.isLogged){
                $scope.user = UserInfo.userInfo

                $scope.UserInfo = UserInfo
            }
        }
    };
}

export { userAccount }
