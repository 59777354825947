function stringUtils(){

    function lightAccentClean(str) {
        str = str.replace(/[àáâãäå]/g,"a");
        str = str.replace(/[èéêë]/g, "e");
        str = str.replace(/[ìíîï]/g, "i");
        str = str.replace(/[òóôõö]/g, "o");
        str = str.replace(/[ùúûü]/g, "u");
        return str;
    }

    function sameWord(a, b) {
        if (a != null && b!= null) {
            a = a.toLowerCase();
            a = lightAccentClean(a);
            b = b.toLowerCase();
            return a.match(b);
        }
    }

    this.updateState = function (statesArray, state) {
        statesArray.forEach(function(s) {
            if (sameWord(s, state)) {
                state = s;
            }
        });
        return state;
    };
    
    this.splitStringByComma = function (str) {
        return str.split(',');
    };
    
    this.getExtraEmail = function (email) {
        if (email != undefined)
            return email.trim();
        return '';
    };
    
    this.rebuiltExtraEmails = function (email1, email2, email3, email4) {
        var mail1 = '';
        var mail2 = '';
        var mail3 = '';
        var mail4 = '';
        if (email1 != undefined || email1 != null)
            mail1 = email1;
        if (email2 != undefined || email2 != null)
            mail2 = email2;
        if (email3 != undefined || email3 != null)
            mail3 = email3;
        if (email4 != undefined || email4 != null)
            mail4 = email4;
        return mail1 + ', ' + mail2 + ', ' + mail3 + ', ' + mail4;
    };

}

export {stringUtils};
