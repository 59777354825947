{
  "PRODUCTS": {
    "TITLE": "Products",
    "NEW_PRODUCT": "New product",
    "SEARCH": "Search...",
    "CODE": "Code",
    "PRODUCT_NAME": "Product name",
    "DESCRIPTION": "Description",
    "PRICE": "Price",
    "SAVE": "Save",
    "CANCEL": "Cancel",
    "EDIT": "Edit",
    "DELETE": "Delete",
    "YES": "Yes",
    "NO": "No",
    "DELETE_PRODUCT": "Delete product?",
    "PRODUCTS_RATES": "Products rates",
    "NEW_RATE": "New rate",
    "ERROR": "Error",
    "NAME": "Name",
    "DELETE_RATE": "Delete rate?",
    "SHOW": "Show",
    "RATES": "Rates",
    "DATE_RATE_PRODUCTS": "Date rate products",
    "RATES_ASSOCIATED_PRODUCTS": "Rates associated with products",
    "CREATE_NEW_SPECIAL_RATE": "Create new special rate for products",
    "STATUS": "Status",
    "ADD": "Add",
    "ENTER_NUMBER": "You must enter a number",
    "TYPE": "Type",
    "CALCULATED_PRICE": "Calculated price",
    "NO_SPECIAL_RATES": "No special rates",
    "NO_ASSOCIATED_RATES": "No associated rates",
    "PRODUCT_CREATED_SUCCESSFULLY": "Product created successfully",
    "PRODUCT_NOT_EXIST": "The product does not exist",
    "ERROR_OCCURRED": "An error has occurred",
    "RATE_CODE_ERROR": "Rate code entered already exist",
    "FIXED": "Fixed",
    "DIFFERENTIAL": "Differential",
    "PERCENTAGE": "Percentage",
    "DAILY_FIXED": "Daily fixed price",
    "YEARLY_FIXED": "Yearly fixed price",
    "STATIC": "Static",
    "DYNAMIC": "Dynamic",
    "Default product rate" : "Default product rate",
    "All products has special price for this rate": "All products has special price for this rate",
    "ANY": "Any",
    "VISIBILITY": "Visibility",
    "REMOTE_CODE": "Remote code",
    "VISIBLE": "Visible",
    "INVISIBLE": "Invisible",
    "Product Visible": "Rate visible",
    "SUCCESS_PRODUCT_RATE_UPDATED": "Product rate successfully updated",
    "ERROR_PRODUCT_RATE_UPDATED": "Product rate can not be updated. Check out errors.",
    "PRODUCT_RATE_CREATED_SUCCESSFULLY": "Product rate created successfully",
    "ERROR_PRODUCT_RATE_CREATED": "Product rate can not be created. Check out errors.",
    "NOT_IN_FORCE": "Not in force",
    "IN_FORCE": "In force",
    "VALIDITY": "Validity",
    "SUCCESS_PRODUCT_UPDATED": "Product  successfully updated",
    "VIEW": "View",
    "PRODUCT": "Product",
    "PRICES": "Prices",
    "ACCOUNTING": "Accounting",
    "TAX": "Tax",
    "SELECT": "Select",
    "START_DATE": "Start date",
    "PRODUCT_RATE": "Rate",
    "PRODUCT_UPDATED": "Product updated",
    "SEARCH_BY_RATE": "Search by rate",
    "START_START_DATE": "Start date FROM",
    "FINISH_START_DATE": "Start date TO",
    "PRICES_BY_DEFAULT": "Prices by default",
    "NEW_PRICE": "New price",
    "RATE": "Rate",
    "VALUE": "Value",
    "PRICE_CREATED": "Price created",
    "PRODUCT_DELETED": "Product deleted",
    "DEFAULT_PRICE": "Default price",
    "SEARCH_BY_PRODUCT": "Search by product",
    "DEFAULT_PRODUCT": "Default product",
    "OTHERS": "Others",
    "IE": "Electric tax",
    "RENT": "Rental",
    "VARS": "Various",
    "VARS06": "Down payment",
    "REACTIVE_ENERGY": "Reactive power",
    "POWER_EXCESS": "Power excess",
    "REGULATED_TOLLS": "Energy term (Regulated tolls B.O.E.)",
    "DEFAULT_RATE": "Default rate",
    "SELECT_DATE": "Select date",
    "ADD_PRICES": "Add prices",
    "ASK_DELETION": "Delete all prices in selected date?",
    "SUCCESSFULLY_DELETED": "Successfully deleted",
    "ERROR_DELETING": "Error deleting",
    "GESTM": "Markets management",
    "PRODUCT_PRICE_DELETED": "Product price deleted",
    "PRODUCT_PRICE_UPDATED": "Product price updated",
    "PRODUCT_RATE_DELETED": "Product rate deleted",
    "PRSCH": "PRSCH",
    "DEVTN": "Deviations",
    "FINCT": "Financial Costs",
    "EXTRA_DAILY_FIXED": "Precio Extra diario",
    "EXTRA_BY_CONSUMPTION_FIXED": "Extra concept by consumption",
    "TABLE_P1": "P1",
		"TABLE_P2": "P2",
		"TABLE_P3": "P3",
		"TABLE_P4": "P4",
		"TABLE_P5": "P5",
		"TABLE_P6": "P6",
    "ACTIVE_ENERGY": "Active energy",
    "POWER": "Power"
  }
}
