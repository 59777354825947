const TaxRuleDetailCtrl = ['$scope', '$filter', '$routeParams', 'TaxRuleAPI', 'Alert', 'UserInfo',
    function ($scope, $filter, $routeParams, TaxRuleAPI, Alert, UserInfo) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var self = this;
        var $translate = $filter('translate');

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.tax_id = $routeParams.code;
        $scope.rules = $scope.results;

        $scope.generalState = false;

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.deleteTaxRule = function (rule_id) {

            TaxRuleAPI.deleteTaxRule($scope.tax_id, rule_id)
                .then(function (taxrule) {
                    Alert.createAlert('success', $translate('TAXES.TAXRULE_DELETED'), true);
                    $scope.$parent.listReload();
                }).catch(function (error) {
                    $scope.error_message = error.data.__all__[0];
                });
        };

        $scope.editTaxRule = function (result_id, result) {
            var json = {
                code: result.code,
                name: result.name,
                percent: result.percent
            };

            if ((result.state !== "") && (result.state !== null)) {
                json.state = result.state
            }

            TaxRuleAPI.updateTaxRule($scope.tax_id, result_id, json)
                .then(function (taxrule) {
                    Alert.createAlert('success', $translate('TAXES.TAXRULE_EDITED') , true);
                    $scope.taxRuleForm.$setPristine();
                    $scope.taxRuleForm.$setUntouched();
                }).catch(function (error) {
                    $scope.error_message = error.data.__all__[0];
                });
        };

        $scope.canUpdateTaxRule = function () {
            return UserInfo.hasPerm('taxes.update_taxrules');
        };

        $scope.canDeleteTaxRule = function () {
            return UserInfo.hasPerm('taxes.delete_taxrules');
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/

    }
]

export { TaxRuleDetailCtrl }
