{
  "INVOICES": {
    "TITLE": "Invoices",
    "TOTAL": "Total",
    "MINIMUM": "Minimum",
    "MAXIMUM": "Maximum",
    "SEARCH": "Search...",
    "STATUS": "Status",
    "ANY": "Any",
    "AGREEMENT": "Agreement",
    "STATE": "State",
    "FROM": "From",
    "TO": "To",
    "PAID": "Paid",
    "UNPAID": "Unpaid",
    "PENDING": "Pending",
    "INVOICE": "Invoice",
    "INVOICE_DATA": "Invoice data",
    "CREATION_DATE": "Creation date",
    "BILLING_PERIOD": "Billing period",
    "EXPIRED": "Expired",
    "INVOICE_LINES": "Invoice lines",
    "ID": "ID",
    "TYPE": "Type",
    "UNIT_PRICE": "Unit price",
    "TEXT": "Text",
    "RATE": "Rate",
    "NO_INVOICE": "No. Invoice",
    "NO_CONTRACT": "No. Contract",
    "ALL": "All",
    "NIF/CIF": "NIF/CIF",
    "CONTRACT": "Contract",
    "DATE": "Date",
    "TOTAL_AMOUNT": "Total amount",
    "COMMENTS": "Comments",
    "FILES": "Files",
    "DOWNLOAD_EINVOICE": "Download E-invoice",
    "Bulk download generation Task success": "Bulk download generation Task success",
    "Bulk download generation Task error": "Bulk download generation Task error",
    "INVOICES_SERIES": "Invoices series",
    "Counter": "Counter",
    "Invoice number length": "Invoice number length",
    "EDIT": "Edit",
    "DELETE": "Delete",
    "DELETE_SERIES": "Do you want to delete?",
    "YES": "Yes",
    "NO": "No",
    "CODE": "Code",
    "SERIES": "Series",
    "NEW_SERIE": "New series",
    "Series code": "Series code",
    "Series description": "Series description",
    "Counter start": "Counter start",
    "Number length": "Number length",
    "SAVE": "Save",
    "CANCEL": "Cancel",
    "INVOICE_SERIES_CREATED_SUCCESSFULLY": "Invoice series created successfully",
    "BANKS": "Banks",
    "CREDIT_LIMIT": "Credit limit",
    "EXPIRATION_DATE": "Expiration date",
    "BANK_DATA": "Bank data",
    "BANK_NAME": "Bank name",
    "BANK_ACCOUNT": "Bank account",
    "MOD_DATE": "Modification date",
    "BANK_UPDATED": "Bank data updated successfully.",
    "BANK_UPDATED_ERROR": "Error updating bank data.",
    "NEW_BANK": "New bank",
    "CREATE_BANK": "Bank created successfully.",
    "CREATE_BANK_ERROR": "Error creating bank.",
    "DELETE_BANK": "Delete bank?",
    "DELETE_BANK_OK": "Bank deleted successfully.",
    "DELETE_BANK_ERROR": "Error deleting bank.",
    "NEW_INVOICE": "New Invoice",
    "EMITTER": "Emitter",
    "RECEIVER": "Receiver",
    "NIFCIF": "NIF/CIF",
    "NAME": "Name",
    "EMAIL": "E-mail",
    "PHONE": "Phone",
    "MOBILE": "Mobile",
    "FAX": "Fax",
    "ADDRESS": "Address",
    "CITY": "City",
    "ZIP": "ZIP",
    "OBSERVATIONS": "Observations",
    "WEB": "Web",
    "DRAFT": "Draft",
    "UPDATE_SUCCESS": "Invoice successfully edited",
    "UPDATE_ERROR": "Error when editing",
    "TOSAVE": "Save",
    "ACTIONS": "Actions",
    "REFUND": "Refund",
    "CREATE_REFUND": "Refund",
    "SURE": "Are you sure?",
    "REFUND_ERROR": "Error generating invoice refund.",
    "SET_AS_SENT": "Set as sent",
    "SENT":"Sent",
    "ADD": "Add",
    "PRODUCT_CODE": "Product code",
    "CLOSE": "Close",
    "QUANTITY": "Quantity",
    "ERROR_UPDATING_INVOICE_LINES": "Error during invoice lines update.",
    "UPDATED_INVOICE_LINES": "Invoice lines correctly updated.",
    "Bulk download PDF": "Bulk download PDF success",
    "CONTRACTS_INVOICE": "Invoice contracts",
    "MANUAL_INVOICE": "Manual invoice",
    "TO_INVOICE": "Start to invoice",
    "DOWNLOAD_CSV": "Download CSV",
    "DOWNLOAD": "Download",
    "DOWNLOAD_PDF": "Download PDF",
    "Bulk download CSV": "Bulk download CSV success",
    "FEES": "Fees",
    "COMMISSION": "Commission",
    "WITHOLDING": "Witholding",
    "LIST_FEES": "List Fees",
    "DELETEQ": "Delete",
    "ELEMENTSQ": "elements?",
    "ALL_SELECTED": "All",
    "SET_AS_INVOICED": "Set as invoiced",
    "SET": "Set",
    "INVOICED": "Invoiced",
    "VIEW": "View",
    "Date range": "Date range",
    "Date from": "Date from",
    "Date to": "Date to",
    "Without Filter": "Without Filter",
    "Creation date range": "Creation date",
    "Billing period date range": "Billing period date",
    "SET_AS_DRAFT": "Set as draft",
    "SENT_DRAFT_OK": "Invoices sent to 'Draft' status. Invoices number updates: ",
    "SENT_DRAFT_KO": "Error changing status.",
    "ONLY_PRINTABLES": "Only printables",
    "NOT_PRINTABLES": "Not printables",
    "PRINTABLE": "Show printable",
    "ADD_PAYMENT": "Add payment",
    "ADD_PAYMENT_TO_INVOICE": "Add payment to invoice: ",
    "BACK": "Back",
    "PAYMENT_TYPE": "Payment type",
    "AMOUNT_TO_PAY": "Amount to pay",
    "ORIGIN": "Payment origin id",
    "TRANSFER": "Transfer",
    "RECEIPT": "Receipt",
    "CREATED_PAYMENT_SUCCESS": "Created payment for invoice",
    "CREATED_PAYMENT_ERROR": "Error when create a payment for invoice",
    "RECEIPTS": "Receipts",
    "SENT_DEFINITIVE_OK": "Invoices sent to 'Invoiced' status. Started PDF generation. ",
    "SENT_DEFINITIVE_KO": "Error changing status.",
    "EXTRA_EXPENSES": "Extra expenses",
    "SENDING_DRAFT": "Sending invoices to 'Draft' status.",
    "SENDING_SENT": "Sending invoices to 'Sent' status.",
    "SENDING_DEFINITIVE": "Sending invoices to 'Invoiced' status.",
    "SENT_SENT_OK": "Invoices sent to 'Sent' status. Invoices number updates: ",
    "SENT_SENT_KO": "Error changing status.",
    "PAYMENTS": "Payments",
    "Invoices PDFs generated": "Invoices PDFs generated",
    "Invoices PDFs not generated": "Invoices PDFs not generated",
    "CUSTOMER_NAME": "Customer Name",
    "CHANGE_REMIT_DATE_OK": "Remit date updated",
    "CHANGE": "Change",
    "CHANGE_REMIT_DATE": "Change remit date",
    "CHANGE_REMIT_DATE_OF": "Change remit date of",
    "REMIT_DATE": "Remit date",
    "UNPAID_DATE": "Unpaid date",
    "PAID_DATE": "Paid date",
    "DELETED": "Deleted ",
    "TO_DEFINITIVE_NUMBER": "Invoices number to invoiced: ",
    "RELATED": "Related invoice",
    "REMITTABLE": "Remittable",
    "REMITTABLES": "Remittables",
    "NOT_REMITTABLES": "Not remittables",
    "SET_AS_PAID": "Set as paid",
    "ORIGIN_PAID": "Origin pay",
    "PAY": "Pay",
    "ORIGIN_PAY": "Origin: ",
    "CON_TYPE": "Consumption",
    "F11_TYPE" : "XML F101",
    "SENT_PAID_OK": "Invoices set as 'Paid' status. Invoices number updates: ",
    "SENT_PAID_KO": "Error paying invoices.",
    "DELETE_PAYMENT_MESSAGE": "Are you sure?",
    "DELETE_PAYMENT_OK": "Payment deleted successfully.",
    "EXPENSES": "Expenses",
    "INVOICED_DATE": "Invoiced date",
    "DEFERMENT": "Deferment",
    "IDN_REPEATED": "The IDN is repeated",
    "ADD_RECEIPT": "Add receipt",
    "ADD_RECEIPT_TO_INVOICE": "Add manual receipt to invoice: ",
    "AMOUNT": "Amount",
    "REASON": "Reason",
    "CREATED_RECEIPT_SUCCESS": "Created receipt for invoice",
    "CREATED_RECEIPT_ERROR": "Error when create a receipt for invoice",
    "MANUAL": "Manual",
    "SEND_EINVOICE": "Send e-invoice",
    "SENDQ": "Send",
    "INVOICESQ": "invoices?",
    "SENDING_EINVOICES": "Sending electronic invoices.",
    "ORIGIN_TYPE": "Origin type",
    "RETURNED": "Ever been returned",
    "POSTAL_DATA": "Postal data",
    "SUPPLY_DATA": "Supply data",
    "SEPA": "SEPA",
    "DOWNLOAD_INDEXED_INVOICE_CSV_DATA": "Indexed Invoice data",
    "PAYMENT_ORIGIN": "Origin: ",
    "HANDMADE": "Handmade",
    "SUPPY_CITY_CODE": "Supply city code",
    "NEW": "New",
    "DOMICILED": "Domiciled",
    "REQUEST_DELETED": "Performing removal process invoices. It may takes a few seconds.",
    "DELETED_INVOICE_ERROR": "Error has appended",
    "AGREEMENT_SEARCH": "Include sub agreements",
    "AGREEMENT_PARTIAL_SEARCH": "Yes",
    "AGREEMENT_TOTAL_SEARCH": "No",
    "ORIGIN_TOO_LARGE": "The limit of origin field is 75 characters.",
    "APPLY": "Apply",
    "UPDATE_CONTRACT_DATA": "Update contract data",
    "UPDATE_CONTRACT_DATA_WARNING" : "Contract data won't be saved until invoice will be saved.",
    "NORMAL_INVOICE": "Normal",
    "INDEXED_INVOICE": "Indexed",
    "INDEXED_INVOICE_WITHOUT_ELEVATION": "Indexed without energy raising",
    "INDEXED_INVOICE_MIXED": "Indexed mixed",
    "INVOICING_MODE": "Invoicing mode",
    "PAYMENT_METHOD": "Payment method",
    "DEBIT": "Debit",
    "OVERVIEW_INVOICE": "Overview Invoice",
    "CONTRACT_ID": "Contract",
    "START_DATE": "Start date",
    "FINISH_DATE": "Finish Date",
    "EXTRA_1": "Extra column 1",
    "EXTRA_2": "Extra column 2",
    "CLEAN_CONTRACT_DATA": "Clean contract date",
    "SHOW_INVOICES_IN_OBSERVATIONS": "Show invoices in observations",
    "SHOW_DEBT_INVOICES": "Show invoices with clients in debt only",
    "HIDE_DEBT_INVOICES": "Exclude invoices with clients in debt",
    "Bulk set to invoiced Task success": "Bulk set to invoiced Task success",
    "Bulk set to invoiced Task error": "Bulk set to invoiced Task error",
    "DOWNLOAD_EINVOICE_WITHOUT_PDF": "Download E-invoice (without PDF)",
    "FACE_COMPATIBLE": "FACe compatible",
    "CUSTOM_PDF": "Custom PDF",
    "CUSTOM_TEXT": "Custom text",
    "Generating_overview_invoice": "Generating overview invoice",
    "Generating_overview_invoice_error": "Error generating overview invoice",
    "Custom_invoice_pdf": "Generating custom invoice pdfs",
    "Custom_invoice_pdf_error": "Error generating custom invoice pdfs",
    "WITH_SIGNATURE": "With signature",
    "CUSTOMER_INVOICES": "Customer invoices",
    "CUSTOMER_INVOICE": "Customer invoice",
    "ITEM_ORIGIN": "Origin",
    "ITEM_ORIGIN_ID": "ID",
    "VALUE": "Value",
    "ITEM_TO_BE_INVOICED": "Item to be inv.",
    "ESTIMATED_INVOICE": "Estimated",
    "Q101": "Q101",
    "TO_UPDATE": "You are going to update",
    "SET_CONTRACT_DATA": "Set Contract Data",
    "INVOICES_NUMBER":"Invoices number",
    "REFUND_WITH_REISSUE": "Refund with reissue",
    "REISSUE": "Reissue",
    "ESTIMATED": "Estimated",
    "COMPENSATORY_UNPAID":"Compensatory",
    "INVOICE_UNPAID": "Unpaid Invoices",
    "Generating_overview_unpaid_invoices":"Generating overview unpaid invoices",
    "Generating_overview_unpaid_invoices_error":"Generating overview unpaid invoices Error",
    "COMPENSATED":"Compensated",
    "UNPAID_ADJUSTMENT" : "Unpaid adjustment",
    "REISSUE_UNPAID_ADJUSTMENT" : "R. unpaid adjustment",
    "CREDIT_RETURNED_CUSTOMER": "Credit returned by the customer",
    "SET_REMITTABLE": "Set remittable",
    "SET_REMITTABLE_OPTIONS": "Allow invoices to be remitted",
    "Bulk remittable CSV": "Bulk remittable success",
    "CONFIRM_BULK_UPDATE": "The change will be performed in the selected invoices!",
    "SET_AS_UNPAID": "Set as unpaid",
    "SENT_UNPAID_OK": "Invoices set as 'Unpaid' status. Invoices number updates: ",
    "SENT_UNPAID_KO": "Error when unpaid invoices.",
    "SENT_UNPAID_NOT_UPDATE": "N. invoices not updated: ",
    "SENT_UNPAID_NOT_UPDATE_IDS": "IDs not updateds: ",
    "CLOSE_ESTIMATED_TYPE": "Close",
    "FACE_INVOICES": "FACE Invoices",
    "FACE_SENT_INVOICES": "Invoices sent FACE",
    "FACE_SENT_STATUS_INVOICES": "Status Face",
    "FACE_PAID": "Paid",
    "OTHER_FACE_STATUS": "Others",
    "FACE_REGISTER": "Face registered",
    "FACE_REGISTER_RCF": "Face RCFregistered",
    "FACE_REJECT": "Reject",
    "FACE_COUNTED": "Counted",
    "DEBIT_B2B": "Debit B2B",
    "REGULARISED": "Regularised",
    "COVER": "Cover",
    "ADD_COMMENT": "Add comment/s",
    "COMMENT": "Comment",
    "Bulk_Comment": "Add bulk comments",
    "COMPENSATE_INVOICES": "Compensate Invoices",
    "MASSIVE_COMPENSATION": "Add massive invoice compensation task",
    "MASSIVE_COMPENSATION_ERROR": "Add massive invoice compensation task error",
    "PRINT_INVOICE": "Print invoice",
    "MASSIVE_PRINT_INVOICES": "Massive print invoices",
    "MASSIVE_PRINT_INVOICES_ERROR": "Massive print invoices error",
    "PRINT_INVOICES": "Print invoices",
    "RECONCILED": "Reconciled",
    "NOT_RECONCILED": "Not Reconciled",
    "SET_RECONCILED": "Set As Reconciled",
    "COMPENSATION": "Compensación",
    "SELF_CONSUMPTION": "Self consumption",
    "METER_ID": "Meter ID",
    "PERIOD": "Period",
    "DATE_PERIOD": "Start date - Finish date",
    "ACTIVE_PREVIOUS": "EN Previous",
    "ACTIVE_CURRENT": "EN Current",
    "ACTIVE_CONSUMPTION":  "EN Consumption",
    "REACTIVE_PREVIOUS": "RE Previous",
    "REACTIVE_CURRENT":  "RE Current",
    "REACTIVE_CONSUMPTION": "RE Consumption",
    "MAXIMETER": "Maximeter",
    "INVOICE_READINGS": "Readings",
    "ELECTRICITY_METER_READINGS": "PDF Electricity meter readings shown",
    "REMITTANCE": "Remittance",
    "REFUND_PAYMENT": "Refund Payment",
    "INVOICE_PAYMENT_TYPE": "Payment type",
    "DOWNLOAD_REMITTABLE_REPORT": "Download Remittable Report",
    "ESTIMATED_BALANCING": "Est. Balancing",
    "SET_HIDDEN": "Set as Hidden in Web",
    "CREATE_MANUAL_INVOICE": "Manual invoice created successfully",
    "GENERATE_ESTIMATED_TASK_CREATED": "Created bulk estimation task",
    "GENERATE_ESTIMATED_TASK_ERROR": "Bulk estimation failed",
    "BULK_DELETE_INVOICES_SUCCESS": "Created bulk deleted invoices task",
    "BULK_DELETE_INVOICES_ERROR": "Bulk delete invoices failed",
    "DETAIL_PREVENT_BACK": "Unsaved changes will be lost.\nContinue?",
    "DOWNLOAD_INVOICE_LINES": "Download invoice lines",
    "GENERATE_REFUNDS": "Generate Refunds",
    "GENERATE_REFUNDSQ": "Invoices to refund: ",
    "SINGLE_INVOICE": "Single Invoice",
    "BULK_REFUND_INVOICES_SUCCESS": "The task Refund Invoices was put in queue",
    "BULK_REFUND_INVOICES_ERROR": "Refund Invoices task failed. Reload the page and select invoices again.",
    "ORIGIN_ID": "Origin ID",
    "SEND_TO_SII": "Send to SII",
    "Bulk_Send_to_sii": "Created task Send to SII successfully",
    "Bulk_Send_to_sii_error": "Created task Send to SII failed"
  }
}
