const RemittanceDetailCtrl = ['$scope', '$routeParams', '$filter', '$window', '$cookies', 'UserInfo',
                              'Remittance', '$location', '$http', 'Alert', 'Bank', 'Task', 'notificationCenter',
    function ($scope, $routeParams, $filter, $window, $cookies, UserInfo, Remittance, $location, $http, Alert, Bank, Task, notificationCenter) {

        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');

        $scope.BANKS_LIST_RESULT = require('../../templates/receipts-list-result.html')

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.loading = true;
        $scope.id = $routeParams.id;
        $scope.action = '';
        $scope.deletingRemittance = false;

        $scope.searchFilters = {
            'id': $translate('BANKS.RECEIPT_NUMBER'),
            'invoice_id': $translate('BANKS.NO_INVOICE'),
            'remittance_id': $translate('BANKS.NO_REMITTANCE'),
            'search': $translate('BANKS.ALL')
        };

        $scope.statuses = [
            {code: 'PAI', label: $translate('BANKS.PAID')},
            {code: 'RTN', label: $translate('BANKS.RETURNED')},
            {code: 'RJC', label: $translate('BANKS.REJECTED')}
        ];

        $scope.getStyles = function (result) {
            var styles = '';
            if (result.status == 'PAI')
                styles += ' ' + 'success';
            if (result.status == 'RTN')
                styles += ' ' + 'warning';
            if (result.status == 'RJC')
                styles += ' ' + 'danger';
            return styles;
        };

        $scope.searchBy = Object.keys($scope.searchFilters)[0];
        $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];

        $scope.receipt_filters = {
            remittance_id: $scope.id,
            search: "",
            id: "",
            invoice_id: "",
            status: ""
        };

        $scope.date_filters = {
            receipt_date_range: null,
            date_range_from: null,
            date_range_to: null
        };

        $scope.filters_by_date = [
            {value: "receipt", description: $translate('BANKS.CREATION_DATE')},
            {value: "unpaid", description: $translate('BANKS.UNPAID_DATE')}
        ];

        $scope.ordering = {
            "total": $translate('BANKS.TOTAL'),
            "date": $translate('BANKS.DATE')
        };

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        $scope.$watchCollection('date_filters', function (newVal, oldVal) {
            if (oldVal.receipt_date_range !== null) {
                var sel_option = oldVal.receipt_date_range.value;
                if (oldVal.date_range_from !== null) {
                    $scope.receipt_filters[sel_option + "_date_from"] = null;
                }
                if (oldVal.date_range_to !== null) {
                    $scope.receipt_filters[sel_option + "_date_to"] = null;
                }
            }
            if (newVal.receipt_date_range !== null) {
                var sel_option = newVal.receipt_date_range.value;
                if (newVal.date_range_from !== null) {
                    var from_date = newVal.date_range_from;
                    $scope.receipt_filters[sel_option + "_date_from"] = from_date;
                }
                if (newVal.date_range_to !== null) {
                    var to_date = newVal.date_range_to;
                    $scope.receipt_filters[sel_option + "_date_to"] = to_date;
                }
            }
        });

        $scope.$watch('searchQuery', function (newVal, oldVal) {
            $scope.receipt_filters[$scope.searchBy] = newVal;
        });

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.setSearchBy = function (val) {
            $scope.receipt_filters[$scope.searchBy] = '';
            $scope.searchBy = val;
            $scope.searchByLabel = $scope.searchFilters[val];
            $scope.receipt_filters[val] = $scope.searchQuery;
        };

        $scope.canEditRemittance = function () {
            return UserInfo.hasPerm('banks.change_remittance') && $scope.remittance.locked == false;
        };

        $scope.canDownloadRemittance = function () {
            return UserInfo.hasPerm('banks.download_remittance_receipts') && UserInfo.hasPerm('banks.retrieve_remittance');
        };

        $scope.downloadRemittanceReceipts = function () {
            var url = API_URL + '/remittances/download/receipts/' + $scope.id + '?token=' + $cookies.get("token");
            $window.open(url);
            return false;
        };

        $scope.confirmDeleteRemittance = function () {
            $scope.deletingRemittance = true;

            var task = new Task();
            task.type = 'EREM';
            task.params = {
                "id": $scope.id
            };
            task.start().then(function (data, status, headers, config) {
                Alert.createAlert('success', $translate('BANKS.DELETING_REMITTANCE'), true);
            }).catch(function (data, status, headers, config) {
                Alert.createAlert('danger', data, true);
            });
        };

        $scope.updateRemittance = function () {
            var data = {
                creation_date: $scope.remittance.creation_date + 'T00:00',
                date: $scope.remittance.date + 'T00:00',
                bank: $scope.remittance.bank
            };

            var url = API_URL + '/api/1.0/remittances/' + $scope.id + '/';
            $http.patch(url, data).then(function (remittance, status, headers, config) {
                Alert.createAlert('success', $translate('BANKS.UPDATED_REMITTANCE_OK'), true);
                $scope.remittance = remittance.data;
                $scope.remittance.creation_date = new Date($scope.remittance.creation_date);
                $scope.remittance.date = new Date($scope.remittance.date);
                $scope.loading = false;
            }).catch(function (data, status, headers, config) {
                Alert.createAlert('danger', data.data, true);
            });
        };

        $scope.canDeleteRemittance = function () {
            return UserInfo.hasPerm('banks.delete_remittance');
        };

        $scope.deleteRemittanceMode = function () {
            $scope.action = 'delete';
        };

        $scope.cancelDeleteRemittance = function () {
            $scope.action = '';
        };

        $scope.canViewRemittance = function () {
            return UserInfo.hasPerm('banks.retrieve_remittance');
        };

        $scope.canViewReceipt = function () {
            return UserInfo.hasPerm('banks.list_receipts');
        };

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        notificationCenter.subscribe('BG_TASK_REMITTANCE_DELETED', function (type, data) {
            $scope.deletingRemittance = false;

            $location.path('/remittances/');
        });

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        Remittance.get({id: $scope.id}, function (remittance) {
            $scope.remittance = remittance;
            $scope.remittance.creation_date = new Date($scope.remittance.creation_date);
            $scope.remittance.date = new Date($scope.remittance.date);
            $scope.loading = false;
        });

        Bank.get(function (data) {
            var arr = [];

            data.results.forEach(function (bank) {
                arr.push(bank);
            });
            $scope.banks = arr;
        });
    }
]

export { RemittanceDetailCtrl }
