
const ContractsDetailTabsCtrl = 
        ['$routeParams', '$scope', 'UserInfo', 'agreements', '$location', 'ContractDetail', 'Alert', '$filter', 'CONTRACT_SUB_STATUS', 'Task',
            function ($routeParams, $scope, UserInfo, agreements, $location, ContractDetail, Alert, $filter, CONTRACT_SUB_STATUS, Task) {
                /***********************************************************************************************************
                 * Scope Attributes
                 ***********************************************************************************************************/
                var $translate = $filter('translate');
                var self = this;

                $scope.selectedTab = 'contract-data';
                $scope.id = $routeParams.id;
                $scope.contract = null;
                $scope.agreements = agreements.data;
                var sub_status = {};
                angular.forEach(CONTRACT_SUB_STATUS, function(value, key) {
                    for(var i=0; i<value.length; i++) {
                        if (!(value[i].code in sub_status)) {
                            sub_status[value[i].code] = value[i].label;
                        }
                    }
                });

                $scope.sub_status = sub_status;

                $scope.canSelectInvoices = false;

                /***********************************************************************************************************
                * Scope Watchers
                ***********************************************************************************************************/
                $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                    $scope.counter = selectedCounterChange;
                });

                /***********************************************************************************************************
                * Controller Methods
                ***********************************************************************************************************/
                this.show_error_message = function (error, color='danger') {
                    for (let k in error) {
                        if (k ==='non_field_errors') {
                            Alert.createAlert(color, $translate(error[k][0]), true);
                        }
                        else {
                            $scope.error_message[k].$error.api = error[k][0];
                        }
                    }
                    if (error === undefined) {
                        Alert.createAlert('danger', $translate(error), true);
                    }
                }

                /***********************************************************************************************************
                 * Scope Methods
                 ***********************************************************************************************************/
                $scope.showInvoicesToDefer = function () {
                    if ($scope.contractInvoicesFilters === undefined)
                        $scope.contractInvoicesFilters = {
                            contract_id: $scope.id,
                            status: ""
                        };

                    if ($scope.contractInvoicesFilters['status'] == '') {
                        $scope.contractInvoicesFilters['status'] = 'INV,UNP';
                        $scope.canSelectInvoices = true;
                    } else {
                        $scope.contractInvoicesFilters['status'] = '';
                        $scope.canSelectInvoices = false;
                    }
                };

                $scope.cancel_unsubscribe_request = function () {
                    var element = {
                        id: $scope.id,
                        cancel_unsubscribe_contract: true
                    };

                    ContractDetail.cancelUnsubscribeContract(element)
                        .then(function (contract) {
                            Alert.createAlert('success', $translate('CONTRACTS.EVENT_CANCEL_UNSUBSCRIBE_FOR_CONTRACT'), true);
                        }).catch(function (error) {
                            self.show_error_message(error, 'warning');
                        });
                };

                $scope.maximetre_request = function () {
                    var element = {
                        id: $scope.id,
                        maximetre_contract: true
                    };

                    ContractDetail.maximetreContract(element)
                        .then(function (contract) {
                            Alert.createAlert('success', $translate('CONTRACTS.EVENT_MAXIMETRE_CREATED_FOR_CONTRACT'), true);
                        }).catch(function (error) {
                            self.show_error_message(error, 'danger');
                        });
                };

                $scope.administrative_changes_request = function () {
                    var element = {
                        id: $scope.id,
                        administrative_changes: true
                    };

                    ContractDetail.administrativeChangesContract(element)
                        .then(function (contract) {
                            Alert.createAlert('success', $translate('CONTRACTS.EVENT_ADMINISTRATIVE_CHANGES_CREATED_FOR_CONTRACT'), true);
                        }).catch(function (error) {
                            self.show_error_message(error, 'danger');
                        });
                };

                $scope.customer_changes_request = function () {
                    var element = {
                        id: $scope.id,
                        customer_changes: true
                    };

                    ContractDetail.customerChangesContract(element)
                        .then(function (contract) {
                            Alert.createAlert('success', $translate('CONTRACTS.EVENT_CUSTOMER_CHANGES_CREATED_FOR_CONTRACT'), true);
                        }).catch(function (error) {
                            self.show_error_message(error, 'danger');
                        });
                };

                $scope.invoice_periodicity_changes_request = function () {
                    var element = {
                        id: $scope.id,
                        invoice_periodicity_change: true
                    };

                    ContractDetail.invoice_periodicity_changes_request(element)
                        .then(function (contract) {
                            Alert.createAlert('success', $translate('CONTRACTS.EVENT_INVOICE_PERIODICITY_CHANGES_CREATED_FOR_CONTRACT'), true);
                        }).catch(function (error) {
                            self.show_error_message(error, 'danger');
                        });
                };

                $scope.resetEstimatedAndGenerateInvoice = function (contract_id) {
                    var task = new Task();
                    task.type = 'CEST';
                    task.params = {ids: [contract_id]};
                    task.start().then(function (data, status, headers, config) {
                        Alert.createAlert('success', $translate('CONTRACTS.Close estimated invoice Task Created'), true);
                        $scope.backgroundTasks.push(data.data.id);
                    }).catch(function (data, status, headers, config) {
                        Alert.createAlert('danger', $translate('CONTRACTS.Close estimated invoice Task Creation ERROR'), true);
                    });
                };

                $scope.createDeferInvoices = function() {
                    $location.path('/contracts/' + $routeParams.id + '/defer-invoices/');
                };

                $scope.canEdit = function () {
                    return UserInfo.hasPerm('contracts.change_contract');
                };

                $scope.canDuplicate = function () {
                    return UserInfo.hasPerm('contracts.add_contract');
                };

                $scope.canListFiles = function () {
                    return UserInfo.hasPerm('contracts.list_contracts_files');
                };

                $scope.canViewInvoices = function () {
                    return UserInfo.hasPerm('invoices.list_invoices');
                };

                $scope.canViewRelatedContracts = function () {
                    return UserInfo.hasPerm('contracts.retrieve_contracts');
                };

                $scope.canViewFees = function () {
                    return UserInfo.hasPerm('fees.list_fees');
                };

                $scope.canViewItemsToBeInvoiced = function () {
                    return UserInfo.hasPerm('invoices.list_item_to_be_invoiced');
                };

                $scope.canListAgreements = function () {
                    return UserInfo.hasPerm('agreements.list_agreements');
                };

                $scope.canViewProductRates = function () {
                    return UserInfo.hasPerm('products.list_products_rates');
                };

                $scope.canViewComplaints = function () {
                    return UserInfo.hasPerm('complaints.list_complaints');
                };

                $scope.canUnsubscribeContract = function () {
                    return UserInfo.hasPerm('contracts.can_unsubscibe_contract_request');
                };

                $scope.canCancelUnsubscribeContract = function () {
                    return UserInfo.hasPerm('contracts.can_cancel_unsubscribe_request');
                };

                $scope.canMaximetreRequestContract = function () {
                    return UserInfo.hasPerm('contracts.change_contract');
                };

                $scope.canAdministrativeChangesRequestContract = function () {
                    return UserInfo.hasPerm('contracts.change_contract');
                };

                $scope.canCustomerChangesRequestContract = function () {
                    return UserInfo.hasPerm('contracts.change_contract');
                };

                $scope.canPeriodicityChangesRequestContract = function () {
                    return UserInfo.hasPerm('contracts.change_contract');
                };

                $scope.canWithdrawalContract = function () {
                    return UserInfo.hasPerm('contracts.change_contract');
                };

                $scope.canResetEstimatedContract = function () {
                    if ($scope.contract === undefined || $scope.contract == null)
                        return false;
                    return (UserInfo.hasPerm('contracts.change_contract') && $scope.contract.invoicing_mode === 'EST');
                };

                $scope.canRegularizeContract = function () {
                    return UserInfo.hasPerm('invoices.add_invoice');
                };

                $scope.canUpdateCustomer = function () {
                    return UserInfo.hasPerm('contracts.update_customer');
                };

                $scope.canAssignLabel = function () {
                    return UserInfo.hasPerm('labels.add_labelledobject');
                };

                $scope.canDeleteAssignLabel = function () {
                    return UserInfo.hasPerm('labels.delete_labelledobject');
                };

                $scope.changeCustomer = function() {
                    $scope.selectedTab = 'change_customer';
                    $scope.$broadcast('change_customer');
                };

                $scope.isOnDate = function () {
                    var activation_date = new Date($scope.contract.activation_date);
                    var today = new Date();
                    var diffDays = parseInt((today - activation_date) / (1000 * 60 * 60 * 24), 10);
                    if (diffDays <= 15) {
                        $scope.is_on_date = true;
                    }
                    else {
                        $scope.is_on_date = false
                    }
                }

                /***********************************************************************************************************
                * Scope Watchers
                ***********************************************************************************************************/
                $scope.$watch('contract.activation_date', function (newVal, oldVal) {
                    if ($scope.contract && newVal !== undefined) {
                        $scope.isOnDate()
                    }
                });

            }]

export {ContractsDetailTabsCtrl}
