function doubleReport(){
    return {
        restrict: 'E',
        templateUrl: require('../../templates/sc-double-report-directive.html'),
        controller: function($scope, $http, $window, $filter, SUPPLY_RATES, SOURCES, UserInfo, Task, Alert) {
            var self = this;
            var $translate = $filter('translate');

            $scope.status = 'WAITING_DR';
            $scope.highlightQty = 0;
            $scope.rateDC = '';
            //$scope.cups = '';
            $scope.availableRates = SUPPLY_RATES;
            $scope.reportDataDoubleReport = {};
            $scope.reportCups1Data = '';
            $scope.reportCups2Data = '';
            $scope.emptyDC = true;
            $scope.sources = SOURCES;
            $scope.source = '';

            var show = false;

            $scope.onDoubleReport = function(){
                show = true;
                return show;
            }

            $scope.offDoubleReport = function(){
                show = false;
                return show;
            }

            $scope.generateDoubleReport = function () {
                $scope.status = 'LOADING_DR';
                var url = API_URL + '/api/1.0/consumption-double-report/' + self.getQueryParamsDoubleReport();
                $http.get(url).then(function (data, status, headers, config) {
                    var reportData = {};
                    $scope.emptyDC = true;
                    for (var i = 0; i < data.data.report.length; i++) {
                        var day_of_week = data.data.report[i].data;
                        for (var j = 0; j < day_of_week.length; j++) {
                            var cellData = day_of_week[j];
                            if (typeof reportData[cellData.time] === 'undefined') {
                                reportData[cellData.time] = [
                                    {time: cellData.time, value: cellData.time, viewValue: cellData.time}
                                ];
                            }
                            if (false == isNaN(cellData.value)) {
                                var viewValue = (Math.round(cellData.value * 100) / 100);
                                if (cellData.value > 0)
                                    cellData.viewValue = '+' + viewValue + '%';
                                else
                                    cellData.viewValue = viewValue + '%';
                                cellData.highlight = false;
                            } else {
                                cellData.viewValue = cellData.value;
                                cellData.highlight = true;
                            }
                            $scope.emptyDC = false;
                            reportData[cellData.time].push(cellData);
                        }
                    }
                    $scope.reportDataDoubleReport = reportData;
                    $scope.reportCups1Data = data.data.cups_1;
                    $scope.reportCups2Data = data.data.cups_2;
                    $scope.status = 'LOADED_DR';
                }).catch(function (response) {
                    // TODO: better error control
                    $scope.status = 'ERROR_DR';
                });
            };

            $scope.downloadCSVDoubleReport = function () {
                var task = new Task();
                task.type = 'GCRP';
                task.params = $scope.getJSONParams();
                task.params.type = 'double-report';
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('SUPPLIES.Double consumption report generation Task success'), true);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('SUPPLIES.Double consumption report generation Task error'), true);
                });
            };

            $scope.getJSONParams = function() {

                var params = {};
                if ($scope.fromDateOne > $scope.toDateOne) {
                    var aux = $scope.fromDateOne;
                    $scope.fromDateOne = $scope.toDateOne;
                    $scope.toDateOne = aux;
                }
                if ($scope.fromDateTwo > $scope.toDateTwo) {
                    var aux = $scope.fromDateTwo;
                    $scope.fromDateTwo = $scope.toDateTwo;
                    $scope.toDateTwo = aux;
                }

                params.date_from = self.transformToRESTyDate($scope.fromDateOne);
                params.date_to = self.transformToRESTyDate($scope.toDateOne);

                params.date_from_2 = self.transformToRESTyDate($scope.fromDateTwo);
                params.date_to_2 = self.transformToRESTyDate($scope.toDateTwo);


                if ($scope.rateDC != undefined && $scope.rateDC != null ){
                    params.rate = $scope.rateDC;
                }

                if ($scope.cups != undefined && $scope.cups != null ){
                    params.cups = $scope.cups;
                }

                if ($scope.source != undefined && $scope.source != null) {
                    params.source = $scope.source;
                }

                return params;
            };

            $scope.canDownloadDoubleReport = function() {
                return UserInfo.hasPerm('consumptions.download_double_report_csv');
            };

            this.transformToRESTyDateDoubleReport = function (date) {
                var yyyy = date.getFullYear().toString();
                var mm = (date.getMonth() + 1).toString(); // getMonth() is zero-based
                var dd = date.getDate().toString();
                return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]); // padding
            };

            this.transformToRESTyDate = function (date) {
                var yyyy = date.getFullYear().toString();
                var mm = (date.getMonth() + 1).toString(); // getMonth() is zero-based
                var dd = date.getDate().toString();
                return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]); // padding
            };

            this.getQueryParamsDoubleReport = function () {
                if ($scope.fromDateOne > $scope.toDateOne) {
                    var aux = $scope.fromDateOne;
                    $scope.fromDateOne = $scope.toDateOne;
                    $scope.toDateOne = aux;
                }
                if ($scope.fromDateTwo > $scope.toDateTwo) {
                    var aux = $scope.fromDateTwo;
                    $scope.fromDateTwo = $scope.toDateTwo;
                    $scope.toDateTwo = aux;
                }
                var params = '?fromDate_1=' + this.transformToRESTyDateDoubleReport($scope.fromDateOne);
                params += '&toDate_1=' + this.transformToRESTyDateDoubleReport($scope.toDateOne);
                params += '&fromDate_2=' + this.transformToRESTyDateDoubleReport($scope.fromDateTwo);
                params += '&toDate_2=' + this.transformToRESTyDateDoubleReport($scope.toDateTwo);
                if ($scope.rateDC == undefined || $scope.rateDC == null ){
                    params += '&rate='
                }
                else{
                    params += '&rate=' + $scope.rateDC;
                }
                if ($scope.cups == undefined || $scope.cups == null ){
                    params += '&cups='
                }
                else{
                    params += '&cups=' + $scope.cups;
                }
                if ($scope.source == undefined || $scope.source == null) {
                    params += '&source=';
                }
                else {
                    params += '&source=' + $scope.source;
                }
                return params;
            };

        },
        controllerAs: 'doubleReport'
    };
}

export {doubleReport};
