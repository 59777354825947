const  CalendarForSupplyCtrl = ['$scope', '$filter', 'Alert', 'CalendarsMaster', 'UserInfo', 'CalendarForSupplyAPI',
    function ($scope, $filter, Alert, CalendarsMaster, UserInfo, CalendarForSupplyAPI) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;
        var id = $scope.cups;

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.calendar = '';
        $scope.creationMode = false;

        /*$scope.filters = {
            supply: id
        };*/

        $scope.action = '';

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        function resetForm() {
            $scope.calendar = "";
            $scope.calendarForSupplyForm.$setPristine();
            $scope.calendarForSupplyForm.$setUntouched();
        }

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.toggleCreationCalendarForSupplyMode = function () {
            $scope.creationMode = !$scope.creationMode;
        };

        $scope.createCalendarForSupply = function () {
            var data = {
                "calendar": $scope.calendar,
                "supply": id
            };

            CalendarForSupplyAPI.createCalendarForSupply(data).then(function (data) {
                $scope.listReload();
                Alert.createAlert('success', $translate('SUPPLIES.CALENDAR_FOR_SUPPLY_ADDED'), true);
                resetForm();
                $scope.creationMode = false;
            }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
            });
        };

        $scope.canCreateCalendarForSupply = function () {
            return UserInfo.hasPerm('calendars.add_calendarforsupply');
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        CalendarsMaster.loadCalendars().then(function (response){
            $scope.calendars = response;
        });
}]

export {CalendarForSupplyCtrl};
