

const ProgrammingUnitsLoading = ['$resource', function ($resource) {
    return $resource(API_URL + '/api/1.0/masters/programming-units/');
}]

const ProgrammingUnitsMaster = ['$q', 'ProgrammingUnitsLoading',  function ($q, ProgrammingUnitsLoading) {

    var programming= {
        items: null,

        loadProgrammingUnits: function () {
            var self = this;
            var deferred = $q.defer();

            if (this.items == null)
                ProgrammingUnitsLoading.query(function (data) {
                    self.items = data;
                    deferred.resolve(data);
                });
            else
                deferred.resolve(self.items);

            return deferred.promise;
        }
    };

    return programming;

}]

export {ProgrammingUnitsLoading, ProgrammingUnitsMaster}
