
const CustomerFormCtrl =  ['$scope', 'CreateContract', 'addressUtils',
        function ($scope, CreateContract, addressUtils) {
            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$watch('customer', function (newVal, oldVal) {
                if (newVal != null) {
                    $scope.$emit("setValidity", true);
                    CreateContract.setCustomer($scope.customer);
                    $scope.$emit("loadCustomer", $scope.customer);
                } else {
                    $scope.$emit("setValidity", false);
                    CreateContract.setCustomer(null);
                }
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.copyFromSupply = function (){
                if ($scope.customer)
                    var id = $scope.customer.id;
                else{
                    $scope.customer = {};
                }

                var data = CreateContract.getSupply();

                $scope.customer = {};

                if(!$scope.customer.name )
                    $scope.customer.name = data.name;
                if(!$scope.customer.surname_1 )
                    $scope.customer.surname_1 = data.surname_1;
                if(!$scope.customer.surname_2 )
                    $scope.customer.surname_2 = data.surname_2;
                if(!$scope.customer.address )
                    $scope.customer.address = data.address;
                // Added Address Info
                var address = addressUtils.splitAddressByComma(data.address);
                if(!$scope.customer.customer_address )
                    $scope.customer.customer_address = addressUtils.getAddressName(address);
                if(!$scope.customer.customer_number )
                    $scope.customer.customer_number = addressUtils.getAddressNumber(address);
                if(!$scope.customer.customer_extension )
                    $scope.customer.customer_extension = addressUtils.getAddressExt(address);

                if(!$scope.customer.state )
                    $scope.customer.state = data.state;
                if(!$scope.customer.city )
                    $scope.customer.city = data.city;
                if(!$scope.customer.zip )
                    $scope.customer.zip = data.zip;

                $scope.customer.data_copy = true;
                if (id)
                    $scope.customer.id = id;
            };

            $scope.copyFromAgreement = function () {

                if ($scope.customer)
                    var id = $scope.customer.id;
                else{
                    $scope.customer = {};
                }

                var data = CreateContract.getAgreement();

                if(!$scope.customer.name )
                    $scope.customer.name = data.name;
                
                if(!$scope.customer.email) {
                    $scope.customer.email = data.email;
                }
                if(!$scope.customer.phone) {
                    $scope.customer.phone = data.phone;
                }
                if(!$scope.customer.mobile) {
                    $scope.customer.mobile = data.mobile;
                }
                if(!$scope.customer.state) {
                    $scope.customer.state = data.state;
                }
                if(!$scope.customer.city) {
                    $scope.customer.city = data.city;
                }
                if(!$scope.customer.zip) {
                    $scope.customer.zip = data.zip;
                }
                if(!$scope.customer.payment_account) {
                    $scope.customer.payment_account = data.default_contract_payment_account;
                }

                $scope.customer.data_copy = true;

                if (id)
                    $scope.customer.id = id;

            };
        }]
        
export {CustomerFormCtrl}
