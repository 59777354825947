function subtypeName(SUBTYPE_CODES) {
    return function (input, code) {
        input = input || '';
        if (input == '')
            return input;

        return SUBTYPE_CODES[input];
    };
}

export { subtypeName }
