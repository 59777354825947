{
  "FEES": {
    "TITLE": "Gestión de honorarios",
    "HISTORICAL_FEE": "Histórico de honorarios",
    "IMPORTING_FINAL_FEES": "Importación de comisiones finales",
    "HISTORY_IMPORT_FEES": "Histórico de importación de honorarios",
    "COMMISSION_REPORT_GENERATION": "Generación del informe de comisiones",
    "FROM": "Desde",
    "AGREEMENTS": "Convenios",
    "AGREEMENT": "Convenio",
    "TO": "Hasta",
    "ALL_AGREEMENTS": "Todos los convenios",
    "GENERATE_COMMISSIONS": "Generar comisiones",
    "MSG": "Se ha puesto la generación de comisiones en cola.",
    "MSG_2": "Se le notificará cuando finalice.",
    "HISTORY_GENERATED_COMMISSIONS": "Histórico de comisiones generadas",
    "ERROR": "Error",
    "TODO": "Por hacer",
    "TO_CANCEL": "Por cancelar",
    "TO_UNDO": "Por deshacer",
    "UNDOING": "Deshaciendo",
    "DOING": "Ejecutando",
    "CANCELED": "Cancelada",
    "FINISHED": "Finalizada",
    "DISCARDED": "Deshecha",
    "DOWNLOAD": "Descargar",
    "HISTORY_IMPORTED_COMMISSIONS": "Histórico de comisiones importadas",
    "PENDING": "Pendiente",
    "IMPORTED_FILE": "Archivo importado",
    "IMPORT_MODE": "Modo de importación",
    "IMPORT_DONE": "Importación realizada",
    "HIDE_IMPORT_RESULTS": "Ocultar Resultado de la importación",
    "SHOW_IMPORT_RESULTS": "Ver Resultado de la importación",
    "YES": "SI",
    "NO": "NO",
    "DATE_IMPORT_FEES": "Fecha de la importación de comisiones",
    "DATE_IMPORT_FEES_REVERSED": "Fecha en que se revirtió la importación de comisiones",
    "IMPORTING_QUEUED_COMMISSIONS": "Importación de comisiones en cola",
    "UNDO": "Deshacer",
    "NEW_IMPORT": "Nueva importación",
    "SELECT_IMPORT_MODE": "Selecciona modo de importación",
    "IMPORT_COMMISSIONS": "Importar comisiones",
    "ERROR_OCCURRED": "Se ha producido un error",
    "DATE_GREATER": "La fecha desde es mayor que la fecha hasta",
    "IMPORT_RUNNING": "La importación se está ejecutando en segundo plano",
    "ERROR_SENDING_TASK": "Se ha producido un error al enviar la tarea",
    "IMPORT_FINISHED": "Importación de comisiones finalizada",
    "FEES_IMPORT_MODE_ERROR": "Debe seleccionar el modo de importación",
    "COMMISSION_REPORT_GENERATION_FILES": "Archivos Generados",
    "LIST_FEES": "Listado de honorarios",
    "DATE": "Fecha",
    "COMMISSION": "Comisión",
    "COMMISSION_CONSUMPTION": "€ / kWh",
    "Consumption": "Consumo"
  }
}