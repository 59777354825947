function reconciledToString($filter){
    return {
        require: 'ngModel',
        link: function(scope, element, attrs, ngModelController) {
            var $translate = $filter('translate');

            ngModelController.$formatters.push(function(data) {
                return data ? $translate('PROVIDERS.RECONCILED') : $translate('PROVIDERS.NOT_RECONCILED');
            });
        }
    }
}

export { reconciledToString }
