function consumptionReport() {
    return {
        restrict: 'E',
        templateUrl: require('../../templates/sc-consumption-report-directive.html'),
        controller: function($scope, $http, $window, UserInfo, SOURCES) {
            var self = this;
            $scope.status = 'WAITING';

            $scope.empty = true;
            $scope.sources = SOURCES;

            $scope.source = SOURCES[2].code;

            var show = false;

            $scope.on = function(){
                show = true;
                return show;
            }

            $scope.off = function(){
                show = false;
                return show;
            }

            $scope.canDownloadConsumptionReport = UserInfo.hasPerm('consumptions.download_consumption_report_csv');

            $scope.downloadConsumptionCSV = function () {
                var url = API_URL + '/consumptions/consumption-report/' + self.getQueryParams();
                $scope.open = false;
                $window.open(url);
            };

            this.transformToRESTyDate = function (date) {
                var yyyy = date.getFullYear().toString();
                var mm = (date.getMonth() + 1).toString(); // getMonth() is zero-based
                var dd = date.getDate().toString();
                return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]); // padding
            };

            this.getQueryParams = function () {
                if ($scope.fromDate > $scope.toDate) {
                    var aux = $scope.fromDateOne;
                    $scope.fromDate = $scope.toDate;
                    $scope.toDate = aux;
                }
                var params = '?fromDate=' + this.transformToRESTyDate($scope.fromDate);
                params += '&toDate=' + this.transformToRESTyDate($scope.toDate);
                if ($scope.rate == undefined || $scope.rate == null ){
                    params += '&rate='
                }
                else{
                    params += '&rate=' + $scope.rate;
                }
                if ($scope.cups== undefined || $scope.cups == null ){
                    params += '&cups='
                }
                else{
                    params += '&cups=' + $scope.cups;
                }
                if ($scope.source == undefined || $scope.source == null) {
                    params += '&source=';
                }
                else {
                    params += '&source=' + $scope.source;
                }
                return params;
            };

        },
        controllerAs: 'consumptionReport'
    };
}

export {consumptionReport};
