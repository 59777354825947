
const BanksLoading = ['$resource', function ($resource) {
    return $resource(API_URL + '/api/1.0/masters/banks/');
}]

const BanksMaster = ['$q', 'BanksLoading',  function ($q, BanksLoading) {

    var banks= {
        items: null,

        loadBanks: function () {
            var self = this;
            var deferred = $q.defer();

            if (this.items == null)
                BanksLoading.query(function (data) {
                    self.items = data;
                    deferred.resolve(data);
                });
            else
                deferred.resolve(self.items);

            return deferred.promise;
        }
    };

    return banks;

}]


export {BanksLoading, BanksMaster}