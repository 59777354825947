import { BackgroundTasksCtrl } from "./controllers/BackgroundTasksCtrl";
import { TaskController } from "./controllers/TaskController";
import { Task } from "./services/Task";

const tasks = angular.module('tasks', ['pascalprecht.translate', 'notifications']);

tasks.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])


tasks.service('Task', Task)
tasks.controller('BackgroundTasksCtrl', BackgroundTasksCtrl)
tasks.controller('TaskController', TaskController)

export {tasks}
