const ProvidersLoading = ['$resource', function ($resource) {
    return $resource(API_URL + '/api/1.0/masters/providers/');
}]

const ProvidersMaster = ['$q', 'ProvidersLoading',  function ($q, ProvidersLoading) {

    var providers= {
        items: null,

        loadProviders: function () {
            var self = this;
            var deferred = $q.defer();

            if (this.items == null)
                ProvidersLoading.query(function (data) {
                    self.items = data;
                    deferred.resolve(data);
                });
            else
                deferred.resolve(self.items);

            return deferred.promise;
        }
    };

    return providers;


}]

export {ProvidersLoading, ProvidersMaster}
