const Invoice = ['$resource', 
    function ($resource) {
        return $resource(API_URL + '/api/1.0/invoices/:id/', { id: '@id'},
        {
                'update': { method:'PUT' },
                'patch': { method:'PATCH' }
            }
        );
    }
]

export { Invoice };