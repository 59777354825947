function providerInvoiceSetReceivePaperInvoice(UserInfo, Alert, filtersUtils, DateUtils, ProviderInvoiceAPI){
    return {
        restrict: 'AE',
        templateUrl: require('../../templates/provider-invoices-set-receive-paper-invoice.html'),
        controller: function ($scope, $rootScope, $filter) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/provider-invoices/';
            var $translate = $filter('translate');
            var self = this;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagPaperConfirm = false;
            $scope.listToUpdate = [];
            $scope.receive_date = new Date;

            $scope.hasPerm = UserInfo.hasPerm('providers.change_set_receive_paper_invoice');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var date = DateUtils.transformToRESTyDate($scope.receive_date);
                var json = {ids: ids, date: date};

                ProviderInvoiceAPI.setReceivePaperInvoices(json).then(function (data) {
                    Alert.createAlert('success', $translate('PROVIDERS.PROVIDER_STATUS_UPDATED') + data.updated_receive_date, true);
                    $rootScope.$broadcast('refreshList');
                }).catch(function (error) {
                    $scope.error_message = error.data.__all__[0];
                }).finally(function () {
                    $scope.flagPaperConfirm = false;
                    $scope.listToUpdate = [];
                });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.clickSetYes = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToUpdate);
                performRequest(ids);
            };

            $scope.confirmSetDate = function () {
                $scope.flagPaperConfirm = true;
            };

            $scope.updateNo = function () {
                $scope.flagPaperConfirm = false;
            };

        }
    }
}

export { providerInvoiceSetReceivePaperInvoice }
