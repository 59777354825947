function zeroPadding() {
    return function (input, n) {
        if (input === undefined)
            input = ""
        if (input.length >= n)
            return input
        var zeros = Array(n).join("0")
        return (zeros + input).slice(-1 * n)
    };
}

export { zeroPadding }
