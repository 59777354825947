const ProgrammingUnitsDetailCtrl = ['$scope', '$http', '$filter', '$location', '$routeParams', 'Alert', 'UserInfo', 'ProgrammingUnitAPI', 'ELECTRICAL_SUBSYSTEMS',
    function ($scope, $http, $filter, $location, $routeParams, Alert, UserInfo, ProgrammingUnitAPI, ELECTRICAL_SUBSYSTEMS) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.loading = true;
        $scope.editionMode = false;

        // Load Programming Unit from URL id
        $scope.id = $routeParams.id;

        // Ordering and filtering Supplies
        $scope.searchBy = 'search';
        $scope.searchByLabel = $translate('SUPPLIES.ALL');
        $scope.searchFilters = {
            'cups': $translate('SUPPLIES.CUPS'),
            'contract_id': $translate('CONTRACTS.CONTRACT_NUM'),
            'vat_id': $translate('CONTRACTS.Vat id'),
            'fullname': $translate('SUPPLIES.SUPPLY_FULLNAME'),
            'search': $translate('SUPPLIES.ALL')
        };
        $scope.searchBy = Object.keys($scope.searchFilters)[0];
        $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];


        $scope.ordering = {
            "cups": $translate('SUPPLIES.CUPS'),
            "status": $translate('SUPPLIES.STATUS'),
            "rate": $translate('SUPPLIES.TOLL_RATE'),
            "state": $translate('SUPPLIES.STATE')
        };

        $scope.filters = {
            search: "",
            programming_unit: $routeParams.id
        };

        $scope.electrical_subsystems = ELECTRICAL_SUBSYSTEMS;

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        $scope.$watch('searchQuery', function (newVal, oldVal) {
            $scope.filters[$scope.searchBy] = newVal;
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.setSearchBy = function (val) {
            $scope.filters[$scope.searchBy] = '';
            $scope.searchBy = val;
            $scope.searchByLabel = $scope.searchFilters[val];
            $scope.filters[val] = $scope.searchQuery;
        };

        $scope.getStyles = function(result){
            var styles = '';
            if(result.status == 'ACT')
                styles += ' '+'success';
            if(result.status == 'INA')
                styles += ' '+'border';

            return styles;
        };

        $scope.enableEdition = function () {
            $scope.editionMode = !$scope.editionMode;
        };

        $scope.saveEdition = function () {
            if ($scope.updateProgrammingUnitForm.$valid) {
                $scope.updateProgrammingUnit();
            }
        };

        $scope.updateProgrammingUnit = function () {
            var json = {
                "name": $scope.programming_unit.name,
                "description": $scope.programming_unit.description,
                "electrical_subsystem": $scope.programming_unit.electrical_subsystem
            };

            ProgrammingUnitAPI.updateProgrammingUnit($scope.id, json)
                .then(function (programming_unit) {
                    $scope.programming_unit = programming_unit;
                    $scope.enableEdition();

                    Alert.createAlert('success', $translate('PROGRAMMING_UNITS.PROGRAMMING_UNIT_UPDATE_SUCCESSFULLY'), true);
                }).catch(function (error) {
                    $scope.error_message = error.data.__all__[0];
                });

        };

        $scope.unlinkPorgrammingUnit = function (cups) {
            $scope.loading = true;
            var json = {
                ids: [cups],
                unlink : true
            };

            ProgrammingUnitAPI.setProgrammingUnitToSupplies(json)
                .then(function (programming_unit) {
                    $scope.loading = false;
                    $scope.listReload();
                    Alert.createAlert('success', $translate('PROGRAMMING_UNITS.PROGRAMMING_UNLINK_SUCCESSFULLY'), true);
                    $scope.action = '';
                    $scope.deletionMode = false;
                }).catch(function (error) {
                    $scope.loading = false;
                    $scope.error_message = error.data.__all__[0];
                });

        };

        $scope.canEdit = function () {
            return (UserInfo.hasPerm("programming_units.change_programmingunit"));
        };

        $scope.canViewSupply = function () {
            return (UserInfo.hasPerm("programming_units.change_programmingunit"));
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        ProgrammingUnitAPI.getProgrammingUnitDetail($scope.id)
            .then(function (programming_unit) {
                $scope.programming_unit = programming_unit;
                    $scope.loading = false;
            });

    }
]

export {ProgrammingUnitsDetailCtrl};
