
function DateUtils() {

    this.transformToRESTyDate = function (date) {
        if (typeof(date) === "undefined")
            return "";
        var yyyy = date.getFullYear().toString();
        var mm = (date.getMonth() + 1).toString(); // getMonth() is zero-based
        var dd = date.getDate().toString();
        return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]); // padding
    };

    this.transformToRESTyDateWithEndMonth = function (month) {
        // We have to recover datetime now, in order to get year
        var int_month = parseInt(month);
        var year = new Date().getFullYear();
        if (int_month-1 < new Date().getMonth()) {
            year += 1;
        }

        if (int_month === 12) {
            int_month = 0;
            year += 1;
        }

        var new_date = new Date(year, int_month, 0);
        return this.transformToRESTyDate(new_date);
    };

    this.transformToRESTyDateTime = function (date) {
        if (typeof(date) === "undefined")
            return "";
        var yyyy = date.getFullYear().toString();
        var mm = (date.getMonth() + 1).toString(); // getMonth() is zero-based
        var dd = date.getDate().toString();
        var hh = date.getHours().toString();
        var min = date.getMinutes().toString();
        return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]) + "T" + hh + ":" + min;
    };

    this.transformToRESTyDateWODays = function (date) {
        if (typeof(date) === "undefined")
            return "";
        var yyyy = date.getFullYear().toString();
        var mm = (date.getMonth() + 1).toString();
        return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]);
    };

    this.transformToInterface = function (date) {
        return new Date(date.substring(0, 4), date.substring(5, 7) - 1, date.substring(8, 10));
    }

}

export {DateUtils}
