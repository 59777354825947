const CalendarsLoading = ['$resource', function ($resource) {
    return $resource(API_URL + '/api/1.0/masters/calendars/');
}]

const CalendarsMaster = ['$q', 'CalendarsLoading',  function ($q, CalendarsLoading) {

    var calendars= {
        items: null,

        loadCalendars: function () {
            var self = this;
            var deferred = $q.defer();
            if (this.items == null)
                CalendarsLoading.query(function (data) {
                    self.items = data;
                    deferred.resolve(data);
                });
            else
                deferred.resolve(self.items);

            return deferred.promise;
        }
    };

    return calendars;
}]

export {CalendarsLoading, CalendarsMaster}
