const CreateRatePriceCtrl = ['$scope', '$filter', '$routeParams', 'Alert', 'RatesService', 'PricesService', 'ProductAPI', 'ProductPriceAPI', 'SUPPLY_RATES',
    function ($scope, $filter, $routeParams, Alert, RatesService, PricesService, ProductAPI, ProductPriceAPI, SUPPLY_RATES) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var default_type = 'FIXD';
        var prices_to_create = 0;

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.code = $routeParams.code;
        $scope.createRateProducts = [];
        $scope.rates_list = SUPPLY_RATES.map(function(rate) { return rate.code });
        $scope.new_period_prices = new Array(6);
        $scope.period_price_start_date = new Date();
        $scope.types = [
            {code: 'FIXD', name: $translate('PRODUCTS.FIXED')},
            {code: 'DIFF', name: $translate('PRODUCTS.DIFFERENTIAL')},
            {code: 'PERC', name: $translate('PRODUCTS.PERCENTAGE')},
            {code: 'DFXD', name: $translate('PRODUCTS.DAILY_FIXED')},
            {code: 'YFXD', name: $translate('PRODUCTS.YEARLY_FIXED')}
        ];
        $scope.other_types = [
            {code: 'FIXD', name: $translate('PRODUCTS.FIXED')},
            {code: 'DIFF', name: $translate('PRODUCTS.DIFFERENTIAL')},
            {code: 'PERC', name: $translate('PRODUCTS.PERCENTAGE')},
            {code: 'DFXD', name: $translate('PRODUCTS.DAILY_FIXED')},
            {code: 'YFXD', name: $translate('PRODUCTS.YEARLY_FIXED')},
            {code: 'XTDF', name: $translate('PRODUCTS.EXTRA_DAILY_FIXED')},
            {code: 'XTCN', name: $translate('PRODUCTS.EXTRA_BY_CONSUMPTION_FIXED')}
        ];
        $scope.priceActive = PricesService.priceActive;

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.resetForm = function () {
            $scope.price = {
                product_rate: $scope.code,
                type: default_type,
                start_date: new Date()
            }
        };

        $scope.newPrice = function () {
            var sent = $scope.price;
            sent.product = $scope.product_to_create.code;

            ProductPriceAPI.createProductPrice(sent).then(function (product_price) {
                Alert.createAlert('success', $translate('PRODUCTS.PRICE_CREATED'), true);
                $scope.resetForm();
                $scope.creationMode = !$scope.creationMode;
                $scope.newPriceForm.$setPristine();
                $scope.newPriceForm.$setUntouched();
                $scope.$emit('refreshProducts');
            }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
            });
        };

        $scope.newPeriodPrices = function () {
            for (var i = 0; i < $scope.new_period_prices.length; i++) {
                if ($scope.new_period_prices[i] !== undefined) {
                    prices_to_create++;
                }
            }
            if (prices_to_create > 0) {
                for (var i = 0; i < $scope.new_period_prices.length; i++) {
                    if ($scope.new_period_prices[i] !== undefined) {
                        var period = i + 1;
                        var rate = $scope.selectedRate;
                        if ((rate === 'T20DHA' || rate === 'T21DHA') && period === 3) {
                            period = 2;
                        }

                        var json = {
                            value: $scope.new_period_prices[i],
                            start_date: $scope.period_price_start_date,
                            type: default_type,
                            product: $scope.selectedRate + $scope.product_to_create.code + 'P' + period,
                            product_rate: $scope.code
                        };

                        ProductPriceAPI.createProductPrice(json).then(function (product_price) {
                            prices_to_create--;
                            afterCreation();
                            $scope.creationMode = !$scope.creationMode;
                            $scope.periodPriceForm.$setPristine();
                            $scope.periodPriceForm.$setUntouched();
                            $scope.$emit('refreshProducts');
                        }).catch(function (error) {
                            $scope.error_message = error.data.__all__[0];
                        });
                    }
                }
            }
        };

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        function afterCreation() {
            if (prices_to_create === 0) {
                Alert.createAlert('success', $translate('PRODUCTS.PRICE_CREATED'), true);
                $scope.new_period_prices = new Array(6);
                $scope.period_price_start_date = new Date();
                $scope.selectedRate = "";
            }
        }

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        ProductAPI.getAllUniqueProducts().then(function (data) {
            var addedCodes = [];
            for (var i = 0; i < data.results.length; i++) {
                var code = data.results[i].code;
                var name = data.results[i].name;
                var is_period = false;
                if (code[0] === 'T' && code.slice(-2)[0] === 'P') {
                    code = RatesService.getMagnitudeFromCode(code);
                    name = name.substring(0, name.indexOf('P2'));
                    is_period = true;
                }
                if (addedCodes.indexOf(code) < 0) {
                        addedCodes.push(code);
                        $scope.createRateProducts.push({'name': name, 'is_period': is_period, 'code': code});
                    }
            }
            // custom ordering to show firstOne first
            $scope.createRateProducts.sort(function(a, b) {
                var firstOnes = ['E', 'P', 'ERG', 'RE', 'EXP', 'GESTM', 'PRSCH', 'DEVTN', 'FINCT'];
                var pointsA = firstOnes.indexOf(a.code) < 0 ? -100 : 10 - firstOnes.indexOf(a.code);
                var pointsB = firstOnes.indexOf(b.code) < 0 ? -100 : 10 - firstOnes.indexOf(a.code);
                return pointsB - pointsA;
            });

        }).catch(function (error) {
            $scope.createRateProducts = [{name: 'Error'}];
        });

        $scope.resetForm();

    }
]

export { CreateRatePriceCtrl }
