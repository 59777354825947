const CnaesLoading = ['$resource', function ($resource) {
    return $resource(API_URL + '/api/1.0/masters/cnaes/');
}]

const CnaesMaster = ['$q', 'CnaesLoading',  function ($q, CnaesLoading) {

    var cnaes= {
        items: null,

        loadCnaes: function () {
            var self = this;
            var deferred = $q.defer();

            if (this.items == null)
                CnaesLoading.query(function (data) {
                    self.items = data;
                    deferred.resolve(data);
                });
            else
                deferred.resolve(self.items);

            return deferred.promise;
        }

    };

    return cnaes;


}]

export {CnaesLoading, CnaesMaster}
