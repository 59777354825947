const ProductDetailCtrl = ['$scope', '$filter', '$routeParams', 'Alert',  'UserInfo', 'ProductAPI', 'TaxAPI', 'ProductRateAPI',
    function ($scope, $filter, $routeParams, Alert,  UserInfo, ProductAPI, TaxAPI, ProductRateAPI) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

        $scope.PRODUCTS_PRICE_CREATION = require('../../templates/product-price-creation.html')
        $scope.PRODUCTS_PRICE_DETAIL = require('../../templates/product-price-detail.html')

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.loading = true;
        $scope.editionMode = false;

        // Load Product from URL id
        $scope.code = $routeParams.code;

        $scope.types = [
            {name: 'FIXED', id: 'FIXD'},
            {name: 'PERCENT', id: 'PERC'},
            {name: 'DIFFERENCE', id: 'DIFF'}
        ];

        $scope.filters = {
            start_date: "",
            product_rate: "default",
            product: $scope.code,
            start_date_from: "",
            start_date_to: "",
            type: ""
        };

        // URL rate
        $scope.urlRate = $routeParams.rate;
        if (($scope.urlRate != null) && ($scope.urlRate != "")) {
            $scope.defaultFilter = false;
            $scope.auxRateFilter = $scope.urlRate;
        }

        // Ordering
        $scope.ordering = {
            "start_date": $translate('PRODUCTS.START_DATE'),
            "type": $translate('PRODUCTS.TYPE')
        };

        // Create price
        $scope.creationMode = false;

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        // Filtering
        $scope.$watch('auxRateFilter', function() {
            if (($scope.auxRateFilter != "") && ($scope.auxRateFilter != null)){
                $scope.filters.product_rate = $scope.auxRateFilter;
            }
            else {
                $scope.filters.product_rate = "default";
            }
        });

        // List - view reload
        $scope.$on('pricesListReload', function (event) {
            $scope.listReload();
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.creationModeToggle = function () {
            $scope.creationMode = !$scope.creationMode;
        };

        $scope.enableEdition = function () {
            $scope.editionMode = !$scope.editionMode;
        };

        $scope.updateProduct = function () {
            ProductAPI.updateProduct($scope.code, $scope.product)
                .then(function (product) {
                    $scope.product = product;
                    $scope.editionMode = false;
                    Alert.createAlert('success', $translate('PRODUCTS.SUCCESS_PRODUCT_UPDATED'), true);
                }).catch(function (error) {
                    $scope.error_message = error.data.__all__[0];
                });
        };

        $scope.canEdit = function () {
            return (UserInfo.hasPerm("products.change_product"));
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        ProductAPI.getProduct($scope.code).then(function (product) {
            $scope.product = product;
            $scope.loading = false;
        }).catch(function (error) {});

        // Taxes
        TaxAPI.getAllTaxes().then(function (taxes) {
            $scope.taxes = taxes.results;
        }).catch(function (error) {
                $scope.taxes = [{name: 'Error'}];
        });

        // Rates
        ProductRateAPI.getProductRateByProductCode($scope.code, 1000).then(function (data) {
            $scope.product_rates = data.results;
        }).catch(function (error) {
                $scope.product_rates = [{name: 'Error'}];
        });

    }
]

export { ProductDetailCtrl }
