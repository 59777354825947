
const InvoicesForContractCtrl = 
        ['$scope', '$http', '$q', '$filter', 'Alert', 'DateUtils', 'UserInfo', 'CreateDeferredInvoices', 'filtersUtils', 'InvoiceDeferredAPI',
        function ($scope, $http, $q, $filter, Alert, DateUtils, UserInfo, CreateDeferredInvoices, filtersUtils, InvoiceDeferredAPI) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                CreateDeferredInvoices.setInvoices(selectedItemsChange);
                setTotalInvoices();
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function setTotalInvoices() {
                var ids = filtersUtils.getItemsToDownload(CreateDeferredInvoices.getInvoices());
                if (ids.length < 1) {
                    CreateDeferredInvoices.setTotal(0);
                } else {
                    InvoiceDeferredAPI.getTotalForDeferred(ids).then(function(data){
                        if (data.total == null) {
                            data.total = 0;
                        }
                        CreateDeferredInvoices.setTotal(data.total);
                        CreateDeferredInvoices.setInvoicesWithTotal(data.invoices);
                    });
                }
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.canViewDetail = function () {
                return UserInfo.hasPerm('invoices.retrieve_invoices');
            };
        }]

export {InvoicesForContractCtrl}
