const AddPaymentInvoiceCtrl = ['$scope', '$location', '$routeParams', '$http', 'Alert', '$filter', 'Invoice',
    function ($scope, $location, $routeParams, $http, Alert, $filter, Invoice) {

        var $translate = $filter('translate');
        $scope.id = $routeParams.id;

        $scope.type = [
            {code: 'TRF', label: $translate('INVOICES.TRANSFER')},
            {code: 'RCP', label: $translate('INVOICES.RECEIPT')},
            {code: 'RFD', label: $translate('INVOICES.REFUND')},
            {code: 'CMP', label: $translate('INVOICES.COMPENSATION')},
            {code: 'TPV', label: 'TPV'},
            {code: 'GES', label: 'GESICO'}
        ];

        $scope.payment = {
            payment_type: null,
            amount: 0,
            payment_date: new Date(),
            origin: ""
        };

        Invoice.get({id: $scope.id}, function (invoice) {
            $scope.invoice = invoice;
            $scope.invoice.total = invoice.total;
            $scope.invoice.extra_expenses = invoice.extra_expenses;
            $scope.payment.amount = Math.round((invoice.total + invoice.extra_expenses - invoice.amount_paid) * 100) / 100;
            $scope.invoice.amount_paid = invoice.amount_paid;
        });

        $scope.addPaymentToInvoice = function () {

            var data = {
                'payment_type': $scope.payment.payment_type,
                'origin':  $scope.payment.origin,
                'amount': Math.round($scope.payment.amount * 100) / 100,
                'payment_date': $scope.payment.payment_date
            };

            $http.post(API_URL + '/api/1.0/invoices/' + $routeParams.id + '/payment/', data).
                then(function (data, status, headers, config) {
                    $location.path('/invoices/' + $routeParams.id);
                    Alert.createAlert('success', $translate('INVOICES.CREATED_PAYMENT_SUCCESS'), true);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('INVOICES.CREATED_PAYMENT_ERROR'), true);
                });
        };

        $scope.back = function () {
            $location.path('/invoices/' + $routeParams.id);
        };
    }
]

export { AddPaymentInvoiceCtrl }