const SettingsListController = ['$scope', '$filter', '$http', 'UserInfo', 'Settings', 'Alert', '$q',
    function ($scope, $filter, $http, UserInfo, Settings, Alert, $q) {
        var $translate = $filter('translate');

        $scope.loadItems = function () {
            var get_settings = $http.get(API_URL + '/api/1.0/settings/');
            var get_taxes = $http.get(API_URL + '/api/1.0/tax-rules/');

            $q.all([get_settings, get_taxes]).then(function(data){

                $scope.settings = {};
                angular.forEach(data[0].data, function (item) {
                    var key = Object.keys(item)[0];
                    if (key == 'company_tax')
                        $scope.settings[key] = parseInt(item[key]);
                    else
                        $scope.settings[key] = item[key];
                }, $scope.settings);

                $scope.taxes = data[1].data;
            })
        };

        $scope.settings = $scope.loadItems();

        $scope.canUpdateSettings = function () {
            return UserInfo.hasPerm('custom_settings.change_settings');
        };

        $scope.updateSettings = function (event, translation) {
            var key = event.target.name;
            var data = {
                "value": $scope.settings[key]
            };

            Settings.update({key: key}, data, function (data) {
                Alert.createAlert('success', $translate(translation) + " " + $translate('SETTINGS.SUCCESS_SETTINGS_UPDATED'), true);
            }, function (error) {
                for (var k in error.data) {
                    $scope.error_message = error.data[k];
                    if (k == 'non_field_errors') {
                        $scope.error_message = error.data[k][0];
                    }
                }
                $scope.saving = false;
                Alert.createAlert('danger', $translate(translation) + " " + $translate('SETTINGS.ERROR_SETTINGS_UPDATED'), true);
            });
        };

    }
]

export { SettingsListController }
