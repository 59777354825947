const ContractDataFormCtrl = 
    ['$scope', '$filter', '$http', 'Contract', 'SUPPLY_RATES', 'CONTRACT_REGULARITIES', 'CONTRACT_ORIGINS', 'CONTRACT_MODE',
        'NORMAL_POWERS', 'SUPPLY_POWER_PERIODS', 'CreateContract', 'CnaesMaster', 'ProductRatesMaster', 'AgreementsMaster', 'AgreementAPI', 'CitiesMaster',
    function ($scope, $filter, $http, Contract, SUPPLY_RATES, CONTRACT_REGULARITIES, CONTRACT_ORIGINS, CONTRACT_MODE,
                NORMAL_POWERS, SUPPLY_POWER_PERIODS, CreateContract, CnaesMaster, ProductRatesMaster, AgreementsMaster, AgreementAPI, CitiesMaster) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var self = this;
        var $translate = $filter('translate');
        var api_check_rate = API_URL + '/api/1.0/rate-valid-in-product-rate/';

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.message = $translate('CONTRACTS.CLOSE_PAGE');
        $scope.contract = null;
        $scope.rates = SUPPLY_RATES;

        $scope.regularities = CONTRACT_REGULARITIES;
        $scope.drafts_origins = CONTRACT_ORIGINS;
        $scope.normal_powers = NORMAL_POWERS;

        $scope.range_errors = {
            T20A: $translate('CONTRACTS.RANGE_ERROR_20A'),
            T20DHA: $translate('CONTRACTS.RANGE_ERROR_20DHA'),
            T21A: $translate('CONTRACTS.RANGE_ERROR_21A'),
            T21DHA: $translate('CONTRACTS.RANGE_ERROR_21DHA'),
            T30A: $translate('CONTRACTS.RANGE_ERROR_30A'),
            T31A: $translate('CONTRACTS.RANGE_ERROR_31A'),
            T31DHA: $translate('CONTRACTS.RANGE_ERROR_31DHA'),
            T61: $translate('CONTRACTS.RANGE_ERROR_61'),
            T61A: $translate('CONTRACTS.RANGE_ERROR_61'),
            T61B: $translate('CONTRACTS.RANGE_ERROR_61'),
            T62: $translate('CONTRACTS.RANGE_ERROR_62'),
            T63: $translate('CONTRACTS.RANGE_ERROR_63'),
            T64: $translate('CONTRACTS.RANGE_ERROR_64'),
            T65: $translate('CONTRACTS.RANGE_ERROR_65')
        };

        $scope.payment_methods = [
            {code: 'TRF', label: $translate('INVOICES.TRANSFER')},
            {code: 'DBT', label: $translate('INVOICES.DEBIT')},
            {code: 'B2B', label: $translate('INVOICES.DEBIT_B2B')}
        ];

        $scope.see_copy_agreement_option = false;
        $scope.rate_product_dont_match = false;

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        $scope.$watch('contract.rate', function (newValue, oldValue) {
            if ($scope.contract) {
                if (String(newValue).indexOf('2') > 0)
                    $scope.contract.regularity = $scope.regularities[1];
                else
                    $scope.contract.regularity = $scope.regularities[0];
            }
        });

        $scope.$watch('contract.agreement', function (newValue, oldValue) {
            if ($scope.contract && newValue != undefined) {
                $scope.loadAgreement();
            }

        });

        $scope.$watch('contract.id', function (newValue, oldValue) {
            if (newValue) {
                Contract.get({id: newValue}, function (contract) {
                    $scope.contractData.contract_id.$setValidity('api', false);
                    $scope.contractData.contract_id.$error.api = $translate('CONTRACTS.This contract number already exists');
                }, function () {
                    if ($scope.contractData.contract_id.$error.api) {
                        delete $scope.contractData.contract_id.$error.api;
                        $scope.contractData.contract_id.$setValidity('api', true);
                    }
                });
            }
        });

        $scope.$watch('contractData.$valid', function (newValue, oldValue) {
            $scope.$emit("setValidity", newValue);

            if (newValue)
                CreateContract.setContractData($scope.contract);
            else
                CreateContract.setContractData(null);

        }, true);

        $scope.$on('customerLoaded', function(event, data) { $scope.checkPrintable(data) })

        $scope.$watchGroup(['contract.rate', 'contract.product_rate', 'contract.invoicing_mode'], function (newValues, oldValues, scope) {
            $scope.rate_product_dont_match = false;
            if (newValues[0] && newValues[1] && newValues[2]) {
                var data = {
                    "product_rate": newValues[1],
                    "rate": newValues[0]
                };

                $http.post(api_check_rate, data).then(function (data, status, headers, config) {
                    if (!data.data.has_prices) {
                        $scope.contractData.contract_product_rate.$setValidity('api', false);
                        $scope.contractData.contract_product_rate.$error.api = '';
                        $scope.rate_product_dont_match = true;
                    } else {
                        if ($scope.contractData.contract_product_rate.$error.api) {
                            delete $scope.contractData.contract_product_rate.$error.api;
                        }
                        $scope.contractData.contract_product_rate.$setValidity('api', true);
                        $scope.rate_product_dont_match = false;
                    }
                }, function (data, status, headers, config) {});
            }
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/
        this.fillData = function (data, from) {
            if (data) {
                $scope.contract.contact_phone = null;
                $scope.contract.contact_mobile = null;
                if (from === 'agreement'){
                    if ($scope.contract == null)
                        $scope.contract = {};
                    if(data.contact_email)
                        $scope.contract.email = data.contact_email;
                    $scope.contract.print_invoices = data.receive_paper_invoices;
                    $scope.contract.name = data.contact_name;
                    $scope.contract.surname_1 = data.contact_surname_1;
                    $scope.contract.surname_2 = data.contact_surname_2;
                    $scope.contract.address = data.contact_address;
                    $scope.contract.city = data.contact_city;
                    $scope.contract.state = data.contact_state;
                    $scope.contract.zip = data.contact_zip;
                    if(data.contact_phone)
                        $scope.contract.contact_phone = parseInt(data.contact_phone);
                }
                else {
                    if ($scope.contract == null)
                        $scope.contract = {};
                    if (data.email)
                        $scope.contract.email = data.email;
                    $scope.contract.name = data.name;
                    $scope.contract.surname_1 = data.surname_1;
                    $scope.contract.surname_2 = data.surname_2;
                    $scope.contract.address = data.address;
                    $scope.contract.city = data.city;
                    $scope.contract.state = data.state;
                    $scope.contract.zip = data.zip;

                    if (from === 'customer'){
                        $scope.contract.contact_phone = parseInt(data.phone);
                        $scope.contract.contact_mobile = parseInt(data.mobile);
                    }
                }
            }
        };

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.powerActive = function (powerIndex, type) {
            if (type == 'supply')
                return angular.isDefined($scope.supply) && $scope.supply != null &&
                    angular.isDefined($scope.supply.rate) && $scope.supply.rate != '' &&
                    !SUPPLY_POWER_PERIODS[$scope.supply.rate][powerIndex - 1];
            else if (type == 'contract')
                return angular.isDefined($scope.contract) && $scope.contract != null &&
                    angular.isDefined($scope.contract.rate) && $scope.contract.rate != '' &&
                    !SUPPLY_POWER_PERIODS[$scope.contract.rate][powerIndex - 1];
            else
                return true;
        };

        $scope.powerNormal = function () {
            if (angular.isDefined($scope.contract) && $scope.contract != null && angular.isDefined($scope.contract.rate)) {
                for (var i = 1; i < 7; i++) {
                    if (!$scope.powerActive(i, 'contract')) {
                        if ($scope.contract['power_p' + i] !== undefined && $scope.normal_powers.indexOf($scope.contract['power_p' + i]) < 0) {
                            return false;
                        }
                    }
                }
                return true;
            }
            return true;
        };

        $scope.pastePostalData = function (from) {
            var data = null;
            if (from == 'supply') {
                data = CreateContract.getSupply();
            } else if (from == 'customer') {
                data = CreateContract.getCustomer();
            } else if (from == 'agreement') {
                data = CreateContract.getAgreement();
                var customer = CreateContract.getCustomer();
                if (customer !== undefined) {
                    data.contact_name = customer.name;
                    data.contact_surname_1 = customer.surname_1;
                    data.contact_surname_2 = customer.surname_2;
                }
            }

            if (data !== undefined) {
                self.fillData(data, from);
            }
        };

        $scope.pasteSupplyPowers = function () {
            var data = CreateContract.getSupply();

            if (data) {
                if ($scope.contract == null)
                    $scope.contract = {};

                $scope.contract.power_p1 = data.power_p1;
                $scope.contract.power_p2 = data.power_p2;
                $scope.contract.power_p3 = data.power_p3;
                $scope.contract.power_p4 = data.power_p4;
                $scope.contract.power_p5 = data.power_p5;
                $scope.contract.power_p6 = data.power_p6;
                $scope.contract.rate = data.rate;
            }
        };

        $scope.loadAgreement = function () {
            if ($scope.contract.agreement) {

                AgreementAPI.getAgreementDetail($scope.contract.agreement)
                    .then(function (data) {
                        CreateContract.setAgreement(data);
                    });
            }
        };
        
        $scope.checkPrintable = function(data) {
            $scope.contract.print_invoices = false;
            if (data && data.vat_id) {
                if (data.vat_id.substring(0, 1) == 'H' || data.vat_id.substring(0, 1) == 'E') {
                    $scope.contract.print_invoices = true;
                }
            }
        }
        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/


        AgreementsMaster.loadAgreements().then(function (response){
            $scope.agreements = response;
        });

        ProductRatesMaster.loadProductRatesNotForce().then(function (response){
            $scope.product_rates = response;
        });

        CnaesMaster.loadCnaes().then(function (response){
            $scope.cnaes = response;
        });

        CitiesMaster.loadCities().then(function(response){
            $scope.cities=response;
        });

    }]

export {ContractDataFormCtrl}
