const ContractRelatedCtrl = ['$scope', '$filter', '$routeParams', '$http', 'ProductRatesMaster', 'UserInfo', 'Alert',
    function ($scope, $filter, $routeParams, $http, ProductRatesMaster, UserInfo, Alert) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;
        var id = $routeParams.id;
        var api = API_URL + '/api/1.0/contracts/' + id + '/members/';

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/

        $scope.action = 'detail';
        $scope.creationMode = false;

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/


        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/


        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/

        $scope.deleteRelated = function ($event) {
          $event.preventDefault();
          $scope.action = 'delete';
        };

        $scope.editRelated = function ($event) {
          $event.preventDefault();
          $scope.action = 'edit';
        };

        $scope.cancelRelated = function ($event) {
          $event.preventDefault();
          $scope.action = 'detail';
        };

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/

        function resetForm() {
            $scope.product_rate = "";
            $scope.related_contract = "";
        }

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
    
         $scope.canDeleteContractRelated = function () {
            return UserInfo.hasPerm("contracts.can_relate_contracts");
          };

          $scope.canEditContractRelated = function () {
            return UserInfo.hasPerm("contracts.can_relate_contracts");
          };


        $scope.toggleCreationMode = function () {
            $scope.creationMode = !$scope.creationMode;
        };


        $scope.createContractRelated = function () {
            var data = {
                "related_contract": $scope.related_contract,
                "product_rate": $scope.product_rate
            };

            $http.post(api, data).then(function (data, status, headers, config) {
                $scope.listReload();
                Alert.createAlert('success', $translate('CONTRACTS.CONFIRM_ADD_RELATED'), true);
                resetForm();
                $scope.creationMode = false;
            }).catch(function (data, status, headers, config) {
                Alert.createAlert('danger', $translate('CONTRACTS.ERROR_CONFIRM_ADD_RELATED'), true);
            });
        };

        $scope.deleteContractRelated = function (id) {

            $http.delete(`${api}${id}/`).then(function(response) {
                $scope.listReload();
                Alert.createAlert('success', $translate('CONTRACTS.CONFIRM_DELETED_RELATED'), true);
            }).catch(function(response){
                let data = response.data;
                Alert.createAlert('danger', data.detail)
            });
        };

        $scope.updateContractRelated = function (id) {

            var data = {
                'product_rate': $scope.product_rate
            };

            $http.patch(`${api}${id}/`, data).then(function (response) {
                $scope.listReload();
                Alert.createAlert('success', $translate('CONTRACTS.CONFIRM_UPDATE_RELATED'), true);
            }).catch(function(response){
              let data = response.data;
              Alert.createAlert('danger', data.detail)
          });
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/

        ProductRatesMaster.loadProductRatesNotForce().then(function (response){
            $scope.product_rates = response;
        });

    }
]

export { ContractRelatedCtrl }
