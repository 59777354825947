// const contractInvoicingGenerationMode = function
function contractInvoicingGenerationMode(UserInfo, CONTRACT_INVOICING_GENERATION_MODE, Task, filtersUtils, Alert, $filter) {
    return {
        restrict: 'AE',
        scope: {},
        //templateUrl: require('../../static/contracts/templates/change-invoicing-generation-mode.html'),
        templateUrl: require('../../templates/change-invoicing-generation-mode.html'),
        controller: function ($scope) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/tasks/';
            var $translate = $filter('translate');
            var type = 'BUIG';

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.listToUpdate = [];
            $scope.update = {};
            $scope.hasPerm = UserInfo.hasPerm('contracts.change_contract');
            $scope.modes = CONTRACT_INVOICING_GENERATION_MODE;

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/
            function performRequest(ids) {
                var data = {};
                data['values'] = $scope.update;
                data['ids'] = ids;

                var task = new Task();
                task.type = type;
                task.params = data;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('CONTRACTS.BULK_UPDATE_QUEUED'), true);
                    delete $scope.update;
                }).catch(function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.BULK_UPDATE_REJECTED'), true);
                });

                // TODO: Si $scope.update.invoicing_generation_mode == 'UNF' llamar también al endpoint de agregar el producto excluído masivamente!!!!
            }

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/
            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
                $('#changeContractInvoicingGenerationModeModal').modal('toggle');
            };

            $scope.clickYes = function () {
                var ids = filtersUtils.getItemsToDownload($scope.listToUpdate);
                performRequest(ids);
                $('#changeContractInvoicingGenerationModeModal').modal('toggle');
            };

        }
    }
}

export {contractInvoicingGenerationMode};
