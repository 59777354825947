// const contractsAddProductToBeInvoiced = function
function contractsAddProductToBeInvoiced(UserInfo, ExcludedProductApi, Task, filtersUtils, Alert, ProductCodeMaster, DateUtils, $filter) {
    return {
        restrict: 'AE',
        scope: {},
        //templateUrl: require('../../static/contracts/templates/add-product-to-be-invoiced.html'),
        templateUrl: require('../../templates/add-product-to-be-invoiced.html'),
        controller: function ($scope) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/tasks/';
            var $translate = $filter('translate');
            var type = 'AITC';

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.listToUpdate = [];
            $scope.update = {};
            $scope.hasPerm = UserInfo.hasPerm('contracts.change_contract');

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/
            function resetForm() {
                $scope.update.quantity = "";
                $scope.selected_product = "";
                $scope.update.validity_date = 0;
                $scope.update.reason = '';
                $scope.addProductoToBeInvoiced.$setPristine();
                $scope.addProductoToBeInvoiced.$setUntouched();
            }

            function performRequest(ids) {
                var data = {};
                $scope.update['product'] = $scope.selected_product.code;
                data['values'] = angular.copy($scope.update);
                data['values']['validity_date'] = DateUtils.transformToRESTyDate($scope.update.validity_date);
                data['ids'] = ids;

                var task = new Task();
                task.type = type;
                task.params = data;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('CONTRACTS.BULK_UPDATE_QUEUED'), true);
                    resetForm();
                }).catch(function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.BULK_UPDATE_REJECTED'), true);
                });
            }

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/
            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
                $('#addNewProductToBeInvoiced').modal('toggle');
                resetForm();
            };

            $scope.clickYes = function () {
                var ids = filtersUtils.getItemsToDownload($scope.listToUpdate);
                performRequest(ids);
                $('#addNewProductToBeInvoiced').modal('toggle');
            };

            /***********************************************************************************************************
             * Initialization
             ***********************************************************************************************************/

            ProductCodeMaster.loadProductCodesNotForce().then(function (response) {
                $scope.products_codes = response;
            });
            if ($scope.update.quantity == null) {
                $scope.update.quantity = 0;
            }
            if ($scope.update.validity_date == null) {
                $scope.update.validity_date = '';
            }
        }
    }
}

export { contractsAddProductToBeInvoiced };
