// const contractsAgreement = function
function contractsAgreement(UserInfo, filtersUtils, DateUtils, Alert, $filter, AgreementsMaster) {
    return {
        restrict: 'AE',
        scope: {},
        //templateUrl: require('../../static/contracts/templates/change-agreement.html'),
        templateUrl: require('../../templates/change-agreement.html'),
        controller: function ($scope, $http, $rootScope) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/tasks/';
            var $translate = $filter('translate');
            var self = this;
            var first_access = true;
            var type = 'CCCV';

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.listToUpdate = [];
            $scope.change_agreement = '';

            $scope.hasPerm = UserInfo.hasPerm('contracts.change_contract_agreement');

            $scope.agreement_date = new Date;
            $scope.selected_agreement = false;
            $scope.management_change = true;
            $scope.reason = '';

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            $scope.$watch('productAgreement.$valid', function (newValue, oldValue) {
                $scope.$emit("setValidity", newValue);
                if (first_access == true)
                    first_access = false;
                else {
                    if (newValue)
                        $scope.selected_agreement = true;
                }
            }, true);

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/
            function performRequest(ids) {
                var date = DateUtils.transformToRESTyDate($scope.agreement_date);
                var json = {
                    type: type,
                    params: {
                        ids: ids,
                        agreement: $scope.change_agreement,
                        date: date,
                        management_change: $scope.management_change,
                        reason: $scope.reason
                    }
                };

                var config = {
                    method: "POST",
                    url: api,
                    data: json,
                    headers: {"Content-Type": "application/json;charset=utf-8"}
                };
                $http(config).then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('CONTRACTS.UPDATED') + data.data.updated_number + $translate('CONTRACTS.CONTRACT_AGREEMENTS'), true);
                    $rootScope.$broadcast('refreshList');
                    $('#changeAgreementModal').modal('toggle');
                }).catch(function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.ERROR_REQUEST'), true);
                }).finally(function () {
                    $scope.flagConfirm = false;
                    $scope.listToUpdate = [];
                });
            }

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/
            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
                $scope.change_agreement = '';
                $scope.selected_agreement = false;
                $scope.reason = '';
            };

            $scope.clickYes = function () {
                var ids = filtersUtils.getItemsToDownload($scope.listToUpdate);
                performRequest(ids);
                $scope.change_agreement = '';
                $scope.selected_agreement = false;
                $scope.reason = '';
            };

            /***********************************************************************************************************
             * Controller Initialization
             ***********************************************************************************************************/
            AgreementsMaster.loadAgreements().then(function (response) {
                $scope.agreements = response;
            });
        }
    }
}

export {contractsAgreement}
