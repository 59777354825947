import showdown from "showdown";

const markdown = [
    function() {
        var LINKY_URL_REGEXP = /((ftp|https|http):[A-Za-z0-9._%+-]+@)\S*[^\s.;,(){}"\u201d\u2019]/i, MAILTO_REGEXP = /^mailto:/i;

        var CHARS_TO_DELETE = /([<>])/i;

        return function(text) {
            text = text.replace(new RegExp(CHARS_TO_DELETE, 'g'), '');
            if (!text) return text;
            var match;
            var raw = text;
            var html = [];
            var url;
            var i;
            var converter = new showdown.Converter();

            while ((match = raw.match(LINKY_URL_REGEXP))) {
                // We can not end in these as they are sometimes found at the end of the sentence
                url = match[0];
                // if we did not match ftp/http/www/mailto then assume mailto
                if (!match[2] && !match[4]) {
                    url = (match[3] ? 'http://' : 'mailto:') + url;
                }
                i = match.index;
                addText(raw.substr(0, i));
                addLink(url, match[0].replace(MAILTO_REGEXP, ''));
                raw = raw.substring(i + match[0].length);
            }
            addText(raw);

            return html.join('');

            function addText(txt) {
                if (!text) {
                    return;
                }
                html.push(converter.makeHtml(txt));
            }

            function addLink(url, text) {
                addText('<' + text + '>');
            }
        };

    }
]

export { markdown }