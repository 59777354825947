// const contractUnsubscribe = function
function contractBulkUnsubscribe(UserInfo, filtersUtils, Alert, $filter){
    return {
        restrict: 'AE',
        templateUrl: require('../../templates/contracts-bulk-unsubscribe.html'),
        controller: function ($scope, $http, $rootScope) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/tasks/';
            var type = 'UNSC';
            var $translate = $filter('translate');
            var self = this;
            var first_access = true;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.listToUpdate = [];
            $scope.motive = '';

            $scope.hasPerm = UserInfo.hasPerm('contracts.can_unsubscibe_contract_request');

            $scope.motives = [
                {code: '01', label: $translate('CONTRACTS.ACTIVITY_CESSATION')},
                {code: '02', label: $translate('CONTRACTS.END_CONTRACT')},
                {code: '03', label: $translate('CONTRACTS.SUPPLY_CUT')},
                {code: '04', label: $translate('CONTRACTS.UNPAID_CONTRACT')},
                {code: '05', label: $translate('CONTRACTS.INACTIVE')},
            ];

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            $scope.$watch('unsubscribeContracts.$valid', function (newValue, oldValue) {
                $scope.$emit("setValidity", newValue);
                if (first_access == true)
                    first_access = false;
                else {
                    if (newValue)
                        $scope.selected_motive = true;
                }
            }, true);

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var json = {
                    type: type,
                    params: {ids: ids, motive: $scope.motive}
                };

                var config = {
                    method: "POST",
                    url: api,
                    data: json,
                    headers: {"Content-Type": "application/json;charset=utf-8"}
                };
                $http(config).
                    then(function(response) {
                        Alert.createAlert('success', $translate('CONTRACTS.BULK_UPDATE_QUEUED'), true);
                        $rootScope.$broadcast('refreshList');
                    }).
                    catch(function (response) {
                        Alert.createAlert('danger', $translate('CONTRACTS.ERROR_REQUEST'), true);
                    }).
                    finally(function () {
                        $scope.flagConfirm = false;
                        $scope.listToUpdate = [];
                    });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.clickNo = function () {
                $scope.flagConfirm = false;
                $scope.motive = '';
            };

            $scope.clickYes = function () {
                var ids = filtersUtils.getItemsToDownload($scope.listToUpdate);
                performRequest(ids);
                $scope.motive = '';
            };

        }
    }
}

export {contractBulkUnsubscribe}
