const CalendarForSupplyAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/calendars-supplies/';

        var self = this;
        var calendar_supply = null;
        
        function deleteCalendarForSupply(id) {
            var deferred = $q.defer();

            $http.delete(url + id)
                .then(function (calendar_supply) {
                    deferred.resolve(calendar_supply.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function getCalendarForSupplyDetail(id) {
            if (calendar_supply == null || calendar_supply.id != id) {
                calendar_supply = {
                    'id': id
                };
                return _getCalendarForSupplyDetail(id);
            } else {
                var deferred = $q.defer();
                deferred.resolve(calendar_supply);
                return deferred.promise;
            }
        }

        function _getCalendarForSupplyDetail(id) {
            var deferred = $q.defer();

            $http.get(url + id)
                .then(function (calendar_supply) {
                    deferred.resolve(calendar_supply.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            self.id = id;
            return deferred.promise;
        }
        
        function createCalendarForSupply(calendar_supply) {
            var deferred = $q.defer();

            $http.post(url, calendar_supply)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function bulkUpdateCalendarForSupply(calendar_supply) {
            var deferred = $q.defer();

            $http.put(url, calendar_supply)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        return {

            deleteCalendarForSupply: deleteCalendarForSupply,
            getCalendarForSupplyDetail: getCalendarForSupplyDetail,
            createCalendarForSupply: createCalendarForSupply,
            bulkUpdateCalendarForSupply: bulkUpdateCalendarForSupply
        };

    }
]

export {CalendarForSupplyAPI};
