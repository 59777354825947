const ComplaintResultController = ['$scope', '$filter', '$routeParams', 'UserInfo', 'ComplaintAPI', 'AGENT_CODES', 'CHANNEL_CODES', 'SUBTYPE_CODES',
    'RATE_CODES', 'COMPLAINT_STATUSES', 'COMPLAINT_RESULTS', 'Alert', 'StatesCodeMaster', 'CompaniesMaster', 'WATIUM_DEPARTMENTS',
    function ($scope, $filter, $routeParams, UserInfo, ComplaintAPI, AGENT_CODES, CHANNEL_CODES, SUBTYPE_CODES,
                RATE_CODES, COMPLAINT_STATUSES, COMPLAINT_RESULTS, Alert, StatesCodeMaster, CompaniesMaster, WATIUM_DEPARTMENTS) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.id = $routeParams.id;

        $scope.agent_codes = AGENT_CODES;
        $scope.channel_codes = CHANNEL_CODES;
        $scope.subtype_codes = SUBTYPE_CODES;
        $scope.rate_codes = RATE_CODES;
        $scope.complaint_statuses = COMPLAINT_STATUSES;
        $scope.complaint_results = COMPLAINT_RESULTS;
        $scope.watium_department = WATIUM_DEPARTMENTS;

        $scope.loading_detail = true;
        $scope.enable_edition = false;

        $scope.initial_counter_status = false;

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        this.formatComplaintInfo = function (complaint) {
            $scope.complaint = complaint;
            $scope.complaint.creation_date = new Date($scope.complaint.creation_date);
            $scope.complaint.modification_date = new Date($scope.complaint.modification_date);
            $scope.complaint.close_date = ($scope.complaint.close_date) ? $scope.complaint.close_date : null;
            $scope.old_status = $scope.complaint.status;
            $scope.initial_counter_status = complaint.manual_counter_edition;

            if ($scope.complaint.last_status_change) {
                $scope.complaint.last_status_change = new Date($scope.complaint.last_status_change);
            }
        };

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.getComplaint = function () {
            $scope.loading_detail = true;

            ComplaintAPI.getComplaintDetail($scope.id)
                .then(function (complaint) {
                    self.formatComplaintInfo(complaint);
                    $scope.loading_detail = false;
                });
        };

        $scope.change_needed_distributor = function (value) {
            $scope.complaint.needed_distributor = value;
        };

        $scope.change_manual_counter_edition = function (value) {
            $scope.complaint.manual_counter_edition = value;
        };

        $scope.updateComplaint = function () {
                $scope.submited = false;

            var complaint_data = {
                total_cups: $scope.complaint.total_cups,
                claimant: $scope.complaint.claimant,
                subtype: $scope.complaint.subtype,
                channel: $scope.complaint.channel,
                waiting_for_response: $scope.complaint.waiting_for_response,
                external_code: $scope.complaint.external_code,
                distributor_code: $scope.complaint.distributor_code,
                needed_distributor: $scope.complaint.needed_distributor,
                current_department: $scope.complaint.current_department,
                description: $scope.complaint.description,
                creation_date: $scope.complaint.creation_date + 'T00:00',
                manual_counter_edition: $scope.complaint.manual_counter_edition
            };

            if (UserInfo.hasPerm('complaints.can_change_status')){
                complaint_data.result = $scope.complaint.result;
                complaint_data.status = $scope.complaint.status;
            }

            if (UserInfo.hasPerm('complaints.can_update_days')){
                //complaint_data.total_days = $scope.complaint.total_days;
                complaint_data.distributor_days = $scope.complaint.distributor_days;
                complaint_data.commercial_days = $scope.complaint.commercial_days;
                complaint_data.client_days = $scope.complaint.client_days;
                complaint_data.other_days = $scope.complaint.other_days;
            }

            ComplaintAPI.updateComplaint($scope.id, complaint_data)
                .then(function (complaint) {
                    $scope.submited = true;
                    self.formatComplaintInfo(complaint);
                    $scope.enable_edition = false;

                    Alert.createAlert('success', $translate('COMPLAINTS.COMPLAINT_UPDATE_SUCCESSFULLY'), true);
                }).catch(function (error) {
                    $scope.submited = true;
                    // $scope.error_message = error.data.__all__[0];
                    for (var k in error) {
                        if (k !== 'non_field_errors') {
                            $scope.error_message = error[k];
                            Alert.createAlert('danger', $scope.error_message, true);
                            break;
                        }
                        else {
                            if (k === 'non_field_errors') {
                                $scope.error_message = error[k][0];
                            }
                            $scope.complaintForm[k].$error.api = error[k][0];
                        }
                    }
                    });
        };

        $scope.enableEdition = function () {
            $scope.enable_edition = !$scope.enable_edition;
        };

        $scope.canEditComplaint = function () {
            return UserInfo.hasPerm('complaints.change_complaint');
        };

        $scope.canEditTotalValuesComplaint = function () {
            return UserInfo.hasPerm('complaints.can_update_days');
        };

        $scope.canEditStatusComplaint = function () {
            return UserInfo.hasPerm('complaints.can_change_status');
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        $scope.getComplaint();

        StatesCodeMaster.loadStatesCodes().then(function (response) {
            $scope.states = response;
        });

        CompaniesMaster.loadCompaniesDistributor().then(function (response){
            $scope.distributors = response;
        });

    }
]

export { ComplaintResultController }
