function duplicateIssue($filter, UserInfo, Alert) {
    return {
        restrict: 'AE',
        templateUrl: require('../../templates/duplicate-issue.html'),
        controller: function ($scope, $http, $routeParams, IssueAPI) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var self = this;
            var first_access = true;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.id = $routeParams.id;
            $scope.flagDuplicateConfirm = false;
            $scope.change_category = '';
            $scope.selected_category = false;
            $scope.motive = '';
            $scope.set_motive = false;
            $scope.set_maximeter = false;
            $scope.maximeter = '';

            $scope.hasPerm = UserInfo.hasPerm('issues.duplicate_issue');

            $scope.categories = [
                {code: 'A3', name: "A3"},
                {code: 'C1', name: "C1"},
                {code: 'C2', name: "C2"},
                {code: 'M1', name: "M1"},
                {code: 'B1', name: "B1"},
                {code: 'R1', name: "R1"}
            ];

            $scope.motives = {
                "01": 'Cese de Actividad',
                "02": 'Fin de contrato de energía',
                "03": 'Corte de suministro',
                "04": 'Baja por impago',
                "05": 'Baja sin desconexión definitiva de instalaciones'
            };

            $scope.maximeters = [
                {code: true, name: "Con maxímetro"},
                {code: false, name: "Sin maxímetro"}
            ];

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$watch('duplicateIssueForm.$valid', function (newValue, oldValue) {
                $scope.$emit("setValidity", newValue);
                if (first_access == true)
                    first_access = false;
                else {
                    if (newValue)
                        $scope.selected_category = true;
                }
            }, true);

            $scope.$watch('change_category', function (newVal, oldVal) {
                if (newVal == 'B1') {
                    $scope.set_motive = true;
                }
                if (newVal == 'M1'){
                    $scope.set_maximeter = true;
                }
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest() {
                var json = {category: $scope.change_category};

                if ($scope.change_category === "B1"){
                    json['motive'] = $scope.motive;
                }

                if ($scope.change_category === "M1"){
                    if ($scope.maximeter === '')
                        json['maximeter'] = false;
                    else
                        json['maximeter'] = $scope.maximeter;
                }

                IssueAPI.duplicateIssue($scope.id ,json)
                    .then(function (data) {
                        Alert.createAlert('success', $translate('ISSUES.DUPLICATED_ISSUE_SUCCESSFULLY') + '(<a ng-href="/issues/' + data.id + '" target="_blank">' + data.id + '</a>).', false);
                    }).catch(function (error) {
                        if(error.non_field_errors)
                            $scope.error_message = error.non_field_errors[0];
                        else
                            $scope.error_message = $translate('ISSUES.ERROR_REQUEST')
                        Alert.createAlert('danger', $scope.error_message, false);
                    }).finally(function () {
                        $scope.flagDuplicateConfirm = false;
                    });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.clickDuplicateNo = function () {
                $scope.flagDuplicateConfirm = false;
                $scope.change_category = '';
                $scope.selected_category = false;
                $scope.motive = '';
                $scope.set_motive = false;
                $scope.maximeter = '';
                $scope.set_maximeter = false;
            };

            $scope.clickDuplicateYes = function () {
                performRequest();
                $scope.change_category = '';
                $scope.selected_category = false;
                $scope.motive = '';
                $scope.set_motive = false;
                $scope.maximeter = '';
                $scope.set_maximeter = false;
            };

            $scope.confirmDuplicateLink = function () {
                $scope.flagDuplicateConfirm = true;
            };

            /***********************************************************************************************************
            * Controller Initialization
            ***********************************************************************************************************/

        }
    }
}

export { duplicateIssue }
