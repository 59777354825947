const ComplaintDetailCtrl = ['$scope', '$filter', '$routeParams',
    function ($scope, $filter, $routeParams) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        $scope.COMPLAINTS_DETAIL_TAB = require('../../templates/complaint-detail-result.html')
        $scope.COMPLAINTS_LOGS_TAB = require('../../templates/complaint-detail-logs.html')

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.id = $routeParams.id;

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/

    }
]

export { ComplaintDetailCtrl }
