const ProductServiceCodesLoading = ['$resource',
    function ($resource) {
        return $resource(API_URL + '/api/1.0/masters/product-services-codes/');
    }
]

const ProductServiceCodeMaster = ['$q', 'ProductServiceCodesLoading',
    function ($q, ProductServiceCodesLoading) {

        var products = {
            items: {
                product_force: null,
                product_not_force: null
            },

            loadProductServiceCodesForce: function () {
                var self = this;
                var deferred = $q.defer();

                if (this.items.product_force == null)
                    ProductServiceCodesLoading.query({visibility: "VISIBLE", not_in_force: 1},function (data) {
                        self.items.product_force = data;
                        deferred.resolve(data);
                    });
                else
                    deferred.resolve(self.items.product_force);

                return deferred.promise;
            },

            loadProductServicesCodesNotForce: function () {
                var self = this;
                var deferred = $q.defer();

                if (this.items.product_not_force == null)
                    ProductServiceCodesLoading.query({visibility: "VISIBLE", not_in_force: 0},function (data) {
                        self.items.product_not_force = data;
                        deferred.resolve(data);
                    });
                else
                    deferred.resolve(self.items.product_not_force);

                return deferred.promise;
            }

        };

        return products;
    }
]

export { ProductServiceCodeMaster, ProductServiceCodesLoading }
