

// Module Config Provider
function listViewConfigProvider() {
    this.PAGE_PARAM = 'page';
    this.ORDER_PARAM = 'ordering';
    this.$get = function () {
        var self = this;
        return {
            PAGE_PARAM: self.PAGE_PARAM,
            ORDER_PARAM: self.ORDER_PARAM
        }
    };
}

function listView() {
    return {
        scope: {
            results: '=ngModel',
            listReload: '=?',
            current: '@url',
            filters: '=?',
            ordering: '=',
            selectable: '=',
            selectedItems: '=?',
            selectedAll: '=?',
            primaryKey: '=?',
            getStyles: '=?',
            defaultAscOrder: '=?'
        },
        restrict: 'E',
        transclude: true,
        //templateUrl: require('../../static/templates/list.html'),
        templateUrl: require('../../templates/list.html'),
        controller: function ($q, $scope, $http, listViewConfig, DateUtils, $rootScope) {
            var self = this;

            $scope.results = [];
            $scope.query_params = {};
            $scope.perPage = null;
            $scope.page = 1;
            $scope.count = 0;
            $scope.next = null;
            $scope.previous = null;
            $scope.loading = false;
            $scope.order_asc = $scope.defaultAscOrder !== undefined ? $scope.defaultAscOrder : true;

            var pk = $scope.primaryKey !== undefined ? $scope.primaryKey : 'id';

            if ($scope.ordering) {
                $scope.order_field = Object.keys($scope.ordering)[0];
                $scope.order = ($scope.order_asc ? '' : '-') + $scope.order_field;
            } else {
                $scope.order = null;
                $scope.order_field = null;
            }

            $scope.nextPage = function () {
                if ($scope.next) {
                    $scope.current = $scope.next;
                    $scope.page++;
                    if ($scope.check_elements == 2) {
                        $scope.check_elements = 1;
                    }
                    if ($scope.check_elements == 3) {
                        $scope.check_elements = 0;
                    }
                    self.loadData();
                }
            };

            $scope.previousPage = function () {
                if ($scope.previous) {
                    $scope.current = $scope.previous;
                    $scope.page--;
                    if ($scope.check_elements == 2) {
                        $scope.check_elements = 1;
                    }
                    if ($scope.check_elements == 3) {
                        $scope.check_elements = 0;
                    }
                    self.loadData();
                }
            };

            $scope.changeOrder = function () {
                if ($scope.order.substr(0, 1) == '-') {
                    $scope.order = $scope.order.substr(1, $scope.order.length);
                    $scope.order_asc = true;
                } else {
                    $scope.order = "-" + $scope.order;
                    $scope.order_asc = false;
                }
            };

            $scope.listReload = function () {
                self.loadData();
            };

            $scope.$watch('query_params', function (newVal, oldVal) {
                if (newVal != oldVal) {
                    $scope.current = self.buildURL($scope.current, $scope.query_params);
                    self.loadData();
                }
            }, true);

            $scope.$watch('filters', function (newVal, oldVal) {
                if (newVal) {
                    $scope.query_params = $scope.filters;
                    $scope.page = 1; // if filters change, then page is the first
                }
                if ($scope.check_elements == 3) {
                    $scope.check_elements = 0;
                    $scope.dictItems = {};
                }

            }, true);

            $scope.setOrderField = function (key) {
                $scope.order_field = key;
                if ($scope.order_asc) {
                    $scope.order = key;
                } else {
                    $scope.order = "-" + key;
                }
            };

            $scope.$watch('order', function (newVal, oldVal) {
                if (newVal) {
                    $scope.query_params[listViewConfig.ORDER_PARAM] = newVal;
                }
            });

            $scope.$on('refreshInterface',function(){
                $scope.listReload();
            });

            this.buildURL = function (url, params) {
                if (!params) return url;

                var baseUrl = url;
                var currentUrlParts = [];
                if (url.indexOf('?') > -1) {
                    baseUrl = url.split('?')[0];
                    currentUrlParts = url.split('?')[1].split('&');
                }

                var urlParams = {};
                for (var i = 0; i < currentUrlParts.length; i++) {
                    var keyValue = currentUrlParts[i].split("=");
                    urlParams[keyValue[0]] = currentUrlParts[keyValue[1]];
                }

                angular.forEach(params, function (value, key) {
                    if (value instanceof Date) {
                        this[key] = DateUtils.transformToRESTyDate(value);
                    } else {
                        this[key] = value;
                    }

                }, urlParams);

                var parts = [];
                angular.forEach(urlParams, function (value, key) {
                    if (key != "" && value && key && key != listViewConfig.PAGE_PARAM)
                        parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
                }, parts);

                return baseUrl + '?' + parts.join('&');
            };

            this.httpCanceller = $q.defer();
            this.loadData = function () {
                if ($scope.current === null) return;
                $scope.loading = true;
                this.httpCanceller.resolve();
                this.httpCanceller = $q.defer();
                this.xhr = $http.get($scope.current, {timeout: this.httpCanceller}).then(function (data, status, headers, config) {
                    $scope.count = data.data.count;
                    $scope.next = data.data.next;
                    $scope.previous = data.data.previous;
                    if ($scope.perPage == null) {
                        $scope.perPage = data.data.results.length;
                    }
                    $scope.results = [];

                    angular.forEach(data.data.results, function (item) {
                        if (item[pk] in $scope.dictItems) {
                            item.isChecked = true;
                        }
                        this.push(item);
                    }, $scope.results);

                    $scope.results.pageItemsSelected = false;
                    $scope.loading = false;
                }, function (data, status, headers, config) {
                    $scope.loading = false;
                });
            };

            if (!$scope.order) this.loadData();

            $scope.dictItems = {};
            $scope.check_contract = true;
            $scope.check_elements = 0;
            $scope.select_counter = 0;

            $scope.checkClick = function () {
                if ($scope.check_elements == 3) {
                    $scope.check_elements = 0;
                }
                else {
                    $scope.check_elements++;
                }

                // Set number
                if ($scope.check_elements == 3) {
                    $scope.select_counter = $scope.count;
                }
                $rootScope.$broadcast('selectionType', $scope.check_elements);
            };

            // Delete item from array
            Array.prototype.remove = function (val) {
                var index = this.indexOf(val);
                if (index >= 0) this.splice(index, 1);
                return this;
            };

            $scope.selectItem = function (contract) {
                for (var i = 0; i < $scope.results.length; i++) {
                    if ($scope.results[i].isChecked == undefined) {
                        $scope.results[i].isChecked = false;
                    }
                }

                $scope.checkItem(contract);
                // If any entity is not checked, then uncheck the "pageItemsSelected" checkbox
                for (var i = 0; i < $scope.results.length; i++) {
                    if (!$scope.results[i].isChecked) {
                        $scope.results.pageItemsSelected = false;
                        return;
                    }
                }

                // ... otherwise check the "pageItemsSelected" checkbox
                $scope.results.pageItemsSelected = true;
            };

            $scope.checkItem = function (result) {

                if ($scope.selectedAll == true && result.isChecked == false) {
                    $scope.select_counter = 0;
                    $scope.check_elements = 0;
                    $scope.dictItems = {};
                }

                if (result.isChecked == false) {
                    $scope.select_counter--;
                    delete $scope.dictItems[result[pk]];
                    if (Object.keys($scope.dictItems).length == 0)
                        $scope.check_elements = 0;

                    if (Object.keys($scope.dictItems).length == $scope.results.length) {
                        $scope.check_elements = 2;
                    }
                    else {
                        if ($scope.check_elements == 2) {
                            $scope.check_elements = 1;
                        }
                    }
                }
                else {
                    $scope.dictItems[result[pk]] = result;
                    $scope.select_counter++;
                    if ((Object.keys($scope.dictItems).length)%($scope.results.length) == 0) {
                        $scope.check_elements = 2;
                        $scope.results.pageItemsSelected = true;
                    }
                    else {
                        $scope.check_elements = 1;
                        $scope.results.pageItemsSelected = false;
                    }
                }
            };

            $scope.selectItemFromDiv = function(item) {
                item.isChecked = item.isChecked ? false : true;
                if($scope.selectable){
                    $scope.selectItem(item);
                }
            };

            $scope.$watch("dictItems", function () {
                //self.checkComplete();
                var values = Object.keys($scope.dictItems).map(function (key) {
                    return $scope.dictItems[key];
                });
                $scope.selectedItems = values;
                $rootScope.$broadcast('selectedItemsChange', values);
            }, true);

            $scope.$watch("check_elements", function () {
                self.checkComplete();
            }, true);

            this.checkComplete = function () {
                if ($scope.results != undefined) {
                    if ($scope.check_elements == 0) {
                        $scope.selectedAll = false;
                        $scope.dictItems = {};
                        $scope.select_counter = 0;
                        for (var i = 0; i < $scope.results.length; i++) {
                            $scope.results[i].isChecked = false;
                        }
                        $scope.results.pageItemsSelected = false;
                    }
                    if ($scope.check_elements == 1) {
                        $scope.selectedAll = false;
                        if (Object.keys($scope.dictItems).length == 0) {
                            $scope.check_elements++;
                        }
                        $scope.results.pageItemsSelected = false;
                    }
                    if ($scope.check_elements == 2) {
                        $scope.selectedAll = false;
                        $scope.select_counter = 0;
                        for (var i = 0; i < $scope.results.length; i++) {
                            $scope.results[i].isChecked = true;
                            $scope.checkItem($scope.results[i]);
                        }
                        $scope.results.pageItemsSelected = true;
                    }
                    if ($scope.check_elements == 3) {
                        $scope.results.pageItemsSelected = false;
                        $scope.selectedAll = true;
                    }
                }
            }

        },
        link: function(scope, element, attr) {
            // Refresh when data is changed
            scope.$on('refreshList', function (e, data) {
                scope.dictItems = {};
                scope.select_counter = 0;
                scope.check_elements = 0;
                scope.listReload();
            });
        }
    };
}


function inject() {
    return {
        link: function ($scope, $element, $attrs, controller, $transclude) {
            // TODO: Revisar este manejo de errores
            if (!$transclude) {
                throw minErr('ngTransclude')('orphan', // eslint-disable-line no-undef
                    'Illegal use of ngTransclude directive in the template! ' +
                    'No parent directive that requires a transclusion found. ' +
                    'Element: {0}',
                    startingTag($element));  // eslint-disable-line no-undef
            }
            var innerScope = $scope.$new();
            $transclude(innerScope, function (clone) {
                $element.empty();
                $element.append(clone);
                $element.on('$destroy', function () {
                    innerScope.$destroy();
                });
            });


        }
    };
}

export {inject, listView, listViewConfigProvider}
