function invoiceSetRemitDate(InvoiceSeries, UserInfo, filtersUtils, DateUtils) {
    return {
        restrict: 'AE',
        scope: {},
        templateUrl: require('../../templates/update-remit-date.html'),
        controller: function ($scope, $http, $rootScope, Alert, $filter) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/invoices/';
            var $translate = $filter('translate');

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.flagRemitDate = false;
            $scope.listToUpdate = [];
            $scope.remit_date = new Date();

            $scope.hasPerm = UserInfo.hasPerm('banks.can_remit');

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/
            $scope.updateRemitDate = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToUpdate);
                var json = {ids: ids, remit_date: DateUtils.transformToRESTyDate($scope.remit_date)};

                var config = {
                    method: "PUT",
                    url: api,
                    data: json,
                    headers: {"Content-Type": "application/json;charset=utf-8"}
                };
                $http(config).then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.CHANGE_REMIT_DATE_OK') + $translate('INVOICES.TO_DEFINITIVE_NUMBER') + data.data.updated_number, true);
                    $rootScope.$broadcast('refreshList');
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', data, true);
                })
                /*
                .finally(function () {
                    $scope.flagRemitDate = false;
                    $scope.listToUpdate = [];
                });
                */
            };

            $scope.confirmRemitDate = function () {
                $scope.flagRemitDate = true;
            };

            $scope.updateNo = function () {
                $scope.flagRemitDate = false;
            };
        }
    }
}

export { invoiceSetRemitDate }
