{
  "COMMENTS": {
    "TITLE": "Comentarios",
    "NO_COMMENTS": "No hay comentarios",
    "CONFIRM_DELETE": "¿Quieres eliminarlo?",
    "COMMENT": "Comentar",
    "WRITE_COMMENT": "Escribe el comentario...",
    "NO": "No",
    "YES": "Si",
    "EDIT": "Editar"
  }
}