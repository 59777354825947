function issuesStatus($filter, UserInfo, Alert, filtersUtils, eventInfoService, IssueAPI){
    return {
        restrict: 'AE',
        scope: {},
        templateUrl: require('../../templates/change-issue-status.html'),
        controller: function ($scope, $http, $rootScope) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var self = this;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.listToUpdate = [];
            $scope.issue_date = new Date;

            $scope.hasPerm = UserInfo.hasPerm('issues.change_issue_status');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            $scope.$watchGroup(['change_event_type', 'change_event_category', 'change_event_status', 'issue_date'],
                function (newValues, oldValues, scope) {
                    if  (newValues[0] && !newValues[1] && !newValues[2]) {
                        $scope.selected_type = true;
                        $scope.reset = true;
                        eventInfoService.getEventCategory(newValues[0])
                            .then(function (categories) {
                                $scope.event_categories  = categories;
                            });
                    }
                    else if (newValues[0] && newValues[1] && !newValues[2]){
                        $scope.selected_category = true;
                        $scope.reset = true;
                        eventInfoService.getEventStatus(newValues[0], newValues[1])
                            .then(function (issue) {
                                $scope.status_types  = issue;
                            });
                        }
                    else if (newValues[0] && newValues[1] && newValues[2]){
                        $scope.selected_status = true;
                        $scope.reset = false;
                    }
                }
            );

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var date = $scope.issue_date;
                date = date.toISOString().slice(0,10);
                var json = {
                    ids: ids,
                    type: $scope.change_event_type,
                    category: $scope.change_event_category,
                    status: $scope.change_event_status,
                    date: date
                };

                IssueAPI.bulkChangeIssuesStatus(json)
                    .then(function (data) {
                        Alert.createAlert('success', $translate('ISSUES.ISSUES_STATUS_UPDATED') + data.updated_status, true);
                        $rootScope.$broadcast('refreshList');
                    }).catch(function (error) {
                        Alert.createAlert('danger', $translate('ISSUES.ERROR_REQUEST'), true);
                    }).
                    finally(function () {
                        $scope.listToUpdate = [];
                        $scope.resetStatusForm();
                });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.resetStatusForm = function () {
                $scope.flagConfirm = false;
                $scope.change_event_type = '';
                $scope.change_event_category = '';
                $scope.change_event_status = '';
                $scope.selected_type = false;
                $scope.selected_category = false;
                $scope.selected_status = false;
                $scope.reset = false;
            };

            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.resetStatusForm();
            };

            $scope.clickYes = function () {
                var ids = filtersUtils.getItemsToDownload($scope.listToUpdate);
                performRequest(ids);
            };

            /***********************************************************************************************************
            * Controller Initialization
            ***********************************************************************************************************/
            $scope.resetStatusForm();

            eventInfoService.getEventType().then(function (types) {
                $scope.event_types  = types;
            });

        }
    }
}

export { issuesStatus }
