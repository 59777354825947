// const contractsProductRate = function
function contractsProductRate(UserInfo, SUPPLY_RATES, filtersUtils, Alert, $filter, ProductRatesMaster, DateUtils) {
    return {
        restrict: 'AE',
        scope: {},
        //templateUrl: require('../../static/contracts/templates/change-product-rate.html'),
        templateUrl: require('../../templates/change-product-rate.html'),
        controller: function ($scope, $http, $rootScope) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/tasks/';
            var $translate = $filter('translate');
            var self = this;
            var first_access = true;
            var type = 'CCPR';

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.listToUpdate = [];
            $scope.change_product_rate = '';
            $scope.product_rate_date = new Date;
            $scope.reason = '';

            $scope.hasPerm = UserInfo.hasPerm('contracts.change_product_rate');

            $scope.selected_product_rate = false;

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            $scope.$watch('productRate.$valid', function (newValue, oldValue) {
                $scope.$emit("setValidity", newValue);
                if (first_access == true)
                    first_access = false;
                else {
                    if (newValue)
                        $scope.selected_product_rate = true;
                }
            }, true);

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/
            function performRequest(ids) {
                var date = DateUtils.transformToRESTyDate($scope.product_rate_date);
                var json = {
                    type: type,
                    params: {
                        ids: ids,
                        product_rate: $scope.change_product_rate,
                        date: date,
                        reason: $scope.reason
                    }
                };
                var config = {
                    method: "POST",
                    url: api,
                    data: json,
                    headers: {"Content-Type": "application/json;charset=utf-8"}
                };
                $http(config).then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('CONTRACTS.BULK_UPDATE_QUEUED'), true);
                    $rootScope.$broadcast('refreshList');
                    $('#changeProductrateModal').modal('toggle');
                }).catch(function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.ERROR_REQUEST'), true);
                }).finally(function () {
                    $scope.flagConfirm = false;
                    $scope.listToUpdate = [];
                });
            }

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/
            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
                $scope.change_product_rate = '';
                $scope.selected_product_rate = false;
                $scope.reason = '';
            };

            $scope.clickYes = function () {
                var ids = filtersUtils.getItemsToDownload($scope.listToUpdate);
                performRequest(ids);
                $scope.change_product_rate = '';
                $scope.selected_product_rate = false;
                $scope.reason = '';
            };

            /***********************************************************************************************************
             * Controller Initialization
             ***********************************************************************************************************/
            ProductRatesMaster.loadProductRatesNotForce().then(function (response) {
                $scope.product_rates = response;
            });

            ProductRatesMaster.loadProductRatesForce().then(function (response) {
                $scope.product_rates_not_in_force = response;
            });

        }
    }
}

export {contractsProductRate}
