const ComplaintATRCtrl = ['$scope', '$filter', 'ComplaintsMaster', 'atrComplaintService', 'UserInfo', 'ATR_TYPES', 'Alert',
    function ($scope, $filter, ComplaintsMaster, atrComplaintService, UserInfo, ATR_TYPES, Alert) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.complaint_atr = {};
        $scope.complaint_types = ATR_TYPES;

        $scope.selected_type = false;
        $scope.selected_subtype= false;

        $scope.order_item = "order";
        $scope.order_reverse = false;

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/

        $scope.$watchGroup(['complaint_atr.type', 'complaint_atr.subtype'],
            function (newValue, oldValue) {
                if (!newValue[0]){
                        $scope.complaint_atr.subtype = null;
                        $scope.selected_type= false;
                        $scope.selected_subtype= false;
                }
                else{
                    $scope.selected_type = true;
                    $scope.metadata = null;
                    $scope.metadata_length = 0;

                    atrComplaintService.getSubtypesComplaints(newValue[0]).then(function (response){
                        $scope.subtypes_complaints = response;
                    });

                    if (newValue[1]){
                        $scope.metadata = search(newValue[1], $scope.subtypes_complaints);
                        $scope.metadata_length = Object.keys($scope.metadata).length;
                        $scope.selected_subtype= true;
                    }
                    else{
                        $scope.selected_subtype= false;
                    }
                }
            }, true);


        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        function search(subtype, myArray){
            for (var i=0; i < myArray.length; i++) {
                if (myArray[i].subtype === subtype) {
                    return myArray[i].metadata;
                }
            }
        }

        function resetForm() {
            $scope.complaint_atr = {};
            $scope.selected_type = false;
            $scope.selected_subtype= false;
            $scope.metadata = null;
            $scope.metadata_length = 0;
            $scope.complaintForm.$setPristine();
            $scope.complaintForm.$setUntouched();
        }

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.createATRComplaint = function () {
            // Built metadata to sent only with value
            var metadata = {};
            for (var el in $scope.metadata){
                metadata[el] = $scope.metadata[el].value;
            }

            $scope.complaint_atr.metadata = metadata;
            
            atrComplaintService.sendATRComplaint($scope.complaint_atr)
                .then(function (response) {
                    Alert.createAlert('success', $translate('COMPLAINTS.EVENT_COMPLAINT_CREATED_SUCCESSFULLY'), true);
                    resetForm();
                }).catch(function (error) {
                    Alert.createAlert('danger', $translate('COMPLAINTS.ERROR_WHEN_CREATE_COMPLAINT_EVENT'), true);
                });
        };

        $scope.canCreateATRComplaint = function () {
            return UserInfo.hasPerm('masters.add_atrcomplaint');
        };


        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        ComplaintsMaster.loadComplaints().then(function (response){
            $scope.complaints = response;
        });

    }
]

export { ComplaintATRCtrl }
