function lowerThan() {
    return {
        require: 'ngModel',
        scope: {
            comparisonModel: "=lowerThan"
        },
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.lowerThan = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }

                if (parseInt(viewValue, 10) <= parseInt(scope.comparisonModel, 10)) {
                    return true;
                }

                return false;
            };

            scope.$watch("comparisonModel", function () {
                ctrl.$validate();
            });
        }
    };
}

export {lowerThan}
