// const contractsTypeInvoices = function
function contractsTypeInvoices(UserInfo, CONTRACT_MODE_MASS_UPDATE, Task, filtersUtils, Alert, $filter) {
    return {
        restrict: 'AE',
        scope: {},
        //templateUrl: require('../../static/contracts/templates/change-mode-invoices.html'),
        templateUrl: require('../../templates/change-mode-invoices.html'),
        controller: function ($scope, $http, $rootScope) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/tasks/';
            var $translate = $filter('translate');
            var self = this;
            var type = 'BUCD';

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.listToUpdate = [];
            $scope.change_mode = '';
            $scope.reason = '';
            $scope.update = {};
            $scope.hasPerm = UserInfo.hasPerm('contracts.change_contract');
            $scope.modes = CONTRACT_MODE_MASS_UPDATE;

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/
            function performRequest(ids) {
                var data = {};
                data['values'] = $scope.update;
                $scope.update.update_type = 'change_invoices_type';
                data['ids'] = ids;

                var task = new Task();
                task.type = type;
                task.params = data;
                task.start().then(function(data, status, headers, config) {
                    Alert.createAlert('success', $translate('CONTRACTS.BULK_UPDATE_QUEUED'), true);
                    delete $scope.update;
                    //$scope.update.change_mode = '';
                    //$scope.update.reason = '';
                }).catch(function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.BULK_UPDATE_REJECTED'), true);
                });
            }

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/
            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
                $('#changeTypeInvoceContractModal').modal('toggle');
            };

            $scope.clickYes = function () {
                var ids = filtersUtils.getItemsToDownload($scope.listToUpdate);
                performRequest(ids);
                $('#changeTypeInvoceContractModal').modal('toggle');
            };

        }
    }
}

export {contractsTypeInvoices}
