function commentsList($location, commentsConfig, UserInfo, $sanitize) {
    return {
        restrict: 'E',
        scope: {
            model: '@commentItem'
        },
        //templateUrl: commentsConfig.TEMPLATES_PREFIX + commentsConfig.UPLOADER_TEMPLATE,
        templateUrl: require('../../templates/comments.html'),
        controller: function ($scope, $http) {
            var self = this;
            var api = commentsConfig.API_PREFIX + $location.path() + commentsConfig.API_SUFFIX;
            $scope.Math = window.Math;
            //$scope.editMode = false;

            $scope.perPage = null;
            $scope.page = 1;
            $scope.count = 0;
            $scope.next = null;
            $scope.previous = null;
            $scope.loading = false;
            $scope.current = api;

            $scope.nextPage = function () {
                if ($scope.next) {
                    $scope.current = $scope.next;
                    $scope.page++;
                }
            };

            $scope.previousPage = function () {
                if ($scope.previous) {
                    $scope.current = $scope.previous;
                    $scope.page--;
                }
            };

            $scope.listReload = function () {
                self.loadData();
            };

            $scope.$watch('current', function (newVal, oldVal) {
                if (newVal) {
                    self.loadData();
                }
            });

            this.loadData = function () {
                $http.get($scope.current).then(function (data, status, headers, config) {
                    $scope.count = data.data.count;
                    $scope.next = data.data.next;
                    $scope.previous = data.data.previous;
                    $scope.perPage = data.data.results.length;


                    $scope.comments = [];

                    angular.forEach(data.data.results, function (item) {
                        item.editMode = false;
                        item.deleteMode = false;
                        item.newText = item.text;
                        this.push(item);
                    }, $scope.comments);

                }, function (data, status, headers, config) {
                });
            };

            $scope.createComment = function () {
                $http.post(api, {text: $scope.commentText}).
                    then(function (data, status, headers, config) {
                        self.loadData();
                        $scope.commentText = '';
                    }, function (data, status, headers, config) {
                        $scope.commentText = '';
                    });
            };

            $scope.deleteComment = function (comment) {
                comment.deleteMode ? comment.deleteMode = false : comment.deleteMode = true;
            };

            $scope.confirmDeleteComment = function (comment) {
                $http.delete(commentsConfig.DELETE_API + comment.id).
                    then(function (data, status, headers, config) {
                        self.loadData();
                    }, function (data, status, headers, config) {
                    });
            };

            $scope.editComment = function (comment) {
                comment.newText = comment.text;
                comment.editMode ? comment.editMode = false : comment.editMode = true;
            };

            $scope.updateComment = function (comment) {
                $http.patch(commentsConfig.DELETE_API + comment.id, {text: comment.newText}).
                    then(function (data, status, headers, config) {
                        self.loadData();
                        comment.editMode = false;
                    }, function (data, status, headers, config) {
                    });
            };

            $scope.canDelete = function (comment) {
                if (UserInfo.hasPerm('comments.delete_comments_permission'))
                    return true;
                else if (UserInfo.hasPerm('comments.delete_comments_owned') && (UserInfo.userInfo.username == comment.user.username))
                    return true;
                else
                    return false;
            };

            $scope.canEdit = function (comment) {
                if (UserInfo.hasPerm('comments.edit_comments_permission'))
                    return true;
                else if (UserInfo.hasPerm('comments.edit_comments_owned') && (UserInfo.userInfo.username == comment.user.username))
                    return true;
                else
                    return false;
            };
        }
    }
}

export { commentsList };
