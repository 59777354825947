
function deleteIssues($filter, UserInfo, filtersUtils, Alert, IssueAPI) {
    return {
        restrict: 'AE',
        scope: {},
        templateUrl: require('../../templates/delete-button.html'),
        controller: function ($scope, $http, $rootScope) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var self = this;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.hasPerm = UserInfo.hasPerm('issues.destroy_issue');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToDelete = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var json = {ids: ids};

                IssueAPI.deleteIssues(json)
                    .then(function (issue) {
                        Alert.createAlert('success', $translate('ISSUES.DELETED') + issue, true);
                        $rootScope.$broadcast('refreshList');
                    }).catch(function (error) {
                        $scope.error_message = error.data.__all__[0];
                    }).
                    finally(function () {
                        $scope.resetForm();
                });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.resetForm = function () {
                $scope.flagConfirm = false;
                $scope.listToDelete = [];
            };

            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNoDelete = function () {
                $scope.flagConfirm = false;
            };

            $scope.clickYesDelete = function () {
                var ids = filtersUtils.getItemsToDownload($scope.listToDelete);
                performRequest(ids);
            };

            /***********************************************************************************************************
            * Controller Initialization
            ***********************************************************************************************************/
            $scope.resetForm();

        }
    }
}

export { deleteIssues }
