const InvoiceDeferredAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/invoices/get_total_for_deferred/';
        var self = this;

        function getTotalForDeferred(ids) {
            var deferred = $q.defer();
            var ids_list = '';

            for (var i=0; i < ids.length; i++){
                if (i === ids.length - 1 || ids.length === 1){
                    ids_list += ids[i]
                } else{
                    ids_list += ids[i] + ',';
                }
            }
            
            var data = {
                ids: ids_list
            };
            $http({
                url: url,
                method: "GET",
                params: data,
                headers: {"Content-Type": "application/json;charset=utf-8"}
            })
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        return {
            getTotalForDeferred: getTotalForDeferred
        };
    }
]

export { InvoiceDeferredAPI };
