{
  "PROGRAMMING_UNITS": {
    "TITLE": "Programming Units",
    "SEARCH_BY_PROGRAMMING_UNIT": "Search by name",
    "ID": "Id",
    "NAME": "Name",
    "NEW_PROGRAMMING_UNIT": "New Programming Unit",
    "VIEW": "View",
    "DELETE": "Delete",
    "YES": "Yes",
    "NO": "No",
    "DELETE_PROGRAMMING_UNITS": "Delete programming unit?",
    "PROGRAMMING_UNIT_DELETED": "Programming Unit deleted.",
    "DESCRIPTION": "Description",
    "ELECTRICAL_SUBSYSTEM": "Electrical subsystem",
    "SAVE": "Save",
    "CANCEL": "Cancel",
    "PROGRAMMING_UNIT_CREATED_SUCCESSFULLY": "Programming Unit save successfully.",
    "PROGRAMMING_UNIT": "Programming Unit",
    "EDIT": "Edit",
    "PROGRAMMING_UNIT_UPDATE_SUCCESSFULLY": "Programming Unit updated successfully.",
    "SUPPLIES": "Linked Supplies",
    "UNLINK": "Unlink",
    "PROGRAMMING_UNLINK_SUCCESSFULLY": "Programming unit Cups unlink successfully.",
    "SET_PROGRAMMING_UNIT_SUCCESSFULLY": "No. programming units linked: ",
    "LINK_PROGRAMMING_UNITS": "Select Programming Units",
    "CHANGE_QUESTION_LINK": "Change Programming Units?",
    "CHANGE_QUESTION_UNLINK": "Unlink Programming Units?"
  }
}