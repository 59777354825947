
function invoiceSetDefinitive(InvoiceSeries, UserInfo, filtersUtils, InvoiceSeriesAPI) {
    return {
        restrict: 'AE',
        types: '?=',
        templateUrl: require('../../templates/definitive-button.html'),
        controller: function ($scope, $http, $rootScope, Alert, $filter, Task) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagDefinitive = false;
            $scope.listToUpdate = [];

            $scope.hasPerm = UserInfo.hasPerm('invoices.set_invoice_definitive');

            // Get series list
            $scope.loadInvoiceSeries = function(ids){
                let data = {
                    "ids": ids
                }

                InvoiceSeriesAPI.postListInvoiceSeries(data).then(function (data) {
                    $scope.series = data.results;
                });
            }

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = filtersUtils.getItemsToDownloadToInt(selectedItemsChange);
                $scope.loadInvoiceSeries($scope.listToUpdate)
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/

            function performRequest(ids) {
                //Alert.createAlert('info', $translate('INVOICES.SENDING_DEFINITIVE'), true);
                var params = {ids: ids, "series_id": $scope.series_id};

                var task = new Task();
                task.type = 'ISIN';
                task.params = params;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.Bulk set to invoiced Task success'), true);
                    $scope.backgroundTasks.push(data.data.id);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('INVOICES.Bulk set to invoiced Task error'), true);
                })
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.confirmUpdate = function () {
                $scope.flagDefinitive = true;
            };

            $scope.clickNo = function () {
                $scope.flagDefinitive = false;
            };

            $scope.clickYes = function () {
                performRequest($scope.listToUpdate);
            };
        }
    }
}

export { invoiceSetDefinitive }
