
// TODO eliminar IberdrolaParser y EonParser

function SipsParser(IberdrolaParser, EonParser, CNMCParser, ConsumptionParser) {
    /**
     * Asks all parser if they understand the data an returns the data parsed by the proper parser
     * @param data
     * @returns object
     */
    this.parse = function(data) {
        //var parsers = [IberdrolaParser, EonParser];
        var parsers = [CNMCParser, ConsumptionParser];
        for (var i in parsers) {
            var parser = parsers[i];
            if (parser.isValid(data)) {
                return parser.parse(data);
            }
        }
        return null;
    };
}

export {SipsParser};