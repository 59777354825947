const ProductPriceAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/product-prices/';

        var self = this;

        function createProductPrice(data){
            var deferred = $q.defer();

            $http.post(url, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function updateProductPrice(id, product_price) {
            var deferred = $q.defer();

            $http.put(url + id, product_price)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function deleteProductPrice(id) {
            var deferred = $q.defer();

            $http.delete(url + id)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function deleteProductPriceByProductAndDate(data) {
            var deferred = $q.defer();

            var sent = {
                data: data,
                headers: {"Content-Type": "application/json;charset=utf-8"}
            };

            $http.delete(url, sent)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function getProductPricesByProductRate(rate_code) {
            var deferred = $q.defer();

            var url_filter = '?product_rate=' + encodeURIComponent(rate_code) + '&group_by_date=1&ordering=-start_date';

            $http.get(url + url_filter)
                .then(function (product_prices) {
                    deferred.resolve(product_prices.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function getProductPricesByProductRateGroupByDate(rate_code, date) {
            var deferred = $q.defer();

            var url_filter = '?product_rate=' + encodeURIComponent(rate_code) + '&date_active=' + date;

            $http.get(url + url_filter)
                .then(function (product_prices) {
                    deferred.resolve(product_prices.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            createProductPrice: createProductPrice,
            updateProductPrice: updateProductPrice,
            deleteProductPrice: deleteProductPrice,
            deleteProductPriceByProductAndDate: deleteProductPriceByProductAndDate,
            getProductPricesByProductRate: getProductPricesByProductRate,
            getProductPricesByProductRateGroupByDate: getProductPricesByProductRateGroupByDate
        };

    }

]

export { ProductPriceAPI }
