const ReportsCtrl = ['$scope', 'Alert', '$filter', 'Report', 'Task',
    function ($scope, Alert, $filter, Report, Task) {

        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/

        var $translate = $filter('translate');


        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/

        $scope.section_title = $translate('REPORTS.NO_REPORT_SELECTED');
        $scope.searchReport= "";

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/

        $scope.selectReporter = function (id) {
            $scope.requestObj = {};
            Report.get(id).then(function (report) {
                for (var key in report.user_interface) {
                    if (report.user_interface.hasOwnProperty(key)) {
                        report.user_interface[key]['name'] = key;
                    }
                }

                $scope.selected_report = report;
                $scope.section_title = report.name;
            });
        };

        $scope.performRequest = function () {
            var request_params = {'slug': $scope.selected_report.slug, 'values': {}};
            angular.forEach($scope.selected_report.user_interface, function (value, index) {
                var val = value.value;
                if (val != undefined) {
                    request_params['values'][index] = val;
                }
            });
            
            var task = new Task();
            task.type = 'GREP';
            for (var i in request_params.values) {
                if(request_params.values[i] === ''){
                    delete request_params.values[i];
                }
            }
            task.params = request_params;

            task.start()
                .then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('REPORTS.GENERATING_REPORT'), true);
                })
                .catch(function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('REPORTS.ERROR_WHEN_GENERATING'), true);
                });

        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/

        Report.getReports().then(function (reports) {
            $scope.reports = reports;
        });

    }
]

export { ReportsCtrl }
