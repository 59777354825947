{
  "TAXES": {
    "TAXES": "Impuestos",
    "TAX": "Impuesto",
    "CODE": "Código",
    "NAME": "Nombre",
    "DEFAULT": "Por defecto (%)",
    "NEX_TAX": "Nuevo impuesto",
    "TAX_CREATED": "Impuesto creado correctamente.",
    "TAX_CREATION_ERROR": "Error al crear impuesto.",
    "DELETE_TAX": "¿Eliminar impuesto?",
    "DELETE_TAX_OK": "Impuesto eliminado correctamente.",
    "DELETE_TAX_ERROR": "Error al eliminar impuesto.",
    "UPDATE": "Actualizar",
    "RULES": "Reglas",
    "STATE": "Provincia",
    "PERCENT": "Porcentaje",
    "EDIT": "Editar",
    "DELETE": "Eliminar",
    "CREATE": "Crear",
    "TAXRULE_DELETED": "Regla eliminada correctamente",
    "TAXRULE_EDITED": "Regla editada",
    "ANY": "Cualquiera",
    "TAX_UPDATED": "Se ha actualizado el impuesto correctamente",
    "TAXRULE_CREATED": "Regla creada correctamente",
    "STATES": "Provincia",
    "PERCENT_TAX": "Porcentaje del impuesto"
  }
}