const TaxAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/taxes/';
        var url_rules = '/rules/';

        var self = this;
        var tax = null;

        function getAllTaxes() {
            var deferred = $q.defer();

            $http.get(url)
                .then(function (taxes) {
                    deferred.resolve(taxes.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function getTaxDetail(id) {
            return _getTaxDetail(id);
        }

        function _getTaxDetail(id) {
            var deferred = $q.defer();

            $http.get(url + id)
                .then(function (tax) {
                    deferred.resolve(tax.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            self.id = id;
            return deferred.promise;
        }

        function createTax(data){
            return _createTax( data);
        }

        function _createTax(data){
            var deferred = $q.defer();

            $http.post(url, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function updateTax(id, tax) {
            var deferred = $q.defer();

            $http.put(url + id, tax)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function deleteTax(id) {
            var deferred = $q.defer();

            $http.delete(url + id)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            getAllTaxes: getAllTaxes,
            getTaxDetail: getTaxDetail,
            createTax: createTax,
            updateTax: updateTax,
            deleteTax: deleteTax
        };

    }

]

export { TaxAPI }
