const ImportCtrl = ['$scope', '$http', 'notificationCenter', 'Task', '$filter', '$rootScope', 'Alert',
    function($scope, $http, notificationCenter, Task, $filter, $rootScope, Alert) {
        var $translate = $filter('translate');

        $scope.backgroundTasks = [];

        // List of contracts which can be changed
        function emptyLists() {
            $scope.status = 'WAITING';
            $scope.files = [];
        }
        emptyLists();

        $scope.startImportation = function() {
            $scope.status = 'LOADING';

            var filesIDs = [];
            angular.forEach($scope.files, function(value, key) {
                filesIDs.push(value.id);
            });

            var task = new Task();
            task.type = 'XMLU';
            task.params = { files: filesIDs };
            task.start()
                .then(function(response) {
                    Alert.createAlert('info', $translate('UPLOADER.IMPORT_BACKGROUND'), false);
                    $scope.backgroundTasks.push(response.data.id);
                })
                .catch(function(response) {
                    Alert.createAlert('danger', $translate('UPLOADER.ERROR_SENDING_TASK'), false);
                })
                .finally(function(){
                    emptyLists();
                    $rootScope.$broadcast('refreshList');
                });

        };

        $scope.startContractImportation = function() {
            $scope.status = 'LOADING';
            var filesIDs = [];
            angular.forEach($scope.files, function(value, key) {
                filesIDs.push(value.id);
            });


            var task = new Task();
            task.type = 'FLTC';
            task.params = { files: filesIDs };
            task.start()
                .then(function(response) {
                    Alert.createAlert('info', $translate('UPLOADER.IMPORT_BACKGROUND'), false);
                    $scope.backgroundTasks.push(response.data.id);
                })
                .catch(function(response) {
                    Alert.createAlert('danger', $translate('UPLOADER.ERROR_SENDING_TASK'), false);
                })
                .finally(function(){
                    emptyLists();
                    $rootScope.$broadcast('refreshList');
                });

            return false;
        };

        notificationCenter.subscribe('BG_TASK_ENDS', function(type, data) {
            var task = data;
            if (task.id) {
                var index = $scope.backgroundTasks.indexOf(task.id);
                if (index > -1) {
                    $scope.$apply(function() {
                        $scope.backgroundTasks.splice(index, 1);
                        Alert.closeAlert();
                        if ($scope.backgroundTasks.length == 0) {
                            Alert.createAlert('info', '', true);
                        }
                        Alert.createAlert('info', $translate('UPLOADER.IMPORT_FINISHED'), true);
                        $rootScope.$broadcast('refreshList');
                    });
                }
            }
        });

    }
]

export { ImportCtrl }
