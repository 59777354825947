const ElectricityMetersCtrl = ['$scope', '$routeParams', '$http', 'Alert', 'UserInfo', '$filter', 'ElectricityMeter',
    function ($scope, $routeParams, $http, Alert, UserInfo, $filter, ElectricityMeter) {

        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.create = false;
        $scope.url = API_URL + '/api/1.0/supplies/' + $routeParams.cups + '/electricity-meters/';

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.canAddElectricityMeter = function () {
            return UserInfo.hasPerm('consumptions.add_electricitymeter');
        };

        $scope.addElectricityMeter = function () {
            $scope.create = !$scope.create;
        };

        $scope.createElectricityMeter = function () {
            ElectricityMeter.createElectricityMeter($scope.electricity_meter).then(function (data) {
                Alert.createAlert('success', $translate('SUPPLIES.ELECTRICITY_METER_CREATED_OK'), true);
                return ElectricityMeter.getElectricityMeters($scope.cups);
            }).then(function (data){
                $scope.electricityMeters = data;
            });
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        ElectricityMeter.getElectricityMeters($scope.cups).then(function (data) {
            $scope.electricityMeters = data;
        });
    }
]

export {ElectricityMetersCtrl};
