// const contractWithdrawal = function
function contractWithdrawal(UserInfo, Alert) {
    return {
        restrict: 'E',
        scope:{
            model: '=activationDate'
        },
        //templateUrl: require('../../static/contracts/templates/contract-detail-withdrawal.html'),
        templateUrl: require('../../templates/contract-detail-withdrawal.html'),
        controller: function ($scope, $http, $rootScope, $filter, $routeParams, ContractDetail) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var self = this;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.id = $routeParams.id;
            $scope.flagWithdrawal = false;
            $scope.activation_date = $scope.model;

            $scope.hasPerm = UserInfo.hasPerm('contracts.change_contract');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest() {
                var element = {
                    id: $scope.id,
                    withdrawal_contract: true
                };

                ContractDetail.withdrawalContract(element)
                    .then(function (contract) {
                        Alert.createAlert('success', $translate('CONTRACTS.EVENT_WITHDRAWAL_CREATED_FOR_CONTRACT'), true);
                    }).catch(function (error) {
                        $scope.error_message = error.data.__all__[0];
                    }).finally(function () {
                        $scope.flagWithdrawal = false;
                    });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.clickWithdrawalYes = function () {
                performRequest();
            };

            $scope.confirmWithdrawal = function () {
                $scope.flagWithdrawal = true;
            };

            $scope.clickWithdrawalNo = function () {
                $scope.flagWithdrawal = false;
            };

        }
    }
}

export {contractWithdrawal}
