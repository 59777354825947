import { AgreementCreateCtrl } from './controllers/AgreementCreateCtrl'
import { AgreementDetailCtrl } from './controllers/AgreementDetailCtrl'
import { AgreementsFeesCtrl } from './controllers/AgreementsFeesCtrl'
import { AgreementsListCtrl } from './controllers/AgreementsListCtrl'
import { AgreementUserManagementCtrl } from './controllers/AgreementUserManagement'
import { CreateAgreementPricesCtrl } from './controllers/CreateAgreementPricesCtrl'
import { lowerThan } from './directives/lowerThan'
import { AgreementAPI } from './services/AgreementAPI'

const agreements = angular.module('agreements', ['pascalprecht.translate', 'ngResource', 'supplies']);

agreements.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

agreements
    .controller('AgreementCreateCtrl', AgreementCreateCtrl)
    .controller('AgreementDetailCtrl', AgreementDetailCtrl)
    .controller('AgreementsFeesCtrl', AgreementsFeesCtrl)
    .controller('AgreementsListCtrl', AgreementsListCtrl)
    .controller('AgreementUserManagementCtrl', AgreementUserManagementCtrl)
    .controller('CreateAgreementPricesCtrl', CreateAgreementPricesCtrl)
    .directive('lowerThan', lowerThan)
    .service('AgreementAPI', AgreementAPI)

// Comentado para poder traducirlos en el controlador
/*app.constant('AGREEMENT_PRICE_TYPES', [
    {code: 'ANN', name: 'Cons. anual estimado'},
    {code: 'MON', name: 'Cons. mensual'},
    {code: 'CON', name: 'Basada en consumo'}
]);*/

export { agreements }
