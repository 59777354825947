// Controllers
import { SettingsListController } from "./controllers/SettingsListController"
import { Settings } from "./services/Settings";

const settings = angular.module('settings', ['pascalprecht.translate', 'ngResource'])

settings.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

settings
    .config(['$resourceProvider', function($resourceProvider) {
        $resourceProvider.defaults.stripTrailingSlashes = false;
    }])
    .controller('SettingsListController', SettingsListController)
    .service('Settings', Settings)

export { settings }
