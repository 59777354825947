const ContractProductServicesDetailCtrl = ['$scope', '$filter', '$routeParams', 'DateUtils', '$http', 'UserInfo', 'Alert',
    function ($scope, $filter, $routeParams, DateUtils, $http, UserInfo, Alert) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var api = API_URL + '/api/1.0/contracts/' + $routeParams.id + '/services/';

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/

        $scope.action = 'detail';
        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/

        $scope.deleteProductService = function ($event) {
            $event.preventDefault();
            $scope.action = 'delete';
        };

        $scope.editProductService = function ($event) {
            $event.preventDefault();
            $scope.action = 'update';
        };

        $scope.cancelProductService = function ($event) {
            $event.preventDefault();
            $scope.action = 'detail';
        };

        $scope.canDeleteContractProductService = function () {
            return UserInfo.hasPerm("contracts.delete_contractserviceproduct");
        };

        $scope.canEditContractProductService = function () {
            return UserInfo.hasPerm("contracts.change_contract_product_service");
        };

        $scope.deleteContractProductService = function (id) {

            $http.delete(api + id).then(function (data, status, headers, config) {
                $scope.listReload();
                Alert.createAlert('success', $translate('CONTRACTS.PRODUCT_RATE_DELETED'), true);
            });
        };

        $scope.updateContractProductService = function (id) {
            if ($scope.finish_date) {
                var data = {
                    "activation_date": DateUtils.transformToRESTyDate($scope.activation_date),
                    "finish_date": DateUtils.transformToRESTyDate($scope.finish_date)
                };
            } else {
                var data = {
                    "activation_date": DateUtils.transformToRESTyDate($scope.activation_date),
                    "finish_date": null
                }
            }
            $http.patch(api + id, data).then(function (data, status, headers, config) {
                $scope.listReload();
                Alert.createAlert('success', $translate('CONTRACTS.PRODUCT_SERVICE_UPDATED'), true);
            });
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/

        $scope.activation_date = DateUtils.transformToInterface($scope.result.activation_date);
        if ($scope.result.finish_date) {
            $scope.finish_date = DateUtils.transformToInterface($scope.result.finish_date)
        }
    }
]

export { ContractProductServicesDetailCtrl }
