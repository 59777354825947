const BrandsListResource = ['$resource', function ($resource) {
        return $resource(API_URL + '/api/1.0/brands/?:query_params', { query_params: '@query_params'});
    }
]

const BrandsList = ['$q', 'BrandsListResource',  function ($q, BrandsListResource) {

        var brands= {
            items: [],

            all: function () {

                var self = this;
                var deferred = $q.defer();

                BrandsListResource.get({'query_params': 'page_size=100'}, function(data) {
                    self.items = self.items + data["results"];
                    deferred.resolve(data["results"]);
                });

                return deferred.promise;
            }

        };

        return brands;
    }
]

export { BrandsList, BrandsListResource }
