function Alert(){
    var newAlert = {
        alert: {
            info: false,
            success: false,
            danger: false,
            warning:false,
            message: '',
            timeout: false
        },

        createAlert: function (type, message, timeout) {
            if (type == 'info')
                newAlert.alert.info = true;
            else if (type == 'success')
                newAlert.alert.success = true;
            else if (type == 'danger')
                newAlert.alert.danger = true;
            else if (type == 'warning')
                newAlert.alert.warning = true;

            newAlert.alert.message = message;
            newAlert.alert.timeout = timeout;
        },

        closeAlert: function(){
            newAlert.alert.info = false;
            newAlert.alert.success = false;
            newAlert.alert.danger = false;
            newAlert.alert.warning = false;
            newAlert.alert.timeout = false;
        }

    };

    return newAlert;

}

export {Alert}