function fileUploader(fileService, filesConfig,
                    FILE_TO_UPLOAD, FILE_UPLOADING, FILE_UPLOADED, FILE_ERROR,
                    FILE_DELETING, FILE_DELETED, FILE_CANCELED) {
        File.prototype.id = null;
        File.prototype.progress = 0;
        File.prototype.status = FILE_TO_UPLOAD;
        File.prototype.xhr = null;

    return {
        require: '^ngModel',
        scope: {
            uploadedFiles : '=ngModel',
            parallelUploads : '=?',
            readyToUpload: '=?',
            apiURL: '@apiUrl',
            maxItems: '=?'
        },
        restrict: 'E',
        templateUrl: require('../../templates/uploader.html'),
        //templateUrl: filesConfig.TEMPLATES_PREFIX + filesConfig.UPLOADER_TEMPLATE,
        controller: function ($scope) {
            if($scope.apiURL != null) {
                filesConfig.API_URL = $scope.apiURL;
            } else {
                filesConfig.API_URL = filesConfig.BASE_API_URL;
            }

            var self = this;
            var maxParallelUploads = $scope.parallelUploads || filesConfig.DEFAULT_PARALLEL_UPLOADS;
            if ($scope.parallelUploads == 0) maxParallelUploads = -1;
            var uploadsInProgress = 0;
            var lastUploadedIndex = 0;
            $scope.previous_res = 0;
            $scope.status_max_length = false;

            // own methods
            this.uploadFiles = function () {
                if ($scope.maxItems == null){
                    while ((maxParallelUploads <= 0 || uploadsInProgress < maxParallelUploads) && lastUploadedIndex < $scope.files.length) {
                        var file = $scope.files[lastUploadedIndex];
                        file.status = FILE_UPLOADING;
                        self.uploadFile(file);
                        uploadsInProgress++;
                        lastUploadedIndex++;
                    }
                }
                else{
                    if ($scope.files.length <= $scope.maxItems){
                        var tempCount = $scope.files.length;

                        while ((maxParallelUploads <= 0 || uploadsInProgress < maxParallelUploads) && lastUploadedIndex < tempCount) {
                            var file = $scope.files[lastUploadedIndex];
                            file.status = FILE_UPLOADING;
                            self.uploadFile(file);
                            uploadsInProgress++;
                            lastUploadedIndex++;
                        }
                    }

                    if ($scope.files.length > $scope.maxItems){
                        $scope.status_max_length = true;
                    }
                }

            };

            this.uploadFile = function (file) {
                fileService.upload(file).then(
                    function (data) {
                        $scope.onUploadSucceed(data.xhr, data.ev, file)
                    },
                    function (data) {
                        $scope.onUploadError(data.xhr, data.ev, file)
                    },
                    function (data) {
                        $scope.onUploadProgress(data.xhr, data.ev, file)
                    }
                );
            };

            // scope intializing
            $scope.files = [];
            $scope.choosen = [];
            $scope.uploadedFiles = $scope.uploadedFiles || [];
            $scope.isInside = false;
            $scope.FILE_TO_UPLOAD = FILE_TO_UPLOAD;
            $scope.FILE_UPLOADING = FILE_UPLOADING;
            $scope.FILE_UPLOADED = FILE_UPLOADED;
            $scope.FILE_ERROR = FILE_ERROR;
            $scope.FILE_CANCELED = FILE_CANCELED;
            $scope.FILE_DELETING = FILE_DELETING;
            $scope.FILE_DELETED = FILE_DELETED;
            $scope.MAX_ITEMS = false;

            $scope.$watch('uploadedFiles', function(newValue, oldValue) {
                if (newValue.length == 0) {
                    $scope.files.splice(0, $scope.files.length);
                    lastUploadedIndex = 0;
                }
            });

            /*
            $scope

            {'active progress-bar-striped' : file.status == FILE_UPLOADING, 'progress-bar-success' : file.status == FILE_UPLOADED ||  file.status == FILE_DELETING, 'progress-bar-warning' : file.status == FILE_CANCELED,'progress-bar-danger' : file.status == FILE_ERROR }
            */
           
            $scope.$on('filesAdded', function(newValue, oldValue) {
                self.uploadFiles();
            });

            $scope.uploaded = function () {
                if (this.files.length <= $scope.maxItems || $scope.maxItems==null){
                    let total = 100 * this.files.length;
                    let done = 0;
                    
                    for (var i = 0; i < this.files.length; i++) {
                        done += this.files[i].progress;
                    }

                    $scope.readyToUpload = parseInt(100 * done / total) == 100;
                    $scope.previous_res = parseInt(100 * done / total);
                }
                return $scope.previous_res
            };

            $scope.onUploadSucceed = function (xhr, ev, file) {
                uploadsInProgress--;
                var data = JSON.parse(xhr.responseText);
                file.id = data.id || null;
                file.status = FILE_UPLOADED;
                $scope.uploadedFiles.push(file);
                self.uploadFiles();
            };

            $scope.onUploadError = function (xhr, ev, file) {
                uploadsInProgress--;
                file.progress = 100;
                if (ev.type == "abort")
                    file.status = FILE_CANCELED;
                else
                    file.status = FILE_ERROR;
                self.uploadFiles();
            };

            $scope.onUploadProgress = function (xhr, ev, file) {
                file.progress = parseInt((ev.loaded * 100) / ev.total);
            };

            $scope.deleteFile = function (file) {
                var previousStatus = file.status;
                file.status = FILE_DELETING;
                fileService.delete(file).then(function () {
                    file.status = FILE_DELETED;
                    var index = $scope.uploadedFiles.indexOf(file);
                    if (index > -1)
                        $scope.uploadedFiles.splice(index, 1);
                }, function () {
                    file.status = previousStatus;
                });
            };

            $scope.cancelFileUpload = function (file) {
                file.cancel();
                file.status = FILE_CANCELED;
            };

            $scope.retryUploadFile = function (file) {
                file.status = FILE_UPLOADING;
                file.progress = 0;
                fileService.upload(file).then(
                    function (data) {
                        $scope.onUploadSucceed(data.xhr, data.ev, file)
                    },
                    function (data) {
                        $scope.onUploadError(data.xhr, data.ev, file)
                    },
                    function (data) {
                        $scope.onUploadProgress(data.xhr, data.ev, file)
                    }
                );
            };

            $scope.$on('clear_files', function (){
                $scope.files = [];
                $scope.uploadedFiles = [];
            });

        }
    }
}

export { fileUploader };
