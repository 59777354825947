const CalendarAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/calendars/';

        var self = this;
        var calendar = null;
        
        function deleteCalendar(id) {
            var deferred = $q.defer();

            $http.delete(url + id)
                .then(function (calendar) {
                    deferred.resolve(calendar.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function getCalendarDetail(id) {
            if (calendar == null || calendar.id != id) {
                calendar = {
                    'id': id
                };
                return _getACalendarDetail(id);
            } else {
                var deferred = $q.defer();
                deferred.resolve(calendar);
                return deferred.promise;
            }
        }

        function _getACalendarDetail(id) {
            var deferred = $q.defer();

            $http.get(url + id)
                .then(function (calendar) {
                    deferred.resolve(calendar);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            self.id = id;
            return deferred.promise;
        }
        
        function createCalendar(calendar) {
            var deferred = $q.defer();

            $http.post(url, calendar)
                .then(function (data) {
                    deferred.resolve(data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        return {
            deleteCalendar: deleteCalendar,
            getCalendarDetail: getCalendarDetail,
            createCalendar: createCalendar,
        };
    }
]

export { CalendarAPI };
