const DeleteTaxCtrl = ['$scope', '$filter', 'TaxAPI', 'Alert', 'UserInfo',
    function ($scope, $filter, TaxAPI, Alert, UserInfo) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.deleteTax = function () {
            $scope.action = 'delete';
        };

        $scope.cancelDeleteTax = function () {
            $scope.action = '';
        };

        $scope.confirmDeleteTax = function (tax) {
            $scope.loading = true;

            TaxAPI.deleteTax(tax.id).then(function (tax) {
                $scope.loading = false;
                $scope.listReload();
                Alert.createAlert('success', $translate('TAXES.DELETE_TAX_OK'), true);
            }).catch(function (error) {
                $scope.loading = false;
                Alert.createAlert('danger', $translate('TAXES.DELETE_TAX_ERROR'), true);
            });

        };

        $scope.canDelete = function () {
            return UserInfo.hasPerm('taxes.delete_tax');
        };

    }
]

export { DeleteTaxCtrl }