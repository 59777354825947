const SCTabsCtrl = ['notificationCenter', '$scope', '$sce',
    function(notificationCenter, $scope, $sce){
        $scope.SUPPLY_SC_SINGLE_REPORT = require('../../templates/secondary-concentrator-single-report.html')
        $scope.SUPPLY_SC_DOUBLE_REPORT = require('../../templates/secondary-concentrator-double-report.html')

        $scope.selectedTab = 'single-report';
        $scope.reloadList = function() {
          notificationCenter.publish('SC_RELOAD_LIST', {});
        };
    }
]

export {SCTabsCtrl};
