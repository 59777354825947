const NewReceiptsRemittanceCtrl = ['$scope', '$http', '$filter', '$rootScope', 'Alert', 'Bank', 'UserInfo', 'filtersUtils', 'Task', 'notificationCenter',
    function ($scope, $http, $filter, $rootScope, Alert, Bank, UserInfo, filtersUtils, Task, notificationCenter) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.performingRemittance = false;
        $scope.dates = {};
        $scope.dates.date = new Date();
        $scope.dates.creationDate = new Date();
        $scope.accumulated_credit = 0;

        $scope.type = [
            {'label': $translate('BANKS.NORMAL'), 'value': false},
            {'label': $translate('BANKS.REFUND_REMIT'), 'value': true},
        ];

        $scope.receipt_types = [
            {code: 'NOR', label: $translate('BANKS.NORMAL')},
            {code: 'RCV', label: $translate('BANKS.RECOVER')}
        ];

        $scope.statuses = [
            {code: 'UNP', label: $translate('INVOICES.UNPAID')},
            {code: 'INV', label: $translate('INVOICES.INVOICED')}
        ];

        $scope.searchFilters = {
            'id': $translate('BANKS.NO_RECEIPT'),
            'search': $translate('INVOICES.ALL')
        };

        $scope.filters = {
            search: "",
            total_min: "",
            total_max: "",
            status: "DFR,MAN",
            type: ""
        };

        $scope.searchBy = Object.keys($scope.searchFilters)[0];
        $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];

        $scope.ordering = {
            "id": $translate('BANKS.NO_RECEIPT'),
            "total": $translate('INVOICES.TOTAL_AMOUNT')
        };

        $scope.date_filters = {
            receipt_date_range: null,
            date_range_from: null,
            date_range_to: null
        };


        $scope.filters_by_date = [
            {value: "receipt", description: $translate('BANKS.REMIT_DATE')}
        ];

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/

        $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
            setGlobalSum();
        });

        $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
            $scope.counter = selectedCounterChange;
        });

        $scope.$watchCollection('date_filters', function (newVal, oldVal) {
            if (oldVal.receipt_date_range !== null) {
                var sel_option = oldVal.receipt_date_range.value;
                if (oldVal.date_range_from !== null) {
                    $scope.filters[sel_option + "_date_from"] = null;
                }
                if (oldVal.date_range_to !== null) {
                    $scope.filters[sel_option + "_date_to"] = null;
                }
            }

            if (newVal.receipt_date_range !== null) {
                var sel_option = newVal.receipt_date_range.value;
                if (newVal.date_range_from !== null) {
                    var from_date = newVal.date_range_from;
                    $scope.filters[sel_option + "_date_from"] = from_date;
                }
                if (newVal.date_range_to !== null) {
                    var to_date = newVal.date_range_to;
                    $scope.filters[sel_option + "_date_to"] = to_date;
                }
            }
        });

        $scope.$watch('searchQuery', function (newVal, oldVal) {
            $scope.filters[$scope.searchBy] = newVal;
        });


        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        function setGlobalSum() {

            var ids = filtersUtils.getItemsToDownload($scope.receiptsToRemit);
            if (ids.length < 1) {
                $scope.accumulated_credit = 0;
            } else {
                var url_get = API_URL + '/api/1.0/receipts/get_total/' + '?ids=' + ids;

                $http.get(url_get, {}).then(function (data, status, headers, config) {
                    if (data.data.total == null) {
                        data.data.total = 0;
                    }
                    $scope.accumulated_credit = data.data.total;
                });
            }
        }

        function performRemittance(ids, bank, creationDate, date, is_refund) {
            $scope.performingRemittance = true;

            var task = new Task();
            task.type = 'GRRB';

            task.params = {
                "ids": ids,
                "bank": bank,
                "creation_date": creationDate,
                "is_refund": is_refund,
                "date": date
            };
            task.start().then(function (data, status, headers, config) {
                Alert.createAlert('success', $translate('CONTRACTS.Bulk download generation Task success'), true);
            }).catch(function (data, status, headers, config) {
                Alert.createAlert('danger', $translate('CONTRACTS.Bulk download generation Task error'), true);
            });
        }

        notificationCenter.subscribe('BG_TASK_REMITTANCE_FINISHED', function (type, data) {
            $scope.performingRemittance = false;
        });

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
            // Get credit
        $scope.updateBankCredit = function () {
            $http.get(API_URL + '/api/1.0/banks/credit/' + $scope.bank.id + '/', {params: {date: $scope.dates.date}}).then(function (data, status, headers, config) {
                $scope.bank.credit_used = $scope.bank.credit_limit - data.data['credit'];
            }).catch(function (response) {
            });
        };

        $scope.generateRemittance = function () {
            var ids = filtersUtils.getItemsToDownload($scope.receiptsToRemit).toString();
            var ids_as_int = ids.split(',').map(function (item) {
                return parseInt(item, 10);
            });
            performRemittance(ids_as_int, $scope.bank.id, $scope.dates.creationDate, $scope.dates.date, $scope.is_refund);

        };

        $scope.setSearchBy = function (val) {
            $scope.filters[$scope.searchBy] = '';
            $scope.searchBy = val;
            $scope.searchByLabel = $scope.searchFilters[val];
            $scope.filters[val] = $scope.searchQuery;
        };

        $scope.getCreditPercentage = function (qty) {
            var bank = $scope.bank || null;
            if (!bank) return 0;
            var credit_percent = (qty * 100) / bank.credit_limit;
            return credit_percent <= 100 ? credit_percent : 100;
        };

        $scope.canCreateRemittance = function () {
            return UserInfo.hasPerm('banks.can_remit');
        };

        $scope.canPerformanceRemittance = function () {
            return $scope.counter == 0 || !$scope.bank ||
                $scope.bank.credit_limit < ($scope.bank.credit_used + $scope.accumulated_credit) ||
                $scope.performingRemittance || $scope.is_refund == null;
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        Bank.get(function (data) {
            var arr = [];
            data.results.forEach(function (bank) {
                arr.push(bank);
            });
            $scope.banks = arr;
        });

    }
]

export { NewReceiptsRemittanceCtrl }
