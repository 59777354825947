{
  "TAXES": {
    "TAXES": "Taxes",
    "TAX": "Tax",
    "CODE": "Code",
    "NAME": "Name",
    "DEFAULT": "Default (%)",
    "NEX_TAX": "New tax",
    "TAX_CREATED": "Tax successfully created.",
    "TAX_CREATION_ERROR": "Error during creation tax.",
    "DELETE_TAX": "Delete tax?",
    "DELETE_TAX_OK": "Tax deleted successfully.",
    "DELETE_TAX_ERROR": "Error deleting tax.",
    "UPDATE": "Update",
    "RULES": "Rules",
    "STATE": "State",
    "PERCENT": "Percentage",
    "EDIT": "Edit",
    "DELETE": "Delete",
    "CREATE": "Create",
    "TAXRULE_DELETED": "Tax rule successfully deleted",
    "TAXRULE_EDITED": "Tax rule edited",
    "ANY": "Any",
    "TAX_UPDATED": "Tax updated successfully.",
    "TAXRULE_CREATED": "Tax Rule created",
    "STATES": "States",
    "PERCENT_TAX": "Percent Tax"
  }
}