const InvoiceReadingsController = ['$scope', '$rootScope', 'UserInfo', 'Alert', 'Invoice', '$http', '$filter',
    function ($scope, $rootScope, UserInfo, Alert, Invoice, $http, $filter) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/

        $scope.readingToDelete = false;
        $scope.readings = [];
        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/

        $scope.canEditReadings = function () {
            if (UserInfo.hasPerm("invoices.edit_invoiced_invoice") || ((UserInfo.hasPerm("invoices.edit_draft_invoice") && $scope.isInvoiceLoaded() && $scope.$parent.invoice.status == 'DRF'))) {
                return true;
            }
            return false;
        };
        $scope.addReading = function () {
            $scope.readings.push({
                periodo: "",
                fecha_inicio: "",
                fecha_fin: "",
                contador: "",
                reactiva: {anterior: "", actual: "", consumo: ""},
                activa: {anterior: "", actual: "", consumo: ""},
                maximetro: "",
            })
        }
        $scope.showDeleteOptions = function (item, value) {
            if (value) {
                $scope.readingToDelete = item;
            } else {
                $scope.readingToDelete = false;
            }
        };
        $scope.isToDelete = function (item) {
            return $scope.readingToDelete === item;
        };
        $scope.deleteReading = function (reading) {
            const index = $scope.readings.indexOf(reading);
            $scope.readings.splice(index, 1);
        };
        $scope.onChangeReadingDate = function (item) {
            item.fecha = [item.fecha_inicio, item.fecha_fin].join('-')
        }
        $scope.saveReadings = function () {
            if ($scope.formEditElectricityReading.$valid) {
                var json = {
                    'electricity_readings': $scope.readings,
                    'partial': true
                };
                Invoice.patch({id: $scope.$parent.invoice.id}, json, function (invoice) {
                    Alert.createAlert('success', $translate('INVOICES.READINGS_UPDATED'), true);
                }).catch(function (error) {
                    Alert.createAlert('danger', $translate('INVOICES.' + error.data.idn[0]), true);
                });
            } else {
                Alert.createAlert('danger', $translate('Lecturas de contador incorrectas.'), true);
            }
        }

        $scope.loadInvoiceReadings = function() {
            if ($scope.$parent.invoice !== undefined) {
                $scope.readings = $scope.$parent.invoice.electricity_readings;
                if (Array.isArray($scope.readings)) {
                    $scope.readings.forEach(function(item) {
                        try {
                            const dateList = item.fecha.split('-');
                            if (dateList.length === 2) {
                                item.fecha_inicio = dateList[0]
                                item.fecha_fin = dateList[1]
                            }
                        } catch (e) {
                            Alert.createAlert('danger', $translate('Lecturas de contador incorrectas.'), true);
                        }
                    });
                }
            }
        }

        $scope.isInvoiceLoaded = function() {
            return $scope.$parent.invoice !== undefined
        }

        $scope.$watch('isInvoiceLoaded()', function (newVal, oldVal) {
            if (newVal === true) {
                $scope.loadInvoiceReadings()
            }
        });
        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/

        $scope.loadInvoiceReadings();

    }
]

export { InvoiceReadingsController }
