const IssueAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/issues/';
        var url_duplicate = '/duplicate/';

        var self = this;
        var issue = null;
        
        function getIssueDetail(id) {
            if (issue == null || issue.id != id) {
                issue = {
                    'id': id
                };
                return _getIssueDetail(id);
            } else {
                var deferred = $q.defer();
                deferred.resolve(issue.data);
                return deferred.promise;
            }
        }

        function _getIssueDetail(id) {
            var deferred = $q.defer();

            $http.get(url + id)
                .then(function (issue) {
                    deferred.resolve(issue.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            self.id = id;
            return deferred.promise;
        }

        function setIssueDetail(id, issue) {
            var deferred = $q.defer();

            $http.put(url + id, issue)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function duplicateIssue(id, category) {
            var deferred = $q.defer();

            $http.post(url + id + url_duplicate, category)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function deleteIssues(ids) {
            var deferred = $q.defer();
            var data = {
                data: JSON.stringify(ids),
                headers: {"Content-Type": "application/json;charset=utf-8"}
            };

            $http.delete(url, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function setIssuesAsChecKed(data) {
            var deferred = $q.defer();

            $http.put(url, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function bulkChangeIssuesStatus(data) {
            var deferred = $q.defer();

            $http.put(url, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            getIssueDetail: getIssueDetail,
            setIssueDetail: setIssueDetail,
            duplicateIssue: duplicateIssue,
            deleteIssues: deleteIssues,
            setIssuesAsChecKed: setIssuesAsChecKed,
            bulkChangeIssuesStatus: bulkChangeIssuesStatus
        };

    }
]

export { IssueAPI }
