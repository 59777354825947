
const ContractExcludedProducts = 
        ['$scope', '$filter', '$routeParams', 'ExcludedProductApi', 'Alert', 'ProductCodeMaster',
            function ($scope, $filter, $routeParams, ExcludedProductApi, Alert, ProductCodeMaster) {
                /***********************************************************************************************************
                 * Controller Attributes
                 ***********************************************************************************************************/
                var $translate = $filter('translate');
                var self = this;

                /***********************************************************************************************************
                 * Scope Attributes
                 ***********************************************************************************************************/
                $scope.id = $routeParams.id;

                $scope.creationMode = false;
                $scope.errors = {};
                $scope.agreement_manages = false;

                /***********************************************************************************************************
                 * Scope Watchers
                 ***********************************************************************************************************/


                /***********************************************************************************************************
                 * Controller Methods
                 ***********************************************************************************************************/
                function resetForm() {
                    $scope.denomination = "";
                    $scope.product = "";
                    $scope.count_exclusion = 0;
                    $scope.max_exclusions = 1;
                    $scope.excludedProduct.$setPristine();
                    $scope.excludedProduct.$setUntouched();

                }

                /***********************************************************************************************************
                 * Scope Methods
                 ***********************************************************************************************************/
                $scope.toggleCreationMode = function () {
                    $scope.creationMode = !$scope.creationMode;
                    resetForm();
                };
                $scope.createExcludedProduct = function () {
                    $scope.errors = {};
                    var data = {
                        'denomination': $scope.denomination,
                        'product': $scope.selected_product.code,
                        'count_exclusion': $scope.count_exclusion,
                        'max_exclusions': $scope.max_exclusions,
                        'contract': $scope.id
                    };


                    ExcludedProductApi.createExcludedProduct(data).then(function (contract) {
                        $scope.listReload();
                        Alert.createAlert('success', $translate('CONTRACTS.EXCLUDED_PRODUCT_ADD'), true);
                        resetForm();
                        $scope.creationMode = false;
                    }).catch(function (error) {
                        $scope.errors = error;
                    });
                };

                /***********************************************************************************************************
                 * Controller Initialization
                 ***********************************************************************************************************/
                ProductCodeMaster.loadProductCodesNotForce().then(function (response) {
                    $scope.products_codes = response;
                });
                if ($scope.max_exclusions == null) {
                    $scope.max_exclusions = 1;
                }
                if ($scope.count_exclusion == null) {
                    $scope.count_exclusion = 0;
                }
            }]

export {ContractExcludedProducts}
