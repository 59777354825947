function PricesService(){

    var powerPriceMatrix = {
        T20A: [false, true, false, false, false, false],
        T20DHA: [true, false, false, false, false, false],
        T21A: [false, true, false, false, false, false],
        T21DHA: [true, false, false, false, false, false],
        T30A: [true, true, true, false, false, false],
        T31A: [true, true, true, false, false, false],
        T31DHA: [true, true, true, false, false, false],
        T61: [true, true, true, true, true, true],
        T62: [true, true, true, true, true, true],
        T63: [true, true, true, true, true, true],
        T64: [true, true, true, true, true, true],
        T65: [true, true, true, true, true, true],
        T61B: [true, true, true, true, true, true],
        T20DHS: [true, true, true, true, true, true],
        T21DHS: [true, true, true, true, true, true],
        // New rates
        T20TD: [true, true, false, false, false, false],
        T30TD: [true, true, true, true, true, true],
        T30TDVE: [true, true, true, true, true, true],
        T61TD: [true, true, true, true, true, true],
        T61TDVE: [true, true, true, true, true, true],
        T62TD: [true, true, true, true, true, true],
        T63TD: [true, true, true, true, true, true],
        T64TD: [true, true, true, true, true, true],
    };

    var energyPriceMatrix = {
        T20A: [false, true, false, false, false, false],
        T20DHA: [true, false, true, false, false, false],
        T21A: [false, true, false, false, false, false],
        T21DHA: [true, false, true, false, false, false],
        T30A: [true, true, true, false, false, false],
        T31A: [true, true, true, false, false, false],
        T31DHA: [true, true, true, false, false, false],
        T61: [true, true, true, true, true, true],
        T62: [true, true, true, true, true, true],
        T63: [true, true, true, true, true, true],
        T64: [true, true, true, true, true, true],
        T65: [true, true, true, true, true, true],
        T61B: [true, true, true, true, true, true],
        T20DHS: [true, true, true, true, true, true],
        T21DHS: [true, true, true, true, true, true],
        // New rates
        T20TD: [true, true, true, false, false, false],
        T30TD: [true, true, true, true, true, true],
        T30TDVE: [true, true, true, true, true, true],
        T61TD: [true, true, true, true, true, true],
        T61TDVE: [true, true, true, true, true, true],
        T62TD: [true, true, true, true, true, true],
        T63TD: [true, true, true, true, true, true],
        T64TD: [true, true, true, true, true, true]
    };

    this.priceActive = function (index, rate, product) {
        if (rate === undefined) {
            return true;
        }
        if (['E', 'ERG', 'GESTM', 'PRSCH', 'DEVTN', 'FINCT'].indexOf(product) >= 0 && rate !== '') {
            return !energyPriceMatrix[rate][index - 1];
        }
        if ((product === 'P' || product === 'EXP') && rate !== '') {
            return !powerPriceMatrix[rate][index - 1];
        }
        return true;
    };
}

export { PricesService }
