const AgreementAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {
        var url = API_URL + '/api/1.0/agreements/';
        var url_prices = '/prices/';

        var self = this;
        var agreement = null;

        function createAgreement(data){
            return _createAgreement( data);
        }

        function _createAgreement(data){
            var deferred = $q.defer();

            $http.post(url, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }
        
        function deleteAgreement(code) {
            var deferred = $q.defer();

            $http.delete(url + code)
                .then(function (agreement) {
                    deferred.resolve(agreement.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function getAgreementDetail(code) {
            return _getAgreementDetail(code);
        }

        function _getAgreementDetail(code) {
            var deferred = $q.defer();

            $http.get(url + code)
                .then(function (agreement) {
                    deferred.resolve(agreement.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            self.code = code;
            return deferred.promise;
        }

        function setAgreementDetail(code, agreement) {
            var deferred = $q.defer();

            $http.put(url + code, agreement)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function setAgreementContactData(code, contactData) {
            var deferred = $q.defer();

            $http.patch(url + code +'/contact-data/', contactData)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function getAgreementPrice(code) {
            return _getAgreementPrice(code);
        }

        function _getAgreementPrice(code) {
            var deferred = $q.defer();

            $http.get(url + code + url_prices)
                .then(function (fees_prices) {
                    deferred.resolve(fees_prices.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function setAgreementPrice(code, price_id, price) {
            var deferred = $q.defer();
            code = code || '-';

            $http.put(url + code + url_prices + price_id, price)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }
        
        function createAgreementPrice(code, price) {
            var deferred = $q.defer();
            code = code || '-';

            $http.post(url + code + url_prices, price)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function deleteAgreementPrice(code, price_id) {
            var deferred = $q.defer();
            code = code || '-';

            $http.delete(url + code + url_prices + price_id)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function getContractFeePrices(contract_id) {
            var deferred = $q.defer();
            var url = API_URL + '/api/1.0/agreements/-/prices/?contract_id=' + contract_id;
            $http.get(url)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        return {
            deleteAgreement: deleteAgreement,
            getAgreementDetail: getAgreementDetail,
            setAgreementDetail: setAgreementDetail,
            getAgreementPrice: getAgreementPrice,
            createAgreementPrice: createAgreementPrice,
            setAgreementPrice: setAgreementPrice,
            deleteAgreementPrice: deleteAgreementPrice,
            setAgreementContactData: setAgreementContactData,
            createAgreement: createAgreement,
            getContractFeePrices: getContractFeePrices
        };
    }
]

export {AgreementAPI}
