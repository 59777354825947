const ActionsComplaintCtrl = ['$scope', '$rootScope','UserInfo',
    function ($scope, $rootScope, UserInfo) {

        $scope.canChangeComplaintStatus = function () {
            return UserInfo.hasPerm('complaints.can_change_status');
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/

    }
]

export { ActionsComplaintCtrl }
