

const SuppliesTypesLoading = ['$resource', function ($resource) {
    return $resource(API_URL + '/api/1.0/masters/supply-type/');
}]

const SuppliesTypesMaster = ['$q', 'SuppliesTypesLoading',  function ($q, SuppliesTypesLoading) {

    var supplies_types= {
        items: null,

        loadSuppliesTypes: function () {
            var self = this;
            var deferred = $q.defer();

            if (this.items == null)
                SuppliesTypesLoading.get(function (data) {
                    self.items = data;
                    deferred.resolve(data);
                });
            else
                deferred.resolve(self.items);

            return deferred.promise;
        }

    };

    return supplies_types;


}]

export {SuppliesTypesLoading, SuppliesTypesMaster}

