const AgreementsFeesCtrl = ['$scope', '$routeParams', '$filter', '$window', '$cookies', 'DateUtils', 'UserInfo',
    function ($scope, $routeParams, $filter, $window, $cookies, DateUtils, UserInfo) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.code = $routeParams.code;

        $scope.filters = {
            'agreement': $scope.code
        };

        $scope.ordering = {
            'date': $translate('AGREEMENTS.DATE')
        };

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.filter = function () {
            if ($scope.fromDate)
                $scope.filters['from_date'] = DateUtils.transformToRESTyDateWODays($scope.fromDate);
            else
                delete $scope.filters['from_date'];

            if ($scope.toDate)
                $scope.filters['to_date'] = DateUtils.transformToRESTyDateWODays($scope.toDate);
            else
                delete $scope.filters['to_date'];
        };

        $scope.downloadCSVFile = function () {
            var date_from = '';
            var date_to = '';
            var agreement = $scope.code;
            if ($scope.fromDate) date_from = DateUtils.transformToRESTyDate($scope.fromDate);
            if ($scope.toDate) date_to = DateUtils.transformToRESTyDate($scope.toDate);
            var params = '?agreement=' + agreement + '&date_from=' + date_from + '&date_to=' + date_to + '&token=' + $cookies.get("token");
            var url = API_URL + '/fees/download/csv/'+ params;
            $window.open(url);
        };

        $scope.canDownloadFees = function () {
            return UserInfo.hasPerm('fees.download_fees_excel');
        };

    }
]

export {AgreementsFeesCtrl}
