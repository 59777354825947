// const contractsRate = function
function contractsRate(UserInfo, SUPPLY_RATES, filtersUtils, Alert, $filter) {
    return {
        restrict: 'AE',
        scope: {},
        //templateUrl: require('../../static/contracts/templates/change-rate.html'),
        templateUrl: require('../../templates/change-rate.html'),
        controller: function ($scope, $http, $rootScope) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/tasks/';
            var $translate = $filter('translate');
            var self = this;
            var type = 'CCRC';

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.listToUpdate = [];
            $scope.change_rate = '';

            $scope.hasPerm = UserInfo.hasPerm('contracts.change_rate');

            $scope.rates = SUPPLY_RATES;

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/
            function performRequest(ids) {
                var json = {
                    type: type,
                    params: {ids: ids, rate: $scope.change_rate}
                };

                var config = {
                    method: "POST",
                    url: api,
                    data: json,
                    headers: {"Content-Type": "application/json;charset=utf-8"}
                };
                $http(config).then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('CONTRACTS.UPDATED') + data.data.updated_number + $translate('CONTRACTS.CONTRACT_RATES'), true);
                    $rootScope.$broadcast('refreshList');
                    $('#changeRateModal').modal('toggle');
                }).catch(function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.ERROR_REQUEST'), true);
                }).finally(function () {
                    $scope.flagConfirm = false;
                    $scope.listToUpdate = [];
                });
            }

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/
            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
                $scope.change_rate = '';
            };

            $scope.clickYes = function () {
                var ids = filtersUtils.getItemsToDownload($scope.listToUpdate);
                performRequest(ids);
                $scope.change_rate = '';
            };

        }
    }
}

export {contractsRate}
