const ProviderAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/providers/';

        var self = this;
        var provider = null;

        function getProvider(id) {
            return _getProvider(id);
        }

        function _getProvider(id) {
            var deferred = $q.defer();

            $http.get(url + id)
                .then(function (provider) {
                    deferred.resolve(provider.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            self.id = id;
            return deferred.promise;
        }

        function createProvider(data){
            var deferred = $q.defer();

            $http.post(url, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function updateProvider(id, provider) {
            var deferred = $q.defer();

            $http.put(url + id, provider)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function deleteProvider(id) {
            var deferred = $q.defer();

            $http.delete(url + id)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            getProvider: getProvider,
            createProvider: createProvider,
            updateProvider: updateProvider,
            deleteProvider: deleteProvider

        };

    }
]

export { ProviderAPI }
