const  SCSimpleReportCtrl = ['$scope', '$window', 'SUPPLY_RATES', 'ConsumptionReportService',
    function ($scope, $window, SUPPLY_RATES, ConsumptionReportService) {
        var self = this;
        $scope.status = 'WAITING';
        $scope.rate = '';
        $scope.availableRates = SUPPLY_RATES;
        $scope.reportData = {};
        $scope.empty = true;

        // actions
        $scope.generateReport = function () {
            $scope.status = 'LOADING';
            ConsumptionReportService.getConsumptionSingleReport(self.getQueryParams())
                        .then(function (data, status, headers, config) {
                var reportData = {};
                $scope.empty = true;
                for (var i = 0; i < data.length; i++) {
                    var day_of_week = data[i].data;
                    for (var j = 0; j < day_of_week.length; j++) {
                        var cellData = day_of_week[j];
                        if (typeof reportData[cellData.time] === 'undefined') {
                            reportData[cellData.time] = [
                                {time: cellData.time, value: cellData.time, viewValue: cellData.time}
                            ];
                        }
                        if (false == isNaN(cellData.value)) {
                            cellData.viewValue = (Math.round(cellData.value * 100) / 100) + ' kWh';
                            cellData.highlight = false;
                        } else {
                            cellData.viewValue = cellData.value;
                            cellData.highlight = true;
                        }
                        $scope.empty = false;
                        reportData[cellData.time].push(cellData);
                    }
                }
                $scope.reportData = reportData;
                $scope.status = 'LOADED';
            }).catch(function (error) {
                // TODO: better error control
                $scope.status = 'ERROR';
            });
        };

        this.transformToRESTyDate = function (date) {
            var yyyy = date.getFullYear().toString();
            var mm = (date.getMonth() + 1).toString(); // getMonth() is zero-based
            var dd = date.getDate().toString();
            return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]); // padding
        };

        this.getQueryParams = function () {
            if ($scope.fromDate > $scope.toDate) {
                var aux = $scope.fromDateOne;
                $scope.fromDate = $scope.toDate;
                $scope.toDate = aux;
            }
            var params = '?fromDate=' + this.transformToRESTyDate($scope.fromDate);
            params += '&toDate=' + this.transformToRESTyDate($scope.toDate);
            params += '&rate=' + $scope.rate;
            return params;
        };

    }
]

export {SCSimpleReportCtrl};
