const SCImportHistoryCtrl = ['$scope', 'notificationCenter', 'Task',
    function ($scope, notificationCenter, Task) {

        var self = this;

        $scope.results = [];

        $scope.cancelTask = function (task) {
            let taskModel = new Task(task);
            taskModel.cancel().then(function (data, status, headers, config) {
                $scope.updateTask(data);
            }).catch(function () {
                // TODO Better errors control
            });
        };

        $scope.revertTask = function (task) {
            let taskModel = new Task(task);
            taskModel.revert().then(function (data) {
                $scope.updateTask(data);
            }).catch(function () {
                // TODO Better errors control
            });
        };

        $scope.updateTask = function(updated_task) {
            for (var i = 0; i < $scope.results.length; i++) {
                var task = $scope.results[i];
                if (task.id == updated_task.id) {
                    $scope.results[i] = updated_task;
                    $scope.results[i].updated = true
                    break;
                }
            }
        };

        notificationCenter.subscribe('BG_TASK_ENDS', function (type, data) {
            $scope.$apply(function(){
                $scope.updateTask(data);
            });
        });

        notificationCenter.subscribe('SC_RELOAD_LIST', function (type, data) {
            $scope.listReload();
        });

    }
]

export {SCImportHistoryCtrl};
