function complaintStatus($filter, UserInfo, Alert) {
    return {
        restrict: 'AE',
        scope: {},
        templateUrl: '/static/complaints/templates/change-complaint-status.html',
        controller: function ($scope, $routeParams, $window, $timeout, ComplaintAPI, COMPLAINT_STATUSES, COMPLAINT_RESULTS) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var api = '/api/1.0/complaints/';
            var $translate = $filter('translate');
            var self = this;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.id = $routeParams.id;

            $scope.statuses = COMPLAINT_STATUSES;
            $scope.results = COMPLAINT_RESULTS;

            $scope.hasPerm = UserInfo.hasPerm('complaints.can_change_status');

            $scope.change_status = '';
            $scope.change_result = '';
            $scope.set_result = false;

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$watch('change_status', function (newVal, oldVal) {
                if (newVal == 'CLO') {
                    $scope.set_result = true;
                }
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest() {
                var json = {
                    status: $scope.change_status
                };

                if ($scope.change_result != ''){
                    json['result']= $scope.change_result;
                }

                ComplaintAPI.updateComplaintStatus($scope.id, json).then(function (data) {
                    Alert.createAlert('success', $translate('COMPLAINTS.COMPLAINT_STATUS_UPDATED'), true);
                    $timeout(function() {
                            $window.location.reload(true);
                        }, 1000);
                }).catch(function (error) {
                    Alert.createAlert('danger', $translate('COMPLAINTS.ERROR_REQUEST'), true);
                }).finally(function () {
                    $scope.flagConfirm = false;
                })
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.clickNo = function () {
                $scope.flagConfirm = false;
                $scope.change_status = '';
                $scope.change_result = '';
                $scope.set_result = false;
            };

            $scope.clickYes = function () {
                performRequest();
                $scope.change_status = '';
                $scope.change_result = '';
                $scope.set_result = false;
            };

            /***********************************************************************************************************
            * Controller Initialization
            ***********************************************************************************************************/

        }
    }
}

export { complaintStatus }