const AgreementCreateCtrl = ['$scope', '$filter', '$location', 'UserInfo', 'AgreementAPI', 'BrandsMaster', 'Alert', 'addressUtils',
    function ($scope, $filter, $location, UserInfo, AgreementAPI, BrandsMaster, Alert, addressUtils) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.statuses = [
            {code: 'ACT', label: $translate('AGREEMENTS.ACTIVE')},
            {code: 'PEN', label: $translate('AGREEMENTS.PENDING')},
            {code: 'INA', label: $translate('AGREEMENTS.INACTIVE')}
        ];

        $scope.payment_methods = [
            {code: 'TRF', label: $translate('INVOICES.TRANSFER')},
            {code: 'DBT', label: $translate('INVOICES.DEBIT')},
            {code: 'B2B', label: $translate('INVOICES.DEBIT_B2B')}
        ];

        $scope.types = [
            {code: 'NOR', label: $translate('AGREEMENTS.NORMAL')},
            {code: 'REN', label: $translate('AGREEMENTS.RENTAL')}
        ];

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/
        this.cleanAgreementFormErrors = function() {
            for (var item in $scope.createAgreement) {
                if (item.substring(0,1) != '$') {
                    for (let key in $scope.createAgreement[item].$error) {
                        $scope.createAgreement[item].$setValidity(key, true);
                    }
                }
            }
        };

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.resetForm = function(){
            $scope.saving = false;
            $scope.agreement = { status : 'PEN' , receive_paper_invoices: true, type: 'NOR'};
            $scope.show_copy_link = false;
        };

        $scope.saveAgreement = function(){
            self.cleanAgreementFormErrors();
            $scope.saving = true;

            var agreement = $scope.agreement;
            agreement.address = addressUtils.rebuiltAddress($scope.agreement.agreement_address, $scope.agreement.address_number, $scope.agreement.address_ext);
            agreement.contact_address = addressUtils.rebuiltAddress($scope.agreement.agreement_contact_address, $scope.agreement.agreement_contact_number, $scope.agreement.agreement_contact_ext);
            agreement.activation_date = ($scope.agreement.activation_date == "") ? null  : $scope.agreement.activation_date;

            AgreementAPI.createAgreement(agreement).then(function (data) {
                $scope.resetForm();
                $location.path('/agreements/' + data.code);

                Alert.createAlert('success', $translate('AGREEMENTS.AGREEMENT_CREATED_SUCCESSFULLY'), true);
            }).catch(function (error) {
                var error_message = "";
                for (var k in error) {
                    if (k == 'non_field_errors') {
                        error_message = error_message + ' ' + error[k][0];
                    }
                    $scope.createAgreement[k].$error.api = error[k][0];
                }
                $scope.saving = false;
                if (error_message != "")
                    Alert.createAlert('danger', error_message, true);
                else
                    Alert.createAlert('danger', $translate('AGREEMENTS.ERROR_AGREEMENT_CREATED'), true);
            });
        };

        $scope.copyContactData = function () {
            $scope.agreement.name = $scope.commercial_contact.name;
            $scope.agreement.surname_1 = $scope.commercial_contact.surname_1;
            $scope.agreement.surname_2 = $scope.commercial_contact.surname_2;
            $scope.agreement.tax_id = $scope.commercial_contact.vat_id;
            $scope.agreement.email = $scope.commercial_contact.email;
            $scope.agreement.phone = $scope.commercial_contact.phone;
            $scope.agreement.mobile = $scope.commercial_contact.mobile;
        };

        $scope.copyContactAddress = function () {
            var commercial_address = addressUtils.splitAddressByComma($scope.commercial_contact.address);
            $scope.agreement.agreement_address = addressUtils.getAddressName(commercial_address);
            $scope.agreement.address_number = addressUtils.getAddressNumber(commercial_address);
            $scope.agreement.address_ext = addressUtils.getAddressExt(commercial_address);
            $scope.agreement.city = $scope.commercial_contact.city;
            $scope.agreement.state = $scope.commercial_contact.state;
            $scope.agreement.zip = $scope.commercial_contact.zip;
        };

        $scope.canCreate = function () {
            return UserInfo.hasPerm('agreements.add_agreement');
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        $scope.resetForm();
        BrandsMaster.loadBrands().then(function(response){
            $scope.brands = response;
            $scope.brandsFlag = true;
        });

    }
]

export {AgreementCreateCtrl}
