const AgreementDataFormCtrl = ['$scope', '$http', '$filter', 'CreateContract', 'Alert', 'AgreementsMaster', 'AgreementAPI',
    function ($scope, $http, $filter,  CreateContract, Alert, AgreementsMaster, AgreementAPI) {
        $scope.$watch('contract.agreement', function (newValue, oldValue) {
            // For avoid fails when copy address information from agreement

            if ($scope.contract && newValue != undefined) {
                $scope.loadAgreement();
            }

        });
        $scope.enableNext = function() {
            $scope.$emit("setValidity", true);
        };

        AgreementsMaster.loadAgreements().then(function (response){
            $scope.agreements = response;
        });

        $scope.loadAgreement = function () {
            if ($scope.contract.agreement) {

                AgreementAPI.getAgreementDetail($scope.contract.agreement)
                    .then(function (data) {
                        CreateContract.setAgreement(data);
                    });
            }
        };
    }
]

export {AgreementDataFormCtrl};
