const ContractDetail = ['DateUtils', '$q', '$http',
    function (DateUtils, $q, $http) {

        var url = API_URL + '/api/1.0/contracts/';
        var contract = null;

        function getContractDetail(id) {
            if (contract == null || contract.id != id) {
                contract = {
                    'id': id
                };
                return _getContractDetail(id);
            } else {
                var deferred = $q.defer();
                deferred.resolve(contract.data);

                return deferred.promise;
            }
        }

        function _getContractDetail(id) {
            var deferred = $q.defer();

            $http.get(url + id)
                .then(function (contract_data) {
                    contract = contract_data;
                    deferred.resolve(contract.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function setContractDetail(id, contract) {
            var deferred = $q.defer();

            $http.put(url + id, contract)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function setContractDetailPartial(id, contract) {
            var deferred = $q.defer();

            $http.patch(url + id, contract)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function requestEventContract(contract) {
            var deferred = $q.defer();

            $http.put(url, contract)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            getContractDetail: getContractDetail,
            setContractDetail: setContractDetail,
            setContractDetailPartial: setContractDetailPartial,
            unsubscribeContract: requestEventContract,
            maximetreContract: requestEventContract,
            administrativeChangesContract: requestEventContract,
            customerChangesContract: requestEventContract,
            invoice_periodicity_changes_request: requestEventContract,
            withdrawalContract: requestEventContract,
            cancelUnsubscribeContract: requestEventContract
        };

    }
]

export {ContractDetail}
