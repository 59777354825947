const ProgrammingUnitsCtrl = ['$scope', '$filter', '$routeParams', '$http', 'UserInfo', 'ProgrammingUnitsMaster', 'ELECTRICAL_SUBSYSTEMS',
    function ($scope, $filter, $routeParams, $http, UserInfo, ProgrammingUnitsMaster, ELECTRICAL_SUBSYSTEMS) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        var programming_unit = null;

        if ($routeParams.programming_unit != null){
            programming_unit = $routeParams.programming_unit;
        }

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.products = [];
        $scope.ordering = {
            "id": $translate('PROGRAMMING_UNITS.ID'),
            "name": $translate('PROGRAMMING_UNITS.NAME')
        };
        $scope.filters = {
            search: ""
        };

        $scope.mode = 'list';

        $scope.electrical_subsystems = ELECTRICAL_SUBSYSTEMS;

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        $scope.$on('cancelCreateProgrammingUnit', function (event) {
            $scope.mode = 'list';
        });

        $scope.$on('newProgrammingUnit', function (event) {
            $scope.listReload();
        });

        $scope.$watch('noProgrammingUnits', function(newValue, oldValue){
            if (newValue) {
                $scope.filters['search'] = null;
            }
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.showCreateForm = function () {
            $scope.mode = 'create';
        };

        $scope.canCreateProgrammingUnit = function () {
            return (UserInfo.hasPerm("programming_units.add_programmingunit"));
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        ProgrammingUnitsMaster.loadProgrammingUnits().then(function (response){
            $scope.programming_units = response;
        });
    }
]

export {ProgrammingUnitsCtrl};
