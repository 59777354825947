{
  "CALENDARS": {
    "TITLE": "Calendars",
    "NEW_CALENDAR": "Add Calendar",
    "CALENDAR_NAME": "Name",
    "CALENDAR_TYPE": "Type",
    "ADD": "Add",
    "CANCEL": "Cancel",
    "SELECT_CALENDAR": "Select Calendar",
    "CALENDAR_ADDED": "Calendar added successfully",
    "SELECT_TYPE": "Select type",
    "GENERAL": "General",
    "STATE": "State",
    "OTHERS": "Others",
    "DELETE_CALENDAR": "Delete Calendar",
    "CALENDAR_DELETED": "Calendar has been removed.",
    "YES": "Yes",
    "NO": "No",
    "CONFIRM_DELETE": "Do you really want to deleted the calendar?",
    "DATE": "Date",
    "NEW_CALENDAR_DAY": "Add calendar day",
    "HOLIDAY": "Holiday",
    "CALENDAR_DAY_ADDED": "Calendar day added successfully",
    "DELETE_CALENDAR_DAY": "Delete Calendar day",
    "CONFIRM_DELETE_CALENDAR_DAY": "Do you really want to deleted the calendar day?",
    "CALENDAR_DAY_DELETED": "Calendar day has been removed.",
    "ADD_CALENDAR_FOR_SUPPLIES": "Link calendar",
    "CHANGE_QUESTION_LINK": "Link Calendars?",
    "CALENDARS_LINK_SUCCESSFULLY": "No. Supplies linked to calendar: ",
    "CALENDARS_ALREADY_LINK": "No. Supplies already linked to calendar before:  ",
    "CALENDARS_UNLINK_SUCCESSFULLY": "No. Supplies unlinked to calendar: ",
    "CALENDARS_NOT_LINK": "No. Supplies not linked to calendar before:  ",
    "UNLINK_CALENDAR_FOR_SUPPLIES": "Unlink calendar",
    "CHANGE_QUESTION_UNLINK": "Unlink Calendars?"
  }
}