const BackgroundTasksCtrl = ['$scope', 'notificationCenter', '$http', '$rootScope',
        function ($scope, notificationCenter, $http, $rootScope) {
            var self = this;

            $scope.backgroundTasks = [];

            $http.get(API_URL + '/api/1.0/tasks/unfinished/')
                .then(function(data){
                    angular.forEach(data.data, function(value, key){
                        this.push(value.id);
                    }, $scope.backgroundTasks);
                });

            this.deleteTask = function(id) {
                var index = $scope.backgroundTasks.indexOf(id);
                
                if (index > -1) {
                    $scope.$apply(function () {
                        $scope.backgroundTasks.splice(index, 1);
                    });
                }
            };

            notificationCenter.subscribe('BG_TASK_STARTS', function (type, data) {
                $scope.backgroundTasks.push(data.id);
            });

            notificationCenter.subscribe('BG_TASK_ENDS', function (type, data) {
                if (data.id) {
                    self.deleteTask(data.id);
                    $rootScope.$broadcast('refreshList');
                }
            });

            notificationCenter.subscribe('DOWNLOAD_FILE', function (type, data) {
                if (data.id) {
                    self.deleteTask(data.id);
                }
            });

        }
    ]

export {BackgroundTasksCtrl}
