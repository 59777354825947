const ContractProductServiceCtrl = ['$scope', '$filter', '$routeParams', 'DateUtils', '$http', 'ProductServiceCodeMaster', 'Alert',
    function ($scope, $filter, $routeParams, DateUtils, $http, ProductServiceCodeMaster, Alert) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;
        var id = $routeParams.id;
        var api = API_URL + '/api/1.0/contracts/' + id + '/services/';

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/

        $scope.ordering = {
            "activation_date": $translate('CONTRACTS.ACTIVATION_DATE'),
            "finish_date": $translate('CONTRACTS.FINISH_DATE')
        };

        $scope.filters = {
            contract: id
        };

        $scope.creationModeProductService = false;
        $scope.product_service = '';

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/

        function resetForm() {
            $scope.activationDateService = "";
            $scope.finishDateService = "";
            $scope.selected_product_services = "";
            $scope.contractProductService.$setPristine();
            $scope.contractProductService.$setUntouched();
        }


        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/

        $scope.toggleCreationModeProductService = function () {
            $scope.creationModeProductService = !$scope.creationModeProductService;
        };


        $scope.createContractProductService = function () {
            if ($scope.finishDateService) {
                var data = {
                    "product": $scope.selected_product_services,
                    "activation_date": DateUtils.transformToRESTyDate($scope.activationDateService),
                    "finish_date": DateUtils.transformToRESTyDate($scope.finishDateService)
                };
            } else {
                var data = {
                    "product": $scope.selected_product_services,
                    "activation_date": DateUtils.transformToRESTyDate($scope.activationDateService),
                }
            }

            $http.post(api, data).then(function (data, status, headers, config) {
                $scope.listReload();
                Alert.createAlert('success', $translate('CONTRACTS.PRODUCT_SERVICE_ADDED'), true);
                resetForm();
                $scope.creationModeProductService = false;
            }).catch(function (data, status, headers, config) {
                Alert.createAlert('danger', $translate('CONTRACTS.ERROR_PRODUCT_SERVICE_ADDED'), true);
            });
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/

        ProductServiceCodeMaster.loadProductServicesCodesNotForce().then(function (response){
            $scope.products = response;
        });

        $scope.$watch('contract', function(newContract) {
            $scope.rate = newContract.rate;
        });
    }
]

export { ContractProductServiceCtrl }
