function invoicesLines() {
    return {
        restrict: 'AE',
        scope: {
            list: '=',
            invoice: "=",
            url: "@",
            permission: "@",
            editable: "=",
            invoiceProducts: '=?',
            listReload: '=?'
        },
        templateUrl: require('../../templates/invoices-lines.html'),
        transclude: true,
        controller: function ($scope, $http, Alert, $filter, UserInfo, TaxRuleAPI) {
            var $translate = $filter('translate');
            var self = this;

            TaxRuleAPI.getAllTaxRules().then(function (taxes) {
                $scope.taxes = []
                $scope.taxes_obj = {}

                angular.forEach(taxes, function (item) {
                    if(!$scope.taxes_obj[item.code]){
                        $scope.taxes_obj[item.code] = item
                    }
                });

                $scope.taxes = Object.values($scope.taxes_obj)
            }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
            });

            $scope.new_invoice = {};
            $scope.lineToDelete = false;
            $scope.lock_lines_save = false;

            $scope.calculateTotal = function (invoice_line) {
                if(invoice_line !== undefined ){
                    var tax_base = 0;
                    var tax_applied = 0;

                    invoice_line.total = invoice_line.qty * invoice_line.unit_price;
                    invoice_line.tax_applied = (invoice_line.total * invoice_line.tax) / 100;
                    invoice_line.total_with_tax = (invoice_line.total + invoice_line.tax_applied);
                };

                angular.forEach($scope.list, function (value, key) {
                    value.total_with_tax = Math.round((value.total + value.tax_applied) * 10000) / 10000;

                    tax_base += Math.round(value.total * 100) / 100;
                    tax_applied += Math.round(value.tax_applied * 100) / 100;
                });

                if(invoice_line !== undefined ){
                    invoice_line.total = Math.round((invoice_line.total) * 100) / 100;
                };
                $scope.invoice.total = Math.round((tax_base + tax_applied) * 100) / 100;

                var data = get_invoice_total($scope.list);
                data['invoice_lines'] = $scope.list;

                $scope.invoice.total = data.total
                $scope.invoice.tax_total = data.tax_total
                $scope.invoice.base_total = data.base_total

                round_lines(data);
            };

            $scope.updateTax = function (invoice_line) {
                invoice_line.tax = $scope.taxes_obj[invoice_line.tax_code].percent;

                $scope.calculateTotal(invoice_line)
            };

            $scope.createInvoiceLine = function () {

                $scope.showCreateInvoiceLine = $scope.showCreateInvoiceLine ? false : true;

                if (!$scope.showCreateInvoiceLine) {
                    $scope.new_invoice = {};
                    $scope.invoice.products = null;
                    $scope.provider.products = null;
                    $scope.products = '';
                }
            };

            $scope.addNewInvoiceLine = function () {
                $scope.updateTax($scope.new_invoice);

                let today = new Date();

                let new_line = JSON.parse(JSON.stringify($scope.new_invoice));
                $scope.new_invoice = {};
                
                new_line.qty = 1;
                new_line.start_date = today.getUTCDate() + '/' + (today.getUTCMonth() + 1) + '/' + today.getUTCFullYear()
                new_line.finish_date = today.getUTCDate() + '/' + (today.getUTCMonth() + 1) + '/' + today.getUTCFullYear()
                
                $scope.list.push(new_line);
                $scope.invoice.products = null;
                $scope.showCreateInvoiceLine = false;
                
                $scope.calculateTotal(new_line)
            };

            $scope.deleteInvoiceLine = function (invoice_line) {
                var index = $scope.list.indexOf(invoice_line);
                $scope.list.splice(index, 1);

                var data = get_invoice_total($scope.list);
                data['invoice_lines'] = $scope.list;

                $scope.calculateTotal()
            };

            $scope.duplicateInvoiceLine = function (invoice_line) {
                let index = $scope.list.indexOf(invoice_line);
                let copied_line = JSON.parse(JSON.stringify(invoice_line));

                delete copied_line['id']
                delete copied_line['$$hashKey']
                
                $scope.list.splice(index+1, 0, copied_line);

                $scope.calculateTotal(copied_line)
            };

            var round_lines = function (invoice) {

                invoice.total = 0;
                invoice.tax_applied = 0;
                invoice.tax_base = 0;

                angular.forEach(invoice.invoice_lines, function (value, key) {
                    value.tax_applied = Math.round(((value.total * value.tax) / 100) * 10000) / 10000;
                    value.total_with_tax = Math.round((value.total + value.tax_applied) * 10000) / 10000;

                    invoice.tax_base += Math.round(value.total * 100) / 100;
                    invoice.tax_applied += value.tax_applied;
                });
                invoice.tax_applied = Math.round((invoice.tax_applied) * 10000) / 10000;
                invoice.tax_base = Math.round(invoice.tax_base * 100) / 100;
                var tax_applied_rounded = (Math.round(invoice.tax_applied * 100) / 100);
                invoice.total = Math.round((invoice.tax_base + tax_applied_rounded) * 100) / 100;
            };

            var get_invoice_total = function (invoice) {

                var total = 0;
                var tax_total = 0;
                var base_total = 0;


                angular.forEach(invoice, function (value, key) {
                    value.tax_applied = (value.total * value.tax) / 100;
                    tax_total += value.tax_applied;
                    base_total = Math.round((base_total + value.total) * 100) / 100;
                    value.total_with_tax = (value.total + value.tax_applied);

                    total += value.total_with_tax;
                });

                return {
                    total: total.toFixed(2),
                    base_total: base_total.toFixed(2),
                    tax_total: tax_total.toFixed(2)
                }
            };

            $scope.showDeleteOptions = function (item, value) {

                if (value) {
                    $scope.lineToDelete = item;
                } else {
                    $scope.lineToDelete = false;
                }
            };

            $scope.isToDelete = function (item) {
                return $scope.lineToDelete == item;
            };

            $scope.hasPermissions = function () {
                return UserInfo.hasPerm($scope.permission);
            };
        },
        link: function (scope, element, attrs) {
            if (scope.invoiceProducts) {

                scope.$watch('invoice.products', function (newValue, oldValue) {
                    if (newValue != null) {
                        scope.new_invoice.qty = 1;
                        scope.new_invoice.type = newValue.code;
                        scope.new_invoice.text = newValue.description;
                        scope.new_invoice.unit_price = newValue.price;

                        scope.new_invoice.tax = newValue.tax_percent;
                        scope.new_invoice.tax_code = newValue.tax_code;

                        scope.new_invoice.total = scope.new_invoice.unit_price * scope.new_invoice.qty;
                    }
                }, true);

                scope.$watch('new_invoice', function (newValue, oldValue) {

                    if (newValue.type != null && oldValue.type != null) {
                        if (newValue.type != oldValue.type) {
                            scope.products = newValue.type;
                            scope.new_invoice = {};
                            scope.invoice.products = null;
                        }

                    }
                }, true);
            }

            scope.$watch('provider.products', function (newValue, oldValue) {

                if (newValue != null) {
                    scope.new_invoice.qty = 1;
                    scope.new_invoice.type = newValue.code;
                    scope.new_invoice.text = newValue.description;
                }
            }, true);

            scope.$watch('new_tax', function (newValue, oldValue) {

                if (newValue != null) {
                    var tax_rule = JSON.parse(newValue);

                    scope.new_invoice.tax = tax_rule.percent;
                    scope.new_invoice.tax_rule_pk = parseInt(tax_rule.id);
                    scope.new_invoice.tax_code = tax_rule.code;
                }
            }, true);
        }
    }
}

export { invoicesLines }
