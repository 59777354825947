function providerInvoiceDeletion(UserInfo, Alert, filtersUtils, ProviderInvoiceAPI) {
    return {
        restrict: 'AE',
        templateUrl: require('../../templates/delete-button.html'),
        controller: function ($scope, $rootScope, $filter) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var self = this;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.hasPerm = UserInfo.hasPerm('providers.delete_providerinvoice');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToDelete = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var json = {ids: ids};

                ProviderInvoiceAPI.deleteProviderInvoices(json).then(function (data) {
                    $rootScope.$broadcast('refreshList');
                    if(data.not_deleted == 0)
                        Alert.createAlert('success', $translate('PROVIDERS.DELETED_PROVIDER_INVOICES') + data.deleted_number + $translate('PROVIDERS.PROVIDER_INVOICES'), true);
                    else
                        Alert.createAlert('success', $translate('PROVIDERS.DELETED_PROVIDER_INVOICES') + data.deleted_number + $translate('PROVIDERS.PROVIDER_INVOICES') + data.not_deleted + $translate('PROVIDERS.NOT_DELETED_PROVIDER_INVOICES'), true);
                }).catch(function (error) {
                    $scope.error_message = error.data.__all__[0];
                }).finally(function () {
                    $scope.resetDeleteForm();
                });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.resetDeleteForm = function () {
                $scope.flagConfirm = false;
                $scope.listToDelete = [];
            };

            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
            };

            $scope.clickYes = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToDelete);
                performRequest(ids);
            };

            /***********************************************************************************************************
            * Controller Initialization
            ***********************************************************************************************************/
            $scope.resetDeleteForm();

        }
    }
}

export { providerInvoiceDeletion }
