//import { io } from "socket.io-client";

const io = require('socket.io-client')


function notificationCenter(notificationCenterConfig, $log, UserInfo) {
    var self = this;
    this.socket = null;
    this.handlers = notificationCenterConfig.DEFAULT_HANDLERS;

    // TODO we should separate the notificationCenter from the WebSocket
    this.setupWebSocket = function () {
        if (this.socket != null || !notificationCenterConfig.CONNECT_TO_WS) return;
        
        var socket = io(notificationCenterConfig.SOCKET_ADDR + ':' + notificationCenterConfig.SOCKET_PORT, {
            reconnectionAttempts: 2,
            reconnectionDelay: 30000,
            reconnectionDelayMax: 30000,
            transports: ['websocket']
        });
        socket.on('connect', function () {
            $log.info('Websocket opened');
            socket.emit('authenticate', {'token': UserInfo.userInfo.jwt});
        });

        socket.on("unauthorized", function (error) {
            if (error.data.type == "UnauthorizedError" || error.data.code == "invalid_token") {
                setTimeout(function () {
                    socket.emit('authenticate', {'token': UserInfo.userInfo.jwt});
                }, 500);
            }
        });

        socket.on('disconnect', function () {
            this.socket = null;
            $log.info('Websocket closed');
        });

        socket.on('message', function (data) {
            $log.info('Message received', data);

            try {
                self.publish(data.type, data.data);
            } catch (e) {
                $log.error("Notification data incorrect JSON format", e.message);
            }
        });

        socket.on('connect_error', function (data) {
            $log.info('connect_error', data);
        });
    };

    this.setupWebSocket();

    // handlers and subscribers
    this.subscribe = function (notificationType, subscriber) {
        var list = this.handlers[notificationType] || [];
        list.push(subscriber);
        self.handlers[notificationType] = list;
    };
    this.unsubscribe = function (subscriber) {
        for (var key in self.handlers) {
            for (var i = 0; i < self.handlers[key].length; i++) {
                var index = self.handlers[key].indexOf(subscriber);
                if (index > -1) {
                    self.handlers[key].splice(index, 1);
                }
            }
        }
    };
    this.publish = function (notificationType, notificationData) {
        var list = this.handlers[notificationType] || [];
        for (var i = 0; i < list.length; i++) {
            var subscriber = list[i];
            try {
                subscriber(notificationType, notificationData);
            } catch (e) {
                $log.error("Notification subscriber crashed", e.message);
                $log.error("Guilty", subscriber);
            }
        }
    };

}

export {notificationCenter}
