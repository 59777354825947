const TaxesListCtrl = ['$scope', 'TaxAPI', 'TaxRuleAPI', 'Alert', '$filter', 'UserInfo', '$location',
    function ($scope, TaxAPI, TaxRuleAPI, Alert, $filter, UserInfo, $location) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.mode = 'list';

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/
        this.createTaxRule = function (tax) {
            var tax_rule = {
                code: tax.code,
                name: tax.name,
                percent: $scope.tax.default,
                tax: tax.id
            };

            TaxRuleAPI.createTaxRule(tax.id, tax_rule).then(function (tax_rule) {
                Alert.createAlert('success', $translate('TAXES.TAX_CREATED'), true);
                $location.path('/taxes/' + tax.id);
            }).catch(function (error) {
                Alert.createAlert('danger', $translate('TAXES.TAX_CREATION_ERROR'), true);
                TaxAPI.deleteTax(tax.id).then(function () {
                }).catch(function (error) {
                });
            });
        };

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.showCreateForm = function () {
            $scope.mode = 'create';
        };

        $scope.hideCreateForm = function () {
            $scope.mode = 'list';
        };

        $scope.createTax = function () {
            var tax = {code: $scope.tax.code, name: $scope.tax.name};

            TaxAPI.createTax(tax).then(function (tax) {
                self.createTaxRule(tax);
            }).catch(function (error) {
                Alert.createAlert('danger', $translate('TAXES.TAX_CREATION_ERROR'), true);
            });
        };

        $scope.canCreate = function () {
            return UserInfo.hasPerm('taxes.add_tax');
        };

    }
]

export { TaxesListCtrl }
