const CalendarCtrl = ['$scope', '$filter', 'Alert', 'CalendarsMaster', 'CalendarAPI', 'UserInfo',
    function ($scope, $filter, Alert, CalendarsMaster, CalendarAPI, UserInfo) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');

        $scope.CALENDAR_DAYS_FORM = require('../../templates/calendar-days.html')

        var self = this;

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.calendar_select_type = '';
        $scope.creationMode = false;

        $scope.calendar_types = [
            {code: 'GEN', label: $translate('CALENDARS.GENERAL')},
            {code: 'STA', label: $translate('CALENDARS.STATE')},
            {code: 'OTH', label: $translate('CALENDARS.OTHERS')}
        ];
        // Only can create calendar type = OTH
        $scope.calendar_type = 'OTH';

        $scope.action = '';
        $scope.deletionMode = false;
        $scope.deletable_calendar = false;

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        $scope.$watch('calendar', function (newValue, oldValue) {
            // First, Init deletion Mode and action to delete
            $scope.action = '';
            $scope.deletionMode = false;
            $scope.deletable_calendar = false;
            if (newValue){
                $scope.calendar_id = newValue.id;
                $scope.calendar_select_type = newValue.type;
                if ($scope.calendar_select_type == 'OTH')
                    $scope.deletable_calendar = true;
            }
        }, true);

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        function resetForm() {
            $scope.calendar_name = "";
            //$scope.calendar_type = "";
            $scope.calendarForm.$setPristine();
            $scope.calendarForm.$setUntouched();
        }

        function resetDeletionForm() {
            $scope.action = '';
            $scope.deletionMode = false;
            $scope.deletable_calendar = false;
            $scope.calendarForm.$setPristine();
            $scope.calendarForm.$setUntouched();
        }

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.toggleCreationMode = function () {
            $scope.creationMode = !$scope.creationMode;
        };

        $scope.createCalendar = function () {
            var data = {
                "name": $scope.calendar_name,
                "type": $scope.calendar_type
            };

            CalendarAPI.createCalendar(data).then(function (data) {
                Alert.createAlert('success', $translate('CALENDARS.CALENDAR_ADDED'), true);
                $scope.calendars.push(data);
                resetForm();
                $scope.creationMode = false;
            }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
            });
        };

        $scope.deleteCalendar = function ($event) {
            $event.preventDefault();
            $scope.deletionMode = true;
            $scope.action = 'delete';
        };

        $scope.confirmDeleteCalendar = function (id) {
            CalendarAPI.deleteCalendar(id).then(function (data) {
                Alert.createAlert('success', $translate('CALENDARS.CALENDAR_DELETED'), true);
                // Delete element from calendar list
                var index = $.inArray(id, $scope.calendars);
                $scope.calendars.splice(index, 1);
                resetDeletionForm();
            }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
            });
        };

        $scope.cancelDeletedCalendar = function ($event) {
            $event.preventDefault();
            $scope.action = '';
            $scope.deletionMode = false;
        };

        $scope.canCreateCalendar = function () {
            return UserInfo.hasPerm('calendars.add_calendar');
        };

        $scope.canDeleteCalendar = function () {
            return UserInfo.hasPerm('calendars.delete_calendar');
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        CalendarsMaster.loadCalendars().then(function (response){
            $scope.calendars = response;
        });

    }
]

export { CalendarCtrl };
