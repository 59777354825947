const atrComplaintService = [
    '$http', '$q', function ($http, $q) {
        var settings = {
            apiUrl: API_URL + '/api/1.0/masters/complaints-subtypes/'
        };

        function getSubtypesComplaints(type) {
            var deferred = $q.defer();

            $http.get(settings.apiUrl + '?type=' + type)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function sendATRComplaint(complaint) {
            var deferred = $q.defer();

            $http.post(settings.apiUrl, complaint)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        return {
            getSubtypesComplaints: getSubtypesComplaints,
            sendATRComplaint: sendATRComplaint
        };

    }
]

export {atrComplaintService}
