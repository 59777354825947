function invoiceRefund(UserInfo) {
    return {
        restrict: 'AE',
        templateUrl: require('../../templates/refund-button.html'),
        controller: function ($scope, $http, $rootScope, $routeParams, Alert, $filter, $location) {
            var $translate = $filter('translate');

            var api = API_URL + '/api/1.0/invoices/' + $routeParams.id + '/refund/';

            $scope.flagConfirm = false;
            $scope.hasPerm = UserInfo.hasPerm('invoices.refund_invoice');

            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            }

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
            }

            $scope.clickYes = function () {
                var config = {
                    method: "POST",
                    url: api,
                    headers: {"Content-Type": "application/json;charset=utf-8"}
                };
                $http(config)
                    .then(function (data, status, headers, config) {
                        $location.path('/invoices/' + data.data.id);
                    }, function (data, status, headers, config) {
                        Alert.createAlert('danger', $translate('INVOICES.REFUND_ERROR'), true);
                    })

                    /*
                    ,finally(function () {
                        $scope.flagConfirm = false;
                    });
                    */
            }
        }
    }
}

export { invoiceRefund }
