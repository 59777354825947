{
  "BANKS": {
    "PORTFOLIO": "Portfolio",
    "REMITTANCES": "Remittances",
    "REMITTANCE": "Remittance",
    "BANK": "Bank",
    "ANY": "Any Bank",
    "ANY_RULE": "Any rule",
    "FROM": "From",
    "TO": "To",
    "TOTAL": "Total",
    "NEW_REMITTANCE": "New remittance",
    "GENERATE": "Generate",
    "INVOICES_TO_REMIT": "Invoices to remit",
    "REMITTING": "Remitting",
    "DOWNLOAD": "Download",
    "SELECT_BANK": "Select bank",
    "EXTRA_PAYMENT": "Extra payment",
    "RECEIPTS": "Receipts",
    "PAID": "Paid",
    "RETURNED": "Returned",
    "REJECTED": "Rejected",
    "SEARCH_RECEIPT": "Search...",
    "RECEIPT_NUMBER": "Nº Receipt",
    "NO_INVOICE": "Nº Invoice",
    "NO_REMITTANCE": "Nº Remittance",
    "ALL": "All",
    "STATUS": "Status",
    "ANY_STATUS": "Cualquiera",
    "CREATION_DATE": "Creation date",
    "UNPAID_DATE": "Unpaid date",
    "DATE_RANGE": "Date range",
    "WITHOUT_FILTER": "Without filters",
    "DATE_FROM": "From",
    "DATE_TO": "To",
    "DELETE": "Delete",
    "YES": "Yes",
    "NO": "No",
    "DELETE_RECEIPT": "Delete Receipt?",
    "XML_SEPA": "N19 SEPA",
    "XML_SEPA_FIRST": "N19 SEPA (FIRST)",
    "XML_SEPA_RECURRENT": "N19 SEPA (REC)",
    "XML_SEPA_FSDD": "N58 SEPA",
    "XML_SEPA_FSDD_FIRST": "N58 SEPA (FIRST)",
    "XML_SEPA_FSDD_RECURRENT": "N58 SEPA (REC)",
    "VIEW": "View",
    "REMITTANCE_DATA": "Remittance data",
    "REMITTANCE_DATE": "Remittance date",
    "COMPANY_NAME": "Company name",
    "COMPANY_ID": "Company id",
    "COMPANY_ADDRESS": "Company address",
    "COMPANY_COUNTRY": "Company country",
    "VALUE_DATE": "Value date",
    "CREDIT_LIMIT": "Credit limit",
    "SUFFIX": "SEPA Suffix",
    "DATE": "Date",
    "REMITTANCE_RECEIPTS": "Remittance Receipts",
    "DOWNLOAD_RECEIPTS": "Download",
    "DELETE_REMITTANCE": "Delete remittance",
    "ACTIONS": "Actions",
    "DELETEQ": "Delete remittance?",
    "DELETED_REMITTANCE_OK": "Remittance deleted successfully.",
    "RETURN": "Return",
    "RETURN_RECEIPT": "Return receipt?",
    "PAY_RECEIPT": "Pay receipt?",
    "ACCOUNT": "Account",
    "N58_TEXT": "N58 (text)",
    "BOOK34": "N34",
    "MINIMUM": "Minimum",
    "MAXIMUM": "Maximum",
    "REASON": "Reason",
    "AC01": "Invalid IBAN",
    "AC04": "Cancelled Account",
    "AC06": "Blocked Account",
    "AC13": "Debtor's Account is a consumer account",
    "AG01": "Account does not support direct debits",
    "AG02": "Invalid operation code",
    "AM04": "Insufficient balance",
    "AM05": "Duplicate operation",
    "BE01": "Holder charge account does not match with debtor",
    "BE05": "Wrong creditor identifier",
    "CNOR": "The beneficiary's institution is not registered",
    "DNOR": "The originator's institution is not registered",
    "FF01": "Invalid format",
    "MD01": "Invalid or nonexistent mandate",
    "MD02": "Mandate data is missing or incorrect",
    "MD07": "Deceased debtor",
    "MS02": "Reason not specified by the customer (debtor warrant)",
    "MS03": "Reason not specified by the institution of the debtor",
    "RC01": "Invalid Entity identifier (invalid BIC)",
    "RR01": "Lack identification or regulatory reasons debtor's account",
    "RR02": "Missing name or address of the debtor regulatory reasons",
    "RR03": "Lack creditor name or address regulatory reasons",
    "RR04": "Regulatory reasons",
    "SL01": "Specific services offered by the institution of the debtor",
    "CREDIT_RETURNED_CUSTOMER": "Credit returned by the customer",
    "REFUND": "Refund",
    "DELETING_REMITTANCE": "Deleting remittance",
    "IT_EXISTS": "It already exists",
    "ANOTHER_INVOICE": "another invoice",
    "WITH_SAME_IDN": "with same IDN and should be changed.",
    "IDN": "IDN",
    "INVOICE": "Invoice",
    "INV": "Inv",
    "SAVE": "Save",
    "PAY": "Pay",
    "UPDATED_REMITTANCE_OK": "Remittance updated successfully.",
    "SECONDARY_SEPA": "Secondary sepa ID",
    "GENERATE_REMIT": "Generate remit",
    "INVOICES": "Invoices",
    "NEW_REMITTANCE_DEFERMENTS": "New remittance of receipts",
    "NO_RECEIPT": "No. Receipt",
    "REMIT_DATE": "Remit date",
    "UPDATE_EXTRA_EXPENSES_OK": "Extra expenses successfully updated",
    "UPDATE_EXTRA_EXPENSES_KO": "Error during updated extra expenses",
    "DEFERMENT": "Deferment",
    "ENTITY": "Entity",
    "BANK_UPDATED": "Bank data updated successfully.",
    "BANK_UPDATED_ERROR": "Error updating bank data.",
    "NEW_BANK": "New bank",
    "CREATE_BANK": "Bank created successfully.",
    "CREATE_BANK_ERROR": "Error creating bank.",
    "DELETE_BANK": "Delete bank?",
    "DELETE_BANK_OK": "Bank deleted successfully.",
    "DELETE_BANK_ERROR": "Error deleting bank.",
    "CREATING_REMITTANCE": "Creating remittance",
    "MANUAL": "Manual",
    "NORMAL": "Normal",
    "REFUND_REMIT": "Refund",
    "REFUND_B2B_REMIT": "Refund B2B",
    "REMITTANCE_TYPE": "Remittance type",
    "SELECT_TYPE": "Select type",
    "TYPE": "Type",
    "Date range": "Date range",
    "Date from": "Date from",
    "Date to": "Date to",
    "Without Filter": "Without Filter",
    "Creation date range": "Creation date",
    "Remit date": "Remit date",
    "Register date": "Register date",
    "LOCK": "Lock",
    "UNLOCK": "Unlock",
    "REMITTANCE_LOCKED": "Remittances locked successfully",
    "REMITTANCE_UNLOCKED": "Remittances unlocked successfully",
    "RULE": "Rule",
    "XML_SEPA_CORE": "N19 SEPA CORE",
    "XML_SEPA_FSDD_CORE": "N58 SEPA CORE",
    "XML_SEPA_FSDD_FIRST_CORE": "N58 SEPA CORE (FIRST)",
    "XML_SEPA_FSDD_RECURRENT_CORE": "N58 SEPA CORE (REC)",
    "FIRST": "FIRST",
    "RCUR": "RCUR",
    "FIRST_RECEIPT": "Is first receipt",
    "FACTORING": "Factoring",
    "PAYMENT_DEADLINE": "Payment deadline days",
    "NOT_DEBITED": "Not debited",
    "XML_SEPA_CORE_REFACTOR": "N19 SEPA CORE REFACTOR",
    "BOOK34_REFACTOR": "N34 REFACTOR",
    "DEBIT_METHOD": "Debit method",
    "B2B_REMITTANCE": "B2B Remittance",
    "XML_SEPA_B2B": "N19 SEPA B2B",
    "XML_SEPA_B2B_REFACTOR": "N19 SEPA B2B REFACTOR",
    "XML_SEPA_B2B_IBERCAJA": "N19 SEPA B2B IBERCAJA",
    "Bulk download CSV": "Bulk download CSV",
    "Bulk download generation Task error": "Bulk download generation Task error",
    "MAN1": "Non existent account or customer",
    "SEPA": "SEPA",
    "SELECT_RECEIPTS": "SELECT",
    "GENERATE_BY_AMOUNT": "GENERATE BY AMOUNT",
    "GENERATE_BY_AMOUNT_HELP_TEXT": "Type the amount of remmit to select invoices",
    "RECONCILED": "Reconciled",
    "RECOVER": "Recover",
    "ANY_TYPE":"Any",
    "XML_SEPA_FIRST_CORE": "N19 SEPA CORE(FIRST)",
    "XML_SEPA_RECURRENT_CORE": "N19 SEPA CORE(REC)",
    "IS_FOREIGN_ACCOUNT": "Is foreign account",
    "XML_SEPA_B2B_FIRST": "N19 SEPA B2B (FIRST)",
    "XML_SEPA_B2B_RCUR": "N19 SEPA B2B (RCUR)"
  }
}
