// TODO: filter files to upload by mimetype or extension
// TODO: add a setup variable to set the API PREFIX
// TODO: split API_URL in two separate vars POST_API_URL and DELETE_API_URL
// TODO: compound the main progress bar with different colors depending of the success/error uploaded files


// Directives

import { attachedFiles } from './directives/attachedFiles';
import { chooseFiles } from './directives/chooseFiles';
import { fileUploader } from './directives/fileUploader';

// Services
import { fileService } from './services/fileService';

const files = angular.module('files', ['pascalprecht.translate', 'ngCookies']);

files.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])


// Constants
files.constant('FILE_TO_UPLOAD', 'file to upload');
files.constant('FILE_UPLOADING', 'uploading file');
files.constant('FILE_UPLOADED', 'file uploaded');
files.constant('FILE_ERROR', 'file error');
files.constant('FILE_CANCELED', 'file canceled');
files.constant('FILE_DELETING', 'deleting file');
files.constant('FILE_DELETED', 'file deleted');

// Module Config Provider
files.provider('filesConfig',
    function () {
        this.DEFAULT_PARALLEL_UPLOADS = 1;
        this.API_URL = API_URL + '/api/1.0/files/';
        this.BASE_API_URL = API_URL + '/api/1.0/files/';
        //this.TEMPLATES_PREFIX = '/static/files/templates/';
        //this.UPLOADER_TEMPLATE = 'uploader.html';
        //this.ATTACH_LIST_TEMPLATE = 'attachedfileslist.html';
        this.DELETE_ATTACHED_API = API_URL + '/api/1.0/attachedfile/';
        this.FILE_SHARED_URL = 'http://apps.watium.es/share/sso/';
        this.FILE_SELF_DELETION_TIME = 36;


        this.$get = function ($q, $http, $cookies) {
            var self = this;
            return {
                API_URL: self.API_URL,
                BASE_API_URL: self.BASE_API_URL,
                //TEMPLATES_PREFIX: self.TEMPLATES_PREFIX,
                UPLOADER_TEMPLATE: self.UPLOADER_TEMPLATE,
                DEFAULT_PARALLEL_UPLOADS: self.DEFAULT_PARALLEL_UPLOADS,
                //ATTACH_LIST_TEMPLATE: self.ATTACH_LIST_TEMPLATE,
                DELETE_ATTACHED_API: self.DELETE_ATTACHED_API,
                FILE_SELF_DELETION_TIME: self.FILE_SELF_DELETION_TIME,
                FILE_SHARED_URL: self.FILE_SHARED_URL
            }
        };
    }
)

files
    .service('fileService', fileService)
    .directive('fileUploader', fileUploader)
    .directive('chooseFiles', chooseFiles)
    .directive('attachedFiles', attachedFiles)

export { files };
