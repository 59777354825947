const ProvidersCtrl = ['$scope', '$filter', 'UserInfo', 'Alert', 'Task','filtersUtils',
    function ($scope, $filter, UserInfo, Alert, Task, filtersUtils) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        $scope.PROVIDERS_LIST_DOWNLOAD_DROPDOWN = require('../../templates/download-providers-dropdown.html')

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.itemsToDownload = [];
        $scope.backgroundTasks = [];

        $scope.searchBy = 'search';
        $scope.searchByLabel = $translate('PROVIDERS.ALL');
        $scope.searchFilters = {
            'id': $translate('PROVIDERS.NO_CLIENT'),
            'vat_id': $translate('PROVIDERS.NIF/CIF'),
            'search': $translate('PROVIDERS.ALL')
        };
        $scope.searchBy = Object.keys($scope.searchFilters)[0];
        $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];
        $scope.ordering = {
            "id": $translate('PROVIDERS.NO_CLIENT'),
            "name": $translate('PROVIDERS.NAME'),
            "vat_id": $translate('PROVIDERS.NIF/CIF'),
            "state": $translate('PROVIDERS.STATE')
        };
        $scope.filters = {
            search:"",
            id: "",
            state: null,
            vat_id: ""
        };

        $scope.action = "detail";
        $scope.mode = 'list';

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        // Watchers
        $scope.$watch('searchQuery', function(newVal, oldVal){
            $scope.filters[$scope.searchBy] = newVal;
        });

        $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
            $scope.counter = selectedCounterChange;
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/
        this.getJSONParams = function (elements) {
            var params = {};
            params.ids = filtersUtils.getItemsToDownloadToInt(elements);
            return params;
        };

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.setSearchBy = function (val) {
            $scope.filters[$scope.searchBy] = '';
            $scope.searchBy = val;
            $scope.searchByLabel = $scope.searchFilters[val];
            $scope.filters[val] = $scope.searchQuery;
        };

        $scope.showCreateForm = function() {
            $scope.mode = 'create';
        };

        $scope.cancelCreateProvider = function() {
            $scope.mode = 'list';
        };

        $scope.canCreateProvider = function() {
            return UserInfo.hasPerm('providers.add_provider');
        };

        $scope.canDownloadProviders = function() {
            return UserInfo.hasPerm('providers.retrieve_provider');
        };

        $scope.canDownloadCSV = function() {
            return $scope.canDownloadProviders();
        };

        $scope.downloadCSV = function () {
            if ($scope.counter > 0) {
                var task = new Task();
                task.type = 'PDWN';
                task.params = self.getJSONParams($scope.itemsToDownload);
                task.start()
                    .then(function (data, status, headers, config) {
                        Alert.createAlert('success', $translate('PROVIDERS.Bulk download CSV'), true);
                        $scope.backgroundTasks.push(data.data.id);
                    })
                    .catch(function (data, status, headers, config) {
                        Alert.createAlert('danger', $translate('PROVIDERS.Bulk download generation Task error'), true);
                    });
            }
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
    }
]

export { ProvidersCtrl }
