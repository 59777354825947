function typeaheadKf($timeout, $document) {
    return {
        restrict: 'AEC',
        require: '^form',
        templateUrl: require('../../templates/typeahead.html'),
        scope: {
            results: '=ngModel',
            listTypeahead: '=?',
            current: '@url',
            items: '=',
            prompt:'@',
            title: '@',
            subtitle:'@',
            name:'@',
            ngRequired: '@',
            ngDisabled: '=',
            model: '=',
            modelValue: '=?',
            onSelect:'&',
            nonValidation: '=?',
            extraParams: '@',
            searchBy: '@'
        },
        link: function (scope, element, attrs, ctrl) {
            ctrl[scope.name].$validators.selected = function(modelValue, viewValue) {
                scope.model = viewValue;
                if (!ctrl[scope.name].$isEmpty(modelValue)) {
                    if (scope.selected)
                        return true;
                    else {
                        if (modelValue != "") {
                            for (var i in scope.items) {

                                if (scope.subtitle && scope.title) {
                                    if (modelValue == scope.items[i][scope.subtitle])
                                        return true;
                                }
                                else if (scope.subtitle) {
                                    if (modelValue == scope.items[i][scope.subtitle])
                                        return true;
                                }
                                else if (scope.title) {
                                    if (modelValue == scope.items[i][scope.title])
                                        return true;
                                }
                            }
                        }
                        return false;
                    }
                }
                return true;
            };

            ctrl[scope.name].$validators.required = function(modelValue, viewValue) {
                scope.model = viewValue;
                if (ctrl[scope.name].$isEmpty(modelValue) && !scope.ngRequired)
                    return true;
                else if (ctrl[scope.name].$isEmpty(modelValue))
                    return false;
                else
                    return true;
            };

            scope.form = ctrl;
        },
        controller: function ($q, $scope, $http, dataFactory) {
            var self = this;

            $scope.query_params = {};
            $scope.index_current=0;
            $scope.selected=true;
            $scope.filtered = null;

            $scope.listTypeahead = function() {
                self.loadData();
            };

            this.loadData = function (val) {
                if ($scope.items === undefined) {
                    dataFactory.get($scope.current).then(function(data){
                        $scope.items=data;
                    });
                }
            };

            $scope.$watch('model', function(newvalue) {
                if ($scope.form && !$scope.selected) {
                    $scope.model = $scope.form[$scope.name].$viewValue;
                }

                if (newvalue !== undefined && $scope.current !== undefined) {
                    let extra_params = $scope.extraParams ? $scope.extraParams : '';
                    let search_by = $scope.searchBy ? $scope.searchBy : 'search';

                    dataFactory.get($scope.current + '?' + search_by + '=' + newvalue + extra_params).then(function(data){
                        $scope.items=data.results;
                    });
                }
            });


            $scope.handleSelection=function(selectedItem){
                if (selectedItem !== undefined) {
                    if ($scope.subtitle && $scope.title)
                        $scope.model = selectedItem[$scope.subtitle] + ' - ' + selectedItem[$scope.title];
                    else if ($scope.subtitle)
                        $scope.model = selectedItem[$scope.subtitle];
                    else if ($scope.title)
                        $scope.model = selectedItem[$scope.title];
                }

                $scope.modelValue = selectedItem;

                $scope.form[$scope.name].$setValidity('selected');
                $scope.index_current = 0;
                $scope.selected = true;
                $timeout(function () {
                    $scope.onSelect();
                }, 200);
            };

            $scope.isCurrent=function(index){
                return $scope.index_current==index;
            };
            $scope.setCurrent=function(index){
                $scope.index_current=index;
            };

            $document.on("keydown", function($event){
                if ($event.target.name == $scope.name && $scope.items && ($scope.items.length > 0 || $scope.nonValidation)) {
                    switch ($event.keyCode) {
                        case 40: // down
                            $event.preventDefault();
                            $event.stopPropagation();
                            $scope.$apply(function(){
                                $scope.index_current++;
                            });
                            break;
                        case 38: // up
                            $event.preventDefault();
                            $event.stopPropagation();
                            $scope.$apply(function(){
                                if ($scope.index_current > 0)
                                    $scope.index_current--;
                            });
                            break;
                        case 27: // esc
                            $event.preventDefault();
                            $event.stopPropagation();
                            $scope.$apply(function(){
                                $scope.model = "";
                            });
                            break;
                        case 13: // enter
                            $event.preventDefault();
                            $event.stopPropagation();
                            $scope.$apply(function(){
                                $scope.handleSelection($scope.filtered[$scope.index_current]);
                            });
                            break;
                        case 9: // tab
                            $event.stopPropagation();
                            $scope.$apply(function(){
                                $scope.handleSelection($scope.filtered[$scope.index_current]);
                            });
                            break;

                    }
                }
            });

            $document.on("click", function($event){
                $scope.$apply(function(){
                    $scope.selected = true;
                });
            });
        }
    }
}


export { typeaheadKf }

/* EJEMPLO CÓDIGO A INCLUIR
<div>
    <typeahead-kf url="{{API_URL}}/api/1.0/masters/cnaes/" ng-model="items" model-value="object_selected" items="items" prompt="Cnae" title="name" subtitle="code" model="name" on-select="onItemSelected()" list-typeahead="listTypeahead"/>
</div>*/
