const ConsumptionReport = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var double_report_url = API_URL + '/api/1.0/consumption-double-report/';
        var single_report_url = API_URL + '/api/1.0/consumption-single-report/';

        var self = this;


        function getConsumptionDoubleReport(params) {
            return _getConsumptionDoubleReport(params);
        }

        function _getConsumptionDoubleReport(params) {
            var deferred = $q.defer();

            $http.get(double_report_url + params)
                .then(function (report) {
                    deferred.resolve(report.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            self.cups = cups; // eslint-disable-line no-undef
            return deferred.promise;
        }

        function getConsumptionSingleReport(params) {
            return _getConsumptionSingleReport(params)
        }

        function _getConsumptionSingleReport(params) {

            var deferred = $q.defer();

            $http.get(single_report_url + params)
                .then(function (report) {
                    deferred.resolve(report.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });

            self.cups = cups; // eslint-disable-line no-undef
            return deferred.promise;
        }

        return {
            getConsumptionDoubleReport: getConsumptionDoubleReport,
            getConsumptionSingleReport: getConsumptionSingleReport
        };
    }
]

export {ConsumptionReport};
