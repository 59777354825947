// Controllers
import { DeleteTaxCtrl } from './controllers/DeleteTaxCtrl'
import { TaxDetailCtrl } from './controllers/TaxDetailCtrl'
import { TaxesListCtrl } from './controllers/TaxesListCtrl'
import { TaxRuleDetailCtrl } from './controllers/TaxRuleDetailCtrl'

// Services
import { TaxAPI } from './services/TaxAPI'
import { TaxRuleAPI } from './services/TaxRuleAPI'

const taxes = angular.module('taxes', ['pascalprecht.translate', ])

taxes.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

taxes
    .controller('DeleteTaxCtrl', DeleteTaxCtrl)
    .controller('TaxDetailCtrl', TaxDetailCtrl)
    .controller('TaxesListCtrl', TaxesListCtrl)
    .controller('TaxRuleDetailCtrl', TaxRuleDetailCtrl)
    .service('TaxAPI', TaxAPI)
    .service('TaxRuleAPI', TaxRuleAPI)

export { taxes }
