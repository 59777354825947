const TaskController = ['$scope', 'notificationCenter', 'Task', '$cookies', '$window',
    function($scope, notificationCenter, Task, $cookies, $window) {
        $scope.tasks = [];
        $scope.results = [];

        $scope.revertTask = function(task) {
            let taskModel = new Task(task);
            taskModel.revert()
                .then(function(data, status, headers, config) {
                    $scope.updateTask(data.data);
                })
                .catch(function(data, status, headers, config) {
                    // TODO Better errors control
                });
        };

        $scope.updateTask = function(updated_task) {
            for (var i = 0; i < $scope.results.length; i++) {
                var task = $scope.results[i];
                if (task.id == updated_task.id) {
                    $scope.results[i] = updated_task;
                    $scope.results[i].updated = true;
                    break;
                }
            }
        };

        $scope.downloadTaskResult = function(task) {
            var url = API_URL + '/file/download/' + task.file + '?token=' + $cookies.get("token");
            $window.open(url);
            return false;
        };

        $scope.showUndoButton = function(item) {
            var listtypes = ['IVGN', 'GFEE', 'CCPR', 'CCCV', 'CCRC', 'BPRP', 'ISCD','INVC'];
            var resultdata = item.result_data;

            try {
                if (resultdata[0].revertible == true && resultdata[0].code != null) {
                    return item.status == 'DONE' && item.type == resultdata[0].code
                }
            }
            catch (err) {
                //Eliminar cunado se verifique que funciona bien.
                if (item.status == 'DONE') {
                    for (var i = 0; i < listtypes.length; i++) {
                        if (item.type == listtypes[i]) {
                            return item.status == 'DONE' && (item.type == listtypes[i])
                        }
                    }
                }
            }
        };

        notificationCenter.subscribe('BG_TASK_ENDS', function(type, data) {
            var updated_task = data;
            for (var i = 0; i < $scope.tasks.length; i++) {
                var task = $scope.tasks[i];
                if (task.id == updated_task.id) {
                    $scope.$apply(function() {
                        $scope.tasks[i] = updated_task;
                        $scope.tasks[i].updated = true;
                    });
                    break;
                }
            }
        });

    }
]

export { TaskController }
