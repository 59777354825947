const InvoicePaymentDetailCtrl = ['$scope', '$rootScope', 'Invoice', 'UserInfo', 'Alert', '$http', '$filter',
    function ($scope, $rootScope, Invoice, UserInfo, Alert, $http, $filter) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.payment = $scope.result;
        $scope.deleteMode = false;

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.enableDelete = function () {
            $scope.deleteMode = !$scope.deleteMode;
        };

        $scope.canDeletePayment = function () {
            return UserInfo.hasPerm('invoices.delete_payment_invoice');
        };

        $scope.isNumber = function (arg) {
            return !isNaN(arg);
        };

        $scope.deletePayment = function () {
            var url = $scope.current + $scope.payment.id + '/';

            $scope.$emit('loadingList', true)

            $http.delete(url).then(function (data, status, headers, config) {
                Alert.createAlert('success', $translate('INVOICES.DELETE_PAYMENT_OK'), true);
                $scope.$emit('refreshList')
            }).catch(function(data, status, headers, config) {
                Alert.createAlert('danger', data, true);
                $scope.$emit('loadingList', false)
            });
        };
    }
]

export { InvoicePaymentDetailCtrl }