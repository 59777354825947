function CNMCParser(){
    /**
     * Checks if the given data is valid for this parser
     * @param data
     * @returns {boolean}
     */
    this.isValid = function (data) {

        var columns = this.parser(data);

        var valid = columns.length == 29; // parts validation

        if(!columns[0].trim().match("R1-[0-9]{3}")) { // Validate codigo empresa emisora
            valid = false;
        }

        if(!columns[1].trim().substr(0, 20).match("ES[0-9]{16}[A-Z]{2}")) { // Validate CUPS format
            valid = false;
        }

        if(!columns[2].trim().match("[0-9]{5}")) { // Validate ZIP
            valid = false;
        }

        if (valid) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * Parses data and return the built object
     * @param data
     */
    this.parse = function (data) {

        var columns = this.parser(data);

        var supply = {};

        // codigoEmpresaEmisora
        // columns[0].trim()

        supply.cups = columns[1].trim().substr(0, 20);
        supply.nt_code = columns[1].trim().substr(20, 2);

        supply.zip = columns[2].trim();

        supply.address = columns[3].trim();

        supply.city = columns[4].trim();

        // Municipio
        supply.state = '';

        supply.contract_started_on = columns[5].trim();

        // Rate
        switch (columns[6].trim()) {
            // TODO MORE RATES
            case "2.0.A":
                supply.rate = 'T20A';
                break;
            case "2.0.DHA":
                supply.rate = 'T20ADHA';
                break;
            case "2.1.A":
                supply.rate = 'T21A';
                break;
            case "2.1.DHA":
                supply.rate = 'T21DHA';
                break;
            case "3.0.A":
                supply.rate = 'T30A';
                break;
            case "3.1.A":
                supply.rate = 'T31A';
                break;
            case "3.1.DHA":
                supply.rate = 'T31DHA';
                break;
            case "6.1":
                supply.rate = 'T61';
                break;
            case "6.1A":
                supply.rate = 'T61A';
                break;
            case "6.2":
                supply.rate = 'T62';
                break;
            case "6.3":
                supply.rate = 'T63';
                break;
            case "6.4":
                supply.rate = 'T64';
                break;
            case "6.5":
                supply.rate = 'T65';
                break;
        }

        supply.voltage = columns[7].trim();

        // potenciaMaximaBIEEnW
        supply.max_authorised_power_bie = columns[8].trim();

        // potenciaMaximaAPMEnW
        supply.max_authorised_power_starting = columns[9].trim();

        // clasificacionPS
        supply.ps_classification = columns[10].trim();

        // disponibilidadICP
        supply.icp_installed = columns[11].trim();

        // tipoPerfilConsumo
        supply.consumption_profile_type = columns[12].trim();

        // valorDerechosExtensionEnW
        supply.extension_rights_reconigzed = columns[13].trim();

        // valorDerechosAccesoEnW
        supply.access_rights_recognized = columns[14].trim();

        // propiedadEquipoMedida
        supply.owns_measurement_device = columns[15].trim();

        // propiedadICP
        supply.owns_icp = columns[16].trim();

        // potenciasContratadasEnW
        var powers =  columns[17].split(';');

        supply.power_p1 = supply.power_p2 = supply.power_p3 = supply.power_p4 = supply.power_p5 = supply.power_p6 = supply.power_p7 = supply.power_p8 = supply.power_p9 = supply.power_p10 = 0;

        switch (supply.rate) {
            case "T20A":
                supply.power_p2 = powers[0];
                break;
            case "T20ADHA":
                supply.power_p1 = powers[0];
                supply.power_p3 = powers[1];
                break;
            case "T21A":
                supply.power_p2 = powers[0];
                break;
            case "T21DHA":
                supply.power_p1 = powers[0];
                supply.power_p3 = powers[1];
                break;
            case "T30A":
                supply.power_p1 = powers[0];
                supply.power_p2 = powers[1];
                supply.power_p3 = powers[2];
                break;
            case "T31A":
                supply.power_p1 = powers[0];
                supply.power_p2 = powers[1];
                supply.power_p3 = powers[2];
                break;
            case "T31DHA":
                supply.power_p1 = powers[0];
                supply.power_p2 = powers[1];
                supply.power_p3 = powers[2];
                break;
            case "T61":
                supply.power_p1 = powers[0];
                supply.power_p2 = powers[1];
                supply.power_p3 = powers[2];
                supply.power_p4 = powers[3];
                supply.power_p5 = powers[4];
                supply.power_p6 = powers[5];
                break;
            case "T61A":
                supply.power_p1 = powers[0];
                supply.power_p2 = powers[1];
                supply.power_p3 = powers[2];
                supply.power_p4 = powers[3];
                supply.power_p5 = powers[4];
                supply.power_p6 = powers[5];
                break;
            case "T62":
                supply.power_p1 = powers[0];
                supply.power_p2 = powers[1];
                supply.power_p3 = powers[2];
                supply.power_p4 = powers[3];
                supply.power_p5 = powers[4];
                supply.power_p6 = powers[5];
                break;
            case "T63":
                supply.power_p1 = powers[0];
                supply.power_p2 = powers[1];
                supply.power_p3 = powers[2];
                supply.power_p4 = powers[3];
                supply.power_p5 = powers[4];
                supply.power_p6 = powers[5];
                break;
            case "T64":
                supply.power_p1 = powers[0];
                supply.power_p2 = powers[1];
                supply.power_p3 = powers[2];
                supply.power_p4 = powers[3];
                supply.power_p5 = powers[4];
                supply.power_p6 = powers[5];
                break;
            case "T65":
                supply.power_p1 = powers[0];
                supply.power_p2 = powers[1];
                supply.power_p3 = powers[2];
                supply.power_p4 = powers[3];
                supply.power_p5 = powers[4];
                supply.power_p6 = powers[5];
                break;
        }

        // fechaUltimoMovimientoContrato
        supply.last_trading_change_on = columns[18].trim();

        // fechaUltimoCambioComercializador
        supply.last_recruitment_change_on = columns[19].trim();

        // fechaLimiteDerechosReconocidos
        supply.extensions_rights_deadline_on = columns[20].trim();

        // fechaUltimaLectura
        supply.last_reading_on = columns[21].trim();

        // informacionImpagos
        supply.non_payment = columns[22].trim();

        // importeDepositoGarantia
        supply.deposit_warranty = columns[23].trim();

        // tipoIdTitular
        supply.vat_id_type = columns[24].trim();

        // idTitular
        supply.vat_id = columns[25].trim();

        // nombreCompletoTitular
        supply.name = columns[26].trim();

        // direccionCompletaTitular
        supply.owner_address = columns[27].trim();

        // esViviendaHabitual
        supply.usual_housing = columns[28].trim();

        return supply;
    };

    this.parser = function (strData, strDelimiter) {

        strDelimiter = (strDelimiter || ",");

        // Create a regular expression to parse the CSV values.
        var objPattern = new RegExp(
            (
                // Delimiters.
            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

                // Quoted fields.
            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

                // Standard fields.
            "([^\"\\" + strDelimiter + "\\r\\n]*))"
            ),
            "gi"
        );

        var arrData = [[]];

        // Create an array to hold our individual pattern
        // matching groups.
        var arrMatches = null;

        // Keep looping over the regular expression matches
        // until we can no longer find a match.
        while (arrMatches = objPattern.exec(strData)) {

            var strMatchedDelimiter = arrMatches[1];

            // Check to see if the given delimiter has a length
            // (is not the start of string) and if it matches
            // field delimiter. If id does not, then we know
            // that this delimiter is a row delimiter.
            if (
                strMatchedDelimiter.length &&
                (strMatchedDelimiter != strDelimiter)
            ) {
                arrData.push([]);
            }

            // Now that we have our delimiter out of the way,
            // let's check to see which kind of value we
            // captured (quoted or unquoted).
            if (arrMatches[2]) {

                // We found a quoted value. When we capture
                // this value, unescape any double quotes.
                var strMatchedValue = arrMatches[2].replace(
                    new RegExp("\"\"", "g"),
                    "\""
                );
            } else {
                // We found a non-quoted value.
                var strMatchedValue = arrMatches[3];
            }

            if(strMatchedValue == undefined)
                strMatchedValue = "";

            arrData[arrData.length - 1].push(strMatchedValue);
        }
        return ( arrData[0] );
    }

}

export {CNMCParser};
