const  ReadingsCtrl = ['$scope', '$sce', '$routeParams', 'Alert', 'UserInfo', '$filter', 'DateUtils', '$q', '$location', 'SupplyAPI', 'ElectricityMeter',
    function ($scope, $sce, $routeParams, Alert, UserInfo, $filter, DateUtils, $q, $location, SupplyAPI, ElectricityMeter) {

        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');

        $scope.SUPPLY_ADD_READING = $sce.trustAsHtml($translate(require('../../templates/add-reading.html')))

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.delete = false;
        $scope.create = false;

        $scope.url = API_URL + '/api/1.0/supplies/' + $routeParams.cups + '/readings/';
        $scope.reading = {};
        $scope.loadingReading = false;
        $scope.forceReading = false;

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.canAddReading = function () {
            return (UserInfo.hasPerm('consumptions.add_electricitymeterreading'));
        };

        $scope.deleteReading = function () {
            $scope.delete = !$scope.delete;
        };

        $scope.saveReading = function (item) {
            var data = {
                'reading_from_reading': item.reading_from_reading,
                'reading_to_reading': item.reading_to_reading,
                'reading_from_date': new Date(item.reading_from_date),
                'reading_to_date': new Date(item.reading_to_date)
            };

            SupplyAPI.patchReadings($routeParams.cups, item.id, data).then(function(data) {
                Alert.createAlert('success', $translate('SUPPLIES.READING_UPDATED_OK'), true);
            }).catch(function(error) {
                Alert.createAlert('danger', error.data, true);
            });
        };

        $scope.addReading = function () {
            $scope.create = !$scope.create;
        };

        $scope.cancelDeleteProduct = function () {
            $scope.delete = false;
        };

        $scope.get_last_reading_item = function (id, item) {
            var path = 'electricity_meter_' + id;

            if ($scope[path] != undefined && $scope[path][item] != undefined)
                return $scope[path][item];
            else
                return '';
        };

        $scope.activate_field = function (id, item) {
            var path = 'electricity_meter_' + id;

            if ($scope[path] != undefined && $scope[path][item] != undefined)
                return $scope[path][item];
            else
                return true;
        };

        $scope.createConsumptionReading = function () {
            $scope.loadingReading = true;

            var data = {};
            data['readings'] = {};

            if ($scope.reading.initial_date != null) {
                data['initial_date'] = $scope.reading.initial_date;
            }

            data['finish_date'] = $scope.reading.finish_date;
            data['generate_issue'] = $scope.reading.generate_issue;
            data['force_reading'] = $scope.forceReading;

            for (var id = 0; id < $scope.electricityMeterIds.length; id++) {
                var electricity_meter_id = $scope.electricityMeterIds[id];

                data['readings'][electricity_meter_id] = {};
                data['readings'][electricity_meter_id] = $scope.reading[electricity_meter_id];
            }

            SupplyAPI.saveReading($routeParams.cups, data)
                .then(function (data) {
                    $scope.loadingReading = false;
                    $location.path('invoices/' + data);
                    Alert.createAlert('success', $translate('SUPPLIES.CONSUMPTION_READINNG_CREATED'), true);
                })
                .catch(function (error) {
                    $scope.loadingReading = false;
                    Alert.createAlert('danger', error.data, true);
                });
        };

        $scope.showReading = function (index) {
            $scope.activePosition = $scope.activePosition == index ? -1 : index;
        };

        $scope.changeForceReading = function() {
            $scope.reading.initial_date = null;
            $scope.reading.finish_date = null;
            $scope.reading.generate_issue = false;
            $scope.forceReading = !$scope.forceReading;
        };

        $scope.isEmptyObject = function(obj){
            if (obj !== undefined)
                return Object.keys(obj).length == 0;
        };

        $scope.change_generate_issue = function (value) {
            $scope.reading.generate_issue = value;
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/

        var loadElectricityMeters = function (cups) {
                return ElectricityMeter.getElectricityMeters(cups)
            },
            loadLastReadings = function (electricityMeters) {
                $scope.electricityMeters = electricityMeters;
                $scope.electricityMeterIds = electricityMeters.map(function (obj) {
                    return obj.id;
                });

                var electricityMeterReadingsPromises = [];

                for (var id = 0; id < $scope.electricityMeterIds.length; id++) {
                    electricityMeterReadingsPromises.push(
                        ElectricityMeter.getElectricityMeter($routeParams.cups, $scope.electricityMeterIds[id])
                    );
                }

                if ($scope.electricityMeterIds.length == 0) {
                    $scope.noLastReading = true;
                    return;
                }

                $q.all(electricityMeterReadingsPromises).then(function (result) {
                    for (var i = 0; i < result.length; i++) {
                        var id_last_electricity_meter_reading = 'electricity_meter_' + result[i].id;
                        $scope[id_last_electricity_meter_reading] = {};

                        if (result[i].last_reading != null) {
                            result[i].last_reading.map(function (reading) {

                                if (reading.source == 'M') {
                                    $scope.lastReadingManual = true;
                                }
                                var reading_count = reading.reading_to_reading;

                                if (result[i].last_reading.length == 1 && reading.term_code == 10) {
                                    $scope[id_last_electricity_meter_reading].last_active_energy_p2 = reading_count;
                                    $scope[id_last_electricity_meter_reading].active_active_energy_p2 = false;
                                } else {
                                    var termCode = reading.term_code.toLowerCase()
                                    var period = termCode.startsWith('a') ? parseInt(termCode.replace('a', '')) : reading.term_code % 10;
                                    if (reading.magnitude == 'AE') {

                                        if (period == 1) {
                                            $scope[id_last_electricity_meter_reading].last_active_energy_p1 = reading_count;
                                            $scope[id_last_electricity_meter_reading].active_active_energy_p1 = false;
                                        } else if (period == 2) {
                                            $scope[id_last_electricity_meter_reading].last_active_energy_p2 = reading_count;
                                            $scope[id_last_electricity_meter_reading].active_active_energy_p2 = false;
                                        } else if (period == 3) {
                                            $scope[id_last_electricity_meter_reading].last_active_energy_p3 = reading_count;
                                            $scope[id_last_electricity_meter_reading].active_active_energy_p3 = false;
                                        } else if (period == 4) {
                                            $scope[id_last_electricity_meter_reading].last_active_energy_p4 = reading_count;
                                            $scope[id_last_electricity_meter_reading].active_active_energy_p4 = false;
                                        } else if (period == 5) {
                                            $scope[id_last_electricity_meter_reading].last_active_energy_p5 = reading_count;
                                            $scope[id_last_electricity_meter_reading].active_active_energy_p5 = false;
                                        } else if (period == 6) {
                                            $scope[id_last_electricity_meter_reading].last_active_energy_p6 = reading_count;
                                            $scope[id_last_electricity_meter_reading].active_active_energy_p6 = false;
                                        }

                                    } else if (reading.magnitude == 'R1') {

                                        if (period == 1) {
                                            $scope[id_last_electricity_meter_reading].last_reactive_energy_p1 = reading_count;
                                            $scope[id_last_electricity_meter_reading].active_reactive_energy_p1 = false;
                                        } else if (period == 2) {
                                            $scope[id_last_electricity_meter_reading].last_reactive_energy_p2 = reading_count;
                                            $scope[id_last_electricity_meter_reading].active_reactive_energy_p2 = false;
                                        } else if (period == 3) {
                                            $scope[id_last_electricity_meter_reading].last_reactive_energy_p3 = reading_count;
                                            $scope[id_last_electricity_meter_reading].active_reactive_energy_p3 = false;
                                        } else if (period == 4) {
                                            $scope[id_last_electricity_meter_reading].last_reactive_energy_p4 = reading_count;
                                            $scope[id_last_electricity_meter_reading].active_reactive_energy_p4 = false;
                                        } else if (period == 5) {
                                            $scope[id_last_electricity_meter_reading].last_reactive_energy_p5 = reading_count;
                                            $scope[id_last_electricity_meter_reading].active_reactive_energy_p5 = false;
                                        } else if (period == 6) {
                                            $scope[id_last_electricity_meter_reading].last_reactive_energy_p6 = reading_count;
                                            $scope[id_last_electricity_meter_reading].active_reactive_energy_p6 = false;
                                        }
                                    }
                                }
                            });
                        }
                    }
                }).catch(function (error) {
                });
            };

        loadElectricityMeters($routeParams.cups)
            .then(function(data) {
                loadLastReadings(data)
            });

        SupplyAPI.getReadings($routeParams.cups).then(function (data) {
                var readings = {};
                data.data.map(function (value) {
                    value.reading_from_date = DateUtils.transformToInterface(value.reading_from_date)
                    value.reading_to_date = DateUtils.transformToInterface(value.reading_to_date) 

                    if (readings.hasOwnProperty(value.reading_from_date + value.reading_to_date)) {
                        readings[value.reading_from_date + value.reading_to_date].push(value)
                    } else {
                        readings[value.reading_from_date + value.reading_to_date] = [value]
                    }
                });
                $scope.grouped_readings = readings;

                if ($scope.isEmptyObject(readings)) {
                    $scope.noLastReading = true;
                }
            }).catch(function (error) {
            $scope.loadingReading = false;
        });

    }
]

export {ReadingsCtrl};
