const CustomerFactory = ['$resource', function ($resource) {
        return $resource(API_URL + '/api/1.0/customers/:id/', { id: '@id' },
            {
                'update': { method: 'PUT'}
            }
        );
    }
]

const CustomerConfig = ['$resourceProvider', function($resourceProvider) {
    $resourceProvider.defaults.stripTrailingSlashes = false;
}]

export { CustomerFactory, CustomerConfig }