const ReceiptsForRemitCtrl = ['$scope', '$location', '$routeParams', '$rootScope', '$http', '$filter', 'Receipt', 'UserInfo', 'Alert',
    function ($scope, $location, $routeParams, $rootScope, $http, $filter, Receipt, UserInfo, Alert) {

        var $translate = $filter('translate');

        $scope.updateExtraExpenses = function (receipt) {
            var json = {
                'extra_expenses': receipt.extra_expenses,
                'total': Math.round((receipt.extra_expenses + receipt.amount) * 100) / 100
            };

            Receipt.patch({id: receipt.id}, json, function (receipt) {
                $rootScope.$broadcast('refreshList');
                Alert.createAlert('success', $translate('BANKS.UPDATE_EXTRA_EXPENSES_OK'), true);
            }).catch(function (error) {
                Alert.createAlert('danger', $translate('BANKS.UPDATE_EXTRA_EXPENSES_KO'), true);
            });
        };

        $scope.userHasPerm = function(perm){
            return UserInfo.hasPerm(perm);
        };
    }
]

export { ReceiptsForRemitCtrl }
