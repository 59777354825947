{
  "COMPLAINTS": {
    "TITLE": "Reclamaciones",
    "SEARCH": "Buscar...",
    "ALL": "Todo",
    "IDENTIFIER": "Identificador",
    "N_CONTRACT": "Nº Contracto",
    "CUPS": "CUPS",
    "WATIUM_CODE": "Código Externo",
    "CREATION_DATE": "Fecha de Creación",
    "LAST_STATUS_CHANGE": "Fecha de último cambio de estado",
    "LAST_MOD_DATE": "Fecha de última modificación",
    "CLOSE_DATE": "Fecha de Cierre",
    "NEW_COMPLAINT": "Nueva reclamación",
    "VIEW": "Ver",
    "DELETE": "Eliminar",
    "YES": "Sí",
    "NO": "No",
    "DELETE_COMPLAINT": "¿Borrar reclamación?",
    "DATE_RANGE": "Fechas",
    "WITHOUT_FILTERS": "Sin Filtros",
    "DATE_FROM": "Desde",
    "DATE_TO": "Hasta",
    "ANY": "Cualquiera",
    "STATUS": "Estado",
    "CREATED": "Abierta",
    "PENDING_CUSTOMER": "Pendiente de Usuario",
    "PENDING_DISTRIBUTOR": "Pendiente de distribuidora",
    "PENDING_OTHERS": "Pendiente de Otros",
    "CLOSED": "Cerrado",
    "FAVORABLE": "Favorable",
    "UNFAVORABLE": "Desfavorable",
    "UNMANAGED": "No Gestionable",
    "COMPLAINT_DELETED": "La reclamación ha sido borrada.",
    "COMPLAINT_DATA": "Datos de la Reclamación",
    "COMMENTS": "Comentarios",
    "FILES": "Archivos",
    "LOGS": "Logs",
    "SYSTEM": "Sistema",
    "ENABLE_COMPLAINT_EDITION": "Desbloquear Edición",
    "UPDATE": "Actualizar",
    "CANCEL": "Cancelar",
    "COMPLAINT_INFO": "Información de la Reclamación",
    "COMPLAINT_SUPPLY": "CUPS",
    "COMPLAINT_RATE_CODE": "Código de Tarifa",
    "COMPLAINT_TOTAL_CUPS": "Nº Suministro afectados",
    "COMPLAINT_CLAIMANT": "Reclamante",
    "COMPLAINT_SUBTYPE": "Tipo",
    "COMPLAINT_CHANNEL": "Canal",
    "COMPLAINT_WAITING_RESPONSE": "Esperando Respuesta de:",
    "EXTERNAL_CODE": "Código Externo",
    "DISTRIBUTOR_CODE": "Código Distribuidora",
    "NEED_DISTRIBUTOR": "Necesita Distribuidora",
    "COMPLAINT_STATUS": "Estado de la Reclamación",
    "COMPLAINT_STATUS_INDICATOR": "Indicador de estado",
    "COMPLAINT_RESULT": "Resultado",
    "COMPLAINT_TOTAL_DAYS": "Días totales de reclamación",
    "COMPLAINT_DISTRIBUTOR_DAYS": "Días en distribuidora",
    "COMPLAINT_COMMERCIAL_DAYS": "Días en comercializadora",
    "COMPLAINT_CLIENT_DAYS": "Días en cliente",
    "COMPLAINT_OTHER_DAYS": "Días en otros",
    "SUPPLY": "Pto. de suministro",
    "CLAIMANT": "Reclamante",
    "SUBTYPE": "Subtipo",
    "CHANNEL": "Canal",
    "CREATE": "Crear",
    "SELECT_ONE": "Selecciona una",
    "COMPLAINT_CREATED_SUCCESSFULLY": "Reclamación creada correctamente",
    "N_COMPLAINTS_CREATED_SUCCESSFULLY": "Reclamaciones creadas correctamente.",
    "TYPE_MANDATORY": "Este campos es requerido",
    "ACTIONS": "Acciones",
    "CHANGE_STATUS": "Cambiar estado",
    "SELECT_STATUS": "Seleccionar Estado",
    "SELECT_RESULT": "Indicar Resultado",
    "CHANGE_QUESTION": "¿Cambiar estado?",
    "COMPLAINT_STATUS_UPDATED": "Estado de la reclamación actualizado.",
    "ERROR_REQUEST": "El estado no pudo ser actualizado.",
    "COMPLAINT_UPDATE_SUCCESSFULLY": "Reclamación actualizada con éxito",
    "COMPLAINT_STATE": "Provincia",
    "INVALID_VALUE": "Código no válido",
    "DEPARTMENT": "Departamento",
    "CURRENT_DEPARTMENT": "Departamento actual",
    "MODIFICATION_DATE": "Fecha de modificación",
    "CONTRACT": "Contrato",
    "CONTRACTS": "Contratos (ids separados por ,)",
    "DESCRIPTION": "Descripcion",
    "COUNTERS_MANUAL_EDITION": "Edición manual de los contadores",
    "SENT_COMPLAINT_ATR": "Crear el Evento de una reclamación",
    "SEARCH_BY_COMPLAINT_ID": "Buscar Reclamación por Id",
    "CREATE_ATR_R1_EVENT": "Crear Evento",
    "COMPLAINT_INFO_PARAMS": "Parámetros del Subtipo seleccionado",
    "REQUIRED_FIELDS": "Campos obligatorios.",
    "EVENT_COMPLAINT_CREATED_SUCCESSFULLY": "Evento de la reclamación creado con éxito.",
    "ERROR_WHEN_CREATE_COMPLAINT_EVENT": "Se ha producido un error."
  }
}