function electronicInvoice(){
    return {
        restrict: 'AE',
        scope: {
            withoutPdf: '=?'
        },
        templateUrl: require('../../templates/einvoice-button.html'),
        controller: function ($scope, UserInfo, $http, $rootScope, Task, Alert, $filter, filtersUtils) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var $translate = $filter('translate');

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.listToSend = [];
            $scope.hasPerm = UserInfo.hasPerm('invoices.send_einvoice');

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToSend = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/
            $scope.confirmSent = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
            };

            $scope.clickYes = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToSend);
                performTask(ids);
            };

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/
            function performTask(ids) {

                var task = new Task();
                task.type = 'ELIN';
                task.params = {'ids': ids};
                if($scope.withoutPdf) {
                    task.params.without_pdf = true;
                }
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.SENDING_EINVOICES'), true);
                }, function (data, status, headers, config) {
                });
            }
        }
    }
}

export { electronicInvoice }
