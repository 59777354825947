const InvoiceSeriesDetailCtrl = ['$scope', 'InvoiceSeries', 'UserInfo',
    function ($scope, InvoiceSeries, UserInfo) {
        var self = this;
        $scope.invoice_series_list = $scope.results;
        $scope.invoice_series = $scope.result;

        $scope.mode = 'detail';
        $scope.action = 'detail';
        $scope.loading = false;
        $scope.edited_invoice_series = { code: "", counter: "", description: "", invoice_number_len: "", id: "" };
        $scope.editInvoiceSeries = function() {
            $scope.edited_invoice_series = {
                code: $scope.invoice_series.code,
                counter: $scope.invoice_series.counter,
                description: $scope.invoice_series.description,
                invoice_number_len: $scope.invoice_series.invoice_number_len,
                id: $scope.invoice_series.id
            };
            $scope.mode = 'edit';
        }
        $scope.cancelEditInvoiceSeries = function() { $scope.mode = 'detail'; }
        $scope.deleteInvoiceSeries = function() { $scope.action = 'delete'; }
        $scope.cancelDeleteInvoiceSeries = function() {
            $scope.action = 'detail';
            $scope.editInvoiceSeriesForm.$setPristine();
            $scope.editInvoiceSeriesForm.$setUntouched();
        }
        $scope.confirmDeleteInvoiceSeries = function() {
            $scope.loading = true;
            InvoiceSeries.delete({id: $scope.invoice_series.id}, $scope.invoice_series, function(){
                for (var i = 0; i < $scope.invoice_series_list.length; i++) {
                    if ($scope.invoice_series_list[i].code == $scope.invoice_series.code) {
                        $scope.invoice_series_list.splice(i, 1);
                        break;
                    }
                }
                $scope.loading = false;
            });
        };
        $scope.saveInvoiceSeries = function() {
            $scope.loading = true;
            InvoiceSeries.update({id: $scope.edited_invoice_series.id}, $scope.edited_invoice_series, function(){
                $scope.loading = false;
                $scope.invoice_series.name = $scope.edited_invoice_series.name;
                $scope.invoice_series.description = $scope.edited_invoice_series.description;
                $scope.invoice_series.price = $scope.edited_invoice_series.price;
                $scope.cancelEditInvoiceSeries();
            });
        };

        $scope.canEditInvoiceSeries = function () {
            return UserInfo.hasPerm('invoices.change_invoiceseries');
        };

        $scope.canDeleteInvoiceSeries = function () {
            return UserInfo.hasPerm('invoices.delete_invoiceseries');
        };
    }
]

export { InvoiceSeriesDetailCtrl }
