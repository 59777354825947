
const SupplyFormCtrl = 
        ['$scope', '$http', '$filter', 'CreateContract', 'Alert', 'ProgrammingUnitsMaster',
            'SUPPLY_RATES', 'SUPPLY_STATUSES', 'SUPPLY_POWER_PERIODS', 'NORMAL_POWERS', 'CompaniesMaster', 'CitiesMaster',
            'SupplyAPI',
        function ($scope, $http, $filter, CreateContract, Alert, ProgrammingUnitsMaster,
                  SUPPLY_RATES, SUPPLY_STATUSES, SUPPLY_POWER_PERIODS, NORMAL_POWERS, CompaniesMaster, CitiesMaster, SupplyAPI) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var sips_api_url = API_URL  + "/api/1.0/sips/";

            var that = this;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.supply = null;
            $scope.new_supply = false;
            $scope.enable_edition = false;
            $scope.searching = false;

            $scope.rates = SUPPLY_RATES;
            $scope.statuses = SUPPLY_STATUSES;

            $scope.supply_loaded = false;
            $scope.submited = false;

            $scope.range_errors = {
                T20A: $translate('CONTRACTS.RANGE_ERROR_20A'),
                T20DHA: $translate('CONTRACTS.RANGE_ERROR_20DHA'),
                T21A: $translate('CONTRACTS.RANGE_ERROR_21A'),
                T21DHA: $translate('CONTRACTS.RANGE_ERROR_21DHA'),
                T30A: $translate('CONTRACTS.RANGE_ERROR_30A'),
                T31A: $translate('CONTRACTS.RANGE_ERROR_31A'),
                T31DHA: $translate('CONTRACTS.RANGE_ERROR_31DHA'),
                T61: $translate('CONTRACTS.RANGE_ERROR_61'),
                T61A: $translate('CONTRACTS.RANGE_ERROR_61'),
                T61B: $translate('CONTRACTS.RANGE_ERROR_61'),
                T62: $translate('CONTRACTS.RANGE_ERROR_62'),
                T63: $translate('CONTRACTS.RANGE_ERROR_63'),
                T64: $translate('CONTRACTS.RANGE_ERROR_64'),
                T65: $translate('CONTRACTS.RANGE_ERROR_65')
            };

            $scope.normal_powers = NORMAL_POWERS;

            $scope.message = $translate('CONTRACTS.CLOSE_PAGE');

            $scope.supply_form = [
                'supply_cups',
                'supply_status',
                'supply_rate',
                'supply_distributor',
                'supply_commercial',
                'supply_name',
                'supply_surname_1',
                'supply_surname_2',
                'supply_city',
                'supply_state',
                'supply_zip',
                'supply_power_p1',
                'supply_power_p2',
                'supply_power_p3',
                'supply_power_p4',
                'supply_power_p5',
                'supply_power_p6',
                'supply_registry',
                'supply_measurement_point_type',
                'supply_programming_unit'
            ];



            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$watch('supply', function (newValue, oldValue) {
                if (newValue) {
                    $scope.$emit("setValidity", true);
                    CreateContract.setSupply($scope.supply);
                } else {
                    $scope.$emit("setValidity", false);
                    CreateContract.setSupply(null);
                }
            }, true);

            $scope.$watch('supplyData.$pristine', function (newValue, oldValue) {
                if (!newValue && $scope.supply)
                    $scope.supply.api = false;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/

            this.update_distributor = function () {
                var code = $scope.supply.cups.substring(2, 6);
                var l_code = $scope.supply.cups.substring(2, 8);
                if (!isNaN(l_code)) {
                    switch (parseInt(l_code)) {
                        case 3130:
                            code = "0029";
                            break;
                        case 3144:
                            code = "0396";
                            break;
                        case 3140:
                            code = "0024";
                            break;
                        case 3110:
                            code = "0023";
                            break;
                        case 3150:
                            code = "0288";
                            break;
                        case 3160:
                            code = "0363";
                            break;
                    }
                }
                $scope.supply.distributor = code;
            };

            this.clean_date_fields = function() {
                let date_fields = ['last_recruitment_change_on','last_trading_change_on','extensions_rights_deadline_on',
                                'last_reading_on','contract_started_on']

                date_fields.forEach(function(field){

                    if(/(\d{2})\/(\d{2})\/(\d{4})/.test($scope.supply[field]) ) {
                        let date = new Date($scope.supply[field].replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
                        let date_string = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
                        $scope.supply[field] = date_string
                    }

                    if($scope.supply[field]==''){
                        delete $scope.supply[field]
                    }

                });


            };

            this.clean_consumption_profile_type = function() {
                let possible_values = ['PA','PB','PC'];

                $scope.supply.consumption_profile_type = $scope.supply.consumption_profile_type.toUpperCase();

                possible_values.forEach(function(value) {
                    if ($scope.supply.consumption_profile_type.indexOf(value) !== -1) {
                        $scope.supply.consumption_profile_type = value;
                    }
                });

                if($scope.supply.consumption_profile_type.length > 2){
                    $scope.supply.consumption_profile_type = '';
                };
            };

            this.clean_ps_classification = function() {
                let regex = /\d+/i;

                $scope.supply.ps_classification = regex.exec($scope.supply.ps_classification)

                if($scope.supply.ps_classification){
                    $scope.supply.ps_classification = $scope.supply.ps_classification[0]
                }else{
                    $scope.supply.ps_classification = 0
                }
            };

            this.clean_sips_fields = function() {
                // A place to add exceptions from non-standard fields from sips
                if( $scope.supply.owner_address )
                    $scope.supply.owner_address = $scope.supply.owner_address.replace(/\s+/g,' ').substr(0,79)
            };

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.editMode = function () {
                $scope.enable_edition = !$scope.enable_edition;
            };

            $scope.cleanSupplyData = function () {
                $scope.supply = null;
                $scope.supply_loaded = false;
            };

            $scope.loadSupplyData = function (form) {
                if ($scope.supply != null && $scope.supply.cups != "" && 'cupsSearch.supply_cups.$valid') {
                    $scope.searching = true;
                    $scope.supply_loaded = false;
                    $scope.$emit("setValidity", false);
                    //$scope.supply =
                    SupplyAPI.getSupplyDetail($scope.supply.cups.substring(0, 20))
                        .then(function (response) {
                            let supply = response.data

                            $scope.searching = false;
                            $scope.supply_loaded = true;
                            $scope.$emit("setValidity", true);

                            $scope.supply = supply;
                            $scope.new_supply = false;

                            SupplyAPI.getContractsForSupply($scope.supply.cups)
                                .then(function (response) {
                                    let contracts = response.data

                                    if(contracts.count > 0){
                                        var message = '';
                                        contracts.results.forEach(function(contract){
                                            if(contract.status=='ACT')
                                                message+= '<p>'+$translate('SUPPLIES.ACTIVE_CONTRACT') + '(<a ng-href="/contracts/' + contract.id + '" target="_blank">' + contract.id + '</a>).'+'</p>';
                                            if(contract.status=='DRF')
                                                message+= '<p>'+$translate('SUPPLIES.DRAFT_CONTRACT') + '(<a ng-href="/contracts/' + contract.id + '" target="_blank">' + contract.id + '</a>).'+'</p>';
                                            if(contract.status=='INA')
                                                message+= '<p>'+$translate('SUPPLIES.INACTIVE_CONTRACT') + '(<a ng-href="/contracts/' + contract.id + '" target="_blank">' + contract.id + '</a>).'+'</p>';
                                        });
                                        if(message!='')
                                            Alert.createAlert('warning', message, true);
                                    }
                            });
                            CreateContract.setSupply(supply);

                        }).catch(function (error) {
                            SupplyAPI.getSupplyFromSIPS($scope.supply.cups)
                                .then(function(response){
                                    let supply = response.data;

                                    $scope.searching = false;
                                    $scope.supply_loaded = true;
                                    $scope.new_supply = true;
                                    $scope.$emit("setValidity", false);

                                    if(supply._id){
                                        $scope.supply = supply;
                                        $scope.supply.cups = supply._id;

                                        that.clean_consumption_profile_type();
                                        that.clean_ps_classification();
                                        that.clean_date_fields();
                                        that.clean_sips_fields();

                                        $scope.supply.consumption_profile_type = (supply.consumption_profile_type).toUpperCase();

                                        $scope.supply.max_authorised_power_bie = parseFloat(supply.max_authorised_power_bie / 1000);
                                        $scope.supply.voltage = parseInt(supply.voltage);
                                        $scope.supply.access_rights_recognized = parseFloat(supply.access_rights_recognized / 1000) ;
                                        $scope.supply.extension_rights_reconigzed = parseFloat(supply.extension_rights_reconigzed / 1000) ;

                                        $scope.supply.deposit_warranty = parseFloat(supply.deposit_warranty);

                                        if($scope.supply.usual_housing){
                                            if($scope.supply.usual_housing == 'Si'){
                                                $scope.supply.usual_housing = '1'
                                            }
                                            else if($scope.supply.usual_housing == 'No'){
                                                $scope.supply.usual_housing = '0'
                                            }
                                            else{
                                                $scope.supply.usual_housing = $scope.supply.usual_housing.substr(0, 1)
                                            }
                                        }

                                        $scope.supply_loaded = true;
                                        
                                        
                                        CreateContract.setSupply(supply);
                                    }

                                    that.update_distributor();
                                })
                                .catch(function (error){
                                    $scope.searching = false;
                                    $scope.supply_loaded = true;
                                    $scope.new_supply = true;
                                    $scope.$emit("setValidity", false);
                                    Alert.createAlert('warning', $translate('SUPPLIES.NOT_IN_SIPS') , true);
                                    that.update_distributor();
                                })
                        })
                }

            };

            $scope.powerActive = function (powerIndex, type) {
                if (type === 'supply')
                    return angular.isDefined($scope.supply) && $scope.supply != null &&
                        angular.isDefined($scope.supply.rate) && $scope.supply.rate != ''
                        && !SUPPLY_POWER_PERIODS[$scope.supply.rate][powerIndex - 1];
                else if (type === 'contract')
                    return angular.isDefined($scope.contract) && $scope.contract != null &&
                        angular.isDefined($scope.contract.rate) && $scope.contract.rate != '' &&
                        !SUPPLY_POWER_PERIODS[$scope.contract.rate][powerIndex - 1];
                else
                    return true;
            };

            $scope.powerNormal = function () {
                if (angular.isDefined($scope.supply) && $scope.supply != null && angular.isDefined($scope.supply.rate)) {
                    for (var i = 1; i < 7; i++) {
                        if (!$scope.powerActive(i, 'contract')) {
                            if ($scope.supply['power_p' + i] !== undefined && $scope.normal_powers.indexOf($scope.supply['power_p' + i]) < 0) {
                                return false;
                            }
                        }
                    }
                    return true;
                }
                return true;
            };

            $scope.createSupply = function(){
                if (!$scope.supply.cie_issued_on) $scope.supply.cie_issued_on = null;
                if (!$scope.supply.cie_activated_on) $scope.supply.cie_activated_on = null;
                if (!$scope.supply.cie_finished_on) $scope.supply.cie_finished_on = null;

                if($scope.supply.cups && $scope.supply.cups.length == 22) {
                    $scope.supply.cups = $scope.supply.cups.substring(0, 20);
                    $scope.supply.nt_code = $scope.supply.cups.substring(20, 22);
                }

                SupplyAPI.createSupply($scope.supply)
                    .then(function (response) {
                        let supply = response.data
                        $scope.supply = supply;
                        Alert.createAlert('success', $translate('CONTRACTS.SUPPLY_CREATE_SUCCESS'), true);
                        $scope.$emit("setValidity", true);
                        $scope.enable_edition = false;
                        CreateContract.setSupply(supply);
                        $scope.new_supply = false;
                    })
                    .catch(function(error){
                        Alert.createAlert('danger', error.data, true);
                        $scope.$emit("setValidity", false);
                        $scope.enable_edition = true;
                    })
            };

            $scope.supplyInvalid = function () {
                if ($scope.new_supply) {
                    angular.forEach($scope.supplyData.$error.required, function(value) {
                        if (value.$name == 'supply_cups') {
                            var position = $scope.supplyData.$error.required.indexOf(value);

                            if ( ~position ) $scope.supplyData.$error.required.splice(position, 1);
                        }
                        if (value.$name == "supply_measurement_point_type") {
                            $scope.supplyData.supply_measurement_point_type.$setTouched();
                        }
                    });

                }
                return $scope.supplyData.$invalid;
            };

            $scope.updateSupply = function(){
                delete $scope.supply['created_on'];
                delete $scope.supply['modified_on'];

                // Don't send date fields when are empty
                if (!$scope.supply.cie_issued_on) $scope.supply.cie_issued_on = null;
                if (!$scope.supply.cie_activated_on) $scope.supply.cie_activated_on = null;
                if (!$scope.supply.cie_finished_on) $scope.supply.cie_finished_on = null;
                
                if ($scope.supply.cups.length == 22) {
                    $scope.supply.cups = $scope.supply.cups.substring(0, 20);
                    $scope.supply.nt_code = $scope.supply.cups.substring(20, 22);
                }

                SupplyAPI.updateSupply($scope.supply)
                    .then(function (supply) {
                        $scope.supply = supply;
                        Alert.createAlert('success', $translate('CONTRACTS.SUPPLY_UPDATE_SUCCESS'), true);
                        $scope.$emit("setValidity", true);
                        $scope.enable_edition = false;
                        CreateContract.setSupply(supply);
                    })
                    .catch(function(error){
                        Alert.createAlert('danger', error, true);
                        $scope.$emit("setValidity", false);
                        $scope.enable_edition = true;
                    })
            };

            $scope.noCups = function () {
                $scope.supply_loaded = true;
                $scope.enable_edition = true;
                $scope.new_supply = true;
            };
            /***********************************************************************************************************
            * Controller Initialization
            ***********************************************************************************************************/

            CompaniesMaster.loadCompaniesDistributor().then(function (response){
                $scope.distributors = response;
            });

            CompaniesMaster.loadCompaniesCommercial().then(function (response){
                $scope.commercials = response;
            });
            
            CitiesMaster.loadCities().then(function(response){
                $scope.cities=response;
            });


            ProgrammingUnitsMaster.loadProgrammingUnits().then(function (response){
                $scope.programming_units = response;
            });

        }]

export {SupplyFormCtrl}
