function Task($http, notificationCenter) {

    function Task() {

        this.celery_id = null;
        this.finished_on = null;
        this.id = null;
        this.params = null;
        this.result = null;
        this.result_data = null;
        this.revert_finished_on = null;
        this.revert_started_on = null;
        this.started_on = null;
        this.status = null;
        this.type = null;
        this.user = null;
        this.name = null;

        this.updateDataWithObject = function(obj) {
            this.celery_id = obj.celery_id;
            this.finished_on = obj.finished_on;
            this.id = obj.id;
            this.params = obj.params;
            this.result = obj.result;
            this.result_data = obj.result_data;
            this.revert_finished_on = obj.revert_finished_on;
            this.revert_started_on = obj.revert_started_on;
            this.started_on = obj.started_on;
            this.status = obj.status;
            this.type = obj.type;
            this.user = obj.user;
            this.name = obj.name;
        };

        if (arguments.length > 0)
            this.updateDataWithObject(arguments[0]);

        this.start = function() {
            var self = this;
            var url = API_URL + '/api/1.0/tasks/';
            var post_data = {
                type: this.type,
                params: this.params
            };
            return $http.post(url, post_data)
                .then(function (data, status, headers, config) {
                    self.updateDataWithObject(data.data);
                    notificationCenter.publish('BG_TASK_STARTS', {id: data.data.id});
                    return data;
                });
        };

        this.revert = function() {
            var self = this;
            return $http.put(API_URL + '/api/1.0/tasks/' + this.id + '/revert/', {})
                .then(function (data, status, headers, config) {
                    self.updateDataWithObject(data.data);
                    notificationCenter.publish('BG_TASK_STARTS', {id: this.id});
                    return data;
                });
        };

        this.cancel = function() {
            var self = this;
            return $http.put(API_URL + '/api/1.0/tasks/' + this.id + '/cancel/', {})
                .then(function (data, status, headers, config) {
                    self.updateDataWithObject(data.data);
                    notificationCenter.publish('BG_TASK_STARTS', {id: this.id});
                    return data;
                });
        };
    }

    return Task;

}

export {Task}
