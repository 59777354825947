// Controllers
import { FCFeesGenerationCtrl } from './controllers/FCFeesGenerationCtrl'
import { FCGenerationHistoryCtrl } from './controllers/FCGenerationHistoryCtrl'
import { FCImportHistoryCtrl } from './controllers/FCImportHistoryCtrl'
import { FeesNewImportCtrl } from './controllers/FeesNewImportCtrl'
import { FeesTabsCtrl } from './controllers/FeesTabsCtrl'
import { listedFeeCtrl } from './controllers/ListedFeeCtrl'
import { ListFeesCtrl } from './controllers/ListFeesCtrl'

// TODO refactor FCSimpleReportCtrl and SCDoubleReportCtrl
// TODO better errors control

const fees = angular.module('fees', ['pascalprecht.translate', 'notifications', 'ngSanitize']);

fees.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

fees.constant('FEE_GENERATION_TASK', 'GFEE');
fees.constant('FEE_IMPORT_TASK', 'IFEE');

fees.constant('IMPORT_MODE', [
    { code: '0', name: 'Dry mode' } ,
    { code: '1', name: 'Not dry mode' },
    { code: '2', name: 'Force mode' }
])

fees
    .controller('FCFeesGenerationCtrl', FCFeesGenerationCtrl)
    .controller('FCGenerationHistoryCtrl', FCGenerationHistoryCtrl)
    .controller('FCImportHistoryCtrl', FCImportHistoryCtrl)
    .controller('FeesNewImportCtrl', FeesNewImportCtrl)
    .controller('FeesTabsCtrl', FeesTabsCtrl)
    .controller('listedFeeCtrl', listedFeeCtrl)
    .controller('ListFeesCtrl', ListFeesCtrl)

export { fees }
