const ProductCtrl = ['$scope', '$filter', '$routeParams',  'UserInfo', 'ProductRatesMaster',
    function ($scope, $filter, $routeParams, UserInfo, ProductRatesMaster) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        var product_rate = null;

        if ($routeParams.rate != null){
            product_rate = $routeParams.rate;
        }

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.products = [];
        $scope.ordering = {
            "code": $translate('PRODUCTS.CODE'),
            "name": $translate('PRODUCTS.NAME')
        };
        $scope.filters = {
            search: ""
        };

        $scope.mode = 'list';

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        $scope.$on('cancelCreate', function (event) {
            $scope.mode = 'list';
        });

        $scope.$on('newProduct', function (event) {
            $scope.listReload();
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.showCreateForm = function () {
            $scope.mode = 'create';
        };

        $scope.canCreateProduct = function () {
            return (UserInfo.hasPerm("products.add_product"));
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        ProductRatesMaster.loadProductRatesNotForce().then(function (response){
            $scope.product_rates = response;
        });

    }
]

export { ProductCtrl }
