const IssuesCtrl = ['$scope', '$filter', 'UserInfo', 'eventInfoService', 'Task', 'filtersUtils', 'Alert',
    function ($scope, $filter, UserInfo, eventInfoService, Task, filtersUtils, Alert) {
        $scope.ISSUES_ACTIONS_DROPDOWN = require('../../templates/actions-dropdown.html')
        $scope.ISSUES_DOWNLOADS_DROPDOWN = require('../../templates/downloads-dropdown.html')
        
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;
        var first_access = true;

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.backgroundTasks = [];

        $scope.searchFilters = {
            'id': $translate('ISSUES.NO_ISSUE'),
            'contract': $translate('ISSUES.NO_CONTRACT'),
            'invoice': $translate('ISSUES.NO_INVOICE'),
            'supply': $translate('ISSUES.SUPPLY_CUPS'),
            'complaint': $translate('ISSUES.NO_COMPLAINT'),
            'distributor_code': $translate('ISSUES.SUPPLY_DISTRIBUTOR'),
            'search': $translate('ISSUES.ALL')
        };

        $scope.searchBy = Object.keys($scope.searchFilters)[0];
        $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];

        $scope.issues = [];
        $scope.ordering = {
            "-creation_date": $translate('ISSUES.CREATION_DATE_RANGE'),
            "id": $translate('ISSUES.NO_ISSUE'),
            "type": $translate('ISSUES.TYPE'),
            "status": $translate('ISSUES.STATUS')
        };

        $scope.date_filters = {
            product_date_range: null,
            date_range_from: null,
            date_range_to: null
        };

        $scope.filters_by_date = [
            {value: "creation", description: $translate('ISSUES.CREATION_DATE_RANGE')},
            {value: "last_mod", description: $translate('ISSUES.LAST_MOD_DATE_RANGE')},
            {value: "last_status_mod", description: $translate('ISSUES.LAST_STATUS_MOD_DATE_RANGE')}
        ];

        $scope.types = [
            {code: "ATR", name: "ATR"},
            {code: "AF", name: "Anomalía/Fraude"}
        ];

        $scope.checked_types = [
            {code: 'CHE', name: $translate('ISSUES.CHECKED')},
            {code: 'PEN', name: $translate('ISSUES.PENDING')},
            {code: 'CLO', name: $translate('ISSUES.CLOSED')}
        ];

        $scope.filters = {
            search: "",
            id: "",
            type: "",
            category: "",
            status: "",
            invoice: "",
            contract: "",
            supply: "",
            complaint: "",
            distributor_code: "",
            request_code: []
        };

        var initial_statuses_filter = {
            '00': $translate('ISSUES.STATUS_PENDING'),
            '01': $translate('ISSUES.STATUS_SENT'),
            '02A': $translate('ISSUES.STATUS_ACCEPT'),
            '02R': $translate('ISSUES.STATUS_REJECT'),
            '05': $translate('ISSUES.STATUS_ACTIVE')
        };

        $scope.has_type = false;
        $scope.has_category = false;
        $scope.categories = null;
        $scope.statuses = initial_statuses_filter;

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
            $scope.counter = selectedCounterChange;
        });

        $scope.$watchCollection('date_filters', function (newVal, oldVal) {
            if (oldVal.product_date_range !== null) {
                var sel_option = oldVal.product_date_range.value;
                if (oldVal.date_range_from !== null) {
                    $scope.filters[sel_option + "_date_from"] = null;
                }
                if (oldVal.date_range_to !== null) {
                    $scope.filters[sel_option + "_date_to"] = null;
                }
            }
            if (newVal.product_date_range !== null) {
                var sel_option = newVal.product_date_range.value;
                if (newVal.date_range_from !== null) {
                    var from_date = newVal.date_range_from;
                    $scope.filters[sel_option + "_date_from"] = from_date;
                }
                if (newVal.date_range_to !== null) {
                    var to_date = newVal.date_range_to;
                    $scope.filters[sel_option + "_date_to"] = to_date;
                }
            }
        });

        $scope.$watch('searchQuery', function (newVal, oldVal) {
            $scope.filters[$scope.searchBy] = newVal;

            if($scope.searchBy != 'supply' && $scope.request_code){
                $scope.request_code = null;
            }
        });

        $scope.$watch('request_code', function (newVal, oldVal) {
            $scope.filters['request_code'] = null;
            if(newVal){
                $scope.filters['request_code'] = {
                    'request_code': newVal,
                    'code': $scope.searchQuery
                }
            }
        });

        $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
            $scope.counter = selectedCounterChange;
        });

        $scope.$watch('filters.type', function (newValue, oldValue) {
            if (first_access == true)
                first_access = false;
            else {
                if (newValue){
                    $scope.$emit('loadCategory');
                    $scope.has_type = true;
                }
                else{
                    $scope.categories = null;
                    $scope.statuses = initial_statuses_filter;
                    $scope.has_type = false;
                    $scope.has_category = false;
                }
            }
        }, true);

        $scope.$watch('filters.category', function (newValue, oldValue) {
            if (newValue){
                $scope.$emit('loadStatuses');
                $scope.has_category = true;
            }
            else{
                $scope.statuses = initial_statuses_filter;
                $scope.has_category = false;
            }
        }, true);

        $scope.$on("loadCategory", function (e, selectedCounterChange) {
            if ($scope.categories !== '') {
                eventInfoService.getEventCategory($scope.filters.type)
                    .then(function (categories) {
                        $scope.categories = categories;
                    });
            }
        });

        $scope.$on("loadStatuses", function (e, selectedCounterChange) {
            if ($scope.statuses !== '') {
                eventInfoService.getEventStatus($scope.filters.type, $scope.filters.category)
                    .then(function (issue) {
                        $scope.statuses = issue;
                    });
            }
        });

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        this.getJSONParams = function (elements) {
            var params = {};
            params.ids = filtersUtils.getItemsToDownloadToInt(elements);
            return params;
        };

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/

        $scope.setSearchBy = function (val) {
            $scope.filters[$scope.searchBy] = '';
            $scope.searchBy = val;
            $scope.searchByLabel = $scope.searchFilters[val];
            $scope.filters[val] = $scope.searchQuery;
        };

        $scope.getStyles = function (result) {
            var styles = '';
            if (result.checked == 'CHE')
                styles += ' ' + 'success';
            if (result.checked == 'PEN')
                styles += ' ' + 'warning';
            if (result.checked == 'CLO')
                styles += ' ' + 'danger';
            return styles;
        };

        $scope.canSelectIssues = function () {
            return $scope.canSelectIssuesForActions || $scope.canSelectIssuesForDownloadsActions;
        };

        $scope.canSelectIssuesForActions = function() {
            // Add more if added another actions
            return this.can_change_status() ||
                this.can_change_checked() ||
                this.can_delete();
        };

        $scope.can_change_status = function () {
            return UserInfo.hasPerm('issues.change_issue_status');
        };

        $scope.can_change_checked = function () {
            return UserInfo.hasPerm('issues.change_issue_checked');
        };

        $scope.can_delete = function () {
            return UserInfo.hasPerm('issues.destroy_issue');
        };

        $scope.canViewIssue = function () {
            return UserInfo.hasPerm('issues.retrieve_issue');
        };

        $scope.canSelectIssuesForDownloadsActions = function() {
            return this.can_download_files();
        };

        $scope.can_download_files = function () {
            return UserInfo.hasPerm('issues.download_files');
        };

        $scope.can_import_atr_files = function () {
            return UserInfo.hasPerm('issues.import_xml_atr');
        };

        $scope.downloadCSV = function () {
            if ($scope.counter != 0) {
                var task = new Task();
                task.type = 'ECSV';
                task.params = self.getJSONParams($scope.itemsToDownload);
                task.start().then(function(data, status, headers, config) {
                    Alert.createAlert('success', $translate('ISSUES.BULK_DOWNLOAD_CSV'), true);
                    $scope.backgroundTasks.push(data.data.id);
                }).catch(function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('ISSUES.BULK_DOWNLOAD_GENERATION_TASK_ERROR'), true);
                });
            }
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/

    }
]

export { IssuesCtrl }
