const AgreementsListCtrl = ['$scope', '$filter', '$window', '$cookies', 'UserInfo', 'Alert', 'filtersUtils', 'AgreementAPI', 'BrandsList', '$http',
    function ($scope, $filter, $window, $cookies, UserInfo, Alert, filtersUtils, AgreementAPI, BrandsList, $http) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.action = 'detail';
        $scope.searchBy = 'search';
        $scope.itemsToDownload = [];
        $scope.primaryKey = 'code';

        $scope.searchByLabel = $translate('AGREEMENTS.ALL');
        $scope.searchFilters = {
            'code': $translate('AGREEMENTS.CODE'),
            'tax_id': $translate('AGREEMENTS.NIF/CIF'),
            'email': $translate('AGREEMENTS.EMAIL'),
            'search': $translate('AGREEMENTS.ALL')
        };

        $scope.searchBy = Object.keys($scope.searchFilters)[0];
        $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];
        $scope.agreements = [];

        $scope.statuses = [
            {code: 'ACT', label: $translate('AGREEMENTS.ACTIVE')},
            {code: 'PEN', label: $translate('AGREEMENTS.PENDING')},
            {code: 'INA', label: $translate('AGREEMENTS.INACTIVE')}
        ];

        $scope.types = [
            {code: 'NOR', label: $translate('AGREEMENTS.NORMAL')},
            {code: 'REN', label: $translate('AGREEMENTS.RENTAL')}
        ];

        $scope.ordering = {
            'code': $translate('AGREEMENTS.CODE'),
            'tax_id': $translate('AGREEMENTS.NIF/CIF'),
            'state': $translate('AGREEMENTS.STATE')
        };

        $scope.filters = {
            search: "",
            code: "",
            tax_id: "",
            email: "",
            status: "",
            type: "",
            brand: ""
        };

        $scope.date_filters = {
            product_date_range: null,
            date_range_from: null,
            date_range_to: null
        };

        $scope.filters_by_date = [
            {value: "activation", description: $translate('AGREEMENTS.Activation date range')},
            {value: "creation", description: $translate('AGREEMENTS.Creation date range')}
        ];

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        $scope.$watch('searchQuery', function (newVal, oldVal) {
            $scope.filters[$scope.searchBy] = newVal;
        });

        $scope.$watchCollection('date_filters', function (newVal, oldVal) {
            if (oldVal.product_date_range !== null) {
                var sel_option = oldVal.product_date_range.value;
                if (oldVal.date_range_from !== null) {
                    $scope.filters[sel_option + "_date_from"] = null;
                }
                if (oldVal.date_range_to !== null) {
                    $scope.filters[sel_option + "_date_to"] = null;
                }
            }
            if (newVal.product_date_range !== null) {
                var sel_option = newVal.product_date_range.value;
                if (newVal.date_range_from !== null) {
                    var from_date = newVal.date_range_from;
                    $scope.filters[sel_option + "_date_from"] = from_date;
                }
                if (newVal.date_range_to !== null) {
                    var to_date = newVal.date_range_to;
                    $scope.filters[sel_option + "_date_to"] = to_date;
                }
            }
        });

        $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
            $scope.counter = selectedCounterChange;
        });

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.getStyles = function (result) {
            var styles = '';
            if (result.status == 'ACT')
                styles += ' ' + 'success';
            if (result.status == 'PEN')
                styles += ' ' + 'info';

            if (result.status == 'INA')
                styles += ' ' + 'danger';
            return styles;
        };

        $scope.setSearchBy = function (val) {
            $scope.filters[$scope.searchBy] = '';
            $scope.searchBy = val;
            $scope.searchByLabel = $scope.searchFilters[val];
            $scope.filters[val] = $scope.searchQuery;
        };

        $scope.downloadExcel = function () {
            // Get list id items to download. Need the param attr name and itemsToDownload dictionary
            let url = API_URL + '/agreements/download/csv/?token=' + $cookies.get("token");
            let params = {
                "codes": []
            }

            Alert.createAlert('success', $translate('AGREEMENTS.CSV_DOWNLOAD'), true);

            for (const [key, value] of Object.entries($scope.itemsToDownload)) {
                if(value){
                    params.codes.push(key)
                }
            }

            this.xhr = $http['post'](url, params).then(function(data){
                const blob = new Blob([data.data], {type: 'text/csv'});
                const filename = 'agreements.csv'

                if(window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else{
                    const elem = window.document.createElement('a');
                    elem.href = window.URL.createObjectURL(blob);
                    elem.download = filename;        
                    document.body.appendChild(elem);
                    elem.click();        
                    document.body.removeChild(elem);
                }
            })
        };

        $scope.showCreateForm = function () {
            $window.location.href = '/agreements/create';
        };

        $scope.canCreateAgreement = function () {
            return UserInfo.hasPerm('agreements.add_agreement');
        };

        $scope.canViewAgreement = function () {
            return UserInfo.hasPerm('agreements.retrieve_agreements');
        };

        $scope.canDeleteAgreement = function () {
            return UserInfo.hasPerm('agreements.delete_agreement');
        };

        $scope.canDownloadAgreements = function () {
            return UserInfo.hasPerm('agreements.retrieve_agreements');
        };

        $scope.deleteAgreement = function ($event) {
            $event.preventDefault();

            $scope.action = 'delete';
        };

        $scope.cancelDeleteAgreement = function ($event) {
            $scope.action = 'detail';
            $event.preventDefault();
        };


        $scope.confirmDeleteAgreement = function ($event, agreement, agreements) {
            $event.preventDefault();

            AgreementAPI.deleteAgreement(agreement.code)
                .then(function (data) {
                    $scope.action = 'detail';

                    for (var i = 0; i < agreements.length; i++) {
                        if (agreements[i].code == agreement.code) {
                            agreements.splice(i, 1);
                            break;
                        }
                    }
                    Alert.createAlert('success', $translate('AGREEMENTS.AGREEMENT_DELETED_SUCCESSFULLY'), true);
                });
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        BrandsList.all().then(function (response) {
            if(response)
                $scope.brands = [{
                    'code': 'null',
                    'name': $translate('BRANDS.NONE')
                }].concat(response)
        });
    }
]

export {AgreementsListCtrl}
