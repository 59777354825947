function customerData(){
    return {
        require: '^ngModel',
        restrict: 'AE',
        templateUrl: require('../../templates/customer-form.html'),
        scope: {
            customerObject: '=ngModel',
            search: '=?'
        },
        link: function (scope, element, attr) {
            element.bind("keydown keypress", function (event) {
                if (event.which === 13) {
                    for (var k in event.srcElement.classList) {
                        if (event.srcElement.classList[k] == 'keydown') {
                            scope.$apply(function () {
                                scope.loadCustomerData();
                            });
                            event.preventDefault();
                        }
                    }
                }
            });
        },
        controller: function ($scope, $filter, Customer, Alert, CitiesMaster) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var self = this;
            var $translate = $filter('translate');

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.message = $translate('CONTRACTS.CLOSE_PAGE');

            $scope.customer = null;
            $scope.customerObject = null;
            $scope.newCustomer = false;
            $scope.search = true;

            $scope.customer_form = [
                'customer_vat_id',
                'customer_email',
                'customer_phone',
                'customer_fax',
                'customer_mobile',
                'customer_name',
                'customer_city',
                'customer_state',
                'customer_zip'
            ];

            $scope.payment_methods = [
                {code: 'TRF', label: $translate('INVOICES.TRANSFER')},
                {code: 'DBT', label: $translate('INVOICES.DEBIT')},
                {code: 'B2B', label: $translate('INVOICES.DEBIT_B2B')}
            ];

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$watchCollection('customerObject', function (newVal, oldVal) {
                if (newVal)
                    if (newVal.data_copy != null && newVal.data_copy) {
                        var vat_id = $scope.customer.vat_id;
                        $scope.customer = $scope.customerObject;
                        $scope.customer.vat_id = vat_id;
                        $scope.customerObject = null;
                        $scope.customerValidation.$setDirty();
                    }
            });

            $scope.$watch('customerValidation.$pristine', function (newValue, oldValue) {
                if (!newValue)
                    $scope.customerObject = null;
            });

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/
            this.cleanCharacters = function(value){
                if (value){
                    return value.replace(/[+*@\\"]/g, "");
                }
                else {
                    return value;
                }
            };

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/
            $scope.cleanCustomerData = function () {
                $scope.vat_id = null;
                $scope.newCustomer = false;
                $scope.customer = null;
                $scope.search = true;
            };

            $scope.loadCustomerData = function () {
                if ($scope.vat_id) {
                    Customer.get({vat_id: $scope.vat_id},function (customer){
                            $scope.search = false;

                            if (customer.results.length != 0) {
                                $scope.customer = customer.results[0];
                                $scope.customerObject = customer.results[0];
                            } else {
                                $scope.customer = {};
                                $scope.customer.vat_id = $scope.vat_id;
                                $scope.customer.web_access = true;
                                $scope.newCustomer = true;
                            }

                        }, function (error) {
                        });
                }
            };

            $scope.createCustomer = function () {
                // Remove " and + character form name
                $scope.customer.name = self.cleanCharacters($scope.customer.name);
                $scope.customer.surname_1 = self.cleanCharacters($scope.customer.surname_1);
                $scope.customer.surname_2 = self.cleanCharacters($scope.customer.surname_2);

                var new_customer = new Customer($scope.customer);
                new_customer.$save(function(customer) {
                        $scope.customer = customer;
                        $scope.newCustomer = false;
                        $scope.customerObject = customer;
                        Alert.createAlert('success', $translate('CONTRACTS.CUSTOMER_CREATED'), true);
                    }, function(error) {
                        for (var k in error.data) {
                            $scope.customerValidation['customer_' + k].$error.api = error.data[k][0];
                        }
                    });
            };

            $scope.updateCustomer = function (form) {
                form.$setUntouched();

                for (var i = 0; i < $scope.customer_form.length; i++) {
                    $scope.customerValidation[$scope.customer_form[i]].$error.api = null;
                }

                // Remove " and + character form name
                $scope.customer.name = self.cleanCharacters($scope.customer.name);
                $scope.customer.surname_1 = self.cleanCharacters($scope.customer.surname_1);
                $scope.customer.surname_2 = self.cleanCharacters($scope.customer.surname_2);

                var update_customer = new Customer($scope.customer);

                Customer.update({id: $scope.customer.id}, update_customer, function (customer) {
                    Alert.createAlert('warning', $translate('CONTRACTS.Customer advise'), true);

                    $scope.customer = customer;
                    form.$setPristine();

                    $scope.customerObject = customer;
                }).catch(function (error) {
                    for (var k in error.data) {
                        $scope.customerValidation['customer_' + k].$error.api = error.data[k][0];
                    }
                });
            };

            $scope.change_web_access = function (value) {
                $scope.customerValidation.$setDirty();
                $scope.customer.web_access = value;
            };

            /***********************************************************************************************************
             * Controller Initialization
             ***********************************************************************************************************/
            CitiesMaster.loadCities().then(function (response) {
                $scope.cities = response;
            });

        }
    }
}

export { customerData };
