function labelsObjectManagement(labelsConfig) {
    return {
        restrict: 'E',
        scope: {
            scope: '@',
            objectId: '@',
            contentType: '@',
            selectData: '=',
            add: '=',
            delete: '='
        },
        templateUrl: require('../../templates/labels-object-management.html'),
        controller: function ($q, $scope, $http) {
            var self = this;
            var api = labelsConfig.API_PREFIX + '/' + $scope.contentType + '/' + $scope.objectId + labelsConfig.API_SUFFIX;
            $scope.Math = window.Math;
            $scope.loading = false;
            $scope.current = api;
            $scope.label = null;
            $scope.objectLabels = [];
            $scope.viewForm =  false
            var deferred = $q.defer();

            this.loadData = function () {
                this.xhr = $http.get($scope.current, {timeout: deferred.promise}).then(function (response) {
                    let data = response.data
                    $scope.count = data.length;
                    $scope.perPage = data;
                    $scope.objectLabels = [];

                    angular.forEach(data, function (item) {
                        item.editMode = false;
                        item.deleteMode = false;
                        item.newText = item.name;
                        this.push(item);
                    }, $scope.objectLabels);

                }).catch(function () {
                    $scope.objectLabels = [];
                });
            };
            $scope.removeLabel = function (id_label) {
                this.xhr = $http.delete($scope.current + id_label + '/', {timeout: deferred.promise}).then(function (data, status, headers, config) {
                    self.loadData()
                }).catch(function (data, status, headers, config) {
                    $scope.label = null;
                });
            };
            $scope.viewFormAction = function () {
                if ($scope.viewForm === true) {
                    $scope.viewForm = false
                } else {
                    $scope.viewForm = true
                }
            };
            $scope.$watch('label', function (newValue, oldValue ){
                if (newValue && newValue.name != null) {
                    var data = {'label': newValue.id}
                    this.xhr = $http.post($scope.current, data, {timeout: deferred.promise}).then(function (data, status, headers, config) {
                        self.loadData()
                        $scope.label = null;
                        $scope.viewForm =  false
                    }).catch(function (data, status, headers, config) {
                        $scope.label = null;
                        $scope.viewForm =  false
                    });
                }
            })
            self.loadData()
        }
    }
}

export { labelsObjectManagement }
