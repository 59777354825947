// const contractDeletion = function
function contractDeletion(UserInfo, filtersUtils, Alert, $filter) {
    return {
        restrict: 'AE',
        scope: {},
        //templateUrl: require('../../static/contracts/templates/delete-button.html'),
        templateUrl: require('../../templates/delete-button.html'),
        controller: function ($scope, $http, $rootScope) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/contracts/delete/';
            var $translate = $filter('translate');
            var self = this;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.listToDelete = [];

            $scope.hasPerm = UserInfo.hasPerm('contracts.destroy_contracts');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToDelete = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var json = {ids: ids};

                var config = {
                    method: "post",
                    url: api,
                    data: json,
                    headers: {"Content-Type": "application/json;charset=utf-8"}
                };
                $http(config).
                    then(function(response) {
                        let data = response.data;
                        Alert.createAlert('success', $translate('CONTRACTS.DELETED') + data + ' ' + $translate('CONTRACTS.TITLE'), true);
                        $rootScope.$broadcast('refreshList');
                    }).
                    catch(function (response) {

                    }).
                    finally(function () {
                        $scope.flagConfirm = false;
                        $scope.listToDelete = [];
                    });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNoDelete = function () {
                $scope.flagConfirm = false;
            };

            $scope.clickYesDelete = function () {
                var ids = filtersUtils.getItemsToDownload($scope.listToDelete);
                performRequest(ids);
            };

        }
    }
}

export {contractDeletion}
