
const ChangeContractForSupplyDatesCtrl = ['$scope', '$location', '$routeParams', '$http', '$filter', 'Alert', 'DateUtils',
    function ($scope, $location, $routeParams, $http, $filter, Alert, DateUtils) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        var my_dates = {};

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.date_filters = {
            activation_date: null,
            finish_date: null
        };

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        $scope.$watchCollection('date_filters', function(newVal, oldVal){
            $scope.validDates = false;

            if (oldVal.activation_date !== null) {
                my_dates["activation_date"] = null;
                $scope.validDates = false;
            }
            if (oldVal.finish_date !== null) {
                my_dates["finish_date"] = null;
                delete my_dates["finish_date"];
                $scope.validDates = true;
            }
            if (newVal.activation_date !== null) {
                var from_date = DateUtils.transformToRESTyDate(newVal.activation_date);
                my_dates["activation_date"] = from_date;
                $scope.validDates = true;
            }
            if (newVal.finish_date !== null) {
                var to_date = DateUtils.transformToRESTyDate(newVal.finish_date);
                my_dates["finish_date"] = to_date;
                $scope.validDates = true;
            }
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.updateDatesContractForSupply = function () {
            $http.put(API_URL + '/api/1.0/contracts/' + $routeParams.id + '/change-dates/', my_dates).
                then(function(response) {
                    $location.path('/contracts/' + $routeParams.id);
                    Alert.createAlert('success', $translate('CONTRACTS.DATE_CONTRACT_FOR_SUPPLY_UPDATED_SUCCESS'), true);
                }).
                catch(function (response) {
                    Alert.createAlert('danger', $translate('CONTRACTS.DATE_CONTRACT_FOR_SUPPLY_UPDATED_ERROR'), true);
                });
        };

        $scope.back = function () {
            $location.path('/contracts/' + $routeParams.id);
        }
    }
]

export {ChangeContractForSupplyDatesCtrl};
