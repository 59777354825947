function apiDownload($rootScope, $cookies, $window) {
    return {
        scope: {},
        link: function(scope, element, attrs) {
            element.on('click', function(ev) {
                ev.preventDefault();
                var separator = attrs.apiDownload.includes('?') ? '&' : '?';
                var url = $rootScope.API_URL + attrs.apiDownload + separator + 'token=' + $cookies.get("token");
                $window.open(url);
                return false;
            });
        }
    };
};

export { apiDownload }
