function simpleReport() {
    return {
        restrict: 'E',
        templateUrl: require('../../templates/sc-single-report-directive.html'),
        controller: function($scope, $http, $window, $filter, SUPPLY_RATES, UserInfo, SOURCES, Task, Alert){
            var self = this;
            var $translate = $filter('translate');
            $scope.status = 'WAITING';
            $scope.rate = '';
            //$scope.cups = '';
            $scope.availableRates = SUPPLY_RATES;
            $scope.reportData = {};
            $scope.reportCupsData = '';
            $scope.empty = true;
            $scope.sources = SOURCES;
            $scope.source = '';

            var show = false;

            $scope.on = function(){
                show = true;
                return show;
            }

            $scope.off = function(){
                show = false;
                return show;
            }

            // actions
            $scope.generateReport = function () {
                $scope.status = 'LOADING';
                var url = API_URL + '/api/1.0/consumption-single-report/' + self.getQueryParams();
                $http.get(url).then(function (data, status, headers, config) {
                    var reportData = {};
                    $scope.empty = true;
                    for (var i = 0; i < data.data.report.length; i++) {
                        var day_of_week = data.data.report[i].data;
                        for (var j = 0; j < day_of_week.length; j++) {
                            var cellData = day_of_week[j];
                            if (typeof reportData[cellData.time] === 'undefined') {
                                reportData[cellData.time] = [
                                    {time: cellData.time, value: cellData.time, viewValue: cellData.time}
                                ];
                            }
                            if (false == isNaN(cellData.value)) {
                                cellData.viewValue = (Math.round(cellData.value * 100) / 100) + ' kWh';
                                cellData.highlight = false;
                            } else {
                                cellData.viewValue = cellData.value;
                                cellData.highlight = true;
                            }
                            $scope.empty = false;
                            reportData[cellData.time].push(cellData);
                        }
                    }
                    $scope.reportData = reportData;
                    $scope.reportCupsData = data.data.cups;
                    $scope.status = 'LOADED';
                }, function (data, status, headers, config) {
                    // TODO: better error control
                    $scope.status = 'ERROR';
                });
            };

            // CSV Single
            $scope.canDownloadSingleCSV = UserInfo.hasPerm('consumptions.download_single_report_csv');

            $scope.downloadCSV = function () {
                var task = new Task();
                task.type = 'GCRP';
                task.params = self.getJSONParams();
                task.params.type = 'single-report';
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('SUPPLIES.Single consumption report generation Task success'), true);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('SUPPLIES.Single consumption report generation Task error'), true);
                });

            };

            // CSV Daily
            $scope.canDownloadDailyCSV = UserInfo.hasPerm('consumptions.download_daily_report_csv');

            $scope.downloadCSVDaily = function () {
                var task = new Task();
                task.type = 'GCRP';
                task.params = self.getJSONParams();
                task.params.type = 'daily-report';
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('SUPPLIES.Daily consumption report generation Task success'), true);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('SUPPLIES.Daily consumption report generation Task error'), true);
                });
            };

            this.transformToRESTyDate = function (date) {
                var yyyy = date.getFullYear().toString();
                var mm = (date.getMonth() + 1).toString(); // getMonth() is zero-based
                var dd = date.getDate().toString();
                return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]); // padding
            };

            this.getQueryParams = function () {
                if ($scope.fromDate > $scope.toDate) {
                    var aux = $scope.fromDateOne;
                    $scope.fromDate = $scope.toDate;
                    $scope.toDate = aux;
                }
                var params = '?fromDate=' + this.transformToRESTyDate($scope.fromDate);
                params += '&toDate=' + this.transformToRESTyDate($scope.toDate);
                if ($scope.rate == undefined || $scope.rate == null ){
                    params += '&rate='
                }
                else{
                    params += '&rate=' + $scope.rate;
                }
                if ($scope.cups== undefined || $scope.cups == null ){
                    params += '&cups='
                }
                else{
                    params += '&cups=' + $scope.cups;
                }
                if ($scope.source == undefined || $scope.source == null) {
                    params += '&source=';
                }
                else {
                    params += '&source=' + $scope.source;
                }
                return params;
            };

            this.getJSONParams = function() {
                var params = {};
                if ($scope.fromDate > $scope.toDate) {
                    var aux = $scope.fromDateOne;
                    $scope.fromDate = $scope.toDate;
                    $scope.toDate = aux;
                }
                params.date_from = this.transformToRESTyDate($scope.fromDate);
                params.date_to = this.transformToRESTyDate($scope.toDate);

                if ($scope.rate != undefined && $scope.rate != null ){
                    params.rate = $scope.rate;
                }

                if ($scope.cups != undefined && $scope.cups != null ){
                    params.cups = $scope.cups;
                }

                if ($scope.source != undefined && $scope.source != null) {
                    params.source = $scope.source;
                }

                return params;
            };

        },
        controllerAs: 'simpleReport'
    };
}

export {simpleReport};
