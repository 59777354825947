function bulkInvoiceRefund(filtersUtils, Alert, $filter) {

    return {
        restrict: 'AE',
        scope: {},
        template: `
            <li class="nestedLi" ng-show="counter > 0">
                <span class="text-center" ng-click="confirmRefund();$event.stopPropagation();" >
                    <a href class="nodecorate" style="padding-top: 5px;">{{ "INVOICES.GENERATE_REFUNDS" | translate }}</a>
                </span>
            </li>

            <modal ng-show="flagConfirm" class="fullscreen-modal" id="generate_refunds_modal" role="dialog" ng-click="$event.stopPropagation();" tabindex="-1" aria-hidden="true">
                <div class="inmodal in">
                    <div class="modal-dialog">
                        <div class="modal-content animated bounceInRight">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal"><span
                                        aria-hidden="true" ng-click="clickNo()">×</span><span class="sr-only">Close</span></button>
                                <i class="fa fa-exclamation-triangle modal-icon"></i>
                                <h4 class="modal-title">{{ "INVOICES.GENERATE_REFUNDS" | translate }}</h4>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-xs-12 text-center"><label>{{ "INVOICES.GENERATE_REFUNDSQ" | translate }} {{ counter }}</label></div>
                                </div>
                                <div class="row">
                                    <ul>
                                        <li>
                                            <span class="col-xs-8">{{ "INVOICES.SINGLE_INVOICE" | translate }}</span>
                                            <input class="col-xs-2" type="checkbox" class="form-control" ng-model="single_invoice">
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button ng-click="clickNo()" type="button" class="btn btn-white" data-dismiss="modal">{{ "CANCEL" | translate }}</button>
                                <button ng-click="clickYes()" type="button" class="btn btn-primary" data-dismiss="modal">{{ "SAVE" | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </modal>
        `,
        controller: function ($scope, Task) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.listToDelete = [];
            $scope.single_invoice = false;

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToDelete = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                let json = {
                    ids: ids,
                    single_invoice: $scope.single_invoice
                };
                let task = new Task();
                task.type = 'RFND';
                task.params = json;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.BULK_REFUND_INVOICES_SUCCESS'), true);
                    $scope.backgroundTasks.push(data.data.id);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('INVOICES.BULK_REFUND_INVOICES_ERROR'), true);
                })
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.confirmRefund = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
            };

            $scope.clickYes = function () {
                let ids = filtersUtils.getItemsToDownloadToInt($scope.listToDelete);
                performRequest(ids);
                $scope.flagConfirm = false;
            };
        }
    }
}

export { bulkInvoiceRefund }
