function cups() {
    var letters = "TRWAGMYFPDXBNJZSQVHLCKE";

    var check = function(value) {
        if (value.length != 20 && value.length != 22)
            return false;

        value = value.trim();
        
        let aux = parseInt(value.substr(2,16));
        aux = aux/529;
        
        let aux_int = parseInt(aux);
        aux = (aux-aux_int)*529;
        aux = (aux/23)+0.001;

        let first_letter = parseInt(aux);
        let second_letter = Math.round(parseFloat((aux-first_letter)*23));
        
        if (value.substr(18,2) == letters[first_letter] + letters[second_letter])
            return true;
        else
            return false;
    };

    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ctrl) {
            ctrl.$validators.cups = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    // consider empty models to be invalid
                    return true;
                }
                return check(modelValue);
            };
        }

    };
}

export { cups }
