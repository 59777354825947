{
  "PROVIDERS": {
    "TITLE": "Lista de proveedores",
    "ALL": "Todos",
    "ANY": "Cualquiera",
    "NO_CLIENT": "Nº Cliente",
    "NIF/CIF": "NIF/CIF",
    "NAME": "Nombre",
    "STATE": "Provincia",
    "New Provider": "Crear Proveedor",
    "Delete": "Eliminar",
    "View": "Ver",
    "Name and surname": "Nombre y apellidos",
    "VAT Id": "NIF/CIF",
    "Create": "Crear",
    "Cancel": "Cancelar",
    "Yes": "Sí",
    "No": "No",
    "Accounting": "Cuenta Contable",
    "Email": "Email",
    "Phone": "Teléfono",
    "Mobile": "Móvil",
    "Fax": "Fax",
    "Address": "Dirección",
    "Address data": "Datos postales",
    "City": "Ciudad",
    "State": "Provincia",
    "ZIP code": "Código postal",
    "Update": "Actualizar",
    "Provider": "Proveedor",
    "Comments": "Comentarios",
    "Files": "Archivos",
    "Invalid Vat Id": "NIF/CIF Inválido",
    "Name required": "El nombre es obligatorio",
    "VAT Id is required": "El NIF/CIF es obligatorio",
    "Provider Updated": "Proveedor actualizado",
    "Do you want to delete": "Desea borrar el proveedor?",
    "Provider Data": "Datos del proveedor",
    "Provider Deleted": "Proveedor eliminado correctamente",
    "ERROR_OCCURRED": "Revise los datos introducidor.",
    "INVOICES": "Facturas de proveedor",
    "INVOICE": "Factura de proveedor",
    "RECONCILED": "Conciliada",
    "NOT_RECONCILED": "No conciliada",
    "IDN": "IDN",
    "TAX": "Impuesto",
    "ACTIONS": "Acciones",
    "DELETEQ": "¿Eliminar",
    "ELEMENTSQ": "elementos?",
    "DOWNLOAD_CSV": "Descargar CSV",
    "Bulk download CSV": "Descarga de CSV iniciada",
    "DOWNLOAD": "Descargar",
    "Bulk download generation Task success": "Descarga de CSV completada",
    "Bulk download generation Task error": "Error en descarga de CSV",
    "ALL_SELECTED": "Todos",
    "DELETED_PROVIDER_INVOICES": "Se han borrado  ",
    "DELETED_READINGS_ERROR": "No se han podido eliminar las facturas de proveedor seleccionadas. Alguna de ellas tiene lecturas de consumo facturadas.",
    "DELETED_NOT_FOUND_ERROR": "No se han podido eliminar las facturas de proveedor seleccionadas. Alguna de ellas no se ha encontrado.",
    "EMISSION_DATE": "Fecha de emisión",
    "PROVIDER_INVOICES": "  facturas de proveedor.  ",
    "NOT_DELETED_PROVIDER_INVOICES": " Facturas de Proveedor no han podido ser eliminadas.",
    "TAX_APPLIED" : "Impuestos aplicados",
    "BALANCE" : "Saldo",
    "CREATION_DATE" : "Fecha de creación",
    "TAX_BASE": "Base Imponible",
    "NORMAL": "Normal",
    "REFUND":"Abono",
    "REFUND_WITH_REISSUE": "Abono con refactura",
    "REISSUE": "Refactura",
    "ORIGIN_TYPE": "Tipo Origen",
    "REMITTANCE": "Remesa",
    "PENDING": "Pendiente",
    "PAID": "Pagado",
    "PROVIDER_STATUS_UPDATED": "Número de facturas de proveedor actualizadas: ",
    "SELECT_NEW_STATUS": "Seleccionar Estado",
    "CHANGE_QUESTION": "¿Cambiar?",
    "CHANGE_STATUS": "Cambiar Estado",
    "RECEIVE_PAPER_INVOICE": "Indicar fecha recepción en papel",
    "PAYMENTS": "Pagos",
    "CNMC_CODE": "Código CNMC (*)",
    "CNMC_REQUIRED": "El código cnmc es obligatorio",
    "CNMC_CODE_INFO": "(*) Consultar los códigos CNMC ",
    "CNMC_CODE_INFO_HERE": "Aquí",
    "CHANGE_PROVIDER": "Cambiar Proveedor",
    "PROVIDER_PROVIDER_UPDATED": "Número de proveedores en facturas de proveedor actualizadas: ",
    "RETURNED": "Devuelta",
    "SET_PAID_INVOICE_DATE": "Indicar Fecha de pago de facturas",
    "PAID_DATE": "Fecha de pago",
    "CHECKED": "Chequeada",
    "DISTRIBUTOR": "Distribuidora",
    "DOMICILED": "Domiciliado",
    "NOT_DOMICILED": "No Domiciliado",
    "STATUS": "Estado",
    "SELECT_BANK_AND_DATE": "Indicar Banco y fecha de Pago",
    "BANK": "Banco pago",
    "CUPS": "CUPS",
    "APPLY_FILTER_DATA": "Aplicar filtro de fecha",
    "APPLIED_DATES_FILTER": "Filtros de fecha aplicados:",
    "EXPIRATION_DATE": "Fecha de vencimiento",
    "SELECT_EXPIRED_DATE": "Indicar fecha de vencimiento",
    "SET_EXPIRATION_INVOICE_DATE": "Indicar Fecha de vencimiento de facturas",
    "TYPE_C": "Complementaria",
    "TYPE_G": "Regularizadora",
    "RECEIVE_PAPER_DATE": "Fecha recepción en papel",
    "RESET_ITEMS": "Regenerar objetos",
    "DUPLICATE_ITEMS": "Duplicar objetos",
    "BANK_ACCOUNT": "Cuenta bancaria",
    "SELECT_REFERENCE": "Seleccione fecha referencia",
    "Calculo fecha vencimiento": "Calculo fecha vencimiento",
    "Fecha referencia desde": "Fecha referencia desde",
    "Número de días": "Número de días",
    "BRAND": "Marca",
    "DISTRIBUTOR_CODE": "Código Distribuidora",
    "DISTRIBUTOR_CODE_REQUIRED": "El código de distribuidora es obligatorio",
    "CUPS_CODE": "Código Cups",
    "CUPS_CODE_REQUIRED": "El código Cups es obligatorio",
    "CONTRACT_TO_DUPLICATE": "Introducir el id contrato al que duplicar los items",
    "SET_CONTRACT_ID": "Introducir id contrato (opcional)",
    "FORCE_IMPORT": "Forzar importación",
    "FORCE_IMPORT_TASK": "Se ha creado una tarea para forzar la importación"
  }
}
