// const contractExtraEmails = function
function contractExtraEmails(){
    return {
        restrict: 'AE',
        require: 'ngModel',
        scope:{
            extra_emails: '=ngModel',
            condition: '@'
        },
        //templateUrl: require('../../static/contracts/templates/extra-emails.html'),
        templateUrl: require('../../templates/extra-emails.html'),
        link: function (scope, element, attrs, ctrl) {
            var inputs = element.find('input');

            inputs.bind('keyup', function (event) {
                var extra_email_1 = '';
                var extra_email_2 = '';
                var extra_email_3 = '';
                var extra_email_4 = '';
                for (var input in inputs) {
                    if (inputs[input].value != undefined) {
                        if (inputs[input].name == 'extra_email_1') {
                            if (inputs[input].value != undefined)
                                extra_email_1 = inputs[input].value;
                        }
                        else if (inputs[input].name == 'extra_email_2') {
                            if (inputs[input].value != undefined)
                                extra_email_2 = inputs[input].value ;
                        }
                        else if (inputs[input].name == 'extra_email_3') {
                            if (inputs[input].value != undefined)
                                extra_email_3 = inputs[input].value;
                        }
                        else if (inputs[input].name == 'extra_email_4') {
                            if (inputs[input].value != undefined)
                                extra_email_4 = inputs[input].value;
                        }
                    }
                }
                scope.error = false;
                scope.extra_emails = extra_email_1 + ',' + extra_email_2 + ',' + extra_email_3 + ',' + extra_email_4;
                scope.$apply();
            });

            /*ctrl.$validators.contract_extra_emails = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue) || scope.extraEmailsData.$valid) {
                    return true;
                }
                else
                    return false;
            };*/


        },
        controller: function ($scope, stringUtils) {
            $scope.contract = {};

            $scope.$watchGroup(['condition', 'extra_emails'], function (newValues, oldValues, scope) {
                if (newValues[0])
                    $scope.condition = JSON.parse(newValues[0]);
                if (newValues[1]) {
                    var email = stringUtils.splitStringByComma($scope.extra_emails);
                    $scope.contract.extra_email_1 = stringUtils.getExtraEmail(email[0]);
                    $scope.contract.extra_email_2 = stringUtils.getExtraEmail(email[1]);
                    $scope.contract.extra_email_3 = stringUtils.getExtraEmail(email[2]);
                    $scope.contract.extra_email_4 = stringUtils.getExtraEmail(email[3]);

                }
            });
        }
    }
}


export {contractExtraEmails}
