const CalendarDaysAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/calendar-days/';

        var self = this;
        var calendar_day = null;

        function deleteCalendarDay(id) {
            var deferred = $q.defer();

            $http.delete(url + id)
                .then(function (calendar_day) {
                    deferred.resolve(calendar_day.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        function getCalendarDayDetail(id) {
            if (calendar_day == null || calendar_day.id != id) {
                calendar_day = {
                    'id': id
                };
                return _getACalendarDayDetail(id);
            } else {
                var deferred = $q.defer();
                deferred.resolve(calendar_day);
                return deferred.promise;
            }
        }

        function _getACalendarDayDetail(id) {
            var deferred = $q.defer();

            $http.get(url + id)
                .then(function (calendar_day) {
                    deferred.resolve(calendar_day.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

            self.id = id;
            return deferred.promise;
        }

        function createCalendarDay(calendar_day) {
            var deferred = $q.defer();

            $http.post(url, calendar_day)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        return {
            deleteCalendarDay: deleteCalendarDay,
            getCalendarDayDetail: getCalendarDayDetail,
            createCalendarDay: createCalendarDay,
        };

    }
]

export { CalendarDaysAPI };
