const SipsCtrl = ['$scope', 'SupplyAPI', '$filter', 'SUPPLY_RATES', 'Alert', 'Task',
    function ($scope, SupplyAPI, $filter,  SUPPLY_RATES, Alert, Task) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        
        var sips_api_url =API_URL  + "/api/1.0/sips/";
        var energy_consumptions_url =API_URL  + "/api/1.0/energy-consumptions/";
        
        var powerMatrix = {
            T20A: [false, true, false, false, false, false],
            T20DHA: [true, false, true, false, false, false],
            T21A: [false, true, false, false, false, false],
            T21DHA: [true, false, true, false, false, false],
            T30A: [true, true, true, false, false, false],
            T31A: [true, true, true, false, false, false],
            T31DHA: [true, true, true, false, false, false],
            T61: [true, true, true, true, true, true],
            T62: [true, true, true, true, true, true],
            T63: [true, true, true, true, true, true],
            T64: [true, true, true, true, true, true]
        };

        var ratesPeriods = {
            T20A: 1, T20DHA: 2, T21A: 1, T21DHA: 2, T30A: 3, T31A: 3, T31DHA: 3, T61: 6, T62: 6, T63: 6, T64: 6
        };
        
        var total_days = 0;
        var power_set = false;
        var invoiced_power_constant = 0.85;

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.rates = SUPPLY_RATES;
        $scope.supply_loaded = false;
        $scope.supply_consumption_loaded = false;
        $scope.supply_info = {};
        $scope.consumptions = {};
        
        $scope.exists_consumption_data = false;

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        // RecoverConsumptions needs rate_type to be launched
        $scope.$watch('rate_type', function (newValue, oldValue) {
            if (newValue)
                recoverConsumptions();
        }, true);

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/
        function processConsumptions() {
            if ($scope.consumptions.length < 1) {
                $scope.exists_consumption_data = false;
            } else {
                $scope.exists_consumption_data = true;
                for (var consumption = 0; consumption < $scope.consumptions.length; consumption++) {

                    var date_from = new Date($scope.consumptions[consumption]['fechaInicioConsumo']);
                    var date_to = new Date($scope.consumptions[consumption]['fechaFinConsumo']);
                    var days = parseInt((date_to - date_from) / (1000 * 60 * 60 * 24));

                    // transform Dates format
                    $scope.consumptions[consumption]['fechaInicioConsumo'] = getFormattedDate($scope.consumptions[consumption]['fechaInicioConsumo']);
                    $scope.consumptions[consumption]['fechaFinConsumo'] = getFormattedDate($scope.consumptions[consumption]['fechaFinConsumo']);

                    $scope.consumptions[consumption]['days'] = days;
                    if (power_set == false) {
                        $scope.consumptions[consumption].invoiced_power = {};
                        $scope.consumptions[consumption].recommended_invoiced_power = {};
                        $scope.contract_power = {};
                        $scope.recommended_contract_power = {};
                    }
                    total_days += days;

                    var reactive_energy_consumptions = $scope.consumptions[consumption]['consumoEnergiaReactivaEnKVAR'].split(";");
                    var active_energy_consumptions = $scope.consumptions[consumption]['consumoEnergiaActivaEnKWh'].split(";");
                    var power_demand = $scope.consumptions[consumption]['potenciaDemandadaEnW'].split(";");



                    for (var period = 0; period < 6; period++) {
                        $scope.consumptions[consumption]['power_demand_p' + parseInt(period + 1)] = power_demand[period];
                        $scope.consumptions[consumption]['active_consumption_p' + parseInt(period + 1)] = active_energy_consumptions[period];
                        $scope.consumptions[consumption]['reactive_consumption_p' + parseInt(period + 1)] = reactive_energy_consumptions[period];
                        /*
                        if (powerMatrix[$scope.rate_type][period] == true) {
                            $scope.consumptions[consumption]['active_energy_consumption_p' + parseInt(period + 1)] = active_energy_consumptions.shift();
                            // potencia demanda maximetros
                            if (different_rate)
                                $scope.consumptions[consumption]['power_demand_p' + parseInt(period + 1)] = 'Tarifa diferente a la actual';
                            else {
                                var demand = parseInt(power_demand.shift() / 1000);
                                if (isNaN(demand))
                                    $scope.consumptions[consumption]['power_demand_p' + parseInt(period + 1)] = "No hay datos disponibles";
                                else
                                    $scope.consumptions[consumption]['power_demand_p' + parseInt(period + 1)] = demand;
                            }

                            var contract_power = getContractPowerPeriod(period + 1);
                            // potencia facturada
                            if (contract_power != 0 && contract_power != null) {
                                if (power_set == false)
                                    $scope.contract_power['Potencia P' + (parseInt(period) + 1) + ' (kW)'] = contract_power;

                                if (isNaN($scope.consumptions[consumption]['power_demand_p' + parseInt(period + 1)]))
                                    $scope.consumptions[consumption].invoiced_power['Potencia P' + (parseInt(period) + 1) + ' (kW)'] = $scope.contract_power['Potencia P' + (parseInt(period) + 1) + ' (kW)'];
                                else
                                    $scope.consumptions[consumption].invoiced_power['Potencia P' + (parseInt(period) + 1) + ' (kW)'] = calculatePowerFromMaximeter($scope.consumptions[consumption]['power_demand_p' + parseInt(period + 1)], $scope.contract_power['Potencia P' + (parseInt(period) + 1) + ' (kW)']);

                            } else {
                                $scope.consumptions[consumption].invoiced_power['Potencia P' + (parseInt(period) + 1) + ' (kW)'] = 0;
                            }
                        }
                        */
                    }
                }
            }
        }

        function calculatePowerFromMaximeter(maximeter_power, contracted_power) {
            var power_to_pay = maximeter_power;
            if (maximeter_power < (contracted_power * invoiced_power_constant)) {
                power_to_pay = (contracted_power * invoiced_power_constant);
            } else if (maximeter_power > (1.05 * contracted_power)) {
                power_to_pay = maximeter_power + (2 * (maximeter_power - (1.05 * contracted_power)));
            }
            return power_to_pay;
        }

        function recoverConsumptions() {
            SupplyAPI.getEnergyConsumptions($scope.cups.substring(0, 20)).then(function (response) {
                let data = response.data

                $scope.consumptions = data;

                processConsumptions();
                $scope.supply_consumption_loaded = true;
            });
        }

        function getContractPowerPeriod(period) {
            var power = 0;
            switch (period) {
                case 1:
                    power = $scope.supply_info.contract_power_p1;
                    break;
                case 2:
                    power = $scope.supply_info.contract_power_p2;
                    break;
                case 3:
                    power = $scope.supply_info.contract_power_p3;
                    break;
                case 4:
                    power = $scope.supply_info.contract_power_p4;
                    break;
                case 5:
                    power = $scope.supply_info.contract_power_p5;
                    break;
                case 6:
                    power = $scope.supply_info.contract_power_p6;
                    break;
            }
            return power;
        }

        function getSipInformation(){
            SupplyAPI.getSupplyFromSIPS($scope.cups.substring(0, 20)).then(function(response) {
                    let supply = response.data;

                    $scope.supply_info = supply;

                    $scope.cups = supply._id;
                    $scope.supply_info.client_name = supply.name;
                    $scope.supply_info.zip = supply.zip;
                    $scope.supply_info.city = supply.city;
                    $scope.supply_info.state = supply.state;
                    $scope.supply_info.supply_address = supply.address;

                    $scope.supply_info.rate_type = supply.rate;
                    $scope.rate_type = supply.rate;
                    $scope.supply_info.consumption_profile_type = (supply.consumption_profile_type).toUpperCase();
                    $scope.supply_info.contract_power_p1 = parseFloat(supply.power_p1);
                    $scope.supply_info.contract_power_p2 = parseFloat(supply.power_p2);
                    $scope.supply_info.contract_power_p3 = parseFloat(supply.power_p3);
                    $scope.supply_info.contract_power_p4 = parseFloat(supply.power_p4);
                    $scope.supply_info.contract_power_p5 = parseFloat(supply.power_p5);
                    $scope.supply_info.contract_power_p6 = parseFloat(supply.power_p6);
                    $scope.supply_info.last_reading_on = new Date(supply.last_reading_on);

                    $scope.supply_info.max_authorised_power_bie = parseFloat(supply.max_authorised_power_bie / 1000);
                    $scope.supply_info.voltage = supply.voltage;
                    $scope.supply_info.access_rights_recognized = parseFloat(supply.access_rights_recognized / 1000) ;
                    $scope.supply_info.extension_rights_reconigzed = parseFloat(supply.extension_rights_reconigzed / 1000) ;

                    $scope.supply_info.vat_id = supply.vat_id;
                    $scope.supply_info.contract_started_on = new Date(supply.contract_started_on);
                    $scope.supply_info.last_trading_change_on = new Date(supply.last_trading_change_on);
                    $scope.supply_info.last_recruitment_change_on = new Date(supply.last_recruitment_change_on);

                    $scope.supply_info.icp_installed = supply.icp_installed;
                    $scope.supply_info.deposit_warranty = parseFloat(supply.deposit_warranty);
                    $scope.supply_info.owns_measurement_device = supply.owns_measurement_device;
                    $scope.supply_info.owns_icp = supply.owns_icp;
                    $scope.supply_info.usual_housing = supply.usual_housing;
                    $scope.supply_info.ps_classification = supply.ps_classification;
                    $scope.supply_info.nt_code = supply.nt_code;
                    $scope.supply_info.non_payment = supply.non_payment;

                    $scope.supply_loaded = true;

                }).catch(function (error) {
                    Alert.createAlert('danger', $translate('SUPPLIES.CUPS_NPT_FOUND'), true);
                });
        }

        function getFormattedDate(date) {
            var todayTime = new Date(date);
            var month = todayTime.getMonth() + 1;
            if (month < 10)
                month = "0" + month;
            var day = todayTime.getDate();
            var year = todayTime.getFullYear();
            return day + "/" + month + "/" + year;
        }

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.cleanSupplyData = function () {
            $scope.supply = null;
            $scope.supply_loaded = false;
            $scope.rate_type = null;
            $scope.supply_consumption_loaded = false;
        };
        
        $scope.numberOfPeriods = function () {
            var total = 0;
            for (var period in powerMatrix[$scope.rate_type]) {
                if (powerMatrix[$scope.rate_type][period] == true) total++;
            }
            return total;
        };

        $scope.loadSupplyData = function (form) {
            if ($scope.cups != "") {
                form.$setPristine();
                getSipInformation();
            }
        };

        $scope.powerActive = function (powerIndex) {
            if ($scope.rate_type)
                return powerMatrix[$scope.rate_type][powerIndex - 1];
        };


        $scope.requestReport = function () {
            var request_params = {'slug': 'consumos_sips',
                                    'values': {'cups': $scope.cups}};
                                    var task = new Task();
            task.type = 'GREP';
            task.params = request_params;

            task.start().then(function (data, status, headers, config) {
                Alert.createAlert('success', $translate('REPORTS.GENERATING_REPORT'), true);
            }, function (data, status, headers, config) {
                Alert.createAlert('danger', $translate('REPORTS.ERROR_WHEN_GENERATING'), true);
            });

        };
    }
]

export {SipsCtrl};
