function setIssuesAsChecKed($filter, UserInfo, Alert, filtersUtils, IssueAPI) {
    return {
        restrict: 'AE',
        scope: {},
        templateUrl: require('../../templates/change-issue-checked.html'),
        controller: function ($scope, $http, $rootScope) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var self = this;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.hasPerm = UserInfo.hasPerm('issues.change_issue_checked');
            
            $scope.checked_types = [
                {code: 'CHE', name: $translate('ISSUES.CHECKED')},
                {code: 'PEN', name: $translate('ISSUES.PENDING')},
                {code: 'CLO', name: $translate('ISSUES.CLOSED')}
            ];

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var json = {
                    ids: ids,
                    checked: $scope.change_checked_value
                };

                IssueAPI.setIssuesAsChecKed(json)
                    .then(function (data) {
                        Alert.createAlert('success', $translate('ISSUES.ISSUES_STATUS_UPDATED') + data.updated_checked, true);
                        $rootScope.$broadcast('refreshList');
                    }).catch(function (error) {
                        Alert.createAlert('danger', $translate('ISSUES.ERROR_REQUEST'), true);
                    }).
                    finally(function () {
                        $scope.resetCheckedForm();
                });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.resetCheckedForm = function () {
                $scope.flagConfirm = false;
                $scope.listToUpdate = [];
                $scope.change_checked_value = '';
            };

            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
                $scope.change_checked_value = '';
            };

            $scope.clickYes = function () {
                var ids = filtersUtils.getItemsToDownload($scope.listToUpdate);
                performRequest(ids);
            };

            /***********************************************************************************************************
            * Controller Initialization
            ***********************************************************************************************************/
            $scope.resetCheckedForm();

        }
    }
}

export { setIssuesAsChecKed }
