const InvoicesFeesCtrl = ['$scope', '$routeParams', '$filter', 'DateUtils',
    function ($scope, $routeParams, $filter, DateUtils) {
        var $translate = $filter('translate');

        var self = this;
        $scope.code = $routeParams.id;

        $scope.filters = {
            'invoice': $scope.code
        };

        $scope.filter = function () {
            if ($scope.fromDate)
                $scope.filters['from_date'] = DateUtils.transformToRESTyDateWODays($scope.fromDate);
            else
                delete $scope.filters['from_date'];

            if ($scope.toDate)
                $scope.filters['to_date'] = DateUtils.transformToRESTyDateWODays($scope.toDate);
            else
                delete $scope.filters['to_date'];
        };

        $scope.ordering = {
            'date': $translate('AGREEMENTS.DATE')
        };

    }
]

export { InvoicesFeesCtrl }
