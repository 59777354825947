const TaxRuleAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/taxes/';
        var url_rules = '/rules/';
        var url_tax_rules = API_URL + '/api/1.0/tax-rules/';

        var self = this;
        var tax = null;

        function getAllTaxRules() {
            var deferred = $q.defer();

            $http.get(url_tax_rules)
                .then(function (tax_rules) {
                    deferred.resolve(tax_rules.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }


        function createTaxRule(tax_id, rule) {
            var deferred = $q.defer();

            $http.post(url + tax_id + url_rules, rule)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function updateTaxRule(id, code, rule) {
            var deferred = $q.defer();

            $http.put(url + id + url_rules + code, rule)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function deleteTaxRule(id, code) {
            var deferred = $q.defer();

            $http.delete(url + id + url_rules + code)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            getAllTaxRules: getAllTaxRules,
            createTaxRule: createTaxRule,
            updateTaxRule: updateTaxRule,
            deleteTaxRule: deleteTaxRule
        };

    }

]

export { TaxRuleAPI }
