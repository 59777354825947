const IssueDetailCtrl = ['$scope', '$filter', '$routeParams', 'UserInfo', 'IssueAPI', 'Alert', 'eventInfoService',
    function ($scope, $filter, $routeParams, UserInfo, IssueAPI, Alert, eventInfoService) {

        $scope.ISSUES_ACTIONS_DROPDOWN = require('../../templates/actions-dropdown-detail.html')
        $scope.ISSUES_DETAIL_RESULT = require('../../templates/issues-detail-result.html')
        $scope.ISSUES_DETAIL_LOGS = require('../../templates/issues-detail-logs.html')

        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.id = $routeParams.id;

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/

        $scope.canEditIssue = function () {
            return UserInfo.hasPerm('issues.change_issue');
        };

        $scope.canListFiles = function () {
            return UserInfo.hasPerm('issues.list_issues_files');
        };

        $scope.canIssueMakeActions= function () {
            return this.can_duplicate_issue();
        };

        $scope.can_duplicate_issue = function () {
            return UserInfo.hasPerm('issues.duplicate_issue');
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/

    }
]

export { IssueDetailCtrl }
