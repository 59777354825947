// const contractStatus = function
function contractStatus(UserInfo, Alert, filtersUtils, $filter){
    return {
        restrict: 'AE',
        scope: {},
        //templateUrl: require('../../static/contracts/templates/contracts-status-dropdown.html'),
        templateUrl: require('../../templates/contracts-status-dropdown.html'),
        controller: function ($scope, $http, $rootScope) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/contracts-status/';
            var $translate = $filter('translate');
            var self = this;

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.status_date = new Date;

            // Permissions
            $scope.hasPerm_draft_contract = UserInfo.hasPerm('contracts.draft_contract');
            $scope.hasPerm_sent_contract = UserInfo.hasPerm('contracts.sent_contract');
            //$scope.hasPerm_signed_contract = UserInfo.hasPerm('contracts.signed_contract');
            $scope.hasPerm_application_contract = UserInfo.hasPerm('contracts.application_contract');
            $scope.hasPerm_pending_contract = UserInfo.hasPerm('contracts.pending_contract');
            $scope.hasPerm_not_processed_contract = UserInfo.hasPerm('contracts.not_to_process_contract');
            $scope.hasPerm_to_connect = UserInfo.hasPerm('contracts.to_connect');
            $scope.hasPerm_to_activate_sb_st = UserInfo.hasPerm('contracts.substatus_active');
            $scope.hasPerm_to_anomaly_fraud = UserInfo.hasPerm('contracts.change_from_substatus_anomaly_fraud');

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/
            // Confirmation flags
            $scope.setFlagsFalse = function () {
                $scope.flagChangeToDraft = false;
                $scope.flagChangeToSent = false;
                $scope.flagChangeToSigned = false;
                $scope.flagChangeToApp = false;
                $scope.flagChangeToNotProcessed = false;
                $scope.flagChangeToPending = false;
                $scope.flagChangeToPendingAtr = false;
                $scope.flagChangeConnect = false;
                $scope.flagChangeActivateSbST = false;
                $scope.flagChangeAnomalyFraud = false;
                $scope.flagChangeOriginalPending = false;
            };
            $scope.setFlagsFalse();

            // API Request
            function performRequest(ids, status) {
                var json = {ids: ids, sub_status: status};

                if (status != 'DRF') {
                    json['date'] = $scope.status_date;
                }

                $http.post(api, json).then(function (data, status, headers, config) {
                    data = data.data

                    var perms_text_error = '';
                    if (data.not_process_perms_exclueded >= 1) {
                        perms_text_error = '. ' + data.not_process_perms_exclueded + ' ' + $translate('CONTRACTS.CONTRACT_PERMS_NOT_PROCESS')
                    }
                    if (data.error_phone_number == 0) {
                        if (data.num_with_draft_account != 0)
                            Alert.createAlert('success', $translate('CONTRACTS.UPDATED') + data.updated_number + $translate('CONTRACTS.CONTRACT_STATUS')
                                + data.num_with_draft_account + $translate('CONTRACTS.CONTRACT_BANK_DEFAULT_NUMBER') + '. ' + data.contract_can_not_process + ' '
                                + $translate('CONTRACTS.CONTRACT_CAN_NOT_BE_PROCESS') + perms_text_error + '. ' + $translate('CONTRACTS.CONTRACT_FIXED_DATE') + ': ' + data.excluded_fixed_date, true);
                        else
                            Alert.createAlert('success', $translate('CONTRACTS.UPDATED') + data.updated_number + $translate('CONTRACTS.CONTRACT_STATUS') + '. '
                                + data.contract_can_not_process + ' ' + $translate('CONTRACTS.CONTRACT_CAN_NOT_BE_PROCESS') + perms_text_error + '. '
                                + $translate('CONTRACTS.CONTRACT_FIXED_DATE') + ': ' + data.excluded_fixed_date, true);
                    }
                    else {
                        if (data.num_with_draft_account != 0)
                            Alert.createAlert('success', $translate('CONTRACTS.UPDATED') + data.updated_number + $translate('CONTRACTS.CONTRACT_STATUS')
                                + data.error_phone_number + $translate('CONTRACTS.CONTRACT_STATUS_ERROR_NUMBER')
                                + data.num_with_draft_account + $translate('CONTRACTS.CONTRACT_BANK_DEFAULT_NUMBER') + '. ' + data.contract_can_not_process + ' '
                                + $translate('CONTRACTS.CONTRACT_CAN_NOT_BE_PROCESS') + perms_text_error + '. ' + $translate('CONTRACTS.CONTRACT_FIXED_DATE') + ': '+ data.excluded_fixed_date, true);
                        else
                            Alert.createAlert('success', $translate('CONTRACTS.UPDATED') + data.updated_number + $translate('CONTRACTS.CONTRACT_STATUS')
                                + data.error_phone_number + $translate('CONTRACTS.CONTRACT_STATUS_ERROR_NUMBER') + '. ' + data.contract_can_not_process + ' '
                                + $translate('CONTRACTS.CONTRACT_CAN_NOT_BE_PROCESS') + perms_text_error + '. ' + $translate('CONTRACTS.CONTRACT_FIXED_DATE') + ': '+ data.excluded_fixed_date, true);
                    }

                    $rootScope.$broadcast('refreshList');
                }).catch(function (data, status, headers, config) {
                    if (status == 403)
                        Alert.createAlert('danger', $translate('CONTRACTS.ERROR_PERMISSIONS'), true);
                    else
                        Alert.createAlert('danger', $translate('CONTRACTS.ERROR_REQUEST'), true);
                }).finally(function () {
                    $scope.listToUpdate = [];
                    $scope.setFlagsFalse();
                });
            }

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/
            // Click on Status to change
            $scope.changeStatusTo = function (new_status) {
                if ($scope.counter > 0)
                    switch (new_status) {
                        case 'RDY':
                            $scope.flagChangeToDraft = true;
                            break;
                        case 'SNT':
                            $scope.flagChangeToSent = true;
                            break;
                        case 'SGN':
                            $scope.flagChangeToSigned = true;
                            break;
                        case 'APP':
                            $scope.flagChangeToApp = true;
                            break;
                        case 'DND':
                            $scope.flagChangeToPending = true;
                            break;
                        case 'DNP':
                            $scope.flagChangeToNotProcessed = true;
                            break;
                        case 'CON':
                            $scope.flagChangeConnect = true;
                            break;
                        case 'DNA':
                            $scope.flagChangeToPendingAtr = true;
                            break;
                        case 'ACT':
                            $scope.flagChangeActivateSbST = true;
                            break;
                        case 'ANO':
                            $scope.flagChangeAnomalyFraud = true;
                            break;
                        case 'ORP':
                            $scope.flagChangeOriginalPending = true;
                    }
            };

            // Click yes
            $scope.changeStatusRequest = function (new_status) {
                var ids = filtersUtils.getItemsToDownload($scope.listToUpdate);
                switch (new_status) {
                    case 'RDY':
                        performRequest(ids, 'RDY');
                        break;
                    case 'SNT':
                        performRequest(ids, 'SNT');
                        break;
                    case 'SGN':
                        performRequest(ids, 'SGN');
                        break;
                    case 'APP':
                        performRequest(ids, 'APP');
                        break;
                    case 'DND':
                        performRequest(ids, 'DND');
                        break;
                    case 'DNA':
                        performRequest(ids, 'DNA');
                        break;
                    case 'DNP':
                        performRequest(ids, 'DNP');
                        break;
                    case 'CON':
                        performRequest(ids, 'CON');
                        break;
                    case 'ACT':
                        performRequest(ids, 'ACT');
                        break;
                    case 'ANO':
                        performRequest(ids, 'ANO');
                        break;
                    case 'ORP':
                        performRequest(ids, 'ORP')
                }
            };

        }
    }
}

export {contractStatus}
