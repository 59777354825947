
const SuppliesRatesLoading = ['$resource', function ($resource) {
    return $resource(API_URL + '/api/1.0/masters/supply-rates/');
}]

const SupplyRatesMaster = ['$q', 'SuppliesRatesLoading',  function ($q, SuppliesRatesLoading) {

    var supplies= {
        items: null,

        loadSuppliesRates: function () {
            var self = this;
            var deferred = $q.defer();

            if (this.items == null)
                SuppliesRatesLoading.get(function (data) {
                    self.items = data;
                    deferred.resolve(data);
                });
            else
                deferred.resolve(self.items);

            return deferred.promise;
        }
    };

    return supplies;


}]
    
export {SuppliesRatesLoading, SupplyRatesMaster}
