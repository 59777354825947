/**
 * Created by aurora on 27/6/16.
 */
const DeleteCalendarDayCtrl = ['$scope', '$filter', 'Alert', 'CalendarDaysAPI', 'UserInfo',
    function ($scope, $filter, Alert, CalendarDaysAPI, UserInfo) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        $scope.actionDay = '';
        $scope.deletionDayMode = false;

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.deleteCalendarDay = function ($event) {
            $event.preventDefault();
            $scope.deletionDayMode = true;
            $scope.actionDay = 'delete';
        };

        $scope.cancelDeletedCalendarDay = function ($event) {
            $event.preventDefault();
            $scope.actionDay = '';
            $scope.deletionDayMode = false;
        };

        $scope.confirmDeleteCalendarDay = function (id) {
            $scope.loading = true;

            CalendarDaysAPI.deleteCalendarDay(id).then(function (data) {
                $scope.loading = false;
                $scope.listReload();
                Alert.createAlert('success', $translate('CALENDARS.CALENDAR_DAY_DELETED'), true);
                $scope.deletionDayMode = false;
            }).catch(function (error) {
                $scope.loading = false;
                $scope.error_message = error.data.__all__[0];
            });
        };

        $scope.canDeleteCalendarDay = function () {
            return UserInfo.hasPerm('calendars.delete_day');
        };

    }
]

export { DeleteCalendarDayCtrl };
