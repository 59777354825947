const ExcludedProductApi = ['DateUtils', '$q', '$http',
    function(DateUtils, $q, $http) {

        var url = API_URL + '/api/1.0/excluded_products/';
        var excluded_product = null;

        function createExcludedProduct(excluded_product) {
            var deferred = $q.defer();

            $http.post(url, excluded_product)
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function setExcludedProductDetail(id, excluded_product) {
            var deferred = $q.defer();

            $http.put(url + id, excluded_product)
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function setExcludedProductDetailPartial(id, excluded_product) {
            var deferred = $q.defer();

            $http.patch(url + id, excluded_product)
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function deleteExcludedProduct(id) {
            var deferred = $q.defer();

            $http.delete(url + id)
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            createExcludedProduct: createExcludedProduct,
            setExcludedProductDetail: setExcludedProductDetail,
            setExcludedProductDetailPartial: setExcludedProductDetailPartial,
            deleteExcludedProduct: deleteExcludedProduct
        };
    }
]

export {ExcludedProductApi}
