function notificationSound() {
    var self = this;

    this.audio = new Audio();
    var canPlayOgg = this.audio.canPlayType("audio/ogg") != "";
    var canPlayMpeg = this.audio.canPlayType("audio/mpeg") != "";
    var canPlayMp4 = this.audio.canPlayType("audio/mp4") != "";

    var notificationSound = {
        ogg: null,
        mpeg: '../../../../notifications/sounds/new-notification.mp3',
        mp4: null
    };

    var newSound = null;
    if (canPlayMp4 && notificationSound.mp4 != null) newSound = notificationSound.mp4;
    if (canPlayMpeg && notificationSound.mpeg != null) newSound = notificationSound.mpeg;
    if (canPlayOgg && notificationSound.ogg != null) newSound = notificationSound.ogg;
    this.audio.src = newSound;
    this.audio.load();
    this.audio.onended = function () {
        // TODO fix this shit that loads the audio again and again
        self.audio.load();
        self.audio.pause();
    };

    this.play = function () {
        self.audio.play();
    };

}

export { notificationSound }
