{
  "PRODUCTS": {
    "TITLE": "Productos",
    "NEW_PRODUCT": "Nuevo producto",
    "SEARCH": "Buscar...",
    "CODE": "Código",
    "PRODUCT_NAME": "Nombre producto",
    "DESCRIPTION": "Descripción",
    "PRICE": "Precio",
    "SAVE": "Guardar",
    "CANCEL": "Cancelar",
    "EDIT": "Editar",
    "DELETE": "Eliminar",
    "YES": "Si",
    "NO": "No",
    "DELETE_PRODUCT": "¿Eliminar producto?",
    "PRODUCTS_RATES": "Tarifas de productos",
    "NEW_RATE": "Nueva tarifa",
    "ERROR": "Error",
    "NAME": "Nombre",
    "DELETE_RATE": "¿Eliminar tarifa?",
    "SHOW": "Ver",
    "RATES": "Tarifas",
    "DATE_RATE_PRODUCTS": "Datos de la tarifa del producto",
    "RATES_ASSOCIATED_PRODUCTS": "Tarifas asociadas a los productos",
    "CREATE_NEW_SPECIAL_RATE": "Crear Nueva tarifa especial para productos de",
    "STATUS": "Estado",
    "ADD": "Añadir",
    "ENTER_NUMBER": "Debes introducir un número",
    "TYPE": "Tipo",
    "CALCULATED_PRICE": "Precio calculado",
    "NO_SPECIAL_RATES": "No hay tarifas especiales",
    "PRODUCT_CREATED_SUCCESSFULLY": "Producto creado con éxito",
    "PRODUCT_NOT_EXIST": "El producto indicado no existe",
    "ERROR_OCCURRED": "Se ha producido un error en el sistema",
    "RATE_CODE_ERROR": "El código de tarifa introducido ya existe",
    "FIXED": "Fijo",
    "DIFFERENTIAL": "Diferencial",
    "PERCENTAGE": "Porcentual",
    "DAILY_FIXED": "Precio fijo diario",
    "YEARLY_FIXED": "Precio fijo anual",
    "STATIC": "Estático",
    "DYNAMIC": "Dinámico",
    "Default product rate": "Tarifa por defecto",
    "All products has special price for this rate": "Todos los productos tienen asignado precio especial para esta tarifa",
    "ANY": "Cualquiera",
    "VISIBILITY": "Visibilidad",
    "REMOTE_CODE": "Código remoto",
    "VISIBLE": "Visible",
    "INVISIBLE": "Invisible",
    "Product Visible": "Tarifa visible",
    "SUCCESS_PRODUCT_RATE_UPDATED": "Tarifa de producto actualizada con éxito.",
    "ERROR_PRODUCT_RATE_UPDATED": "Tarifa de producto no actualizada. Comprueba los errores.",
    "PRODUCT_RATE_CREATED_SUCCESSFULLY": "Tarifa de producto creada con éxito",
    "ERROR_PRODUCT_RATE_CREATED": "Tarifa de producto no creada. Compruebe los errores.",
    "NOT_IN_FORCE": "No vigente",
    "IN_FORCE": "Vigente",
    "VALIDITY": "Vigencia",
    "SUCCESS_PRODUCT_UPDATED": "Producto actualizado correctamente.",
    "VIEW": "Ver",
    "PRODUCT": "Producto",
    "PRICES": "Precios",
    "ACCOUNTING": "Cuenta contable",
    "TAX": "Impuesto",
    "SELECT": "Selecciona",
    "START_DATE": "Fecha inicio",
    "PRODUCT_RATE": "Tarifa",
    "PRODUCT_UPDATED": "Producto actualizado",
    "SEARCH_BY_RATE": "Buscar por tarifa",
    "START_START_DATE": "Fecha inicio DESDE",
    "FINISH_START_DATE": "Fecha inicio HASTA",
    "PRICES_BY_DEFAULT": "Precios por defecto",
    "NEW_PRICE": "Nuevo precio",
    "RATE": "Tarifa",
    "VALUE": "Valor",
    "PRICE_CREATED": "Precio creado",
    "PRODUCT_DELETED": "Producto eliminado",
    "DEFAULT_PRICE": "Precio por defecto",
    "SEARCH_BY_PRODUCT": "Buscar por producto",
    "DEFAULT_PRODUCT": "Producto por defecto",
    "OTHERS": "Otros",
    "IE": "Impuesto eléctrico",
    "RENT": "Alquiler",
    "VARS": "Varios",
    "VARS06": "Depósito de garantía",
    "REACTIVE_ENERGY": "Potencia reactiva",
    "POWER_EXCESS": "Exceso de potencia",
    "REGULATED_TOLLS": "Término de energía (Peajes Regulado B.O.E.)",
    "DEFAULT_RATE": "Tarifa por defecto",
    "SELECT_DATE": "Selecciona una fecha",
    "ADD_PRICES": "Añadir precios",
    "ASK_DELETION": "¿Eliminar precios de la fecha seleccionada?",
    "SUCCESSFULLY_DELETED": "Eliminado correctamente",
    "ERROR_DELETING": "Error al eliminar",
    "GESTM": "Gestión de mercados",
    "PRODUCT_PRICE_DELETED": "Precio eliminado",
    "PRODUCT_PRICE_UPDATED": "Precio actualizado",
    "PRODUCT_RATE_DELETED": "Tarifa de producto eliminada",
    "PRSCH": "PRSCH",
    "DEVTN": "Desvíos",
    "FINCT": "Coste financiero",
    "EXTRA_DAILY_FIXED": "Precio Extra diario",
    "EXTRA_BY_CONSUMPTION_FIXED": "Precio Extra por consumo",
    "TABLE_P1": "P1",
		"TABLE_P2": "P2",
		"TABLE_P3": "P3",
		"TABLE_P4": "P4",
		"TABLE_P5": "P5",
		"TABLE_P6": "P6",
    "ACTIVE_ENERGY": "Energia activa",
    "POWER": "Potencia"
  }
}
