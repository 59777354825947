const NotificationListsController = ['$scope', '$http', '$log',
    function ($scope, $http, $log) {
        $scope.notifications = [];
        $scope.results = [];

            $scope.$watchCollection('results', function(new_values, old_values){
                if (new_values.length != 0){
                $http.post(API_URL + '/api/1.0/notifications-user-read/ ', {}).error(function (error) {
                    $log.error("ERROR Actualizado tiempo lectura", error);
                });
                }
            });
    }
]

export {NotificationListsController}