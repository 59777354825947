{
  "CALENDARS": {
    "TITLE": "Calendarios",
    "NEW_CALENDAR": "Añadir Calendario",
    "CALENDAR_NAME": "Nombre",
    "CALENDAR_TYPE": "Tipo",
    "ADD": "Añadir",
    "CANCEL": "Cancelar",
    "SELECT_CALENDAR": "Seleccionar Calendario",
    "CALENDAR_ADDED": "Se ha creado el calendario.",
    "SELECT_TYPE": "Seleccionar tipo",
    "GENERAL": "General",
    "STATE": "Provincial",
    "OTHERS": "Otros",
    "DELETE_CALENDAR": "Eliminar Calendario",
    "CALENDAR_DELETED": "El calendario ha sido eliminado.",
    "YES": "Sí",
    "NO": "No",
    "CONFIRM_DELETE": "¿Desea realmente eliminar el calendario?",
    "DATE": "Fecha",
    "NEW_CALENDAR_DAY": "Añadir día al calendario",
    "HOLIDAY": "Festivo",
    "CALENDAR_DAY_ADDED": "Se ha añadido el día al calendario.",
    "DELETE_CALENDAR_DAY": "Eliminar Día",
    "CONFIRM_DELETE_CALENDAR_DAY": "¿Desea realmente eliminar el día del calendario?",
    "CALENDAR_DAY_DELETED": "El día del calendario ha sido eliminado.",
    "ADD_CALENDAR_FOR_SUPPLIES": "Vincular calendario",
    "CHANGE_QUESTION_LINK": "¿Vincular calendarios?",
    "CALENDARS_LINK_SUCCESSFULLY": "Nº Pto.Suministro vinculados al calendario: ",
    "CALENDARS_ALREADY_LINK": "Nº Pto.Suministro ya vinculados al calendario anteriormente: ",
    "CALENDARS_UNLINK_SUCCESSFULLY": "Nº Pto.Suministro desvinculados al calendario: ",
    "CALENDARS_NOT_LINK": "Nº Pto.Suministro no vinculados al calendario anteriormente: ",
    "UNLINK_CALENDAR_FOR_SUPPLIES": "Desvincular calendario",
    "CHANGE_QUESTION_UNLINK": "¿Desvincular calendarios?"
  }
}