
const ContractFeePricesCtrl =  ['$scope', '$routeParams', '$filter', 'AgreementAPI', 'SUPPLY_RATES', 'UserInfo', 'Alert',
        function ($scope, $routeParams, $filter, AgreementAPI, SUPPLY_RATES, UserInfo, Alert) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var self = this;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.fee_prices = [];
            $scope.contractId = $routeParams.id;
            $scope.rates = SUPPLY_RATES;
            $scope.rage = {
                type: '',
                annual_price: '',
                power_range_min: '',
                power_range_max: '',
                consumption_range_min: '',
                consumption_range_max: ''
            };
            $scope.types = [
                {code: 'ANN', name: $translate('AGREEMENTS.ESTIMATED_ANNUAL_CONSUMPTION')},
                {code: 'MON', name: $translate('AGREEMENTS.MONTHLY_CONSUMPTION')},
                {code: 'CON', name: $translate('AGREEMENTS.CONSUMPTION_BASED')}
            ];

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.canDelete = function () {
                return UserInfo.hasPerm('agreements.delete_agreementfeeprice');
            };

            $scope.canEdit = function () {
                return UserInfo.hasPerm('agreements.change_agreementfeeprice');
            };

            $scope.activeDeletePrice = function (price) {
                price.delete_mode ? price.delete_mode = false : price.delete_mode = true;
            };

            $scope.deletePrice = function (price) {
                AgreementAPI.deleteAgreementPrice('-', price.id)
                    .then(function () {
                        Alert.createAlert('success', $translate('AGREEMENTS.PRICE_DELETED'), true);
                        $scope.getPrices();
                    }).catch(function (error) {
                        Alert.createAlert('danger', error.data.__all__[0], true);
                    });
            };

            $scope.editPriceRequest = function (index, price) {
                AgreementAPI.setAgreementPrice('-', price.id, price)
                    .then(function (data) {
                        Alert.createAlert('success', $translate('AGREEMENTS.SUCCESS_UPDATED'), true);
                    }).catch(function (error) {
                        Alert.createAlert('danger', error.data.__all__[0], true);
                    });
            };

            $scope.getPrices = function() {
                $scope.fee_prices = [];
                AgreementAPI.getContractFeePrices($scope.contractId).then(function(prices) {
                    $scope.fee_prices = prices;
                });
            };

            $scope.savePrice = function(price) {
                price.contract = $scope.contractId;
                AgreementAPI.createAgreementPrice('-', price).then(function(prices) {
                    $scope.rage = {
                        type: '',
                        annual_price: '',
                        power_range_min: '',
                        power_range_max: '',
                        consumption_range_min: '',
                        consumption_range_max: ''
                    };
                    $scope.getPrices();
                }).catch(function (error) {
                    Alert.createAlert('danger', error.data.__all__[0], true);
                });
            };

            /***********************************************************************************************************
            * Controller initialization
            ***********************************************************************************************************/
            $scope.getPrices();

        }]
        
export {ContractFeePricesCtrl}
