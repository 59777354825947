// Controllers
import { CustomerDetailCtrl } from './controllers/CustomerDetailCtrl';
import { CustomersCtrl } from './controllers/CustomersCtrl';

// Directives
import { customerData } from './directives/customerData';

// Services
import { CustomerAPI } from './services/CustomerAPI';

// Others
import { CustomerFactory, CustomerConfig } from './services/Customer';

var customers = angular.module('customers', ['pascalprecht.translate', ]);

customers.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

customers
    .controller('CustomerDetailCtrl', CustomerDetailCtrl)
    .controller('CustomersCtrl', CustomersCtrl)
    .directive('customerData', customerData)
    .service('CustomerAPI', CustomerAPI)
    .factory('Customer', CustomerFactory)
    .config(CustomerConfig)

export { customers };
