const MetaResources  = ['$q', '$http',
    function ($q, $http) {

        function listQueues() {
            var deferred = $q.defer();
            var url = API_URL + '/queues/';

            $http.get(url).then(function (data) {
                deferred.resolve(data.data);
            }).catch(function (error) {
                deferred.reject(error.data);
            });

            return deferred.promise;
        }

        return {
            listQueues: listQueues
        };
    }
]

export { MetaResources }