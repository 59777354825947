import { saveAs } from 'file-saver';

function attachedFiles($location, filesConfig, UserInfo, $routeParams) {
    return {
        restrict: 'E',
        scope: {
            model: '@attachedFiles',
            apiURL: '@apiUrl'
        },
        templateUrl: require('../../templates/attachedfileslist.html'),
        controller: function ($scope, $http, $filter) {
            var $translate = $filter('translate');
            var self = this;

            $scope.Math = window.Math;

            $scope.perPage = null;
            $scope.page = 1;
            $scope.count = 0;
            $scope.next = null;
            $scope.previous = null;
            $scope.loading = false;
            $scope.current = $scope.apiURL;
            $scope.createMode = false;
            $scope.msg_create_button = $translate('FILES.UPLOAD_FILES');

            $scope.nextPage = function () {
                if ($scope.next) {
                    $scope.current = $scope.next;
                    $scope.page++;
                }
            };

            $scope.previousPage = function () {
                if ($scope.previous) {
                    $scope.current = $scope.previous;
                    $scope.page--;
                }
            };

            $scope.listReload = function () {
                self.loadData();
            };

            $scope.$watch('current', function (newVal, oldVal) {
                if (newVal) {
                    self.loadData();
                }
            });

            this.loadData = function () {
                $http.get($scope.current).then(function (data, status, headers, config) {
                    $scope.count = data.data.count;
                    $scope.next = data.data.next;
                    $scope.previous = data.data.previous;
                    $scope.perPage = data.data.results.length;

                    $scope.files = [];

                    angular.forEach(data.data.results, function (item) {
                        item.editMode = false;
                        item.deleteMode = false;
                        item.shareMode = false;
                        item.name = item.file.split('/')[item.file.split('/').length - 1];
                        item.share_url = filesConfig.FILE_SHARED_URL + UserInfo.userInfo.jwt + '?next=/files/add/' + item.id + '/';
                        this.push(item);
                    }, $scope.files);

                }, function (data, status, headers, config) {
                });
            };

            $scope.deleteFile = function (file) {
                file.deleteMode ? file.deleteMode = false : file.deleteMode = true;
            };

            $scope.confirmDeleteFile = function (file) {
                $http.delete(filesConfig.API_URL + file.id).
                    then(function(response) {
                        self.loadData();
                    }).
                    catch(function (response) {
                    });
            };

            $scope.shareFile = function (file) {
                file.shareMode ? file.shareMode = false : file.shareMode = true;
            };

            $scope.canDelete = function (file) {
                if (UserInfo.hasPerm('files.delete_file_attachment'))
                    return true;
                else if (UserInfo.hasPerm('files.delete_owned_attachment') && (UserInfo.userInfo.username == file.user.username))
                    return true;
                else if ((file.owner !== undefined) && (UserInfo.userInfo.username == file.owner.username) && (new Date(Date.now() - filesConfig.FILE_SELF_DELETION_TIME * 36e5) <= new Date(file.created_on))) {
                    return true;
                } else {
                    return false;
                }
            };

            $scope.canShare = function (file) {
                if (UserInfo.hasPerm('files.share_file_attachment')) {
                    return true;
                }
                return false;
            };

            $scope.showCreateMode = function (){
                filesConfig.API_URL = $scope.apiURL;
                if ($scope.createMode) {
                    $scope.createMode = false;
                    $scope.msg_create_button = $translate('FILES.UPLOAD_FILES');
                    $scope.$broadcast('clear_files');
                    self.loadData();
                }
                else {
                    $scope.createMode = true;
                    $scope.msg_create_button = $translate('FILES.SHOW_ATTACHED_FILES');
                }
            }
        }
    }
}

export { attachedFiles };
