// const contractCloseInvoice = function
function contractCloseInvoice(UserInfo, filtersUtils, DateUtils) {
    return {
        restrict: 'AE',
        scope: {},
        //templateUrl: require('../../static/contracts/templates/actions-dropdown-close-invoice-period.html'),
        templateUrl: require('../../templates/actions-dropdown-close-invoice-period.html'),
        controller: function ($scope, $http, $rootScope, Alert, $filter, Task) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var $translate = $filter('translate');

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.flagCloseDate = false;
            $scope.listToUpdate = [];
            $scope.closeDate = new Date();
            $scope.months = [
                {code: 1, label: 'Enero'},
                {code: 2, label: 'Febrero'},
                {code: 3, label: 'Marzo'},
                {code: 4, label: 'Abril'},
                {code: 5, label: 'Mayo'},
                {code: 6, label: 'Junio'},
                {code: 7, label: 'Julio'},
                {code: 8, label: 'Agosto'},
                {code: 9, label: 'Septiembre'},
                {code: 10, label: 'Octubre'},
                {code: 11, label: 'Noviembre'},
                {code: 12, label: 'Diciembre'}
            ];

            $scope.hasPerm = true; // UserInfo.hasPerm('invoices.can_create_estimated_invoices');

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/
            $scope.closeInvoicePeriod = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToUpdate);
                var json = {
                    ids: ids,
                    close_date: $scope.remit_date
                };

                var task = new Task();
                task.type = 'IEST';
                task.params = json;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.GENERATE_ESTIMATED_TASK_CREATED'), true);
                }).catch(function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('INVOICES.GENERATE_ESTIMATED_TASK_ERROR'), true);
                }).finally(function () {
                    $scope.remit_date = '';
                    $scope.flagRemitDate = false;
                    $scope.listToUpdate = [];
                    $scope.$parent.loading = false;
                });

            };

            $scope.confirmCloseDate = function () {
                $scope.flagCloseDate = true;
            };

            $scope.updateNo = function () {
                $scope.flagCloseDate = false;
            };
        }
    }
}

export {contractCloseInvoice}
