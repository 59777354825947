const SingleProductRateDetail = ['$scope', '$filter', 'ProductRateAPI', 'UserInfo', 'PRODUCTS_VISIBILITY', 'Alert',
    function ($scope, $filter, ProductRateAPI, UserInfo, PRODUCTS_VISIBILITY, Alert) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.product_rate = $scope.result;
        $scope.products_rates = $scope.results;
        $scope.mode = 'detail';
        $scope.action = 'detail';
        $scope.loading = false;

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.deleteSingleProductRate = function() { $scope.action = 'delete'; };
        $scope.cancelDeleteSingleProductRate = function() { $scope.action = 'detail'; };

        $scope.confirmDeleteSingleProductRate = function() {
            $scope.loading = true;
            ProductRateAPI.deleteProductRate($scope.product_rate.code).then(function (product) {
                $scope.loading = false;
                $scope.listReload();
                Alert.createAlert('success', $translate('PRODUCTS.PRODUCT_RATE_DELETED'), true);
            }).catch(function (error) {
                $scope.loading = false;
                $scope.error_message = error.data.__all__[0];
            });
        };

        $scope.canShowProductRate = function () {
            return UserInfo.hasPerm('products.retrieve_products_rates');
        };

        $scope.canDeleteProductRate = function () {
            return UserInfo.hasPerm('products.delete_productrate');
        };

        $scope.canEditProductRate = function () {
            return UserInfo.hasPerm('products.change_productrate');
        };

    }
]

export { SingleProductRateDetail }
