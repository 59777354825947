
const ContractForAgreementDetailCtrl = 
        ['$scope', '$filter', '$routeParams', 'DateUtils', '$http', 'UserInfo', 'ContractForAgreement', 'Alert',
            function ($scope, $filter, $routeParams, DateUtils, $http, UserInfo, ContractForAgreement, Alert) {
                /***********************************************************************************************************
                 * Controller Attributes
                 ***********************************************************************************************************/
                var $translate = $filter('translate');

                /***********************************************************************************************************
                 * Scope Attributes
                 ***********************************************************************************************************/

                $scope.action = 'detail';
                $scope.errors = {};

                /***********************************************************************************************************
                 * Scope Watchers
                 ***********************************************************************************************************/


                /***********************************************************************************************************
                 * Controller Methods
                 ***********************************************************************************************************/


                /***********************************************************************************************************
                 * Scope Methods
                 ***********************************************************************************************************/

                $scope.deleteContractForAgreementMode = function ($event) {
                    $event.preventDefault();
                    $scope.action = 'delete';
                };

                $scope.editContractForAgreementMode = function ($event) {
                    $event.preventDefault();
                    $scope.action = 'edit';
                };

                $scope.cancelContractForAgreement = function ($event) {
                    $event.preventDefault();
                    $scope.action = 'detail';
                };

                $scope.canDeleteContractForAgreement = function () {
                    return UserInfo.hasPerm("agreements.delete_contractforagreement");
                };

                $scope.canEditContractForAgreement = function () {
                    return UserInfo.hasPerm("agreements.change_contractforagreement");
                };


                $scope.deleteContractForAgreement = function (id) {
                    ContractForAgreement.deleteContractForAgreement(id).then(function (contract) {
                        $scope.listReload();
                        Alert.createAlert('success', $translate('CONTRACTS.AGREEMENT_DELETED'), true);
                    }).catch(function (error) {

                    });
                };

                $scope.updateContractForAgreement = function (id) {
                    $scope.errors = {};

                    if ($scope.finished_date == "") {
                        $scope.finished_date = null;
                    }
                    if ($scope.not_manage_date == "") {
                        $scope.not_manage_date = null;
                    }

                    var data = {
                        'started_date': $scope.started_date + 'T00:00',
                        'finished_date': ($scope.finished_date == null) ? null : $scope.finished_date + 'T00:00',
                        'contract': $scope.result.contract,
                        'agreement_manages': $scope.result.agreement_manages,
                        'not_manage_date': ($scope.not_manage_date == null) ? null : $scope.not_manage_date + 'T00:00'
                    };

                    ContractForAgreement.setContractForAgreementDetailPartial(id, data).then(function (contract) {
                        $scope.listReload();
                        Alert.createAlert('success', $translate('CONTRACTS.AGREEMENT_UPDATED'), true);
                    }).catch(function (error) {
                        $scope.errors = error;
                    });

                };

                $scope.change_agreement_manages = function (value) {
                    if ($scope.action == 'edit') {
                        $scope.result.agreement_manages = value;
                    }
                };

                /***********************************************************************************************************
                 * Controller Initialization
                 ***********************************************************************************************************/

                if ($scope.result.started_date != null) {
                    $scope.started_date = DateUtils.transformToInterface($scope.result.started_date);
                }

                if ($scope.result.finished_date != null) {
                    $scope.finished_date = DateUtils.transformToInterface($scope.result.finished_date);
                }
                if ($scope.result.not_manage_date != null) {
                    $scope.not_manage_date = DateUtils.transformToInterface($scope.result.not_manage_date);
                }


            }]

export {ContractForAgreementDetailCtrl}
