

const StatesCodesLoading = ['$resource', function ($resource) {
    return $resource(API_URL + '/api/1.0/masters/states-codes/');
}]

const StatesCodeMaster = ['$q', 'StatesCodesLoading',  function ($q, StatesCodesLoading) {

    var states= {
        items: null,

        loadStatesCodes: function () {
            var self = this;
            var deferred = $q.defer();

            if (this.items == null)
                StatesCodesLoading.get(function (data) {
                    var arr = [];
                    for (var key in data.toJSON()) {
                        var item = {};
                        item.code = data.toJSON()[key];
                        item.value = key;
                        arr.push(item);
                    }
                    arr.sort();
                    self.items = arr;
                    deferred.resolve(arr);
                });
            else
                deferred.resolve(self.items);

            return deferred.promise;
        }
    };

    return states;


}]

export {StatesCodeMaster, StatesCodesLoading}