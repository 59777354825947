
const ContractsFeesCtrl =  ['$scope', '$routeParams', '$filter', 'DateUtils',
        function ($scope, $routeParams, $filter, DateUtils) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var self = this;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.code = $routeParams.id;

            $scope.filters = {
                'contract': $scope.code
            };

            $scope.ordering = {
                'date': $translate('CONTRACTS.DATE')
            };

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.filter = function () {
                if ($scope.fromDate)
                    $scope.filters['from_date'] = DateUtils.transformToRESTyDateWODays($scope.fromDate);
                else
                    delete $scope.filters['from_date'];

                if ($scope.toDate)
                    $scope.filters['to_date'] = DateUtils.transformToRESTyDateWODays($scope.toDate);
                else
                    delete $scope.filters['to_date'];
            };

        }]

export {ContractsFeesCtrl}
