const Bank = ['$resource',
    function ($resource){
        return $resource(API_URL + '/api/1.0/banks/:id/', { id: '@id'},{
                'patch': { method:'PATCH' }
        });
    }
]


const Receipt = ['$resource',
    function ($resource) {
        return $resource(API_URL + '/api/1.0/receipts/:id/', { id: '@id'},{
                'delete': { method:'DELETE' },
                'patch': { method:'PATCH' }
        });
    }
]

const Remittance = ['$resource',
    function ($resource) {
        return $resource(API_URL + '/api/1.0/remittances/:id/', { id: '@id'});
    }
]

export { Bank, Receipt, Remittance }
