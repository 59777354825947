const eventInfoDownloadService = [
    '$http', '$q', function ($http, $q) {
        var settings = {
            apiUrl: API_URL + '/api/1.0/masters/event-download-info/'
        };

        function getEventType() {
            var deferred = $q.defer();

            $http.get(settings.apiUrl)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function getEventCategory(type) {
            var deferred = $q.defer();

            $http.get(settings.apiUrl + '?type=' + type)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function getEventDownloads(type, category) {
            var deferred = $q.defer();

            $http.get(settings.apiUrl + '?type=' + type + '&category=' + category)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            getEventType: getEventType,
            getEventCategory: getEventCategory,
            getEventDownloads: getEventDownloads
        };

    }
]

export {eventInfoDownloadService}
