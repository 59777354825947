import { NotificationListsController } from "./controllers/NotificationsListCtrl";
import { notificationName } from "./filters/notificationName";
import { createHTML5Notification } from "./services/createHTML5Notification";
import { notificationCenter } from "./services/notificationCenter";

const notifications = angular.module('notifications', ['ngRoute']);

notifications.provider('notificationCenterConfig', function () {
    var self = this;
    this.SOCKET_PORT = null;
    this.SOCKET_ADDR = null;
    this.CONNECT_TO_WS = true;
    this.DEFAULT_HANDLERS = {};
    this.$get = function ($location) {
        var config = {
            SOCKET_ADDR: self.SOCKET_ADDR || $location.host(),
            SOCKET_PORT: self.SOCKET_PORT || $location.port(),
            DEFAULT_HANDLERS: self.DEFAULT_HANDLERS,
            CONNECT_TO_WS: self.CONNECT_TO_WS
        };
        return config;
    };
});

notifications.provider('notificationsConfig', function () {
    var self = this;
    this.NOTIFICATION_NAMES = {};
    this.$get = function () {
        return {
            NOTIFICATION_NAMES: self.NOTIFICATION_NAMES
        }
    };
});

notifications.controller('NotificationListsController', NotificationListsController)
notifications.filter('notificationName', notificationName)
notifications.service('createHTML5Notification', createHTML5Notification)
notifications.service('notificationCenter', notificationCenter)

export {notifications}
