const BankDetailCtrl = ['$scope', '$routeParams', 'Bank', 'UserInfo', '$filter', 'Alert',
    function ($scope, $routeParams, Bank, UserInfo, $filter, Alert) {
        var $translate = $filter('translate');

        $scope.BANKS_DETAIL_LOGS = require('../../templates/banks-detail-logs.html')

        $scope.loading = true;
        $scope.id = $routeParams.id;

        Bank.get({id: $scope.id}, function (bank) {
            $scope.bank = bank;
            $scope.loading = false;
        });

        $scope.canViewDetail = function () {
            return UserInfo.hasPerm('banks.retrieve_banks');
        };

        $scope.updateBank = function () {
            Bank.patch({id: $scope.id}, {
                name: $scope.bank.name,
                account: $scope.bank.account,
                expiration_date: $scope.bank.expiration_date,
                credit_limit: $scope.bank.credit_limit,
                sepa_line_id: $scope.bank.sepa_line_id,
                suffix: $scope.bank.suffix
            }).then(function (bank) {
                $scope.bank = bank;
                Alert.createAlert('success', $translate('INVOICES.BANK_UPDATED'), true);
                $scope.validateBank.$setPristine();
            }).catch(function (error) {
                Alert.createAlert('danger', $translate('INVOICES.BANK_UPDATED_ERROR'), true);
            });
        };

        $scope.canUpdateBank = function () {
            return UserInfo.hasPerm('banks.change_bank');
        };

    }
]

export { BankDetailCtrl }
