const InvoicesForRemitCtrl = ['$scope', '$location', '$routeParams', '$rootScope', '$http', '$filter', 'Invoice', 'UserInfo', 'Alert',
    function ($scope, $location, $routeParams, $rootScope, $http, $filter, Invoice, UserInfo, Alert) {

        var $translate = $filter('translate');

        $scope.updateExtraExpenses = function () {
            var json = {
                'extra_expenses': $scope.result.extra_expenses
            };

            Invoice.patch({id: $scope.result.id}, json, function (invoice) {
                Alert.createAlert('success', 'Gastos extra actualizados', true);
            }).catch(function (error) {
                Alert.createAlert('danger', 'Error al actualizar gastos extra', true);
            });
        };

        $scope.userHasPerm = function(perm){
            return UserInfo.hasPerm(perm);
        };


    }
]

export { InvoicesForRemitCtrl }
