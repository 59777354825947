
const DraftCreationCtrl =  ['$scope', 'CreateContract', '$http', 'Alert', '$filter', '$location',
        function ($scope, CreateContract, $http, Alert, $filter, $location) {
            var $translate = $filter('translate');

            $scope.CONTRACTS_SUPPLY_DATA_WIZARD = require('../../templates/creation/form-supply-data.html')
            $scope.CONTRACTS_CONTRACT_DATA_WIZARD = require('../../templates/creation/form-contract-data.html')
            $scope.CONTRACTS_PAYMENT_DATA_WIZARD = require('../../templates/creation/form-payment-data.html')

            $scope.onSave = function () {
                var contract = CreateContract.getContract();

                $http.post(API_URL + '/api/1.0/contracts/ ', contract).then(function (response) {
                    let contract = response.data
                    Alert.createAlert('success', $translate('CONTRACTS.Contract successfully created'), true);
                    $location.path('/contracts/' + contract.id);
                }).catch(function (error) {
                });
            }
            $scope.$on('loadCustomer', function (event, data) {
                $scope.$broadcast('customerLoaded', data)
            })

        }]

export {DraftCreationCtrl}
