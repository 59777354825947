{
  "FEES": {
    "TITLE": "Management fees",
    "HISTORICAL_FEE": "Historical fee",
    "IMPORTING_FINAL_FEES": "Importing final fees",
    "HISTORY_IMPORT_FEES": "History import fees",
    "COMMISSION_REPORT_GENERATION": "Commission report generation",
    "FROM": "From",
    "AGREEMENTS": "Agreements",
    "AGREEMENT": "Agreement",
    "TO": "To",
    "ALL_AGREEMENTS": "All agreements",
    "GENERATE_COMMISSIONS": "Generate commissions",
    "MSG": "Commissions have been queued.",
    "MSG_2": "You will be notified when finished.",
    "HISTORY_GENERATED_COMMISSIONS": "History generated commissions",
    "ERROR": "Error",
    "TODO": "To do",
    "TO_CANCEL": "To cancel",
    "TO_UNDO": "To undo",
    "UNDOING": "Undoing",
    "DOING": "Doing",
    "CANCELED": "Canceled",
    "FINISHED": "Finished",
    "DISCARDED": "Discarded",
    "DOWNLOAD": "Download",
    "HISTORY_IMPORTED_COMMISSIONS": "History imported commissions",
    "PENDING": "Pending",
    "IMPORTED_FILE": "Imported file",
    "IMPORT_MODE": "Import mode",
    "IMPORT_DONE": "Import done",
    "HIDE_IMPORT_RESULTS": "Hide import results",
    "SHOW_IMPORT_RESULTS": "Show import results",
    "YES": "YES",
    "NO": "NO",
    "DATE_IMPORT_FEES": "Date import fees",
    "DATE_IMPORT_FEES_REVERSED": "Date import fees reversed",
    "IMPORTING_QUEUED_COMMISSIONS": "Importing queued commissions",
    "UNDO": "Undo",
    "NEW_IMPORT": "New import",
    "SELECT_IMPORT_MODE": "Select import mode",
    "IMPORT_COMMISSIONS": "Import commissions",
    "ERROR_OCCURRED": "An error has occurred",
    "DATE_GREATER": "Date from is greater than the date up",
    "IMPORT_RUNNING": "The import is running in the background",
    "ERROR_SENDING_TASK": "An error has occurred sending the task",
    "IMPORT_FINISHED": "Fees import finished",
    "FEES_IMPORT_MODE_ERROR": "Import mode must be selected",
    "COMMISSION_REPORT_GENERATION_FILES": "Generated Files",
    "LIST_FEES": "List fees",
    "DATE": "Date",
    "COMMISSION": "Commission",
    "COMMISSION_CONSUMPTION": "€ / kWh",
    "Consumption": "Consumption"
  }
}