// Controllers
import { ImportCtrl } from "./controllers/ImportCtrl"
import { ImportTabCtrl  } from "./controllers/ImportTabCtrl"

const uploader = angular.module('uploader', ['pascalprecht.translate', 'notifications', 'tasks']);

uploader.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

uploader
    .controller('ImportCtrl', ImportCtrl)
    .controller('ImportTabCtrl', ImportTabCtrl)

export { uploader }
