function consumptionList() {
    return {
        restrict: 'E',
        templateUrl: require('../../templates/supplies-list-consumption.html'),
        scope: {},
        controller: function ($scope, $http, $filter, Alert, $routeParams, UserInfo, Task) {
            var $translate = $filter('translate');
            var self = this;
            $scope.cups = $routeParams.cups;

            $scope.sources = [
                {'label': 'MANUAL', 'code': 'MAN'},
                {'label': 'SIPS', 'code': 'SIP'},
                {'label': 'SC', 'code': 'SC'},
                {'label': 'F101', 'code': 'F101'},
                {'label': 'F101_A', 'code': 'F101A'},
                {'label': 'F101_B', 'code': 'F101B'},
                {'label': 'F101_R', 'code': 'F101R'},
                {'label': 'F101_A_AUTO', 'code': 'F101AUTO'}
            ];

            self.transformToRESTyDate = function (date) {
                var yyyy = date.getFullYear().toString();
                var mm = (date.getMonth() + 1).toString(); // getMonth() is zero-based
                var dd = date.getDate().toString();
                return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]);
            };

            $scope.filters = {
                'source': "",
                'from_date': "",
                'to_date': ""
            };

            $scope.source = "";

            $scope.$watch('source', function (newVal, oldVal) {
                $scope.filters['source'] = newVal;
            });

            $scope.periods = {
                'WI': $translate('SUPPLIES.WINTER'),
                'SU': $translate('SUPPLIES.SUMMER')
            };

            $scope.canDownloadConsumptionReport = UserInfo.hasPerm('consumptions.download_consumption_report_csv');

            $scope.downloadConsumptionCSV = function () {
                var task = new Task();
                task.type = 'GCRP';
                task.params = self.getJSONParams();
                task.params.type = 'consumption-report';
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('SUPPLIES.Double consumption report generation Task success'), true);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('SUPPLIES.Double consumption report generation Task error'), true);
                });
            };

            this.getJSONParams = function() {

                var params = {};
                if ($scope.filters.from_date > $scope.filters.to_date) {
                    Alert.createAlert('danger', $translate('SUPPLIES.TO_DATE_GREATER_FROM_DATE'), true);
                    return null;
                }

                params.date_from = this.transformToRESTyDate($scope.filters.from_date);
                params.date_to =this.transformToRESTyDate($scope.filters.to_date);

                if ($scope.rate != undefined && $scope.rate != null ){
                    params.rate = $scope.rate;
                }

                if ($scope.cups != undefined && $scope.cups != null ){
                    params.cups = $scope.cups;
                }

                if ($scope.source != undefined && $scope.source != null){
                    params.source = $scope.source;
                }

                return params;
            };


            this.transformToRESTyDate = function (date) {
                var yyyy = date.getFullYear().toString();
                var mm = (date.getMonth() + 1).toString(); // getMonth() is zero-based
                var dd = date.getDate().toString();
                return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]); // padding
            };

            this.getQueryParams = function () {
                if ($scope.filters.from_date > $scope.filters.to_date) {
                    Alert.createAlert('danger', $translate('SUPPLIES.TO_DATE_GREATER_FROM_DATE'), true);
                    return null;
                }

                var params = '?fromDate=' + this.transformToRESTyDate($scope.filters.from_date);
                params += '&toDate=' + this.transformToRESTyDate($scope.filters.to_date);

                if ($scope.rate == undefined || $scope.rate == null ){
                    params += '&rate='
                }
                else{
                    params += '&rate=' + $scope.rate;
                }
                if ($scope.cups== undefined || $scope.cups == null ){
                    params += '&cups='
                }
                else{
                    params += '&cups=' + $scope.cups;
                }
                if ($scope.source == undefined || $scope.source == null) {
                    params += '&source=';
                }
                else {
                    params += '&source=' + $scope.source;
                }
                return params;
            };

        }

    };
}

export {consumptionList};
