const AddReceiptInvoiceCtrl = ['$scope', '$location', '$routeParams', '$http', 'Alert', '$filter', 'Invoice',
    function ($scope, $location, $routeParams, $http, Alert, $filter, Invoice) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.id = $routeParams.id;

        $scope.receipt = {
            invoice: $scope.id,
            amount: null,
            date: new Date(),
            reason: "",
            extra_expenses: 0,
            type: ""
        };

        $scope.types = [
            {code: 'NOR', label: $translate('BANKS.NORMAL')},
            {code: 'RCV', label: $translate('BANKS.RECOVER')}
        ];

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.addReceiptToInvoice = function () {

            $http.post(API_URL + '/api/1.0/receipts/', $scope.receipt)
                .then(function (data, status, headers, config) {
                    $location.path('/invoices/' + $routeParams.id);
                    Alert.createAlert('success', $translate('INVOICES.CREATED_RECEIPT_SUCCESS'), true);
                }, function (data, status, headers, config) {});
        };

        $scope.back = function () {
            $location.path('/invoices/' + $routeParams.id);
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        Invoice.get({id: $scope.id}, function (invoice) {
            $scope.invoice = invoice;
            $scope.receipt.type = (invoice.status === 'UNP') ? 'RCV' : 'NOR'
        });
    }
]

export { AddReceiptInvoiceCtrl }
