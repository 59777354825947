const CreateProgrammingUnitCtrl = ['$scope', '$filter', '$http', 'Alert', 'ProgrammingUnitAPI',
    function ($scope, $filter, $http, Alert, ProgrammingUnitAPI) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.saving = false;

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.resetForm = function(){
            $scope.name = "";
            $scope.description = "";
            $scope.electrical_subsystem = "";
            $scope.createProgrammingUnitForm.$setPristine();
            $scope.createProgrammingUnitForm.$setUntouched();
        };

        $scope.hideCreateForm = function() {
            $scope.resetForm();
            $scope.$emit('cancelCreateProgrammingUnit');
        };

        $scope.saveProgrammingUnit = function(){
            $scope.saving = true;
            var json = {
                name : $scope.name,
                description : $scope.description,
                electrical_subsystem : $scope.electrical_subsystem.code
            };

            ProgrammingUnitAPI.createProgrammingUnit(json)
                .then(function (data) {
                    $scope.saving = false;
                    Alert.createAlert('success', $translate('PROGRAMMING_UNITS.PROGRAMMING_UNIT_CREATED_SUCCESSFULLY'), true);
                    $scope.hideCreateForm();
                    $scope.listReload();
                }).catch(function (error) {
                    $scope.error_message = error.data.__all__[0];
                    $scope.saving = false;
                });
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/

    }
]

export {CreateProgrammingUnitCtrl}
