import { BankCtrl } from './controllers/BankCtrl'
import { BankDetailCtrl } from './controllers/BankDetailCtrl'
import { DeleteBankCtrl } from './controllers/DeleteBankCtrl'
import { InvoicesForRemitCtrl } from './controllers/InvoicesForRemitCtrl'
import { NewReceiptsRemittanceCtrl } from './controllers/NewReceiptsRemittanceCtrl'
import { NewRemittanceCtrl } from './controllers/NewRemittanceCtrl'
import { ReceiptActionsCtrl } from './controllers/ReceiptActionsCtrl'
import { ReceiptsForRemitCtrl } from './controllers/ReceiptsForRemitCtrl'
import { ReceiptsListCtrl } from './controllers/ReceiptsListCtrl'
import { RemittanceDetailCtrl } from './controllers/RemittanceDetailCtrl'
import { RemittancesListCtrl } from './controllers/RemittancesListCtrl'
import { Bank, Receipt, Remittance } from './services/Bank'
import { lockUnlockRemittance } from './directives/LockUnlockRemittance'


const banks =  angular.module('banks', ['pascalprecht.translate', 'ngResource', 'powr']);

banks.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

banks.constant('REMITTANCE_RULE', {
        'N19': 'N19',
        'N34': 'N34',
        'N58': 'N58',
        'B2B': 'B2B'
    }
)

banks
    .controller('BankCtrl', BankCtrl)
    .controller('BankDetailCtrl', BankDetailCtrl)
    .controller('DeleteBankCtrl', DeleteBankCtrl)
    .controller('InvoicesForRemitCtrl', InvoicesForRemitCtrl)
    .controller('NewReceiptsRemittanceCtrl', NewReceiptsRemittanceCtrl)
    .controller('NewRemittanceCtrl', NewRemittanceCtrl)
    .controller('ReceiptActionsCtrl', ReceiptActionsCtrl)
    .controller('ReceiptsForRemitCtrl', ReceiptsForRemitCtrl)
    .controller('ReceiptsListCtrl', ReceiptsListCtrl)
    .controller('RemittanceDetailCtrl', RemittanceDetailCtrl)
    .controller('RemittancesListCtrl', RemittancesListCtrl)
    .directive('lockUnlockRemittance', lockUnlockRemittance)
    .factory('Bank', Bank)
    .factory('Receipt', Receipt)
    .factory('Remittance', Remittance)


export { banks }
