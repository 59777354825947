const ProductAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/products/';

        var self = this;
        var product = null;

        function getAllProducts(page_size) {
            var deferred = $q.defer();

            var extra_url = '';
            if (page_size !== undefined)
                extra_url = '?page_size=' + page_size;

            $http.get(url + extra_url)
                .then(function (product) {
                    deferred.resolve(product.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function getAllUniqueProducts() {
            var deferred = $q.defer();

            var extra_url = '?page_size=1000&unique_products=1';

            $http.get(url + extra_url)
                .then(function (product) {
                    deferred.resolve(product.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function getProduct(id) {
            return _getProductDetail(id);
        }

        function _getProductDetail(id) {
            var deferred = $q.defer();

            $http.get(url + id)
                .then(function (product) {
                    deferred.resolve(product.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            self.id = id;
            return deferred.promise;
        }

        function createProduct(data){
            var deferred = $q.defer();

            $http.post(url, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function updateProduct(id, product) {
            var deferred = $q.defer();

            $http.put(url + id, product)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function deleteProduct(id) {
            var deferred = $q.defer();

            $http.delete(url + id)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function getProductByRate(rate_code, page_size) {
            var deferred = $q.defer();

            var url_filter = '?rate=' + rate_code;
            if (page_size !== 0)
                url_filter += '&page_size=' + page_size;

            $http.get(url + url_filter)
                .then(function (product_rates) {
                    deferred.resolve(product_rates.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            getAllProducts: getAllProducts,
            getAllUniqueProducts: getAllUniqueProducts,
            getProduct: getProduct,
            createProduct: createProduct,
            updateProduct: updateProduct,
            deleteProduct: deleteProduct,
            getProductByRate: getProductByRate
        };

    }

]

export { ProductAPI }
