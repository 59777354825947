
const DeferInvoicesCtrl = 
        ['$scope', '$http', '$filter', 'Alert', 'DateUtils', 'UserInfo', 'CreateDeferredInvoices', '$location', '$routeParams',
            function ($scope, $http, $filter, Alert, DateUtils, UserInfo, CreateDeferredInvoices, $location, $routeParams) {
                /***********************************************************************************************************
                 * Controller Attributes
                 ***********************************************************************************************************/
                var self = this;
                var $translate = $filter('translate');

                /***********************************************************************************************************
                 * Scope Attributes
                 ***********************************************************************************************************/
                $scope.totalToDefer = CreateDeferredInvoices.getTotal();

                $scope.totalDeferred = 0;
                $scope.receiptsAmount = null;

                /***********************************************************************************************************
                 * Scope Watchers
                 ***********************************************************************************************************/

                /***********************************************************************************************************
                 * Controller Methods
                 ***********************************************************************************************************/
                this.getNumberOfReceiptsToCreate = function (invoiceTotal, receiptsAmount, next_receipt_amount) {
                    var numOfReceipts = 0;
                    if (next_receipt_amount <= invoiceTotal) {
                        numOfReceipts++;
                        invoiceTotal -= next_receipt_amount;
                    }

                    numOfReceipts += Math.trunc(invoiceTotal / receiptsAmount);

                    return numOfReceipts;
                };

                this.addReceiptToReceiptsList = function (receipts, amount, invoiceId, remittanceNumber) {
                    if (Math.round(amount * 100) / 100 > 0) {
                        receipts.push({
                            amount: Math.round(amount * 100) / 100,
                            invoice_idn: invoiceId,
                            remittance: remittanceNumber,
                            date: null,
                            status: 'DFR',
                            extra_expenses: 0
                        });
                    }
                };

                /***********************************************************************************************************
                 * Scope Methods
                 ***********************************************************************************************************/
                $scope.getPercentDeferred = function () {
                    return Math.round((($scope.totalDeferred * 100) / $scope.totalToDefer) * 100) / 100;
                };

                $scope.resetReceipts = function () {
                    $scope.receipts = null;
                    $scope.receiptsAmount = null;
                    $scope.totalDeferred = 0;
                };

                $scope.getTotalDeferred = function () {
                    var total = 0;
                    for (var i = 0; i < $scope.receipts.length; i++) {
                        var receipt = $scope.receipts[i];
                        total += receipt.amount;
                    }
                    $scope.totalDeferred = Math.round(total * 100) / 100;
                };

                $scope.checkReceiptData = function () {
                    for (var i = 0; i < $scope.receipts.length; i++) {
                        if ($scope.receipts[i].date === '')
                            return false;
                    }

                    return true;
                };

                $scope.createDeferment = function () {
                    var receipts = [];
                    angular.copy($scope.receipts, receipts);

                    receipts = CreateDeferredInvoices.prepareDataToApi(receipts);

                    var data = {
                        receipts: receipts
                    };

                    $http.post(API_URL + '/api/1.0/contracts/create-deferment/', data)
                        .then(function (data, status, headers, config) {
                            $location.path('/contracts/' + $routeParams.id);
                            Alert.createAlert('success', $translate('CONTRACTS.DEFERMENT_CREATED_OK'), true);
                        }).catch(function (data, status, headers, config) {
                    });
                };

                $scope.generateReceipts = function () {
                    var receipts = [];
                    var receiptsAmount = $scope.receiptsAmount;
                    var numOfRemittance = 1;
                    var invoices = CreateDeferredInvoices.getInvoicesWithTotal();

                    var amountOfNextReceipt = receiptsAmount;

                    angular.forEach(invoices, function (invoiceTotal, invoiceId) {
                        var numOfReceiptsToCreate = self.getNumberOfReceiptsToCreate(invoiceTotal, receiptsAmount, amountOfNextReceipt);
                        var amountOfReceiptsCreatedForInvoice = 0;

                        if (numOfReceiptsToCreate > 0) {

                            for (var i = 0; i < numOfReceiptsToCreate; i++) {

                                amountOfReceiptsCreatedForInvoice += amountOfNextReceipt;
                                $scope.totalDeferred += amountOfNextReceipt;
                                self.addReceiptToReceiptsList(receipts, amountOfNextReceipt, invoiceId, numOfRemittance);

                                // Reset the amount of the next receipt and change remittance number
                                amountOfNextReceipt = receiptsAmount;
                                numOfRemittance++;

                                // If is the last receipt, check if there is still a remainder
                                if (i === numOfReceiptsToCreate - 1) {
                                    if (invoiceTotal - amountOfReceiptsCreatedForInvoice > 0) {
                                        $scope.totalDeferred += (invoiceTotal - amountOfReceiptsCreatedForInvoice);
                                        self.addReceiptToReceiptsList(receipts, (invoiceTotal - amountOfReceiptsCreatedForInvoice), invoiceId, numOfRemittance);
                                        amountOfNextReceipt = receiptsAmount - (invoiceTotal - amountOfReceiptsCreatedForInvoice);
                                    }
                                    // As it is the last receipt, reset de amount of receipts created to 0
                                    amountOfReceiptsCreatedForInvoice = 0;
                                }
                            }
                        } else {
                            // Create a receipt for the whole amount of the invoice
                            amountOfNextReceipt -= invoiceTotal;
                            $scope.totalDeferred += invoiceTotal;
                            self.addReceiptToReceiptsList(receipts, invoiceTotal, invoiceId, numOfRemittance);

                        }
                    });

                    $scope.receipts = receipts;
                    $scope.totalDeferred = Math.round($scope.totalDeferred * 100) / 100;
                };
            }]

export {DeferInvoicesCtrl}
