// Directives
import { labelsObjectManagement } from './directives/LabelsObjectManagement'
import { labelsSelect } from './directives/LabelsSelect'

const labels = angular.module('labels', ['pascalprecht.translate', 'pascalprecht.translate']);

labels.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

labels.provider('labelsConfig', function () {
    this.API_PREFIX = API_URL + '/api/1.0';
    this.API_SUFFIX = '/labels/ ';
    this.$get = function () {
        var self = this;
        return {
            API_PREFIX: self.API_PREFIX,
            API_SUFFIX: self.API_SUFFIX
        }
    };
})

labels
    .directive('labelsObjectManagement', labelsObjectManagement)
    .directive('labelsSelect', labelsSelect)

export { labels }
