function invoiceDeletion(UserInfo, filtersUtils, Alert, $filter) {
    return {
        restrict: 'AE',
        scope: {},
        templateUrl: require('../../templates/delete-button.html'),
        controller: function ($scope, Task) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.listToDelete = [];

            $scope.hasPerm = UserInfo.hasPerm('invoices.delete_draft');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToDelete = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                let json = {ids: ids};
                let task = new Task();
                task.type = 'DINV';
                task.params = json;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.BULK_DELETE_INVOICES_SUCCESS'), true);
                    $scope.backgroundTasks.push(data.data.id);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('INVOICES.BULK_DELETE_INVOICES_ERROR'), true);
                })

            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
            };

            $scope.clickYes = function () {
                let ids = filtersUtils.getItemsToDownloadToInt($scope.listToDelete);
                performRequest(ids);
            };
        }
    }
}

export { invoiceDeletion }
