
const StatesLoading = ['$resource',
    function ($resource){
        return $resource(API_URL + '/api/1.0/masters/states/');
    }
]

const StatesMaster = ['$q', 'StatesLoading',
    function ($q, StatesLoading) {

        var states= {
            items: null,

            loadStates: function () {
                var self = this;
                var deferred = $q.defer();

                if (this.items == null)
                    StatesLoading.get(function (data) {
                        var arr = [];
                        for (var key in data.toJSON()) {
                            arr.push(key);
                        }
                        arr.sort();
                        self.items = arr;
                        deferred.resolve(arr);
                    });
                else
                    deferred.resolve(self.items);

                return deferred.promise;
            }
        };

        return states;
    }
]

export { StatesLoading, StatesMaster };