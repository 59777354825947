function invoiceSetUnpaid(UserInfo, filtersUtils, DateUtils) {
    return {
        restrict: 'AE',
        scope: {},
        templateUrl: require('../../templates/unpaid-button.html'),
        controller: function ($scope, $http, $rootScope, $filter, Alert) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/invoices/';
            var $translate = $filter('translate');

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagPaid = false;
            $scope.listToUpdate = [];
            $scope.payment = {};
            $scope.payment.unpaid_date = new Date();

            $scope.hasPerm = UserInfo.hasPerm('invoices.add_unpaid_invoice');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                    $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var json = {
                    ids: ids,
                    unpaid_date: DateUtils.transformToRESTyDate($scope.payment.unpaid_date),
                    reason: $scope.payment.reason
                };

                var config = {
                    method: "PUT",
                    url: api,
                    data: json,
                    headers: {"Content-Type": "application/json;charset=utf-8"}
                };
                $http(config)
                    .then(function (data, status, headers, config) {
                        var message = $translate('INVOICES.SENT_UNPAID_OK') + data.data.updated_number;
                        if (data.data.can_not_be_returned_number != 0) {
                            message += '. ' + $translate('INVOICES.SENT_UNPAID_NOT_UPDATE') + data.data.can_not_be_returned_number;
                            message += '. ' + $translate('INVOICES.SENT_UNPAID_NOT_UPDATE_IDS') + data.data.can_not_be_returned;
                        }

                        Alert.createAlert('success', message, true);
                        $rootScope.$broadcast('refreshList');
                    }, function (data, status, headers, config) {
                        Alert.createAlert('danger', $translate('INVOICES.SENT_UNPAID_KO'), true);
                    })

                    /*
                    .finally(function () {
                        $scope.flagPaid = false;
                        $scope.listToUpdate = [];
                        $scope.$parent.loading = false;
                    });
                    */
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.confirmUnpaid = function () {
                $scope.flagPaid = true;
            };

            $scope.updateNo = function () {
                $scope.flagPaid = false;
            };

            $scope.updateYes = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToUpdate);
                performRequest(ids);
            };
        }
    }
}

export { invoiceSetUnpaid }
