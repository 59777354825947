function linkProgrammingUnitToSupplies(UserInfo, Alert, filtersUtils) {
    return {
        restrict: 'AE',
        templateUrl: require('../../templates/link_programming_units.html'),
        controller: function ($scope, $filter, $http, $rootScope, ProgrammingUnitsMaster, ProgrammingUnitAPI) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var self = this;
            var first_access = true;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagLinkConfirm = false;
            $scope.listToUpdate = [];
            $scope.change_programming = '';
            $scope.selected_programming_unit = false;

            $scope.hasPerm = UserInfo.hasPerm('programming_units.set_programming_unit_to_supplies');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            $scope.$watch('programmingUnitForm.$valid', function (newValue, oldValue) {
                $scope.$emit("setValidity", newValue);
                if (first_access == true)
                    first_access = false;
                else {
                    if (newValue)
                        $scope.selected_programming_unit = true;
                }
            }, true);

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var json = {ids: ids, programming_unit: $scope.change_programming, link: true};

                ProgrammingUnitAPI.setProgrammingUnitToSupplies(json)
                    .then(function (data) {
                        Alert.createAlert('success', $translate('PROGRAMMING_UNITS.SET_PROGRAMMING_UNIT_SUCCESSFULLY') + data.updated_programming_unit, true);
                        $rootScope.$broadcast('refreshList');
                    }).catch(function (error) {
                        $scope.error_message = error.data.__all__[0];
                    }).finally(function () {
                        $scope.flagLinkConfirm = false;
                        $scope.listToUpdate = [];
                    });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.clickLinkNo = function () {
                $scope.flagLinkConfirm = false;
                $scope.change_programming = '';
                $scope.selected_programming_unit = false;
            };

            $scope.clickLinkYes = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToUpdate);
                performRequest(ids);
                $scope.change_programming = '';
                $scope.selected_programming_unit = false;
            };

            $scope.confirmSetLink = function () {
                $scope.flagLinkConfirm = true;
            };

            /***********************************************************************************************************
            * Controller Initialization
            ***********************************************************************************************************/
            ProgrammingUnitsMaster.loadProgrammingUnits().then(function (response){
                $scope.programming_units = response;
            });

        }
    }
}

export {linkProgrammingUnitToSupplies};