function ConsumptionParser() {
    /**
     * Checks if the given data is valid for this parser
     * @param data
     * @returns {boolean}
     */
    this.isValid = function (data) {

        var columns = this.parser(data);

        var valid = columns.length == 6; // parts validation

        if(!columns[0].trim().substr(0, 20).match("ES[0-9]{16}[A-Z]{2}")) { // Validate CUPS format
            valid = false;
        }

        if(!columns[1].trim().match("[0-9]{4}-[0-9]{2}-[0-9]{2}")) { // Validar fecha inicio consumo
            valid = false;
        }

        if(!columns[2].trim().match("[0-9]{4}-[0-9]{2}-[0-9]{2}")) { // Validar fecha fin consumo
            valid = false;
        }

        if (valid) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * Parses data and return the built object
     * @param data
     */
    this.parse = function (data) {

        var columns = this.parser(data);

        var consumption = {};
        consumption.consumptions = {};

        consumption.cups = columns[0].trim().substr(0, 20);

        // fechaInicioConsumo
        consumption['consumptions'].initial_date = columns[1].trim();

        // fechaFinConsumo
        consumption['consumptions'].finish_date = columns[2].trim();

        // consumoEnergiaActivaEnKWh
        var active_power = columns[3].split(';');

        consumption['consumptions'].active_energy_p1 = parseFloat(active_power[0]) || 0;
        consumption['consumptions'].active_energy_p2 = parseFloat(active_power[1]) || 0;
        consumption['consumptions'].active_energy_p3 = parseFloat(active_power[2]) || 0;
        consumption['consumptions'].active_energy_p4 = parseFloat(active_power[3]) || 0;
        consumption['consumptions'].active_energy_p5 = parseFloat(active_power[4]) || 0;
        consumption['consumptions'].active_energy_p6 = parseFloat(active_power[5]) || 0;

        // consumoEnergíaReactivaEnKVAR
        var reactive_power = columns[4].split(';');

        consumption['consumptions'].reactive_energy_p1 = parseFloat(reactive_power[0]) || 0;
        consumption['consumptions'].reactive_energy_p2 = parseFloat(reactive_power[1]) || 0;
        consumption['consumptions'].reactive_energy_p3 = parseFloat(reactive_power[2]) || 0;
        consumption['consumptions'].reactive_energy_p4 = parseFloat(reactive_power[3]) || 0;
        consumption['consumptions'].reactive_energy_p5 = parseFloat(reactive_power[4]) || 0;
        consumption['consumptions'].reactive_energy_p6 = parseFloat(reactive_power[5]) || 0;

        // potenciaDemandadaEnW
        var power_demand = columns[4].split(';');

        consumption['consumptions'].power_demand_p1 = parseFloat(power_demand[0]) || 0;
        consumption['consumptions'].power_demand_p2 = parseFloat(power_demand[1]) || 0;
        consumption['consumptions'].power_demand_p3 = parseFloat(power_demand[2]) || 0;
        consumption['consumptions'].power_demand_p4 = parseFloat(power_demand[3]) || 0;
        consumption['consumptions'].power_demand_p5 = parseFloat(power_demand[4]) || 0;
        consumption['consumptions'].power_demand_p6 = parseFloat(power_demand[5]) || 0;

        return consumption;
    };

    this.parser = function (strData, strDelimiter) {

        strDelimiter = (strDelimiter || ",");

        // Create a regular expression to parse the CSV values.
        var objPattern = new RegExp(
            (
                // Delimiters.
            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

                // Quoted fields.
            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

                // Standard fields.
            "([^\"\\" + strDelimiter + "\\r\\n]*))"
            ),
            "gi"
        );

        var arrData = [[]];

        // Create an array to hold our individual pattern
        // matching groups.
        var arrMatches = null;

        // Keep looping over the regular expression matches
        // until we can no longer find a match.
        while (arrMatches = objPattern.exec(strData)) {

            var strMatchedDelimiter = arrMatches[1];

            // Check to see if the given delimiter has a length
            // (is not the start of string) and if it matches
            // field delimiter. If id does not, then we know
            // that this delimiter is a row delimiter.
            if (
                strMatchedDelimiter.length &&
                (strMatchedDelimiter != strDelimiter)
            ) {
                arrData.push([]);
            }

            // Now that we have our delimiter out of the way,
            // let's check to see which kind of value we
            // captured (quoted or unquoted).
            if (arrMatches[2]) {

                // We found a quoted value. When we capture
                // this value, unescape any double quotes.
                var strMatchedValue = arrMatches[2].replace(
                    new RegExp("\"\"", "g"),
                    "\""
                );
            } else {
                // We found a non-quoted value.
                var strMatchedValue = arrMatches[3];
            }

            if(strMatchedValue == undefined)
                strMatchedValue = "";

            arrData[arrData.length - 1].push(strMatchedValue);
        }
        return ( arrData[0] );
    }
}

export {ConsumptionParser};