function createHTML5Notification($window){

    this.sendNotification = function (title, content) {
        if (!("Notification" in $window)) {
        }

        else if (Notification.permission === "granted") {
            content['icon'] = '/static/img/powr-logo.png';
            var notification = new Notification(title, content);
        }

        // Note, Chrome does not implement the permission static property
        // So we have to check for NOT 'denied' instead of 'default'
        else if (Notification.permission !== 'denied') {
            Notification.requestPermission(function (permission) {
                if (permission === "granted") {
                    var notification = new Notification(title, content);
                }
            });
        }
    };
}

export {createHTML5Notification}
