
const ContractForAgreement = ['DateUtils', '$q', '$http',
    function (DateUtils, $q, $http) {

        var url = API_URL + '/api/1.0/contractforagreement/';
        var contractforagreement = null;

        function createContractForAgreement(contractforagreement) {
            var deferred = $q.defer();

            $http.post(url, contractforagreement)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function setContractForAgreementDetail(id, contractforagreement) {
            var deferred = $q.defer();

            $http.put(url + id, contractforagreement)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function setContractForAgreementDetailPartial(id, contractforagreement) {
            var deferred = $q.defer();

            $http.patch(url + id, contractforagreement)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function deleteContractForAgreement(id) {
            var deferred = $q.defer();

            $http.delete(url + id)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }


        return {
            createContractForAgreement: createContractForAgreement,
            setContractForAgreementDetail: setContractForAgreementDetail,
            setContractForAgreementDetailPartial: setContractForAgreementDetailPartial,
            deleteContractForAgreement: deleteContractForAgreement
        };

    }
]

export {ContractForAgreement}
