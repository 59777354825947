

const LoginOauthCtrl = ['$scope', '$http', '$window', '$cookies',
    function($scope, $http, $window, $cookies) {
        $scope.status = 'WAITING';

        function generateRandomString(length = 10) {
            let result = ''
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            const charactersLength = characters.length
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength))
            }
            return result
        }

        $scope.login = function(){
            const urlParams = new URLSearchParams(window.location.search);  // eslint-disable-line no-undef
            const code = urlParams.get('code');
            const urlState = urlParams.get('state');
            const redirectUri = window.location.href.split("?")[0];
            const responseType = "code"

            if (!code) {
                const state = generateRandomString();
                $cookies.put("state", state)
                window.location.href = `${GATEKEEPER_URL}/o/authorize/?client_id=${GATEKEEPER_CLIENT_ID}&redirect_uri=${encodeURI(redirectUri)}&response_type=${responseType}&protocol=oauth2&access_type=online&scope=powr&state=${state}`
            }
            
            // We avoid an exploit this way because the state acts as a CSRF token
            const state = $cookies.get("state");
            if (state !== urlState) throw new Error("CSRF State verification failed");

            var data = {
                code,
                client_id: GATEKEEPER_CLIENT_ID,
                redirect_uri: redirectUri,
                response_type: responseType,
                grant_type: "authorization_code",
            };

            $scope.status = 'TRYING';

            $http({
                method: 'POST',
                url: `${GATEKEEPER_URL}/o/token/`,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    const str = [];
                    for(const p in obj) str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    return str.join("&");
                },
                data,
            }).then(function(data) {
                const expires = new Date(data.data.expires * 1000)
                $cookies.put('token', data.data.access_token, {expires})
                $cookies.put('token_type', data.data.token_type, {expires})

                $window.location.href = '/contracts';

            }).catch(function(data) {

                if (data.status == 404) {
                    $scope.status = 'LOGIN_ERROR';
                } else {
                    $scope.status = 'UNKNOWN_ERROR';
                }

            });
            return false;
        };
    }
];

export { LoginOauthCtrl }
