const ContractProductPriceCtrl = ['$scope', '$filter', '$routeParams', 'DateUtils', '$http', 'Alert',
    function ($scope, $filter, $routeParams, DateUtils, $http, Alert) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;
        var id = $routeParams.id;
        var api = API_URL + '/api/1.0/contracts/' + id + '/product-prices/';

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/

        $scope.ordering = {
            "activation_date": $translate('CONTRACTS.ACTIVATION_DATE')
        };

        $scope.filters = {
            contract: id
        };

        $scope.creationModeProductPrice = false;
        $scope.product_price = '';

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/

        function resetForm() {
            $scope.activationDate = "";
            $scope.selectedProduct = "";
            $scope.value = 0;
            $scope.contractProductPrice.$setPristine();
            $scope.contractProductPrice.$setUntouched();
        }


        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/

        $scope.toggleCreationModeProductPrice = function () {
            $scope.creationModeProductPrice = !$scope.creationModeProductPrice;
        };


        $scope.createContractProductPrice = function () {
            var data = {
                "product": $scope.selectedProduct,
                "value": $scope.value,
                "activation_date": DateUtils.transformToRESTyDate($scope.activationDate),
            }

            $http.post(api, data).then(function (data, status, headers, config) {
                $scope.listReload();
                Alert.createAlert('success', $translate('CONTRACTS.PRODUCT_PRICE_ADDED'), true);
                resetForm();
                $scope.creationModeProductPrice = false;
            }).catch(function (data, status, headers, config) {
                Alert.createAlert('danger', $translate('CONTRACTS.ERROR_PRODUCT_PRICE_ADDED'), true);
            });
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/

        $scope.$watch('contract', function(newContract) {
            $scope.rate = newContract.rate;
        });
        $http.get(API_URL + '/api/1.0/contracts/' + id + '/products/?filter_rate=true').then(function (data, status, headers, config) {
                $scope.products = data.data;
                $scope.loading = false;
            }).catch(function (data, status, headers, config) {
                $scope.loading = false;
            });
    }
]

export { ContractProductPriceCtrl }
