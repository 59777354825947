
const CompaniesLoading = ['$resource', function ($resource) {
    return $resource(API_URL + '/api/1.0/masters/companies/');
}]

const CompaniesMaster = ['$q', 'CompaniesLoading', function ($q, CompaniesLoading) {

    var companies= {
        items: {
            commercials: null,
            distributors: null
        },

        loadCompaniesCommercial: function () {
            var self = this;
            var deferred = $q.defer();

            if (this.items.commercials == null)
                CompaniesLoading.query({type: "COM", ordering: "code"},function (data) {
                    self.items.commercials = data;
                    deferred.resolve(data);
                });
            else
                deferred.resolve(self.items.commercials);

            return deferred.promise;
        },

        loadCompaniesDistributor: function () {
            var self = this;
            var deferred = $q.defer();

            if (this.items.distributors == null)
                CompaniesLoading.query({type: "DIS", ordering: "code"},function (data) {
                    self.items.distributors = data;
                    deferred.resolve(data);
                });
            else
                deferred.resolve(self.items.distributors);

            return deferred.promise;
        }
    };

    return companies;

}]

export {CompaniesLoading, CompaniesMaster}

