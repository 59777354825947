
const DuplicateContractCtrl = 
        ['$scope', '$location', '$routeParams', '$http', 'Alert', '$filter', 'CONTRACT_ORIGINS_TYPES', 'Contract',
        function ($scope, $location, $routeParams, $http, Alert, $filter, CONTRACT_ORIGINS_TYPES, Contract) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var contract = null;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.drafts_origins = CONTRACT_ORIGINS_TYPES;

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            Contract.get({id: $routeParams.id}, function (contract_data) {
                contract = contract_data;

                $http.get(API_URL + '/api/1.0/contracts-product-rates/?ordering=activation_date&contract=' + contract.id)
                    .then(function (product_rates) {
                        contract.product_rate = product_rates.data.results[product_rates.data.results.length - 1].product_rate.code;
                    });
                
            });
            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.duplicateContract = function () {
                contract.status = 'DRF';
                contract.old_id = (contract.id).toString();
                contract.id = $scope.id;
                contract.origin = $scope.origin.code;

                if(!contract.consumption_from)
                {
                    delete contract.consumption_from;
                    delete contract.consumption_to;
                    delete contract.consumption_p1;
                    delete contract.consumption_p2;
                    delete contract.consumption_p3;
                    delete contract.consumption_p4;
                    delete contract.consumption_p5;
                    delete contract.consumption_p6;
                }

                delete contract.sent_date;
                delete contract.app_date;
                delete contract.pending_date;
                delete contract.activation_date;
                delete contract.expiration_date;
                delete contract.expiration_date;
                
                $http.post(API_URL + '/api/1.0/contracts/', contract).then(function (contract_data) {
                    $location.path('/contracts/' + contract_data.data.id);
                    Alert.createAlert('success', $translate('CONTRACTS.Contract successfully duplicated'), true);

                }).catch(function (error) {
                    Alert.createAlert('danger', $translate('CONTRACTS.Contract error duplicating'), true);
                });
            };

            $scope.back = function () {
                $location.path('/contracts/' + $routeParams.id);
            };

        }]

export {DuplicateContractCtrl}