{
  "FILES": {
    "ATTACHED_FILES": "Attached files",
    "NO_ATTACHED_FILES": "No attached files",
    "CONFIRM_DELETE": "Do you want to delete?",
    "YES": "Yes",
    "NO": "No",
    "UPLOAD_FILES": "Upload files",
    "SHOW_ATTACHED_FILES": "Show attached files",
    "CLICK_HERE": "Click here or drag the files you want to upload",
    "MAX_ITEMS_UPLOAD": "Max items uploaded.",
    "NUM_MAX_ITEMS_UPLOAD": "Max. number items to upload: ",
    "CONFIRM_SHARE": "Do you really want to share this file?",
    "SHARE_FILE": "Share"
  }
}
