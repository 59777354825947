{
  "POWR": {
    "SUPPLIES": "Supplies",
    "SUPPLY_POINTS": "Supply points",
    "SECONDARY_HUB": "Consumptions report",
    "CLIENTS": "Clients",
    "CONTRACT": "Contract",
    "INVOICES": "Invoices",
    "AGREEMENTS": "Agreements",
    "FEES": "Fees",
    "PRODUCTS": "Products",
    "PRICE_RATES": "Price rates",
    "NO_NOTIFICATIONS": "No notifications",
    "ERROR": "Error",
    "TODO": "To do",
    "TO_CANCEL": "To cancel",
    "TO_UNDO": "To undo",
    "UNDOING": "Undoing",
    "DOING": "Doing",
    "CANCELED": "Canceled",
    "FINISHED": "Finished",
    "DISCARDED": "Discarded",
    "NO_DATA": "No data",
    "Load SIPS": "Load SIPS",
    "Previous": "Previous",
    "Next": "Next",
    "Create": "Create",
    "Cancel": "Cancel",
    "ENTITY": "Entity",
    "OFFICE": "Office",
    "DC": "DC",
    "ACCOUNT" : "Account",
    "WRONG_account-number": "Wrong account number",
    "CREATE_CONTRACT": "Create contract",
    "INVOICES_SERIES": "invoices series",
    "PROVIDERS": "Providers",
    "BANKS": "Banks",
    "TAXES": "Taxes",
    "LOGOUT": "Logout",
    "COMPANY_DATA": "Company data",
    "YOUR_ACCOUNT": "Your account",
    "SETTINGS": "Settings",
    "PORTFOLIO": "Portfolio",
    "REMITTANCES": "Remittances",
    "RECEIPTS": "Receipts",
    "SELECT_PAGE": "Select Page",
    "SELECT_ALL": "Select All",
    "UNSELECT_PAGE": "Unselect Page",
    "UNSELECT_ALL": "Unselect All",
    "REPORTS": "Reports",
    "UPDATE": "Update",
    "COMMERCIAL": "Commercial",
    "CONTACTS": "Contacts",
    "COMPARATIVE_REPORTS": "Comparative Reports",
    "COMMERCIAL_PRICE_RATES": "Comercial price rates",
    "SIPS": "SIPS",
    "VIEW_SELECTED": "Show selected",
    "VIEW_ALL": "Show all",
    "CALENDARS": "Calendars",
    "ISSUES": "Issues",
    "COMPLAINTS": "Complaints",
    "PROGRAMMING_UNITS": "Programming Units",
    "STATE_REQUIRED": "State is required",
    "DEBTS": "Debts",
    "ATR_COMPLAINTS": "ATR Complaints",
    "ENDORSEMENTS": "Endorsements",
    "CLEAN_FILTERS": "Clean filters",
    "SHARE": "Share",
    "COPIED_TO_CLIPBOARD": "Copied to clipboard",
    "STATUS": "Service status"
  },
  "BRANDS": {
    "TITLE": "Brands",
    "ANY": "Any",
    "NONE": "None"
  },
  "USER": {
    "CURRENT_PASS": "Current password",
    "NEW_PASS": "New password",
    "REPEAT_NEW_PASS": "Repeat new password",
    "CHANGE_PASS": "Change password",
    "PASS_UPDATED": "Password updated",
    "ERROR": "Unknown error"
  },
  "SAVE": "Save",
  "CANCEL": "Cancel",
  "CREATE": "Create",
  "QUEUES": {
    "default": "Default Queue",
    "invoicing": "Invoice Processing Queue",
    "files": "Files Queue",
    "invoiced": "Invoiced Queue",
    "EMPTY": "No tasks",
    "RUNNING": "Running",
    "NEXT": "Waiting",
    "NOT_WORKING": "The queue is not working!",
    "BY": "by",
    "AT": "at",
    "SYSTEM_TASK": "Scheduled system Task"
  }
}