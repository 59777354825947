{
  "LABELS": {
    "LABEL": "Etiqueta",
    "LABELS": "Etiquetas",
    "SELECT_LABEL": "Seleciona etiqueta",
    "CANCEL": "Cancelar",
    "ASSIGN_LABELS": "Asignar etiquetas",
    "MASSIVE_LABELS": "Etiquetas Masivas",
    "ASSIGN_MASSIVE_LABELS": "Asignar etiquetas masivamente",
    "MASSIVE_LABELS_ERROR": "Error al asignar etiquetas masivas",
    "ADD": "Añadir",
    "REMOVE": "Eliminar"
  }
}
