const CustomerDetailCtrl = ['$scope', '$routeParams', 'Customer', 'UserInfo', 'Alert', '$filter', 'stringUtils', 'StatesMaster', 'CitiesMaster',
    function ($scope, $routeParams, Customer, UserInfo, Alert, $filter, stringUtils, StatesMaster, CitiesMaster) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var self = this;
        var $translate = $filter('translate');

        $scope.CUSTOMERS_CONTRACTS_TAB = require('../../templates/customer-contracts.html');
        $scope.CUSTOMERS_LOGS_TAB = require('../../templates/customers-detail-logs.html');

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.platforms = [
            {code: 'FC', label: 'Face'}
        ];

        $scope.types = [
            {code: 0, label: $translate('CUSTOMERS.TYPES.ZERO')},
            {code: 1, label: $translate('CUSTOMERS.TYPES.ONE')},
            {code: 2, label: $translate('CUSTOMERS.TYPES.TWO')},
            {code: 3, label: $translate('CUSTOMERS.TYPES.THREE')},
            {code: 4, label: $translate('CUSTOMERS.TYPES.FOUR')}, ,
            {code: 5, label: $translate('CUSTOMERS.TYPES.FIVE')},
        ];

        $scope.payment_methods = [
            {code: 'TRF', label: $translate('INVOICES.TRANSFER')},
            {code: 'DBT', label: $translate('INVOICES.DEBIT')},
            {code: 'B2B', label: $translate('INVOICES.DEBIT_B2B')}
        ];

        $scope.scorings = [
            {code: 'NIN', label: $translate('CUSTOMERS.NOT_INFO')},
            {code: 'VAL', label: $translate('CUSTOMERS.VALID')},
            {code: 'INV', label: $translate('CUSTOMERS.INVALID')},
            {code: 'FOR', label: $translate('CUSTOMERS.FORCED')}
        ];
        $scope.scoring_deposit_options = [
            {code: 'WAR', label: $translate('CUSTOMERS.WARRANTY')},
            {code: 'DEP', label: $translate('CUSTOMERS.DEPOSIT')}
        ];
        $scope.customer_form = [
            'customer_vat_id',
            'customer_email',
            'customer_phone',
            'customer_fax',
            'customer_mobile',
            'customer_name',
            'customer_city',
            'customer_state',
            'customer_zip',
            'customer_administrative_centre_1',
            'customer_administrative_centre_2',
            'customer_administrative_centre_3',
            'customer_platform'
        ];
        $scope.loading = true;
        $scope.id = $routeParams.id;
        $scope.enable_edition = false;
        $scope.scoring_deposit = [];
        $scope.hidde_scoring_deposit = true

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        // Asignacion de STATE a uno que exista en el master de STATES
        $scope.$watchGroup(['customerStateFlag', 'statesFlag'], function (newValues, oldValues, scope) {
            if (newValues[0] && newValues[1]) {
                $scope.customer.state = stringUtils.updateState($scope.states, $scope.customer.state);
            }
        });

        $scope.$watchGroup(['customer.phone', 'customer.mobile'], function (newValues, oldValues, scope) {
            scope.hasPhone = false;
            if (newValues[0] != null && newValues[0] != '')
                scope.hasPhone = true;
            if (newValues[1] != null && newValues[1] != '')
                scope.hasPhone = true;
        });
        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        Customer.get({id: $scope.id}, function (customer) {
            $scope.customer = customer;
            $scope.loading = false;
            $scope.customerStateFlag = true;
            if('deposit' in $scope.customer && $scope.customer.deposit > 0){
                    $scope.hidde_scoring_deposit = false;
                    $scope.scoring_deposit = 'DEP';
                }
        });

        this.cleanCharacters = function (value) {
            if (value) {
                return value.replace(/[+*@\\"]/g, "");
            } else {
                return value
            }
        };

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.updateCustomer = function (form) {
            form.$setUntouched();
            for (var i = 0; i < $scope.customer_form.length; i++) {
                $scope.editCustomer[$scope.customer_form[i]].$error.api = null;
            }

            // Remove " and + character form name
            $scope.customer.name = self.cleanCharacters($scope.customer.name);
            $scope.customer.surname_1 = self.cleanCharacters($scope.customer.surname_1);
            $scope.customer.surname_2 = self.cleanCharacters($scope.customer.surname_2);

            var update_customer = new Customer($scope.customer);
            update_customer['reason'] = $scope.customer.reason;

            Customer.update({id: $scope.customer.id}, update_customer, function (customer) {
                Alert.createAlert('success', $translate('CUSTOMERS.CUSTOMER_UPDATED'), true);

                $scope.customer = customer;
                form.$setPristine();
            }).catch(function (error) {
                                    Alert.createAlert('danger', $translate('CUSTOMERS.CUSTOMER_UPDATED_ERROR'), true);

                for (var k in error.data) {
                    $scope.editCustomer['customer_' + k].$error.api = error.data[k][0];
                }
            });
        };

        $scope.change_web_access = function (value) {
            $scope.editCustomer.$setDirty();
            $scope.customer.web_access = value;
        };

        $scope.change_social_bonus = function (value) {
            $scope.editCustomer.$setDirty();
            $scope.customer.social_bono = value;
        };

        $scope.enableEdition = function () {
            $scope.enable_edition = !$scope.enable_edition;
        };

        $scope.canViewDetail = function () {
            return (UserInfo.hasPerm('customers.retrieve_customers'));
        };

        $scope.canEditCustomer = function () {
            return (UserInfo.hasPerm('customers.change_customer'));
        };

        $scope.canViewCustomerContracts = function () {
            return (UserInfo.hasPerm('customers.list_customercontracts') && UserInfo.hasPerm('customers.retrieve_customers'));
        };
        $scope.changeScoringDeposit = function () {
            if ($scope.scoring_deposit === 'WAR') {
                $scope.hidde_scoring_deposit = true;
                $scope.customer.deposit = -1;
            } else if ($scope.scoring_deposit === 'DEP') {
                $scope.hidde_scoring_deposit = false;
                $scope.customer.deposit = 0;
            } else {
                $scope.hidde_scoring_deposit = true;
                $scope.customer.deposit = 0;
            }
        };

        $scope.canAssignLabel = function () {
            return UserInfo.hasPerm('labels.add_labelledobject');
        };

        $scope.canDeleteAssignLabel = function () {
            return UserInfo.hasPerm('labels.delete_labelledobject');
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        StatesMaster.loadStates().then(function (response) {
            $scope.states = response;
            $scope.statesFlag = true;
        });


        CitiesMaster.loadCities().then(function (response) {
            $scope.cities = response;
        });

    }
]

export { CustomerDetailCtrl };
