
const ContractProductRatesDetailCtrl = 
        ['$scope', '$filter', '$routeParams', 'DateUtils', '$http', 'UserInfo', 'Alert',
            function ($scope, $filter, $routeParams, DateUtils, $http, UserInfo, Alert) {
                /***********************************************************************************************************
                 * Controller Attributes
                 ***********************************************************************************************************/
                var $translate = $filter('translate');
                var api = API_URL + '/api/1.0/contracts-product-rates/';

                /***********************************************************************************************************
                 * Scope Attributes
                 ***********************************************************************************************************/
                
                $scope.action = 'detail';

                /***********************************************************************************************************
                 * Scope Watchers
                 ***********************************************************************************************************/


                /***********************************************************************************************************
                 * Controller Methods
                 ***********************************************************************************************************/


                /***********************************************************************************************************
                 * Scope Methods
                 ***********************************************************************************************************/

                $scope.deleteProductPrice = function ($event) {
                    $event.preventDefault();
                    $scope.action = 'delete';
                };

                $scope.editProductPrice = function ($event) {
                    $event.preventDefault();
                    $scope.action = 'edit';
                };

                $scope.cancelProductPrice = function ($event) {
                    $event.preventDefault();
                    $scope.action = 'detail';
                };

                $scope.canDeleteContractProductRate = function () {
                    return UserInfo.hasPerm("contracts.delete_contractproductsrates");
                };

                $scope.canEditContractProductRate = function () {
                    return UserInfo.hasPerm("contracts.change_contractproductsrates");
                };


                $scope.deleteContractProductRate = function (id) {

                    $http.delete(api + id).then(function(response) {
                        $scope.listReload();
                        Alert.createAlert('success', $translate('CONTRACTS.PRODUCT_RATE_DELETED'), true);
                    }).catch(function(response){
                        let data = response.data;
                        Alert.createAlert('danger', data.detail)
                    });
                };

                $scope.updateContractProductRate = function (id) {

                    var data = {
                        'activation_date': DateUtils.transformToRESTyDate($scope.activation_date),
                        'contract': $scope.result.contract
                    };

                    $http.patch(api + id, data).then(function (response) {
                        $scope.listReload();
                        Alert.createAlert('success', $translate('CONTRACTS.PRODUCT_RATE_UPDATED'), true);
                    });
                };

                /***********************************************************************************************************
                 * Controller Initialization
                 ***********************************************************************************************************/

                $scope.activation_date = DateUtils.transformToInterface($scope.result.activation_date);


            }]

            
export {ContractProductRatesDetailCtrl}
