function addressUtils(){
    this.splitAddressByComma = function (address) {
        return address.split(',');
    };

    this.rebuiltAddress = function (address, number, extension) {
        var new_address = '';
        var new_number = '';
        var new_extension = '';
        // deleted commas in address and number field when save
        if (address!== undefined || address != null)
            new_address = address.trim().replace(",","");
        if (number!== undefined || number != null)
            new_number  = number;
        if (extension!== undefined || extension != null)
            new_extension = extension.trim();
        return new_address + ', ' + new_number + ', ' + new_extension;
    };
    
    this.getAddressName = function (address) {
        return address[0].trim();
    };
    
    this.getAddressNumber = function (address) {
        if (address.length >= 2) {
            if (address[1] != undefined || address[1] != '' || address[1] != null)
                return parseInt(address[1].trim());
        }
        return '';
    };
    
    this.getAddressExt = function (address) {
        var extension = '';
        if (address.length > 1) {
            var has_ext = false;
            for (var i = 2; i < address.length; i++){
                extension += address[i].trim() + ', ';
                has_ext = true;
            }
            if (has_ext)
                extension = extension.slice(0, -2);
        }
        return extension.trim();
    };
}

export { addressUtils };
