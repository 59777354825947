{
  "SETTINGS": {
    "COMPANY_INFO": "Company Information",
    "NAME": "Name",
    "CIF": "CIF",
    "ADDRESS": "Address",
    "TOWN": "City",
    "STATE": "State",
    "ZIP_CODE": "Zip code",
    "PHONE": "Phone",
    "FAX": "Fax",
    "WEB": "Web",
    "EMAIL": "Email",
    "SAVE": "Save",
    "SUCCESS_SETTINGS_UPDATED": "updated successfully",
    "ERROR_SETTINGS_UPDATED": ": Settings updated error. Any field may be empty or exceed 255 characters.",
    "TAX": "Tax",
    "COMPANY_ADDRESS": "Company Address",
    "CONTRACT_SETTINGS": "Contract Settings",
    "INVOICE_SETTINGS": "Invoice Settings",
    "CONTRACT_EXPIRATION_1": "Set days for first Contract expiration date advice",
    "CONTRACT_EXPIRATION_2": "Set days for second Contract expiration date advice",
    "CONTRACT_EXPIRATION_3": "Set days for third Contract expiration date advice",
    "DRAFT_INVOICES": "Set Draft Invoice",
    "INVOICING_DAYS": "Set days for Invoicing expiration date",
    "DEFAULT_INVOICES": "Set default invoice series"
  }

}