function providerInvoiceChangeProvider(UserInfo, Alert, filtersUtils, ProviderInvoiceAPI){
    return {
        restrict: 'AE',
        templateUrl: require('../../templates/provider-invoices-change-provider.html'),
        controller: function ($scope, $rootScope, $filter, ProvidersMaster) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var self = this;
            var first_access = true;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.hasPerm = UserInfo.hasPerm('providers.change_provider_invoice_provider');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            $scope.$watch('providerForm.$valid', function (newValue, oldValue) {
                $scope.$emit("setValidity", newValue);
                if (first_access === true)
                    first_access = false;
                else {
                    if (newValue)
                        $scope.selected_provider = true;
                }
            }, true);

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var json = {ids: ids, provider: $scope.change_provider};

                ProviderInvoiceAPI.changeProviderInProviderInvoice(json).then(function (data) {
                    Alert.createAlert('success', $translate('PROVIDERS.PROVIDER_PROVIDER_UPDATED') + data.updated_provider, true);
                    $scope.providerForm.$setPristine();
                    $scope.providerForm.$setUntouched();
                    $rootScope.$broadcast('refreshList');
                }).catch(function (error) {
                    $scope.error_message = error.data.__all__[0];
                }).finally(function () {
                    $scope.resetChangeProviderForm();
                });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.resetChangeProviderForm = function () {
                $scope.flagConfirm = false;
                $scope.listToUpdate = [];
                $scope.change_provider = '';
                $scope.selected_provider = false;
            };

            $scope.change_provider_clickNo = function () {
                $scope.resetChangeProviderForm();
                $scope.providerForm.$setPristine();
                $scope.providerForm.$setUntouched();
            };

            $scope.change_provider_clickYes = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToUpdate);
                performRequest(ids);
                //$scope.resetChangeProviderForm();
            };

            /***********************************************************************************************************
            * Controller Initialization
            ***********************************************************************************************************/
            ProvidersMaster.loadProviders().then(function (response){
                $scope.providers = response;
            });

            $scope.resetChangeProviderForm();

        }
    }
}

export { providerInvoiceChangeProvider }
