
const ProductCodesLoading = ['$resource', function ($resource) {
    return $resource(API_URL + '/api/1.0/masters/product-codes/');
}]

const ProductCodeMaster = ['$q', 'ProductCodesLoading', function ($q, ProductCodesLoading) {

    var products= {
        items: {
            product_force: null,
            product_not_force: null
        },

        loadProductCodesForce: function () {
            var self = this;
            var deferred = $q.defer();

            if (this.items.product_force == null)
                ProductCodesLoading.query({visibility: "VISIBLE", not_in_force: 1},function (data) {
                    self.items.product_force = data;
                    deferred.resolve(data);
                });
            else
                deferred.resolve(self.items.product_force);

            return deferred.promise;
        },

        loadProductCodesNotForce: function () {
            var self = this;
            var deferred = $q.defer();

            if (this.items.product_not_force == null)
                ProductCodesLoading.query({visibility: "VISIBLE", not_in_force: 0},function (data) {
                    self.items.product_not_force = data;
                    deferred.resolve(data);
                });
            else
                deferred.resolve(self.items.product_not_force);

            return deferred.promise;
        }

    };

    return products;


}]

export {ProductCodeMaster, ProductCodesLoading}

