function RatesService() {

    function _isRate(code) {
        var isRate = false;
        var re = /T[0-9]*/;
        var m;
        if ((m = re.exec(code)) !== null) {
            if (m.index === re.lastIndex) {
                isRate = true;
            }
        }
        return isRate;
    }

    this.getMagintudeIndex= function (code) {
        if (code.slice(-4).indexOf('REP') > -1) return code.lastIndexOf('REP');
        if (code.slice(-5).indexOf('EXPP') > -1) return code.lastIndexOf('EXPP');
        if (code.slice(-5).indexOf('ERGP') > -1) return code.lastIndexOf('ERGP');
        if (code.slice(-3).indexOf('PP') > -1) return code.lastIndexOf('PP');
        if (code.slice(-3).indexOf('EP') > -1) return code.lastIndexOf('EP');
        if (code.slice(-7).indexOf('GESTMP') > -1) return code.lastIndexOf('GESTMP');
        if (code.slice(-7).indexOf('PRSCHP') > -1) return code.lastIndexOf('PRSCHP');
        if (code.slice(-7).indexOf('DEVTNP') > -1) return code.lastIndexOf('DEVTNP');
        if (code.slice(-7).indexOf('FINCTP') > -1) return code.lastIndexOf('FINCTP');
        return -1;
    };

    this.getRateFromCode = function (code) {
        if (typeof(code) === "undefined")
            return "";
        if (code == 'IE' || code == 'RENT' || code == 'VARS06' || code == 'VARS') return "";
        var magnitudeIndex = this.getMagintudeIndex(code);
        if (_isRate(code) && magnitudeIndex > -1, code.substr(0, magnitudeIndex)) {
            return code.substr(0, magnitudeIndex);
        }
        return "";
    };

    this.getMagnitudeFromCode = function (code) {
        if (typeof(code) === "undefined")
            return "";
        if (code == 'IE' || code == 'RENT' || code == 'VARS06' || code == 'VARS') return code;

        if (code.slice(-4).indexOf('REP') > -1) return 'RE';
        if (code.slice(-5).indexOf('EXPP') > -1) return 'EXP';
        if (code.slice(-5).indexOf('ERGP') > -1) return 'ERG';
        if (code.slice(-3).indexOf('PP') > -1) return 'P';
        if (code.slice(-3).indexOf('EP') > -1) return 'E';
        if (code.slice(-7).indexOf('GESTMP') > -1) return 'GESTM';
        if (code.slice(-7).indexOf('PRSCHP') > -1) return 'PRSCH';
        if (code.slice(-7).indexOf('DEVTNP') > -1) return 'DEVTN';
        if (code.slice(-7).indexOf('FINCTP') > -1) return 'FINCT';

        return code;
    };

    this.getPeriodFromCode = function (code) {
        if (typeof(code) === "undefined")
            return "";
        var possiblePeriod = code.slice(-2);
        if (_isRate(code) && ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'].indexOf(possiblePeriod) >= 0) {
            return possiblePeriod;
        } else {
            return "";
        }

    };
}

export { RatesService }