
const ContractProductRatesCtrl = 
        ['$scope', '$filter', '$routeParams', 'DateUtils', '$http', 'ProductRatesMaster', 'Alert', 'UserInfo',
            function ($scope, $filter, $routeParams, DateUtils, $http, ProductRatesMaster, Alert, UserInfo) {
                /***********************************************************************************************************
                 * Controller Attributes
                 ***********************************************************************************************************/
                var $translate = $filter('translate');
                var self = this;
                var id = $routeParams.id;
                var api = API_URL + '/api/1.0/contracts-product-rates/';
                var api_check_rate = API_URL + '/api/1.0/rate-valid-in-product-rate/';

                /***********************************************************************************************************
                 * Scope Attributes
                 ***********************************************************************************************************/

                $scope.ordering = {
                    "activation_date": $translate('CONTRACTS.ACTIVATION_DATE')
                };

                $scope.filters = {
                    contract: id
                };

                $scope.creationMode = false;
                $scope.rate = '';

                /***********************************************************************************************************
                 * Scope Watchers
                 ***********************************************************************************************************/
                $scope.$watchGroup(['rate', 'selected_product_rates'], function (newValues, oldValues, scope) {
                    if (newValues[0] && newValues[1] && newValues[2]) {
                        var data = {
                            "product_rate": $scope.selected_product_rates,
                            "rate": $scope.rate
                        };

                        $http.post(api_check_rate, data).then(function (data) {
                            if (!data.data.has_prices) {
                                $scope.contractProductRate.$setValidity('selected_product_rates', false);
                                Alert.createAlert('danger', $translate('CONTRACTS.INVALID_PRODUCT_RATE'), true);
                            } else {
                                $scope.contractProductRate.$setValidity('selected_product_rates', true);
                            }
                        }).catch(function (error) {
                            Alert.createAlert('danger', $translate('CONTRACTS.ERROR_CHECKING_PRODUCT_RATE'), true);
                        });

                    }
                });


                /***********************************************************************************************************
                 * Controller Methods
                 ***********************************************************************************************************/

                function resetForm() {
                    $scope.activationDate = "";
                    $scope.selected_product_rates = "";
                    $scope.contractProductRate.$setPristine();
                    $scope.contractProductRate.$setUntouched();
                }


                /***********************************************************************************************************
                 * Scope Methods
                 ***********************************************************************************************************/

                $scope.toggleCreationMode = function () {
                    $scope.creationMode = !$scope.creationMode;
                };

                $scope.createContractProductRate = function () {
                    var data = {
                        "contract": id,
                        "product_rate": $scope.selected_product_rates,
                        "activation_date": DateUtils.transformToRESTyDate($scope.activationDate)
                    };

                    $http.post(api, data).then(function (data) {
                        $scope.listReload();
                        Alert.createAlert('success', $translate('CONTRACTS.PRODUCT_RATE_ADDED'), true);
                        resetForm();
                        $scope.creationMode = false;
                    }).catch(function (error) {
                        Alert.createAlert('danger', $translate('CONTRACTS.ERROR_ADDING_PRODUCT_RATE'), true);
                    });
                };

                $scope.canDeleteContractProductRate = function () {
                    return UserInfo.hasPerm("contracts.delete_contractproductsrates");
                };

                $scope.canEditContractProductRate = function () {
                    return UserInfo.hasPerm("contracts.change_contractproductsrates");
                };

                /***********************************************************************************************************
                 * Controller Initialization
                 ***********************************************************************************************************/

                ProductRatesMaster.loadProductRatesNotForce().then(function (response){
                    $scope.product_rates = response;
                });

                $scope.$watch('contract', function(newContract) {
                    $scope.rate = newContract.rate;
                });
            }]

export {ContractProductRatesCtrl}
