const ContractProductPriceDetailCtrl = ['$scope', '$filter', '$routeParams', 'DateUtils', '$http', 'UserInfo', 'Alert',
    function ($scope, $filter, $routeParams, DateUtils, $http, UserInfo, Alert) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var api = API_URL + '/api/1.0/contracts/' + $routeParams.id + '/product-prices/';
        var contract_id = $routeParams.id;

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/

        $scope.action = 'detail';
        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/


        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/

        $scope.deleteProductPrice = function ($event) {
            $event.preventDefault();
            $scope.action = 'delete';
        };

        $scope.editProductPrice = function ($event) {
            $event.preventDefault();
            $scope.action = 'update';
        };

        $scope.cancelProductPrice = function ($event) {
            $event.preventDefault();
            $scope.action = 'detail';
        };

        $scope.canDeleteContractProductPrice = function () {
            return UserInfo.hasPerm("contracts.change_contract_product_price");
        };

        $scope.canEditContractProductPrice = function () {
            return UserInfo.hasPerm("contracts.change_contract_product_price");
        };

        $scope.deleteContractProductPrice = function (id) {

            $http.delete(api + id).then(function (data, status, headers, config) {
                $scope.listReload();
                Alert.createAlert('success', $translate('CONTRACTS.PRODUCT_PRICE_DELETED'), true);
            });
        };

        $scope.updateContractProductPrice = function (id) {
            const data = {
                "activation_date": DateUtils.transformToRESTyDate($scope.activation_date),
                "product": $scope.product,
                "contract": contract_id,
                "value": $scope.value
            }
            $http.put(api + id, data).then(function (data, status, headers, config) {
                $scope.listReload();
                Alert.createAlert('success', $translate('CONTRACTS.PRODUCT_PRICE_UPDATED'), true);
            });
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        $scope.activation_date = DateUtils.transformToInterface($scope.result.activation_date);
        $scope.value = $scope.result.value;
        $scope.product = $scope.result.product;
    }
]

export { ContractProductPriceDetailCtrl }
