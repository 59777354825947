// Controllers
import { CreateProductCtrl } from './controllers/CreateProductCtrl'
import { CreateProductPriceCtrl } from './controllers/CreateProductPriceCtrl'
import { CreateRatePriceCtrl } from './controllers/CreateRatePrice'
import { ProductCtrl } from './controllers/ProductCtrl'
import { ProductDetailCtrl } from './controllers/ProductDetailCtrl'
import { ProductListCtrl } from './controllers/ProductListCtrl'
import { ProductPriceCtrl } from './controllers/ProductPriceCtrl'
import { ProductRateDetailCtrl } from './controllers/ProductRateDetailCtrl'
import { ProductRatesListCtrl } from './controllers/ProductRatesListCtrl'
import { SingleProductRateCreateCtrl } from './controllers/SingleProductRateCreateCtrl'
import { SingleProductRateDetail } from './controllers/SingleProductRateDetail'

// Services
import { ProductAPI } from './services/ProductAPI'
import { ProductPriceAPI } from './services/ProductPriceAPI'
import { ProductRateAPI } from './services/ProductRateAPI'


const products = angular.module('products', ['pascalprecht.translate', 'supplies']);

products.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

products.constant('PRODUCTS_VISIBILITY', {
    'VISIBLE': 'VISIBLE',
    'INVISIBLE': 'INVISIBLE'
})

products.constant('RATES_INVOICING_MODE', [
    {code: 'NOR', label: 'Normal'},
    {code: 'IND', label: 'Indexada'},
    {code: 'INB', label: 'Indexada sin elevar a barras'},
    {code: 'INM', label: 'Indexada Mixta'},
    {code: 'Q11', label: 'Q101'}
])

products
    .controller('CreateProductCtrl', CreateProductCtrl)
    .controller('CreateProductPriceCtrl', CreateProductPriceCtrl)
    .controller('CreateRatePriceCtrl', CreateRatePriceCtrl)
    .controller('ProductCtrl', ProductCtrl)
    .controller('ProductDetailCtrl', ProductDetailCtrl)
    .controller('ProductListCtrl', ProductListCtrl)
    .controller('ProductPriceCtrl', ProductPriceCtrl)
    .controller('ProductRateDetailCtrl', ProductRateDetailCtrl)
    .controller('ProductRatesListCtrl', ProductRatesListCtrl)
    .controller('SingleProductRateCreateCtrl', SingleProductRateCreateCtrl)
    .controller('SingleProductRateDetail', SingleProductRateDetail)
    .service('ProductAPI', ProductAPI)
    .service('ProductPriceAPI', ProductPriceAPI)
    .service('ProductRateAPI', ProductRateAPI)


export { products }
