const Settings = ['$resource',
    function ($resource) {
        return $resource(API_URL + '/api/1.0/settings/:key/', { key: '@key' },
            {
                'update': { method: 'PUT'}
            }
        );
    }
]

export { Settings }
