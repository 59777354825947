function invoiceSetSent(UserInfo, filtersUtils) {
    return {
        restrict: 'AE',
        scope: {},
        templateUrl: require('../../templates/sent-button.html'),
        controller: function ($scope, $http, $rootScope, Alert, $filter) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/invoices/';
            var $translate = $filter('translate');

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagSend = false;
            $scope.listToUpdate = [];

            $scope.hasPerm = UserInfo.hasPerm('invoices.set_invoice_sent');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                //Alert.createAlert('info', $translate('INVOICES.SENDING_SENT'), true);
                var json = {ids: ids, "status": "SNT"};

                var config = {
                    method: "PUT",
                    url: api,
                    data: json,
                    headers: {"Content-Type": "application/json;charset=utf-8"}
                };
                $http(config)
                    .then(function (data, status, headers, config) {
                        Alert.createAlert('success', $translate('INVOICES.SENT_SENT_OK') + data.data.updated_number, true);
                        $rootScope.$broadcast('refreshList');
                    }, function (data, status, headers, config) {
                        Alert.createAlert('danger', $translate('INVOICES.SENT_SENT_KO'), true);
                    })
                    /*
                    .finally(function () {
                        $scope.flagSend = false;
                        $scope.listToUpdate = [];
                        $scope.$parent.loading = false;
                    });
                    */
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.confirmUpdate = function () {
                $scope.flagSend = true;
            };

            $scope.updateNo = function () {
                $scope.flagSend = false;
            };

            $scope.updateYes = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToUpdate);
                performRequest(ids);
            };

        }
    }
}

export { invoiceSetSent }
