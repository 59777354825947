import { isValidIBAN } from 'ibantools'

function accountNumber(){

    return {
        require: 'ngModel',
        scope:{
            iban: '=ngModel',
            ngDisabled: '=?',
            zeros: '=?'
        },
        restrict: 'E',
        templateUrl: require('../../templates/account-number.html'),
        link: function (scope, element, attrs, ctrl) {

            var inputs = element.find('input');
            var self = this;
            var is_valid = false;
            var is_foreign = true;

            inputs.bind('keyup', function (event) {
                scope.iban = event.target.value.replace(' ', '')
                is_valid = isValidIBAN(scope.iban)
                if (is_valid) {
                    delete scope.error;
                } else {
                    scope.error = !is_valid;
                }
                scope.$apply();
            });

            ctrl.$validators.accountNumber = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    // consider empty models to be invalid
                    return true;
                }
                return isValidIBAN(modelValue)
            };
        },
        controller: function ($scope) {
            $scope.next_input = 0;
            $scope.error = false;

            $scope.next = function (index) {
                $scope.next_input = index;
            };

            $scope.$watch("iban", function() {
                if ($scope.iban) {
                    $scope.iban = $scope.iban.replace(/ /g,'');
                    if (isValidIBAN($scope.iban)) {
                        delete $scope.error;
                    } else {
                        $scope.error = true;
                    }
                }
                return
            });
        }

    };
}

export { accountNumber };
