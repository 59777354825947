
// const contractsAgreement = function
function queuesList(UserInfo, filtersUtils, DateUtils, Alert, $filter, MetaResources) {
    return {
        restrict: 'AE',
        scope: {},
        template: `
            <span class="fa fa-circle-o-notch fa-spin" ng-show="loading"></span>
            <div ng-show="!loading" class="tab-content">
                <div class="">
                    <div class="panel panel-default" ng-repeat="(queue, value) in queues">
                        <div class="panel-heading">
                            <h3 class="panel-title" >{{ "QUEUES."+queue.split('-')[1] | translate }}</h3>
                        </div>
                        <div class="panel-body">
                            <div ng-if="queues[queue]" class="row">
                                <div class="col-xs-12 col-md-6">
                                    <h4>{{ "QUEUES.RUNNING" | translate }}</h4>
                                    <div class="item" ng-if="queues[queue].running.length" ng-repeat="task in queues[queue].running">
                                        <span ng-if="task.name"><b>{{ task.name }}</b> {{ "QUEUES.BY" | translate }} {{ task.username }} {{ "QUEUES.AT" | translate }} {{ task.started_on }}</span>
                                        <span ng-if="!task.name"><b>{{ "QUEUES.SYSTEM_TASK" | translate }}</b></span>
                                    </div>
                                    <div ng-if="!queues[queue].running.length">
                                        {{ "QUEUES.EMPTY" | translate }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <h4>{{ "QUEUES.NEXT" | translate }}</h4>
                                    <div class="item" ng-if="queues[queue].next.length" ng-repeat="task in queues[queue].next">
                                        <span ng-if="task.name"><b>{{ task.name }}</b> {{ "QUEUES.BY" | translate }} {{ task.username }} {{ "QUEUES.AT" | translate }} {{ task.started_on }}</span>
                                        <span ng-if="!task.name">{{ "QUEUES.SYSTEM_TASK" | translate }}</span>
                                    </div>
                                    <div ng-if="!queues[queue].next.length">
                                        {{ "QUEUES.EMPTY" | translate }}
                                    </div>
                                </div>
                            </div>
                            <div ng-if="!queues[queue]">
                                ¡La cola está parada!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `,
        controller: function ($scope, $http, $rootScope) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var $translate = $filter('translate');

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/

            $scope.operative_queues = ["default", "invoicing", "invoiced", "files"]

            $scope.loading = true;

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/

            /***********************************************************************************************************
             * Controller Initialization
             ***********************************************************************************************************/
            MetaResources.listQueues().then(function (response) {
                $scope.queues = response;
                $scope.loading = false;
            });
        }
    }
}

export { queuesList }
