// Controllers
import { IssueDetailCtrl } from './controllers/IssueDetailCtrl'
import { IssueResultDetailCtrl } from './controllers/IssueResultDetailCtrl'
import { IssuesCtrl } from './controllers/IssuesCtrl'

// Directives
import { deleteIssues } from './directives/deleteIssues'
import { duplicateIssue } from './directives/duplicateIssue'
import { issuesDownloads } from './directives/issuesDownloads'
import { issuesStatus } from './directives/issuesStatus'
import { setIssuesAsChecKed } from './directives/setIssuesAsChecKed'

// Services
import { IssueAPI } from './services/IssueAPI'

const issues = angular.module('issues', ['pascalprecht.translate', ]);

issues.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

issues
    .controller('IssueDetailCtrl', IssueDetailCtrl)
    .controller('IssueResultDetailCtrl', IssueResultDetailCtrl)
    .controller('IssuesCtrl', IssuesCtrl)
    .directive('deleteIssues', deleteIssues)
    .directive('duplicateIssue', duplicateIssue)
    .directive('issuesDownloads', issuesDownloads)
    .directive('issuesStatus', issuesStatus)
    .directive('setIssuesAsChecKed', setIssuesAsChecKed)
    .service('IssueAPI', IssueAPI)

export { issues }
