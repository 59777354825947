ChangeEstimatedConsumptionCtrl
const ChangeEstimatedConsumptionCtrl = 
        ['$scope', '$location', '$routeParams', '$http', '$filter', 'Alert',
        function ($scope, $location, $routeParams, $http, $filter, Alert) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$watch('annual_consumption', function (newValue, oldValue, scope) {
                scope.validConsumption = false;

                if (newValue != null && newValue != '') {
                    if (/^[0-9]+([,.][0-9]+)?$/.test(newValue))
                        scope.validConsumption = true;
                    else
                        scope.validConsumption = false;
                }

            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.updateEstimatedConsumption = function () {
                var consumption = $scope.annual_consumption.replace(/,/g, '.');

                $http.put(API_URL + '/api/1.0/contracts/' + $routeParams.id + '/process/change-consumption/', {yearly_consumption: consumption}).
                    then(function(response) {
                        $location.path('/contracts/' + $routeParams.id);
                        Alert.createAlert('success', $translate('CONTRACTS.ESTIMATED_CONSUMPTION_UPDATED'), true);
                    }).
                    catch(function (response) {
                        Alert.createAlert('danger', $translate('CONTRACTS.ESTIMATED_CONSUMPTION_UPDATED_ERROR'), true);
                    });
            };

            $scope.back = function () {
                $location.path('/contracts/' + $routeParams.id);
            };

        }]

export {ChangeEstimatedConsumptionCtrl}
