const ProductListCtrl = ['$scope', '$filter', '$location', 'ProductAPI', 'Alert', 'UserInfo',
    function ($scope, $filter, $location, ProductAPI, Alert, UserInfo) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.product = $scope.result;
        $scope.products = $scope.results;

        $scope.mode = 'detail';
        $scope.action = 'detail';
        $scope.loading = false;
        $scope.edited_product = { code: "", name: "", description: "", price: "", tax: "" };

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        $scope.$on('productsListReload', function (event) {
            $scope.listReload();
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.viewProduct = function(product) {
            var extra_param = "";

            if (($scope.$parent.filters.rate != "") && ($scope.$parent.filters.rate != null)) {
                var rate = $scope.$parent.filters.rate;
                rate = rate.slice(0, rate.indexOf(' - '));

                $location.path( '/products/' + product.code).search('rate', rate);
            }
            else {
                $location.path( '/products/' + product.code);
            }
        };

        $scope.deleteProduct = function() { $scope.action = 'delete'; };
        $scope.cancelDeleteProduct = function() { $scope.action = 'detail'; };
        $scope.confirmDeleteProduct = function() {
            $scope.loading = true;

            ProductAPI.deleteProduct($scope.product.code).then(function (product) {
                $scope.loading = false;
                $scope.listReload();
                Alert.createAlert('success', $translate('PRODUCTS.PRODUCT_DELETED'), true);
            }).catch(function (error) {
                $scope.loading = false;
                $scope.error_message = error.data.__all__[0];
            });
        };

        $scope.canShowProduct = function () {
            return UserInfo.hasPerm('products.retrieve_products');
        };

        $scope.canDeleteProduct = function () {
            return UserInfo.hasPerm('products.add_product');
        };

    }
]

export { ProductListCtrl }
