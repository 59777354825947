function wizard() {
    return {
        restrict: 'E',
        transclude: true,
        replace: true,
        //templateUrl: require('../../templates/wizard.html'),
        template: `
            <div>

                <h1>{{ mainTitle }}</h1>
            
                <div class="row wizard-buttons">
            
                    <div class="col-xs-3">
                        <div class="btn-group btn-group-sm pull-left">
                            <button class="btn btn-small btn-default margin-button-left"
                                    ng-click="prevPage()"
                                    ng-show="hasPrev()">
                                {{ "POWR.Previous" | translate }}
                            </button>
                        </div>
                    </div>
            
                    <div class="col-xs-6">
                        <div class="center-block text-center" ng-if="hasSteps==true && pages.length > 1">
                            <div class="btn-group btn-group-sm">
                                <button type="button"
                                        class="btn btn-sm btn-default"
                                        ng-class="{'btn-primary': page.activePage==true, 'disabled': currentPageIndex < $index}"
                                        ng-click="goToPage($index)"
                                        ng-repeat="page in pages track by $index">
            
                                    {{ page.pageTag || $index + 1}}
                                </button>
                            </div>
                        </div>
                    </div>
            
                    <div class="col-xs-3">
                        <div class="btn-group btn-group-sm pull-right margin-button-right" ng-if="hasCancel && hasCreate">
                            <button class="btn btn-small btn-default"
                                    ng-if="hasCancel==true"
                                    ng-click="cancel()">
                                {{ "POWR.Cancel" | translate }}
                            </button>
            
                            <button class="btn btn-small btn-primary"
                                    ng-if="hasCreate==true"
                                    ng-click="save()"
                                    ng-hide="hasNext()"
                                    ng-disabled="!validity">
                                {{ lastButtonText }}
                            </button>
                        </div>
            
                        <div class="margin-button-right" ng-if="hasCancel">
                            <button class="btn btn-small btn-default"
                                    ng-if="hasCancel==true"
                                    ng-click="cancel()">
                                {{ "POWR.Cancel" | translate }}
                            </button>
                        </div>
            
                        <div class="margin-button-right" ng-if="hasCreate">
                            <button class="btn btn-small btn-primary"
                                    ng-if="hasCreate==true"
                                    ng-click="save()"
                                    ng-hide="hasNext()"
                                    >
                                {{ lastButtonText }}
                            </button>
                        </div>
            
                        <div class="margin-button-right">
                            <button class="btn btn-small btn-default"
                                    ng-click="nextPage()"
                                    ng-show="hasNext()"
                                    ng-disabled="!validity">
                                {{ "POWR.Next" | translate }}
                            </button>
                        </div>
                    </div>
            
                </div>
            
                <div ng-transclude></div>
            
            </div>
        `,
        scope: {
            hasValidation: '=',
            mainTitle: '@',
            hasSteps: '=',
            hasCreate: '=',
            hasCancel: '=',
            onSave: '&',
            onCancel: '&',
            initiallyValid: '=?',
            lastButtonText: '@?'
        },

        controller: function ($scope, $filter) {
            var self = this;
            var $translate = $filter('translate');

            $scope.pages = [];
            $scope.validity = null;

            if (!$scope.lastButtonText) {
                $scope.lastButtonText = $translate("POWR.Create");
            }


            this.max_page_edited = 0;

            if ($scope.hasValidation)
                $scope.validity = false;
            else
                $scope.validity = true;

            var initiallyValid = false;
            if ($scope.initiallyValid) initiallyValid = $scope.initiallyValid;

            // Metodos para cambiar de página

            var changePage = function (nextIndex) {
                $scope.state = {state: {previousPage: $scope.currentPageIndex, currentPage: nextIndex}};
                $scope.pages[$scope.currentPageIndex].activePage = false;
                setCurrentPage(nextIndex);
            };

            var setCurrentPage = function (index) {
                $scope.currentPageIndex = index;
                $scope.pages[$scope.currentPageIndex].activePage = true;
            };

            // Métodos de crear y cancelar

            $scope.save = function () {
                if ($scope.onSave) $scope.onSave();
            };
            $scope.cancel = function () {
                if ($scope.onCancel) $scope.onCancel();
            };

            // Función para agregar páginas

            this.addPage = function (page) {
                if (!this.pageAlreadyExists(page)) {
                    $scope.pages.push(page);
                    if ($scope.pages.length === 1) setCurrentPage(0);
                }

            };

            // Validación formulario

            this.setValidation = function (validity) {
                if ($scope.hasValidation)
                    $scope.validity = validity;
            }

            // Siguiente pagina

            this.next = function () {
                $scope.nextPage();
            }

            // Métodos para controlar el cambio de páginas

            $scope.goToPage = function (index) {
                changePage(index);
                self.setValidation(true);
            };
            $scope.nextPage = function () {
                if (self.max_page_edited < $scope.currentPageIndex + 1) {
                    self.max_page_edited = $scope.currentPageIndex;
                    self.setValidation(initiallyValid);
                } else {
                    self.setValidation(true);
                }
                changePage($scope.currentPageIndex + 1);
            };
            $scope.prevPage = function () {
                changePage($scope.currentPageIndex - 1);
                self.setValidation(true);
            };

            // Métodos para controlar la navegación entre páginas
            $scope.isLast = function () {
                return $scope.currentPageIndex === ($scope.pages.length - 1);
            };
            $scope.isFirst = function () {
                return $scope.currentPageIndex === 0;
            };
            $scope.hasNext = function () {
                return !$scope.isLast();
            };
            $scope.hasPrev = function () {
                return !$scope.isFirst();
            };

            function deletePageByTag (tag) {
                var pos = null;
                for (var p in $scope.pages) {
                    if (tag == $scope.pages[p].pageTag) {
                        pos = p;
                    }
                }
                if (pos != null) {
                    $scope.pages.splice(pos, 1);
                }
            };

            $scope.$on('deleteWizardPages', function(event, tagList) {
                for (var t in tagList) {
                    deletePageByTag(tagList[t]);
                }

            });

            this.pageAlreadyExists = function (page) {
                var exists = false;
                for (var p in $scope.pages) {
                    if ($scope.pages[p] == page.pageTag) {
                        exists = true;
                    }
                }
                return exists;
            }
        }
    };
}


function wizardPage() {

    return {
        restrict: 'E',
        transclude: true,
        replace: true,
        //templateUrl: require('../../templates/wizard-page.html'),
        template: `
            <div ng-show="activePage==true">
                <h3 ng-if="pageTitle!=undefined|| pageTitle!=''">
                    {{pageTitle}}
                </h3>
            
                <div ng-transclude></div>
            
            </div>
        `,
        scope: {
            pageTitle: '@',
            pageTag: '@',
            childTemplateUrl: '=?'
        },

        require: '^wizard',

        link: function (scope, element, attr, wizard) {
            wizard.addPage(scope);

            scope.$on('setValidity', function (event, value) {
                wizard.setValidation(value);
            });

            scope.$on('nextPage', function () {
                wizard.next();
            });
        }
    };

}

export { wizard, wizardPage }
