const states = ['StatesMaster', 
    function(StatesMaster){
        return {
            require: 'ngModel',
            scope : {
                state: '=ngModel',
                disabled: '=',
                condition: '=ngDisabled',
                placeholder: '@',
                required: '=ngRequired'
            },
            templateUrl: require('../../templates/states.html'),
            link: function(scope, element, attrs, ctrl){
                StatesMaster.loadStates().then(function (response) {
                    
                    scope.states = response;
                    scope.statesFlag = true;

                    ctrl.$validators.state = function (modelValue, viewValue) {
                        if (ctrl.$isEmpty(modelValue) && scope.required) {
                            // consider empty models and required value to be invalid
                            scope.error = true;
                            return false;
                        }
                        else {
                            scope.error = false;
                            return true;
                        }
                    };
                });
            }
        }
    }
]

export { states };