function selectApi(){
    return {
        require: 'field',
        scope : {
            field: '='
        },
        templateUrl: require('../../../static/templates/select-api.html'),
        controller:
            function ($q, $scope, $http, dataFactory, APISelectService) {
                APISelectService.init($scope.field.url, $scope.field.key, $scope.field.value);
                APISelectService.getData().then(function(data) {
                    $scope.field.options = data;
                });
        }
    };
}

export { selectApi };