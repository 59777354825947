const CreateContract = [
    function () {
        var supply = null;
        var customer = null;
        var contract_data = null;
        var payment_data = null;
        var consumptions_data = null;
        var agreement = null;

        this.getSupply = function(){
            return supply;
        };

        this.getCustomer = function(){
            return customer;
        };

        this.getContractData = function(){
            return contract_data;
        };

        this.getPaymentData = function(){
            return payment_data;
        };

        this.getConsumptionsData = function(){
            return consumptions_data;
        };

        this.getAgreement = function(){
            return agreement;
        };

        this.getContract = function(){
            var contract = angular.extend({}, contract_data, payment_data, consumptions_data);

            // If contract id is not given, 0 will make API autogenerate contract id
            if (!contract.id) {
                contract.id = 0;
            }
            contract.customer = customer.id;
            contract.cups = supply.cups;
            contract.origin = contract_data.origin.code;
            contract.regularity = contract_data.regularity.code;

            if (contract.consumption_from) {
                contract.consumption_from = this.transformToRESTyDate(contract.consumption_from);
            }

            if (contract.consumption_to) {
                contract.consumption_to = this.transformToRESTyDate(contract.consumption_to);
            }

            return contract;
        };

        this.setSupply = function(supplyReceived){
            supply = supplyReceived;
        };

        this.setCustomer = function(customerReceived){
            customer = customerReceived;
        };

        this.setContractData = function(contractDataReceived){
            contract_data = contractDataReceived;
        };

        this.setPaymentData = function(paymentDataReceived){
            payment_data = paymentDataReceived;
        };

        this.setConsumptionsData = function(consumptionsDataReceived){
            consumptions_data = consumptionsDataReceived;
        };

        this.setAgreement = function(agreementDataReceived){
            agreement = agreementDataReceived;
        };

        this.transformToRESTyDate = function (date) {
            var yyyy = date.getFullYear().toString();
            var mm = (date.getMonth() + 1).toString(); // getMonth() is zero-based
            var dd = date.getDate().toString();
            return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]); // padding
        };

    }
]

export {CreateContract}
