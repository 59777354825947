function issuesDownloads($filter, $window, UserInfo, Alert, filtersUtils, eventInfoDownloadService){
    return {
        restrict: 'AE',
        scope: {},
        templateUrl: require('../../templates/download-issue.html'),
        controller: function ($scope, $cookies) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var api = '/issues/download/';
            var $translate = $filter('translate');
            var self = this;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagConfirm = false;
            $scope.listToUpdate = [];
            $scope.change_event_type = '';
            $scope.change_event_category = '';
            $scope.change_action = '';

            $scope.hasPerm = UserInfo.hasPerm('issues.download_files');

            $scope.selected_type = false;
            $scope.selected_category = false;
            $scope.selected_action = false;

            $scope.reset = false;

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            $scope.$watchGroup(['change_event_type', 'change_event_category', 'change_action'],
                function (newValues, oldValues, scope) {
                    if  (newValues[0] && !newValues[1] && !newValues[2]) {
                        $scope.selected_type = true;
                        $scope.reset = true;
                        eventInfoDownloadService.getEventCategory(newValues[0])
                            .then(function (categories) {
                                $scope.event_categories  = categories;
                            });
                    }
                    else if (newValues[0] && newValues[1] && !newValues[2]){
                        $scope.selected_category = true;
                        $scope.reset = true;
                        eventInfoDownloadService.getEventDownloads(newValues[0], newValues[1])
                            .then(function (issue) {
                                $scope.actions_types  = issue;
                            });
                        }
                    else if (newValues[0] && newValues[1] && newValues[2]){
                        $scope.selected_action = true;
                        $scope.reset = false;
                    }
                }
            );

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest() {
                var ids_param = filtersUtils.getItemsToDownloadParams('ids', $scope.listToUpdate);

                var json = {
                    type: $scope.change_event_type,
                    category: $scope.change_event_category,
                    action: $scope.change_action
                };
                var other_params = filtersUtils.serializeFilters(json);

                var url = API_URL + api + ids_param + '&' + other_params + '&token=' + $cookies.get("token");
                $window.open(url);
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.confirmDeletion = function () {
                $scope.flagConfirm = true;
            };

            $scope.clickNo = function () {
                $scope.flagConfirm = false;
                $scope.change_event_type = '';
                $scope.change_event_category = '';
                $scope.change_action = '';
                $scope.selected_type = false;
                $scope.selected_category = false;
                $scope.selected_action = false;
                $scope.reset = false;
            };

            $scope.clickYes = function () {
                performRequest();
                $scope.change_event_type = '';
                $scope.change_event_category = '';
                $scope.change_action = '';
                $scope.selected_type = false;
                $scope.selected_category = false;
                $scope.selected_action = false;
            };

            /***********************************************************************************************************
            * Controller Initialization
            ***********************************************************************************************************/
            eventInfoDownloadService.getEventType().then(function (types) {
                $scope.event_types  = types;
            });

        }
    }
}

export { issuesDownloads }
