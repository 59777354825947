const FeesNewImportCtrl = ['$scope', '$http', 'notificationCenter', 'Task', 'IMPORT_MODE', '$filter', 'Alert',
    function ($scope, $http, notificationCenter, Task, IMPORT_MODE, $filter, Alert) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.backgroundTasks = [];
        $scope.errorMessage = '';
        $scope.infoMessage = '';
        $scope.status = 'WAITING';
        $scope.files = [];
        $scope.mode = '';
        $scope.availableModes = IMPORT_MODE;

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.startImportation = function () {
            Alert.closeAlert();
            $scope.errorMessage = '';
            if ($scope.mode.code === undefined)
                Alert.createAlert('danger', $translate('FEES.FEES_IMPORT_MODE_ERROR'), true);
            else{
                $scope.status = 'LOADING';
                var filesIDs = [];
                angular.forEach($scope.files, function (value, key) {
                    filesIDs.push(value.id);
                });

                var task = new Task();
                task.type = 'IFEE';
                task.params = { files: $scope.files[0].name, mode: $scope.mode.code };
                task.start().then(function (data, status, headers, config) {
                    $scope.files = [];
                    $scope.status = 'WAITING';
                    $scope.infoMessage = $translate('FEES.IMPORT_RUNNING');
                    $scope.backgroundTasks.push(data.data.id);
                }).catch(function (data, status, headers, config) {
                    $scope.errorMessage = $translate('FEES.ERROR_SENDING_TASK');
                });
            }

        };

        $scope.closeInfoMessage = function () {
            $scope.infoMessage = '';
        };

        $scope.closeErrorMessage = function () {
            $scope.errorMessage = '';
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        notificationCenter.subscribe('BG_TASK_ENDS', function (type, data) {
            Alert.closeAlert();
            var task = data;
            if (task.id) {
                var index = $scope.backgroundTasks.indexOf(task.id);
                if (index > -1) {
                    $scope.$apply(function () {
                        $scope.backgroundTasks.splice(index, 1);
                        if ($scope.backgroundTasks.length == 0) {
                            $scope.infoMessage = '';
                        }
                            Alert.createAlert('success', $translate('FEES.IMPORT_FINISHED'), true);
                    });
                }
            }
        });

    }
]

export { FeesNewImportCtrl }
