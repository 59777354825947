function exportUtils() {

    const _render_excel = (jsonObject, headers) => {
        let xml;
        let data = typeof jsonObject !== 'object' ? JSON.parse(jsonObject) : jsonObject;
    
        // header generation
        let headerRow =  '<ss:Row>\n';
        for (let colName in headers) {
            let column = headers[colName]

            if(typeof column == 'string'){
                headerRow += '  <ss:Cell>\n';
                headerRow += '    <ss:Data ss:Type="String">';
                headerRow += column + '</ss:Data>\n';
                headerRow += '  </ss:Cell>\n';
            }
        }
        headerRow += '</ss:Row>\n';
        xml = '<?xml version="1.0"?>\n' +
            '<ss:Workbook xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">\n' +
            '<ss:Worksheet ss:Name="Sheet1">\n' +
            '<ss:Table>\n\n' + headerRow;
    
        // body generation
        for (let row = 0; row < data.length; row++) {
            xml += '<ss:Row>\n';
            for (let col in data[row]) {
                if(!col.startsWith('$')){
                    xml += '  <ss:Cell>\n';
                    xml += '    <ss:Data ss:Type="String">';
                    let value = data[row][col] !== undefined && data[row][col] !== null ? data[row][col] : ''
                    xml += value.toString().replace(/\./g, ',') + '</ss:Data>\n';
                    xml += '  </ss:Cell>\n';
                }
            }
            xml += '</ss:Row>\n';
        }
    
        // footer generation
        xml += '\n</ss:Table>\n' +
            '</ss:Worksheet>\n' +
            '</ss:Workbook>\n';
    
        return xml;
    }

    const _map_keys = (list, keys) => list.map((item) => {
        const newItem = {}
        Object.keys(keys).forEach((key) => {
            newItem[keys[key]] = item[key]
        })
        return newItem
    })
    
    
    const _convert_array_of_objects = (items, headers = undefined) => {
        let data;
        if (headers) {
            data = _map_keys(items, headers)
        } else {
            data = items
        }
        let columns = []
        
        Object.keys(data[0]).forEach((key) => {
            if(!key.startsWith('$')) columns.push(key);
        })

        return [columns, data]
    }

    this.to_excel = (data, filename, headers=undefined) => {
        const transformData = _convert_array_of_objects(data, headers)
        const headersToShow = transformData[0]
        const dataToShow = transformData[1]
        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const content = _render_excel(dataToShow, headersToShow)
        const blob = new Blob([content], { type: contentType })
        const link = document.createElement('a')
        document.body.appendChild(link)
        link.setAttribute('href', window.URL.createObjectURL(blob))
        link.setAttribute('target', '_blank')
        link.setAttribute('download', filename + '.xls')
        link.click()
        document.body.removeChild(link)
    }
}

export { exportUtils }
