function labelsSelect(labelsConfig){
    return {
        restrict: 'E',
        scope: {
            scope: '@',
            selectData: '=',
            add: '@',
            delete: '@',
            objectMode: '='
        },
        templateUrl: require('../../templates/labels-select.html'),
        controller: function ($q, $scope, $http) {
            var self = this;
            var api = labelsConfig.API_PREFIX + '/scope-' + $scope.scope + labelsConfig.API_SUFFIX;
            $scope.Math = window.Math;
            $scope.loading = false;
            $scope.current = api;
            $scope.labels = [];
            var deferred = $q.defer();

            this.loadData = function () {
                this.xhr = $http.get($scope.current, {timeout: deferred.promise}).then(function (response) {
                    let data = response.data

                    $scope.count = data.length;
                    $scope.perPage = data;
                    $scope.labels = [];

                    angular.forEach(data, function (item) {
                        item.editMode = false;
                        item.deleteMode = false;
                        item.newText = item.name;
                        this.push(item);
                    }, $scope.labels);

                }).catch(function (data, status, headers, config) {
                });
            };
            $scope.onSelect = function ($item) {
                if ($scope.objectMode === true) {
                    $scope.selectData = {'name': $item.name, 'id': $item.id};
                }else {
                    $scope.selectData = $item.name;
                }

            }
            self.loadData()
        }
    }
}

export { labelsSelect }
