const ProviderListCtrl = ['$scope', '$filter', 'ProviderAPI', 'UserInfo', 'Alert',
    function ($scope, $filter, ProviderAPI, UserInfo, Alert) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.providers = $scope.results;
        $scope.provider = $scope.result;

        $scope.loading = false;

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
            $scope.counter = selectedCounterChange;
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.cancelDeleteProvider = function() {
            $scope.action = 'detail';
        };

        $scope.deleteProvider = function() {
            $scope.action = 'delete';
        };

        $scope.confirmDeleteProvider = function() {
            $scope.loading = true;

            ProviderAPI.deleteProvider($scope.provider.id).then(function (provider) {
                $scope.loading = false;
                $scope.listReload();
                Alert.createAlert('success', $translate('PROVIDERS.Provider Deleted'), true);
            }).catch(function (error) {
                    $scope.error_message = error.data.__all__[0];
            });
        };

        $scope.canViewProvider = function() {
            return UserInfo.hasPerm('providers.retrieve_provider');
        };

        $scope.canDeleteProvider = function() {
            return UserInfo.hasPerm('providers.delete_provider');
        };

    }
]

export { ProviderListCtrl }
