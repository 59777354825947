
const ContractDetailCtrl =
        ['$scope', '$routeParams', '$filter', 'SUPPLY_RATES',
            'SUPPLY_STATUSES', 'CONTRACT_STATUS', 'CONTRACT_REGULARITIES', 'CONTRACT_ORIGINS', 'CONTRACT_MODE',
            'CONTRACT_INVOICING_GENERATION_MODE', 'INVOICES_COVERS', 'stringUtils', 'UserInfo', 'Alert',
            'StatesMaster', 'CnaesMaster', 'ContractDetail',
            'IE_TYPES', 'CitiesMaster', 'CustomerAPI', 'SupplyAPI', 'AgreementAPI', 'BrandsMaster', 'Task', '$cookies', '$window',
            function ($scope, $routeParams, $filter, SUPPLY_RATES,
                      SUPPLY_STATUSES, CONTRACT_STATUS, CONTRACT_REGULARITIES, CONTRACT_ORIGINS, CONTRACT_MODE,
                      CONTRACT_INVOICING_GENERATION_MODE, INVOICES_COVERS, stringUtils, UserInfo, Alert,
                      StatesMaster, CnaesMaster, ContractDetail,
                      IE_TYPES, CitiesMaster, CustomerAPI, SupplyAPI, AgreementAPI, BrandsMaster, Task, $cookies, $window) {
                /***********************************************************************************************************
                 * Controller Attributes
                 ***********************************************************************************************************/
                var $translate = $filter('translate');
                var self = this;

                $scope.CONTRACTS_EDIT_CONTRACT_TAB = require('../../templates/contracts-edit-contract.html')
                $scope.CONTRACTS_INVOICES_TAB = require('../../templates/invoice-list-for-contract.html')
                $scope.CONTRACTS_RELATED_TAB = require('../../templates/contracts-detail-related.html')
                $scope.CONTRACTS_PRODUCT_RATES_TAB = require('../../templates/contracts-detail-product-rates.html')
                $scope.CONTRACTS_AGREEMENTS_DETAIL_TAB = require('../../templates/contracts-detail-agreements.html')
                $scope.CONTRACTS_COMPLAINTS_TAB = require('../../templates/contracts-complaints.html')
                $scope.CONTRACTS_ITEMS_TO_BE_INVOICED_TAB = require('../../templates/contracts-items-to-be-invoiced.html')
                $scope.CONTRACTS_EXCLUDED_PRODUCTS_TAB = require('../../templates/excluded-products-list-view.html')
                $scope.CONTRACTS_LOGS_TAB = require('../../templates/contract-detail-logs.html')

                $scope.CONTRACTS_ACTIONS_DROPDOWN = require('../../templates/actions-dropdown-detail.html')
                $scope.CONTRACTS_FEES_TAB = require('../../templates/contracts-fees-list.html')
                $scope.CONTRACTS_FEE_PRICES_TAB = require('../../templates/contracts-detail-fee-prices.html')

                $scope.CONTRACTS_PRODUCT_SERVICES_TAB = require('../../templates/contracts-detail-product-services.html')
                $scope.CONTRACTS_PRODUCT_PRICES_TAB = require('../../templates/contracts-detail-product-prices.html')

                /***********************************************************************************************************
                 * Scope Attributes
                 ***********************************************************************************************************/
                $scope.contract = {};
                $scope.rates = SUPPLY_RATES;
                $scope.supply_statuses = SUPPLY_STATUSES;
                $scope.supply_updated = false;
                $scope.statuses = CONTRACT_STATUS;
                $scope.regularities = CONTRACT_REGULARITIES;
                $scope.drafts_origins = CONTRACT_ORIGINS;
                $scope.allow_zeros = false;
                $scope.loading_payment_data = false;
                $scope.brands = [];

                $scope.selected_item_to_be_invoiced = [];

                $scope.contract_statuses = [
                    {code: 'DRF', label: $translate('CONTRACTS.DRAFT')}
                ];

                $scope.platforms = [
                    {code: 'FC', label: 'Face'}
                ];

                $scope.invoice_covers = INVOICES_COVERS;

                $scope.contract_type = CONTRACT_MODE;
                $scope.contract_invoicing_generation_mode = CONTRACT_INVOICING_GENERATION_MODE;

                $scope.payment_methods = [
                    {code: 'TRF', label: $translate('INVOICES.TRANSFER')},
                    {code: 'DBT', label: $translate('INVOICES.DEBIT')},
                    {code: 'B2B', label: $translate('INVOICES.DEBIT_B2B')}
                ];

                $scope.estimated_types = [
                    {code: 0, label: $translate('CONTRACTS.NO')},
                    {code: 2, label: $translate('CONTRACTS.ESTIMATED_PROFILED')}
                ];

                $scope.agreements = $scope.$parent.agreements;
                $scope.loading = true;
                $scope.id = $routeParams.id;
                $scope.ie_types = IE_TYPES;

                $scope.enable_edition = false;

                // ADDED for signed pdf file
                $scope.signed_status = 'WAITING';
                $scope.eneable_add_signed_file = false;
                $scope.files = [];
                $scope.has_power_values = false;
                $scope.power_values_change = false;
                $scope.date = ''
                $scope.place = ''


                /***********************************************************************************************************
                 * Scope Watchers
                 ***********************************************************************************************************/
                // Asignacion de STATE a uno que exista en el master de STATES
                $scope.$watchGroup(['contractStateFlag', 'statesFlag', 'cnaesFlag', 'citiesFlag', 'brandsFlag'], function (newValues, oldValues, scope) {
                    if (newValues[0] && newValues[1]) {
                        $scope.contract.state = stringUtils.updateState($scope.states, $scope.contract.state);
                    }
                    else if (newValues[1] && newValues[2] && newValues[3] && newValues[4]) {
                        self.loadContract();
                    }
                });

                $scope.$watchGroup(['has_power_values', 'contract.power_p1', 'contract.power_p2', 'contract.power_p3',
                    'contract.power_p4', 'contract.power_p5', 'contract.power_p6'], function (newValues, oldValues, scope) {
                    if (newValues[0] === true && (newValues[1] || newValues[2] || newValues[3] || newValues[4] || newValues[5] || newValues[6])) {
                        if (newValues[1] !== $scope.current_p1 || newValues[2] !== $scope.current_p2 || newValues[3] !== $scope.current_p3
                            || newValues[4] !== $scope.current_p4 || newValues[5] !== $scope.current_p5 || newValues[6] !== $scope.current_p6){
                            $scope.power_values_change = true;
                        }
                        else {
                            $scope.power_values_change = false;
                        }
                    }
                    else {
                       $scope.power_values_change = false;
                    }
                });

                /***********************************************************************************************************
                 * Controller Methods
                 ***********************************************************************************************************/
                this.loadContract = function () {
                    ContractDetail.getContractDetail($scope.id)
                        .then(function (contract) {
                            $scope.contract = contract;
                            $scope.$parent.contract = contract;
                            $scope.contract.generate_event = true;
                            $scope.current_contract_rate = contract.rate;
                            $scope.current_p1 = contract.power_p1;
                            $scope.current_p2 = contract.power_p2;
                            $scope.current_p3 = contract.power_p3;
                            $scope.current_p4 = contract.power_p4;
                            $scope.current_p5 = contract.power_p5;
                            $scope.current_p6 = contract.power_p6;
                            $scope.has_power_values = true;

                            if ($scope.contract.signed_file_name != null){
                                var partsOfStr = ($scope.contract.signed_file_name).split('/');
                                $scope.contract.signed_file_name = partsOfStr.slice(-1).pop();
                            }


                            if (contract.regularity) {
                                for (var i = 0; i < $scope.regularities.length; i++) {
                                    if (contract.regularity == $scope.regularities[i].code) {
                                        $scope.contract.regularity = $scope.regularities[i];
                                    }
                                }
                            }

                            // for bank account
                            if (contract.status == 'DRF') {
                                $scope.allow_zeros = true;
                                $scope.contract.generate_event = false;
                            }

                            if (contract.remittance_day === 0) {
                                $scope.contract.remittance_day = "";
                            }

                            $scope.contractStateFlag = true;
                            $scope.loading = false;
                        });
                };

                this.powerRange = function (powerMax, rate) {
                    var powerMatrix = {
                        T20A: [0, 10, 'La tarifa 2.0A debe tener una potencia  inferior o igual a 10kW.'],
                        T20DHA: [0, 10, 'La tarifa 2.0A debe tener una potencia  inferior o igual a 10kW.'],
                        T21A: [10, 15, 'La tarifa 2.1A debe tener una potencia mayor de 10kW y menor o igual a 15kW.'],
                        T21DHA: [10, 15, 'La tarifa 2.1DHA debe tener una potencia mayor de 10kW y menor o igual a 15kW.'],
                        T30A: [15, 0, 'La tarifa 3.0A debe tener una potencia mayor a 15kW.'],
                        T31A: [0, 450, 'Las tarifas 3.1A deben tener una potencia inferior a 450 KW.'],
                        T31DHA: [0, 450, 'Las tarifas 3.1DHA deben tener una potencia inferior a 450 KW.'],
                        T61: [450, 0, 'Las tarifas 6.1 deben tener una potencia superior a 450 KW.'],
                        T61A: [450, 0, 'Las tarifas 6.1 A deben tener una potencia superior a 450 KW.'],
                        T61B: [450, 0, 'Las tarifas 6.1 B deben tener una potencia superior a 450 KW.'],
                        T62: [450, 0, 'Las tarifas 6.2 deben tener una potencia superior a 450 KW.'],
                        T63: [450, 0, 'Las tarifas 6.3 deben tener una potencia superior a 450 KW.'],
                        T64: [450, 0, 'Las tarifas 6.4 deben tener una potencia superior a 450 KW.']
                    };

                    var p_range = powerMatrix[rate];
                    if (p_range[0] > powerMax || (p_range[1] != 0 && p_range[1] < powerMax))
                        return p_range[2];
                    else
                        return false;
                };

                this.cleanContractFormErrors = function () {
                    for (var item in $scope.contractForm) {
                        if (item.substring(0, 1) != '$') {
                            for (let key in $scope.contractForm[item].$error) {
                                $scope.contractForm[item].$setValidity(key, true);
                            }
                        }
                    }
                };

                // Used for sent null when deleted an existing surname_1 or surname_2.
                // We could use for other cases.
                function replacer(key, value) {
                    if ((key === "surname_1" || key === "surname_2") && value == undefined) {
                        return null;
                    }
                    return value;
                }

                /***********************************************************************************************************
                 * Scope Methods
                 ***********************************************************************************************************/
                $scope.getStyles = function (result) {
                    var styles = '';
                    if (result.status == 'PAI')
                        styles += ' ' + 'success';
                    if (result.status == 'PEN' || result.status == 'DFR')
                        styles += ' ' + 'info';
                    if (result.status == 'INV')
                        styles += ' ' + 'invoiced';
                    if (result.status == 'DRF' || result.status == 'SNT')
                        styles += ' ' + 'warning';
                    if (result.status == 'UNP')
                        styles += ' ' + 'danger';

                    return styles;
                };

                $scope.change_agreement_manages = function (value) {
                    $scope.contractForm.$setDirty();
                    $scope.contract.agreement_manages = value;
                };

                $scope.change_print_invoices = function (value) {
                    $scope.contractForm.$setDirty();
                    $scope.contract.print_invoices = value;
                };

                $scope.change_generate_event = function (value) {
                    $scope.contract.generate_event = value;
                };

                $scope.cleanPowers = function (type) {
                    $scope[type].power_p1 = 0;
                    $scope[type].power_p2 = 0;
                    $scope[type].power_p3 = 0;
                    $scope[type].power_p4 = 0;
                    $scope[type].power_p5 = 0;
                    $scope[type].power_p6 = 0;
                    $scope.power_values_change = false;
                };

                $scope.updateContract = function () {
                    // We have to copy the object in order to modify some data without modifying the interface
                    var data = JSON.parse(JSON.stringify($scope.contract, replacer));
                    if ($scope.contract.regularity)
                        data['regularity'] = $scope.contract.regularity.code;

                    if (data['activation_date'] == '') data['activation_date'] = null;
                    if (data['expiration_date'] == '') data['expiration_date'] = null;
                    if (data['finish_date'] == '') data['finish_date'] = null;
                    if (data['fixed_date'] == '') data['fixed_date'] = null;
                    if (data['remittance_day'] === '' || data['remittance_day'] == null) data['remittance_day'] = 0;

                    if($scope.customer)
                        data['customer'] = $scope.customer.id.toString();

                    // remove signed_file_name from json
                    delete data['signed_file_name'];

                    self.cleanContractFormErrors();

                    ContractDetail.setContractDetail($scope.id, data)
                        .then(function (contract) {
                            $scope.contractForm.$setPristine();
                            $scope.submited = true;
                            $scope.contractUpdated = true;
                            if($scope.customer)
                                $scope.contract.customer = $scope.customer.id;
                            Alert.createAlert('success', $translate('CONTRACTS.Contract successfully updated'), true);
                        }).catch(function (error) {
                            $scope.submited = true;
                            for (var k in error) {
                                if (k === 'sub_status' || k ==='non_field_errors' || k === 'invoicing_generation_mode') {
                                    Alert.createAlert('warning', $translate(error[k][0]), true);
                                }
                                else {
                                    $scope.contractForm[k].$error.api = error[k][0];
                                }
                            }
                            if (error === undefined) {
                                Alert.createAlert('warning', $translate(error), true);
                            }
                        });

                    $scope.enable_edition = false;
                };

                $scope.powerActive = function (powerIndex, type) {
                    var powerMatrix = {
                        T20A: [false, true, false, false, false, false],
                        T20DHA: [true, false, true, false, false, false],
                        T21A: [false, true, false, false, false, false],
                        T21DHA: [true, false, true, false, false, false],
                        T30A: [true, true, true, false, false, false],
                        T31A: [true, true, true, false, false, false],
                        T31DHA: [true, true, true, false, false, false],
                        T61: [true, true, true, true, true, true],
                        T61A: [true, true, true, true, true, true],
                        T61B: [true, true, true, true, true, true],
                        T62: [true, true, true, true, true, true],
                        T63: [true, true, true, true, true, true],
                        T64: [true, true, true, true, true, true]
                    };

                    if (type == 'contract')
                        return angular.isDefined($scope.contract) && $scope.contract != null &&
                            angular.isDefined($scope.contract.rate) && $scope.contract.rate != '' && !powerMatrix[$scope.contract.rate][powerIndex - 1];
                    else
                        return true;
                };

                $scope.addSignedToContract = function () {
                    if ($scope.files.length > 0){
                        $scope.contract.signed_file = $scope.files[0].id;
                        $scope.contract.signed_file_name = $scope.files[0].name;
                        $scope.eneable_add_signed_file = false;
                        Alert.createAlert('success', "Archivo Adjuntado", true);
                    }
                    else{
                        Alert.createAlert('warning', "No hay archivos a adjuntar", true);
                    }
                    // reset files
                    $scope.files = [];
                };

                $scope.enableEdition = function () {
                    $scope.enable_edition = !$scope.enable_edition;
                };

                $scope.canEditContract = function () {
                    return UserInfo.hasPerm('contracts.change_contract');
                };

                $scope.addSignedFile = function (){
                    $scope.eneable_add_signed_file = !$scope.eneable_add_signed_file;
                };

                $scope.loadingPaymentPostalData = function () {
                    $scope.contract.payment_name = '';
                    $scope.contract.payment_address = '';
                    $scope.contract.payment_zip = '';
                    $scope.contract.payment_state = '';
                    $scope.contract.payment_city = '';
                    $scope.loading_payment_data = true;
                }

                $scope.copyFromContract = function (){
                    $scope.loadingPaymentPostalData();
                    $scope.contract.payment_name = (
                        ($scope.contract.name + ' ' + $scope.contract.surname_1).trim() + ' ' + $scope.contract.surname_2
                    ).trim();
                    $scope.contract.payment_address = $scope.contract.address;
                    $scope.contract.payment_zip = $scope.contract.zip;
                    $scope.contract.payment_state = $scope.contract.state;
                    $scope.contract.payment_city = $scope.contract.city;
                    $scope.loading_payment_data = false;
                };

                $scope.copyFromSupply = function (){
                    $scope.loadingPaymentPostalData();
                    SupplyAPI.getSupplyDetail($scope.contract.cups)
                        .then(function (supply) {
                            $scope.contract.payment_name = supply.fullname;
                            $scope.contract.payment_address = supply.address;
                            $scope.contract.payment_zip = supply.zip;
                            $scope.contract.payment_state = supply.state;
                            $scope.contract.payment_city = supply.city;
                        }).finally(function() {
                            $scope.loading_payment_data = false;
                    });
                };

                $scope.copyFromCustomer = function (){
                    $scope.loadingPaymentPostalData();
                    CustomerAPI.getCustomerDetail($scope.contract.customer)
                        .then(function (response) {
                            let customer = response.data

                            $scope.contract.payment_name = customer.fullname;
                            $scope.contract.payment_address = customer.address;
                            $scope.contract.payment_zip = customer.zip;
                            $scope.contract.payment_state = customer.state;
                            $scope.contract.payment_city = customer.city;
                        }).finally(function() {
                            $scope.loading_payment_data = false;
                    })
                };

                $scope.copyFromAgreement = function (){
                    $scope.loadingPaymentPostalData();
                    AgreementAPI.getAgreementDetail($scope.contract.agreement)
                        .then(function (agreement) {
                            $scope.contract.payment_name = agreement.fullname;
                            $scope.contract.payment_address = agreement.address;
                            $scope.contract.payment_zip = agreement.zip;
                            $scope.contract.payment_state = agreement.state;
                            $scope.contract.payment_city = agreement.city;
                        }).finally(function() {
                            $scope.loading_payment_data = false;
                    })
                };

                $scope.downloadPdfUrl = function (template, mode) {
                    try {
                        if(mode)
                            mode = '&mode=' + mode;
                        else
                            mode = '';

                        return API_URL + '/contracts/download/pdf/?ids=' + $scope.contract.id + "&template=" + template
                            + "&place=" + $scope.place + "&date=" + $scope.date + mode + '&token=' + $cookies.get("token");
                    }
                    catch(err) {
                        return '';

                    }
                };

                $scope.downloadSignedPdf = function (template, mode) {
                    try {
                        return API_URL + '/contracts/download_signed_pdf/' + $scope.contract.id + '?token=' + $cookies.get("token");
                    }
                    catch(err) {
                        return false;
                    }
                };

                $scope.downloadPdfDetail = function (template, mode, supply_info) {
                    var url = $scope.downloadPdfUrl(template, mode)

                    if(url){
                        if (supply_info !== undefined) {
                            url += '&supply_info=' + supply_info;
                        }
                        $window.open(url);
                    }

                    $('#downloadPdfModal').modal('toggle');

                    $scope.place = '';
                    $scope.date = '';
                    return false;
                };

                $scope.regularize = function(start_date, finish_date, strict_dates) {
                    var task = new Task();
                    task.type = 'REGC';
                    task.params = {
                        contract: $scope.contract.id,
                        start_date,
                        finish_date,
                        strict_dates
                    };
                    task.start().then(function (data, status, headers, config) {
                        Alert.createAlert('success', $translate('CONTRACTS.REGULARIZE_OK'), true);
                    }).catch(function (error) {
                        Alert.createAlert('danger', $translate('CONTRACTS.REGULARIZE_ERROR'), true);
                    });
                }

                /***********************************************************************************************************
                 * Controller Initialization
                 ***********************************************************************************************************/
                StatesMaster.loadStates().then(function(response){
                    $scope.states = response;
                    $scope.statesFlag = true;
                });

                CnaesMaster.loadCnaes().then(function (response) {
                    $scope.cnaes = response;
                    $scope.cnaesFlag = true;
                });

                CitiesMaster.loadCities().then(function(response){
                    $scope.cities = response;
                    $scope.citiesFlag = true;
                });

                BrandsMaster.loadBrands().then(function(response){
                    $scope.brands = response;
                    $scope.brandsFlag = true;
                });

                $scope.$on('change_customer', function() {

                    CustomerAPI.getCustomerDetail($scope.contract.customer)
                        .then(function (response) {
                            let customer = response.data;

                            $scope.customer = customer;
                        });

                });


            }]

export {ContractDetailCtrl}
