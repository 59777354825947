const BrandsLoading = ['$resource', function ($resource) {
    return $resource(API_URL + '/api/1.0/masters/brands/');
}]

const BrandsMaster = ['$q', 'BrandsLoading',  function ($q, BrandsLoading) {
    var brands= {
        items: null,

        loadBrands: function () {
            var self = this;
            var deferred = $q.defer();
            if (this.items == null)
                BrandsLoading.query(function (data) {
                    self.items = data;
                    deferred.resolve(data);
                });
            else
                deferred.resolve(self.items);

            return deferred.promise;
        }

    };

    return brands;
}]

export { BrandsLoading, BrandsMaster}
