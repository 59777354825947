const ListFeesCtrl = ['$scope', '$filter', '$window', '$cookies', 'UserInfo', 'AgreementsMaster',
    function ($scope, $filter, $window, $cookies, UserInfo, AgreementsMaster) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var self = this;
        var $translate = $filter('translate');

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.filters = {};
        $scope.ordering = {
            'agreement_id': $translate('FEES.AGREEMENT'),
            'date': $translate('FEES.DATE')
        };

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.filter = function () {
            if ($scope.fromDate)
                $scope.filters['from_date'] = $scope.fromDate;
            else
                delete $scope.filters['from_date'];

            if ($scope.toDate)
                $scope.filters['to_date'] = $scope.toDate;
            else
                delete $scope.filters['to_date'];


            if ($scope.agreement)
                $scope.filters['agreement'] = $scope.agreement;
            else
                delete $scope.filters['agreement'];
        };

        $scope.downloadCSVFile = function () {
            var date_from = '';
            var date_to = '';
            var agreement = '';
            if ($scope.fromDate) date_from = $scope.fromDate;
            if ($scope.toDate) date_to = $scope.toDate;
            if ($scope.agreement) agreement = $scope.agreement;
            var params = '?agreement=' + agreement + '&date_from=' + date_from + '&date_to=' + date_to + '&token=' + $cookies.get("token");
            var url = API_URL + '/fees/download/csv/'+ params;
            $window.open(url);
        };

        $scope.canDownloadFees = function () {
            return UserInfo.hasPerm('fees.download_fees_excel');
        };


        $scope.$watchGroup(['fromDate', 'toDate'], function(newValues, oldValues, scope) {
            scope.filter();
        })
        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        AgreementsMaster.loadAgreements().then(function (response){
            $scope.agreements = response;
        });

    }
]

export { ListFeesCtrl }
