const ElectricityMeter = function ($http, $q) {
    
    var url = API_URL + '/api/1.0/supplies/';
    var electricity_meter_cups = null;

    function getElectricityMeters(cups) {
        var deferred = $q.defer();

        $http.get(url + cups + '/electricity-meters/')
            .then(function (data, status, headers, config) {
                deferred.resolve(data.data);
            },function (error) {
                electricity_meter_cups = null;
                deferred.reject(error.data);
            });

        electricity_meter_cups = cups;
        return deferred.promise;
    }

    function getElectricityMeter(cups, id) {
        var deferred = $q.defer();

        $http.get(url + cups + '/electricity-meters/' + id)
            .then(function (data, status, headers, config) {
                deferred.resolve(data.data);
            },function (error) {
                electricity_meter_cups = null;
                deferred.reject(error.data);
            });

        electricity_meter_cups = cups;
        return deferred.promise;
    }

    function createElectricityMeter(electricity_meter) {
        var deferred = $q.defer();

        if (electricity_meter_cups != null) {

            var data = {
                'serial_number': electricity_meter.serial_number,
                'dh_code': electricity_meter.dh_code,
                'brand': electricity_meter.brand,
                'type': electricity_meter.type,
                'supply': electricity_meter_cups
            };

            $http.post(url + electricity_meter_cups + '/electricity-meters/', data)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data.data);
                },function (error) {
                    deferred.reject(error.data);
                });

        } else {
            deferred.reject();
        }

        return deferred.promise;
    }

    return {
        getElectricityMeters: getElectricityMeters,
        getElectricityMeter: getElectricityMeter,
        createElectricityMeter: createElectricityMeter
    }
}

export {ElectricityMeter};
