function addCalendarForSupplies(UserInfo, Alert, filtersUtils) {
    return {
        restrict: 'AE',
        templateUrl: require('../../templates/link_calendar_for_supplies.html'),
        controller: function ($scope, $filter, $http, $rootScope, CalendarsMaster, CalendarForSupplyAPI) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var self = this;
            var first_access = true;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.flagLinkCalendarConfirm = false;
            $scope.listToUpdate = [];
            $scope.new_calendar = '';
            $scope.selected_calendar = false;

            $scope.hasPerm = UserInfo.hasPerm('calendars.add_calendarforsupply');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            $scope.$watch('addCalendarForm.$valid', function (newValue, oldValue) {
                $scope.$emit("setValidity", newValue);
                if (first_access == true)
                    first_access = false;
                else {
                    if (newValue)
                        $scope.selected_calendar = true;
                }
            }, true);

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var json = {ids: ids, calendar: $scope.new_calendar, link: true};

                CalendarForSupplyAPI.bulkUpdateCalendarForSupply(json)
                    .then(function (data) {
                        var message = $translate('CALENDARS.CALENDARS_LINK_SUCCESSFULLY') + data.num_calendars_to_create + '. ' + $translate('CALENDARS.CALENDARS_ALREADY_LINK') + data.num_calendars_exist
                        Alert.createAlert('success', message, true);
                        $rootScope.$broadcast('refreshList');
                    }).catch(function (error) {
                        $scope.error_message = error.data.__all__[0];
                    }).finally(function () {
                        $scope.flagLinkCalendarConfirm = false;
                        $scope.listToUpdate = [];
                    });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.clickAddCalendarNo = function () {
                $scope.flagLinkCalendarConfirm = false;
                $scope.new_calendar = '';
                $scope.selected_calendar = false;
            };

            $scope.clickAddCalendarYes = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToUpdate);
                performRequest(ids);
                $scope.new_calendar = '';
                $scope.selected_calendar = false;
            };

            $scope.confirmSetAdd = function () {
                $scope.flagLinkCalendarConfirm = true;
            };

            /***********************************************************************************************************
            * Controller Initialization
            ***********************************************************************************************************/
            CalendarsMaster.loadCalendars().then(function (response){
                $scope.calendars = response;
            });

        }
    }
}

export { addCalendarForSupplies };
