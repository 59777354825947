// Controllers
import { CalendarCtrl } from './controllers/CalendarCtrl'
import { CalendarDaysCtrl } from './controllers/CalendarDaysCtrl'
import { DeleteCalendarDayCtrl } from './controllers/DeleteCalendarDayCtrl'

// Directives
import { deleteCalendarForSupplies } from './directives/deleteCalendarForSupplies'
import { addCalendarForSupplies } from './directives/addCalendarForSupplies'

// Services
import { CalendarDaysAPI } from './services/CalendarDaysAPI'
import { CalendarAPI } from './services/CalendarAPI'


var calendars = angular.module('calendars', ['pascalprecht.translate', ]);

calendars.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

calendars
    .service('CalendarDaysAPI', CalendarDaysAPI)
    .service('CalendarAPI', CalendarAPI)
    .controller('CalendarCtrl', CalendarCtrl)
    .controller('CalendarDaysCtrl', CalendarDaysCtrl)
    .controller('DeleteCalendarDayCtrl', DeleteCalendarDayCtrl)
    .directive('addCalendarForSupplies', addCalendarForSupplies)
    .directive('deleteCalendarForSupplies', deleteCalendarForSupplies)

export { calendars };
