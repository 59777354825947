{
  "PROGRAMMING_UNITS": {
    "TITLE": "Unidades de Programación",
    "SEARCH_BY_PROGRAMMING_UNIT": "Buscar por Nombre",
    "ID": "Id",
    "NAME": "Nombre",
    "NEW_PROGRAMMING_UNIT": "Nueva Unidad de programación",
    "VIEW": "Ver",
    "DELETE": "Eliminar",
    "YES": "Sí",
    "NO": "No",
    "DELETE_PROGRAMMING_UNITS": "¿Eliminar unidad de programación?",
    "PROGRAMMING_UNIT_DELETED": "Unidad de Programacion eliminada.",
    "DESCRIPTION": "Descripción",
    "ELECTRICAL_SUBSYSTEM": "Subsistema eléctrico",
    "SAVE": "Guardar",
    "CANCEL": "Cancelar",
    "PROGRAMMING_UNIT_CREATED_SUCCESSFULLY": "Unidad de Programación creada con éxito.",
    "PROGRAMMING_UNIT": "Unidad de Programación",
    "EDIT": "Editar",
    "PROGRAMMING_UNIT_UPDATE_SUCCESSFULLY": "Unidad de programación actualizada con éxito",
    "SUPPLIES": "Puntos de Suministro Asociados",
    "UNLINK": "Desvincular",
    "PROGRAMMING_UNLINK_SUCCESSFULLY": "Cups Desvinculado a la unidad de programación con éxito",
    "SET_PROGRAMMING_UNIT_SUCCESSFULLY": "Nº de unidades de programación vinculadas: ",
    "LINK_PROGRAMMING_UNITS": "Seleccionar Unidad de Programación",
    "CHANGE_QUESTION_LINK": "¿Vincular Unidad de Programación?",
    "CHANGE_QUESTION_UNLINK": "¿Desvincular Unidad de Programación?"
  }
}