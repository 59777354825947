const CreateComplaintCtrl = ['$scope', '$filter', 'AGENT_CODES', 'UserInfo', 'Alert', 'CHANNEL_CODES', 'SUBTYPE_CODES', 'LEGACY_SUBTYPES', 'ComplaintAPI', '$location', 'WATIUM_DEPARTMENTS',
    function ($scope, $filter, AGENT_CODES, UserInfo, Alert, CHANNEL_CODES, SUBTYPE_CODES, LEGACY_SUBTYPES, ComplaintAPI, $location, WATIUM_DEPARTMENTS) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.saving = false;
        $scope.agent_codes = AGENT_CODES;
        $scope.channel_codes = CHANNEL_CODES;
        $scope.subtype_codes = {};
        Object.assign($scope.subtype_codes, SUBTYPE_CODES);
        for (var i = 0; i < LEGACY_SUBTYPES.length; i++) { // We have to remove legacy sub_types on creation form
            var sub_type = LEGACY_SUBTYPES[i];
            delete $scope.subtype_codes[sub_type];
        }
        $scope.watium_department = WATIUM_DEPARTMENTS;

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.resetComplaintForm = function () {
            $scope.complaint = {creation_date: new Date()};
        };

        $scope.saveComplaint = function () {
            $scope.saving = true;
            $scope.complaint.ids = $scope.complaint.ids.split(",");
            $scope.complaint.creation_date += 'T00:00';

            ComplaintAPI.createComplaint($scope.complaint)
                .then(function (data) {
                    $scope.saving = false;
                    if (data.created_number == 1) {
                        $location.path('/complaints/' + data.complaint_id);
                        Alert.createAlert('success', $translate('COMPLAINTS.COMPLAINT_CREATED_SUCCESSFULLY'), true);
                    }
                    else {
                        Alert.createAlert('success', data.created_number + ' ' + $translate('COMPLAINTS.N_COMPLAINTS_CREATED_SUCCESSFULLY'), true);
                    }
                }).catch(function (error) {
                    $scope.saving = false;
                    if (error.ids != null)
                        Alert.createAlert('danger', error.ids, true);
                    else
                        Alert.createAlert('danger',$translate('COMPLAINTS.ERROR_WHEN_CREATE_COMPLAINT_EVENT'), true);
                }).
                finally(function () {
                    $scope.resetComplaintForm();
                });
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        $scope.resetComplaintForm();
    }
]

export { CreateComplaintCtrl }
