function providerInvoiceChangeStatus(UserInfo, Alert, filtersUtils, ProviderInvoiceAPI){
    return {
        restrict: 'AE',
        templateUrl: require('../../templates/provider-invoices-change-status.html'),
        controller: function ($scope, $rootScope, $filter, DateUtils, BanksMaster) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var $translate = $filter('translate');
            var self = this;
            var first_access = true;

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.status_types = {
                "PEN": $translate('PROVIDERS.PENDING'),
                "CHE": $translate('PROVIDERS.CHECKED'),
                "PAI": $translate('PROVIDERS.PAID'),
                "RET": $translate('PROVIDERS.RETURNED')
            };

            $scope.hasPerm = UserInfo.hasPerm('providers.change_provider_invoice_status');

            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            $scope.$watch('statusForm.$valid', function (newValue, oldValue) {
                $scope.$emit("setValidity", newValue);
                if (first_access === true)
                    first_access = false;
                else {
                    if (newValue)
                        $scope.selected_status = true;
                }
            }, true);

            $scope.$watch('status', function (newVal, oldVal) {
                if (newVal == 'PAI') {
                    $scope.set_bank = true;
                }
            });

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            function performRequest(ids) {
                var json = {ids: ids, status: $scope.status};
                if ($scope.status === 'PAI') {
                    json['paid_date'] = DateUtils.transformToRESTyDate($scope.paid_date);
                    json['payment_bank'] = $scope.payment_bank;
                    json['comments'] = $scope.comments;
                }

                ProviderInvoiceAPI.changeStatusProviderInvoice(json).then(function (data) {
                    Alert.createAlert('success', $translate('PROVIDERS.PROVIDER_STATUS_UPDATED') + data.updated_status, true);
                    $scope.statusForm.$setPristine();
                    $scope.statusForm.$setUntouched();
                    $rootScope.$broadcast('refreshList');
                }).catch(function (error) {
                    $scope.error_message = error.data.__all__[0];
                }).finally(function () {
                    $scope.resetChangeStatusForm();
                });
            }

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.resetChangeStatusForm = function () {
                $scope.flagConfirm = false;
                $scope.listToUpdate = [];
                $scope.status = '';
                $scope.selected_status = false;
                $scope.set_bank = false;
                $scope.payment_bank = '';
                $scope.comments = '';
                $scope.paid_date = new Date();
            };

            $scope.clickStatusNo = function () {
                $scope.resetChangeStatusForm();
                $scope.statusForm.$setPristine();
                $scope.statusForm.$setUntouched();
            };

            $scope.clickStatusYes = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToUpdate);
                performRequest(ids);
            };

            /***********************************************************************************************************
            * Controller Initialization
            ***********************************************************************************************************/
            BanksMaster.loadBanks().then(function (response){
                $scope.banks = response;
            });

            $scope.resetChangeStatusForm();

        }
    }
}

export { providerInvoiceChangeStatus }
