function replaceComma(){
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModelCtrl) {
            scope.$watch(attrs.ngModel, function (newVal) {
                if (newVal !== undefined && newVal !== null) {
                    ngModelCtrl.$setViewValue(String(newVal).replace(/,/g, ''));
                    element.val(String(newVal).replace(/,/g, ''));
                }
            })

        }
    }
}

function addressData(){
    return {
        restrict: 'AE',
        require: 'ngModel',
        scope:{
            address: '=ngModel',
            condition: '@',
            required: '=?'
        },
        templateUrl: require('../../templates/address-data.html'),
        link: function (scope, element, attrs, ctrl) {
            var inputs = element.find('input');

            inputs.bind('keyup', function (event) {
                var address = '';
                var address_number = '';
                var address_ext = '';
                for (var input in inputs) {
                    if (inputs[input].value != undefined) {
                        if (inputs[input].name == 'address') {
                            if (inputs[input].value != undefined)
                                address = inputs[input].value;
                        }
                        else if (inputs[input].name == 'address_number') {
                            if (inputs[input].value != undefined)
                                address_number = inputs[input].value ;
                        }
                        else if (inputs[input].name == 'address_ext') {
                            if (inputs[input].value != undefined)
                                address_ext = inputs[input].value;
                        }
                    }
                }
                scope.error = false;
                scope.address = address + ', ' + address_number + ', ' + address_ext;
                scope.$apply();
            });
        },
        controller: function ($scope, addressUtils) {
            $scope.info = {};

            $scope.$watchGroup(['condition', 'address'], function (newValues, oldValues, scope) {
                if (newValues[0])
                    $scope.condition = JSON.parse(newValues[0]);
                if (newValues[1]) {
                    var address = addressUtils.splitAddressByComma($scope.address);
                    $scope.info.address = addressUtils.getAddressName(address);
                    $scope.info.number = addressUtils.getAddressNumber(address);
                    $scope.info.extension = addressUtils.getAddressExt(address);

                }
            });
        }
    }
}

export { replaceComma, addressData };
