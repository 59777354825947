const suppliesTypes = ['SuppliesTypesMaster',
    function(SuppliesTypesMaster){

        return {
            require: 'ngModel',
            scope : {
                supplyType: '=ngModel',
                disabled: '=',
                required: '=',
                name: '=',
                condition: '=ngDisabled'
            },
            templateUrl: require('../../templates/supplies-types.html'),
            link: function(scope, element, attrs, ctrl){
                SuppliesTypesMaster.loadSuppliesTypes().then(function(response) {
                    scope.suppliesTypes = response;
                    if (scope.supplyType === undefined) {
                        scope.supplyType = '01'
                    }
                })
            }
        }

    }
]

export { suppliesTypes }
