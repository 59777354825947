const ProviderInvoiceFactory = ['$resource',
    function ($resource) {
        return $resource(API_URL + '/api/1.0/provider-invoices/:id/', { id: '@id' },
            {
                'update': { method: 'PUT'},
                'patch': { method:'PATCH' }
            }
        )
    }
]

export { ProviderInvoiceFactory }
