const CreateAgreementPricesCtrl = ['$scope', 'Alert', '$filter', 'AgreementAPI',
    function ($scope, Alert, $filter, AgreementAPI) {
        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        $scope.error_message = '';

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.create = function () {
            $scope.agreement.annual_price = $scope.prices.fees;
            $scope.agreement.rate = $scope.prices.rate;
            $scope.agreement.type = $scope.prices.mode;
            $scope.agreement.consumption_range_min = $scope.prices.con_min;
            $scope.agreement.consumption_range_max = $scope.prices.con_max;
            $scope.agreement.power_range_min = $scope.prices.pot_min;
            $scope.agreement.power_range_max = $scope.prices.pot_max;
            
            AgreementAPI.createAgreementPrice($scope.code, $scope.prices)
                .then(function (data) {
                    $scope.prices.annual_price = '';
                    $scope.prices.rate = null;
                    $scope.prices.type = '';
                    $scope.prices.contract=null;

                    $scope.error_message = '';
                    $scope.agreementPriceCreate.$setPristine();
                    $scope.$emit('refreshPrices');
                    Alert.createAlert('success', $translate('AGREEMENTS.PRICE_CREATED'), true);
                }).catch(function (error) {
                    $scope.error_message = error.data.__all__[0];
                });
        };

        $scope.closeAlert = function () {
            $scope.error_message = '';
        }
    }
]

export {CreateAgreementPricesCtrl}
