{
  "version": "2.2.1",
  "name": "powr-frontend",
  "description": "Powr UI",
  "author": "Kas Factory",
  "private": true,
  "engineStrict": true,
  "engines": {
    "node": ">=16.0"
  },
  "scripts": {
    "dev": "parcel index.html -p 3000",
    "build": "parcel build index.html --no-optimize",
    "lint": "eslint --ext .js --quiet --ignore-path .gitignore ."
  },
  "devDependencies": {
    "@parcel/transformer-image": "^2.0.0-rc.0",
    "@parcel/transformer-less": "^2.0.0-rc.0",
    "@types/dotenv": "^8.2.0",
    "eslint": "^7.32.0",
    "less": "^4.1.1",
    "parcel": "^2.0.0-rc.0",
    "parcel-bundler": "^1.9.4",
    "rimraf": "^3.0.2"
  },
  "dependencies": {
    "adm-dtp": "^1.1.6",
    "angular": "^1.8.2",
    "angular-cookies": "^1.8.2",
    "angular-drag-and-drop-lists": "^2.1.0",
    "angular-i18n": "^1.8.2",
    "angular-messages": "^1.8.2",
    "angular-resource": "^1.8.2",
    "angular-route": "^1.8.2",
    "angular-sanitize": "^1.8.2",
    "angular-translate": "^2.18.4",
    "bootstrap": "^5.0.2",
    "file-saver": "^2.0.5",
    "ibantools": "^4.1.6",
    "jquery": "^3.6.0",
    "jquery-ui": "^1.12.1",
    "npm": "^7.24.1",
    "showdown": "^1.9.1",
    "socket.io": "2.4.0",
    "socket.io-client": "2.4.0",
    "socketio-jwt": "^4.5.0"
  },
  "default": "dist/index.html",
  "targets": {
    "default": {
      "context": "browser"
    }
  }
}
