const ComplaintAPI = ['$q', 'UserInfo', '$http',
    function ($q, UserInfo, $http) {

        var url = API_URL + '/api/1.0/complaints/';
        var status_url = '/change-status/';

        var self = this;
        var complaint = null;

        function getComplaintDetail(id) {
            if (complaint == null || complaint.id != id) {
                complaint = {
                    'id': id
                };
                return _getComplaintDetail(id);
            } else {
                var deferred = $q.defer();
                deferred.resolve(complaint.data);
                return deferred.promise;
            }
        }

        function _getComplaintDetail(id) {
            var deferred = $q.defer();

            $http.get(url + id)
                .then(function (complaint) {
                    deferred.resolve(complaint.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            self.id = id;
            return deferred.promise;
        }

        function createComplaint(complaint) {
            var deferred = $q.defer();

            $http.post(url, complaint)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function deleteComplaint(id) {
            var deferred = $q.defer();

            $http.delete(url + id)
                .then(function (complaint) {
                    deferred.resolve(complaint.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        }

        function updateComplaint(id, data) {
            var deferred = $q.defer();

            $http.put(url + id, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function updateComplaintStatus(id, data) {
            var deferred = $q.defer();

            $http.put(url + id + status_url, data)
                .then(function (data) {
                    deferred.resolve(data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            getComplaintDetail: getComplaintDetail,
            createComplaint: createComplaint,
            deleteComplaint: deleteComplaint,
            updateComplaint: updateComplaint,
            updateComplaintStatus: updateComplaintStatus
        };

    }
]

export { ComplaintAPI };
