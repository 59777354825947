const DeleteCalendarForSupplyCtrl = ['$scope', '$filter', 'Alert', 'CalendarForSupplyAPI', 'UserInfo',
    function ($scope, $filter, Alert, CalendarForSupplyAPI, UserInfo) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        $scope.actionCFS = '';
        $scope.deletionCFSMode = false;

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.deleteCalendarForSupply = function ($event) {
            $event.preventDefault();
            $scope.deletionCFSMode = true;
            $scope.actionCFS = 'delete';
        };

        $scope.cancelDeletedCalendarForSupply = function ($event) {
            $event.preventDefault();
            $scope.actionCFS = '';
            $scope.deletionCFSMode = false;
        };

        $scope.confirmDeleteCalendarForSupply = function (id) {
            $scope.loading = true;

            CalendarForSupplyAPI.deleteCalendarForSupply(id).then(function (data) {
                $scope.loading = false;
                $scope.listReload();
                Alert.createAlert('success', $translate('SUPPLIES.CALENDAR_FOR_SUPPLY_DELETED'), true);
                $scope.actionCFS = '';
                $scope.deletionCFSMode = false;
            }).catch(function (error) {
                $scope.loading = false;
                $scope.error_message = error.data.__all__[0];
            });
        };

        $scope.canDeleteCalendarForSupply = function () {
            return UserInfo.hasPerm('calendars.delete_calendarforsupply');
        };
}]

export {DeleteCalendarForSupplyCtrl};
