// Directives
import { commentsList } from './directives/commentsList'

var comments = angular.module('comments', ['pascalprecht.translate']);

comments.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])


// Module Config Provider
comments.provider('commentsConfig',
        function () {
        this.API_PREFIX = API_URL + '/api/1.0';
        this.API_SUFFIX = '/comments/ ';
        this.TEMPLATES_PREFIX = '/static/comments/templates/';
        this.UPLOADER_TEMPLATE = 'comments.html';
        this.DELETE_API = API_URL + '/api/1.0/comments/';
        this.$get = function () {
            var self = this;
            return {
                TEMPLATES_PREFIX: self.TEMPLATES_PREFIX,
                UPLOADER_TEMPLATE: self.UPLOADER_TEMPLATE,
                API_PREFIX: self.API_PREFIX,
                API_SUFFIX: self.API_SUFFIX,
                DELETE_API: self.DELETE_API
            }
        };
    }
)

comments.directive('comments', commentsList)

export { comments };
