const ChangePwdCtrl = ['$scope', '$http', '$window', '$cookies',
    function($scope, $http, $window, $cookies) {

        $scope.change = function(){
            var data = {
                current_password : $scope.current,
                password : $scope.password,
                repeat_password : $scope.new_password
            };
            $scope.status = 'TRYING';
            var pass_url = '/api/1.0/users/me/password/';
            $http.post(API_URL + pass_url, data).then(function(data) {
                $scope.status = 'CHANGED';
            }).catch(function(data) {
                if (data.status == 400){
                    $scope.status = 'CHANGED_ERROR';
                    $scope.message = "";
                    for (let i in data.data){
                        $scope.message += data.data[i];
                    }
                }
                else{
                    $scope.status = 'UNKNOWN_ERROR';
                    $scope.message = 'UNKNOWN_ERROR';
                }
            });
            return false;
        };
    }
];

export {ChangePwdCtrl}