{
  "SETTINGS": {
    "COMPANY_INFO": "Información de la Empresa",
    "NAME": "Nombre",
    "CIF": "CIF",
    "ADDRESS": "Dirección",
    "TOWN": "Ciudad",
    "STATE": "Provincia",
    "ZIP_CODE": "Código Postal",
    "PHONE": "Teléfono",
    "FAX": "Fax",
    "WEB": "Web",
    "EMAIL": "Email",
    "SAVE": "Guardar",
    "SUCCESS_SETTINGS_UPDATED": "actualizada con éxito",
    "ERROR_SETTINGS_UPDATED": ": Error al actualizar. Ningún campo puede ser vacio o exceder los 255 caracteres",
    "TAX": "Impuesto",
    "COMPANY_ADDRESS": "Dirección de la Empresa",
    "CONTRACT_SETTINGS": "Parámetros de Configuración de Contratos",
    "INVOICE_SETTINGS": "Parámetros de Configuración de Facturas",
    "CONTRACT_EXPIRATION_1": "Indicar días para el primer aviso de próxima fecha de expiración de contrato",
    "CONTRACT_EXPIRATION_2": "Indicar días para el segundo aviso de próxima fecha de expiración de contrato",
    "CONTRACT_EXPIRATION_3": "Indicar días para el tercer aviso de próxima fecha de expiración de contrato",
    "DRAFT_INVOICES": "Indicar serie 'Borrador' de Facturas",
    "INVOICING_DAYS": "Indicar días para la fecha de expiración de factura",
    "DEFAULT_INVOICES": "Indicar serie por defecto de facturas"
  }

}