const powerData = ['SUPPLY_POWER_RANGES', 'SUPPLY_POWER_PERIODS',
    function(SUPPLY_POWER_RANGES, SUPPLY_POWER_PERIODS) {

    var powerRange = function(power, rate) {
        var p_range = SUPPLY_POWER_RANGES[rate];
        return !(power <= 0 || p_range[0] > power || (p_range[1] != 0 && p_range[1] < power));
    };

    var validateFields = function(supply){
        if (supply == null || supply.rate == null) {
            return false;
        }
        var supplyRate = supply.rate || "";
        switch (supplyRate) {
            case 'T20A':
            case 'T21A':
                return powerRange(supply.power_p2, supplyRate);
                break;
            case 'T20DHA':
            case 'T21DHA':
            case 'T20DHS':
            case 'T21DHS':
                return powerRange(supply.power_p1, supplyRate) || powerRange(supply.power_p3, supplyRate);
                break;
            case 'T30A':
            case 'T30DHA':
            case 'T31A':
            case 'T31DHA':
                return powerRange(supply.power_p1, supplyRate) || powerRange(supply.power_p2, supplyRate) ||
                        powerRange(supply.power_p3, supplyRate);
                break;
            case 'T61':
            case 'T61A':
            case 'T61B':
            case 'T62':
            case 'T63':
            case 'T64':
                return powerRange(supply.power_p1, supplyRate) || powerRange(supply.power_p2, supplyRate) ||
                        powerRange(supply.power_p3, supplyRate) || powerRange(supply.power_p4, supplyRate) ||
                        powerRange(supply.power_p5, supplyRate) || powerRange(supply.power_p6, supplyRate);
            case 'T20TD':
                return powerRange(supply.power_p1, supplyRate) && powerRange(supply.power_p2, supplyRate);
            case 'T30TD':
            case 'T30TDVE':
            case 'T61TD':
            case 'T61TDVE':
            case 'T62TD':
            case 'T63TD':
            case 'T64TD':
                return (
                        powerRange(supply.power_p1, supplyRate) ||
                        powerRange(supply.power_p2, supplyRate) ||
                        powerRange(supply.power_p3, supplyRate) ||
                        powerRange(supply.power_p4, supplyRate) ||
                        powerRange(supply.power_p5, supplyRate) ||
                        powerRange(supply.power_p6, supplyRate)
                        ) && (
                        supply.power_p1 <= supply.power_p2 &&
                        supply.power_p2 <= supply.power_p3 &&
                        supply.power_p3 <= supply.power_p4 &&
                        supply.power_p4 <= supply.power_p5 &&
                        supply.power_p5 <= supply.power_p6
                        );
            default:
                console.error("UNKNOWN RATE", supplyRate);
                return false;
        }
    };

    return {
        require: 'ngModel',
        scope : {
            supply: '=ngModel',
            disabled: '=?',
            condition: '=?ngDisabled',
            errorMessage: '=?'
        },
        templateUrl: require('../../templates/power-data.html'),
        link: function(scope, element, attrs, ctrl){
            scope.errorMessage = { message: '', show: false };

            scope.disablePower = function (powerIndex) {
                var rate = scope.supply ? scope.supply.rate || null : null;
                if (rate == null) {
                    return false;
                }

                if(!SUPPLY_POWER_PERIODS[scope.supply.rate][powerIndex - 1])
                    scope.supply['power_p' + powerIndex] = 0;

                return scope.disabled || !SUPPLY_POWER_PERIODS[scope.supply.rate][powerIndex - 1];
            };
            scope.$watchGroup(["supply.rate", "supply.power_p1", "supply.power_p2", "supply.power_p3",
                "supply.power_p4", "supply.power_p5", "supply.power_p6"], function(newValue, oldValue){
                scope.errorMessage.show = scope.supply && !validateFields(scope.supply);
                if (scope.errorMessage.show) {
                    var range = SUPPLY_POWER_RANGES[scope.supply.rate] || {};
                    scope.errorMessage.message = range.length === 3 ? range[2] : '';
                }
            });
        }
    };
}]

export {powerData};
