function EonParser(){
    /**
     * Checks if the given data is valid for this parser
     * @param data
     * @returns {boolean}
     */
    this.isValid = function (data) {

        var columns = this.parser(data);
        var valid = columns.length == 52; // parts validation

        if(!columns[0].trim().substr(0, 20).match("ES[0-9]{16}[A-Z]{2}")) { // Validate CUPS format
            valid = false;
        }

        if(!columns[8].trim().match("[0-9]{5}")) { // Validate ZIP
            valid = false;
        }

        // Parse powers
        var p1 = parseFloat(columns[28].trim()|| 0); // POTENCIA PERIODO 1
        var p2 = parseFloat(columns[29].trim()|| 0); // POTENCIA PERIODO 2
        var p3 = parseFloat(columns[30].trim()|| 0); // POTENCIA PERIODO 3
        var p4 = parseFloat(columns[31].trim()|| 0); // POTENCIA PERIODO 4
        var p5 = parseFloat(columns[32].trim()|| 0); // POTENCIA PERIODO 5
        var p6 = parseFloat(columns[33].trim()|| 0); // POTENCIA PERIODO 6

        if ( isNaN(p1) || isNaN(p2) || isNaN(p3) || isNaN(p4) || isNaN(p5) || isNaN(p6)) {
            valid = false;
        }

        if (valid) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * Converts a number with left padding zeros into a float
     * @param string
     * @returns float
     */
    this.parseNumber = function (number) {
        return parseFloat(number.trim().replace('.', '').replace(',', '.'));
    };

    /**
     * Transforms a date in format dd/mm/aaaa in format aaaa-mm-dd
     * @param dateString
     */
    this.parseDate = function (dateString) {
        var date_parts = dateString.split("/");
        return date_parts[2] + "-" + date_parts[1] + "-" + date_parts[0];
    };

    /**
     * Parses data and return the built object
     * @param data
     */
    this.parse = function (data) {

        var columns = this.parser(data);

        var supply = {};

        supply.cups = columns[0].trim().substr(0, 20);
        supply.nt_code = columns[0].trim().substr(20, 2);

        supply.distributor = '0027'; // Forced to EON columns[1].substr(0, 4).trim();

        supply.address = columns[2].trim();
        supply.address += ' ';
        supply.address += columns[3].trim();
        supply.address += ' ';
        supply.address += columns[4].trim();
        supply.address += ' ';
        supply.address += columns[5].trim();
        supply.address += ' ';
        supply.address += columns[6].trim();
        supply.address += ' ';
        supply.address += columns[7].trim();
        supply.address = supply.address.trim().replace('  ', ' ');

        supply.zip = columns[8].trim();

        supply.city = columns[9].trim();
        // 10: municipio
        supply.state = columns[11].trim();
        // 12: UBICACION TIPO ACLARADOR FINCA
        // 13: UBICACION ACLARADOR FINCA
        // 14: UBICACION DUPLICADOR FINCA
        var date_parts = columns[15].trim().split("/");
        supply.contract_started_on = this.parseDate(columns[15].trim());

        switch (columns[16].trim()) {
            // TODO MORE RATES
            case "2.0A":
                supply.rate = 'T20A';
                break;
            case "2.0DHA":
                supply.rate = 'T20ADHA';
                break;
            case "2.1A":
                supply.rate = 'T21A';
                break;
            case "3.0A":
                supply.rate = 'T30A';
                break;
            case "3.1A":
                supply.rate = 'T31A';
                break;
            case "6.1":
                supply.rate = 'T61';
                break;
            case "6.1A":
                supply.rate = 'T61A';
                break;
            case "6.2":
                supply.rate = 'T62';
                break;
            case "6.3":
                supply.rate = 'T63';
                break;
            case "6.4":
                supply.rate = 'T64';
                break;
            case "6.5":
                supply.rate = 'T65';
                break;
        }

        supply.voltage = columns[17].trim();

        supply.max_authorised_power_bie = columns[18].trim();

        supply.max_authorised_power_starting = columns[19].trim();
        // 20: TIPO FRONTERA
        supply.icp_installed = columns[21].trim();

        supply.consumption_profile_type = columns[22].trim();
        // 23: DH
        supply.extension_rights_reconigzed = columns[24].trim();

        supply.access_rights_recognized = columns[25].trim();

        supply.owns_measurement_device = columns[26].trim();

        supply.owns_icp = columns[27].trim();

        supply.power_p1 = this.parseNumber(columns[28].trim()) || 0;
        supply.power_p2 = this.parseNumber(columns[29].trim()) || 0;
        supply.power_p3 = this.parseNumber(columns[30].trim()) || 0;
        supply.power_p4 = this.parseNumber(columns[31].trim()) || 0;
        supply.power_p5 = this.parseNumber(columns[32].trim()) || 0;
        supply.power_p6 = this.parseNumber(columns[33].trim()) || 0;
        supply.power_p7 = 0;
        supply.power_p8 = 0;
        supply.power_p9 = 0;
        supply.power_p10 = 0;

        supply.last_recruitment_change_on = this.parseDate(columns[34].trim());

        supply.last_trading_change_on = this.parseDate(columns[35].trim());

        supply.extensions_rights_deadline_on = this.parseDate(columns[36].trim());

        supply.last_reading_on = this.parseDate(columns[37].trim());

        supply.non_payment = columns[38].trim();

        supply.deposit_amount = this.parseNumber(columns[39].trim());
        supply.deposit_warranty = supply.deposit_amount > 0;

        supply.owner_type = columns[40].trim();

        supply.name = columns[41].trim();

        supply.owner_address = columns[42].trim();
        supply.owner_address += ' '; // Keep calm, index 44 is used in owner_state
        supply.owner_address += columns[44].trim();
        supply.owner_address += ' ';
        supply.owner_address += columns[45].trim();
        supply.owner_address += ' ';
        supply.owner_address += columns[46].trim();
        supply.owner_address += ' ';
        supply.owner_address += columns[47].trim();
        supply.owner_address += ' ';
        supply.owner_address += columns[48].trim();
        supply.owner_address = supply.owner_address.trim().replace('  ', ' ');

        supply.owner_state = columns[43].trim();

        supply.owner_zip = columns[49].trim();

        supply.owner_city = columns[50].trim();

        supply.usual_housing = columns[51].trim();

        /* supply.measurement_point_type = data.substr(550, 2).trim(); // TIPO PUNTO MEDIDA
            supply.available_box_power = data.substr(741, 12).trim(); // POTENCIA DISPONIBLE CAJA
            supply.remote_management_active = data.substr(777, 2).trim(); // TELEGESTIONADO ACTIVO
            supply.measurement_device_change_year = data.substr(779, 4).trim(); // AÑO
            supply.measurement_device_change_quarter = data.substr(783, 1).trim(); // TRIMESTRE */

        return supply;
    };

    this.parser = function (strData, strDelimiter) {

        strDelimiter = (strDelimiter || ";");

        // Create a regular expression to parse the CSV values.
        var objPattern = new RegExp(
            (
                // Delimiters.
            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

                // Quoted fields.
            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

                // Standard fields.
            "([^\"\\" + strDelimiter + "\\r\\n]*))"
            ),
            "gi"
        );

        var arrData = [[]];

        // Create an array to hold our individual pattern
        // matching groups.
        var arrMatches = null;

        // Keep looping over the regular expression matches
        // until we can no longer find a match.
        while (arrMatches = objPattern.exec(strData)) {

            var strMatchedDelimiter = arrMatches[1];

            // Check to see if the given delimiter has a length
            // (is not the start of string) and if it matches
            // field delimiter. If id does not, then we know
            // that this delimiter is a row delimiter.
            if (
                strMatchedDelimiter.length &&
                (strMatchedDelimiter != strDelimiter)
            ) {
                arrData.push([]);
            }


            // Now that we have our delimiter out of the way,
            // let's check to see which kind of value we
            // captured (quoted or unquoted).
            if (arrMatches[2]) {

                // We found a quoted value. When we capture
                // this value, unescape any double quotes.
                var strMatchedValue = arrMatches[2].replace(
                    new RegExp("\"\"", "g"),
                    "\""
                );

            } else {

                // We found a non-quoted value.
                var strMatchedValue = arrMatches[3];

            }

            if(strMatchedValue == undefined)
                strMatchedValue = "";

            arrData[arrData.length - 1].push(strMatchedValue);
        }

        return ( arrData[0] );

    }
}

export {EonParser};
